import React, { useState, useEffect, useMemo, useCallback } from "react";
import JSONSchemaForm from "react-jsonschema-form";
import { JsonForms } from "@jsonforms/react";
import schema from "./schema.json";
import { patientActions } from "../../core/actions";
import { jsonSchema } from "../../shared/schema";
import { ehrActions } from "../../core/actions";
import { useDispatch, useSelector } from "react-redux";
import { setUISchema } from "@jsonforms/core";
import { ecg } from "../../shared/schema/ecg.json";
import { doctorActions } from "../../core/actions";
import {
  List,
  ListItem,
  Paper,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import {
  CFollowupNote,
  DFollowupNote,
  FASTING_SUGAR_TEST,
  SCHEMA_OBJ,
  testNames,
} from "../../shared/constants";
import Components from "..";
import { Box } from "@mui/material";
import { PRODUCT_PHARMACY_LOCATION } from "../../shared/constants";
//import { event } from "react-ga";
// import {handleInputChange } from "../../shared/constants";
import {
  VITAL_SIGNS,
  EPRESCRIPTION,
  DIAGNOSIS,
  KESSLERTEST,
  HEMATOLOGYREPORTCBCDLC,
  BLOODGLUCOSEPROFILE,
} from "../../shared/constants";
// import { Select } from "@mui/material";
import Select, { createFilter } from "react-select";
import { memo } from "react";
// import {Form} from 'react-jsonschema-form';
import { onConvertSchematoJsonBloodSugarProfile } from "../../shared/schema/BloodSugarProfile";

// import formJSON from "form-json";
function PatientSchema(props) {
  const dispatch = useDispatch();
  const [formschema, setFormschema] = useState(false);
  const [formTitle, setFormTitle] = useState();
  const [schemajson, setSchemajson] = useState();
  const [patientId, setPatientId] = useState();
  const [visitId, setVisitId] = useState();
  const [uiSchema, setuiSchema] = useState({});
  const [templateId, setTemplateId] = useState();
  const [icd10, setIcd10] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productDetails, setProductDetails] = useState();
  const patientEhrid = useSelector((state) => state.patientDetailsState.ehrid);
  const [presData, setPresData] = useState([]);

  // const [selectedOption, setSelectedOption] = useState();
  // const [selectedProduct, setSelectedProduct] = useState(null);
  // const [productBrand, setProductBrand] = useState('');
  const [selectProcedureTemplateId, setSelectProcedureTemplateId] = useState();
  const [eprescription, seteprescription] = useState();
  // const[prescriptionValue,setPrescriptionValue]=useState({
  //   localMedicalBrand: '',
  //   instructionstoPatient: '',
  //   advicetoDispenser: '',
  //   comments: ''
  // });

  // const MemoizedExternalModule = memo(JSONSchemaForm);

  const modifyNotes = {
    localMedicalBrand: "",
    instructionstoPatient: "",
    advicetoDispenser: "",
    comments: "",
    problemDiagnosis: "",
    dose: "",
  };

  const modifyVitalNotes = {};

  const [compositionPreviewValue, setCompositionPreviewValue] = useState({
    brandName: "",
    icdName: "",
  });

  // const[icd10Name,setIcd10Name]=useState(
  //   {
  //     icdName:' ',
  //   }
  // );

  // const newIcd10Name = {
  //   ...icd10Name,
  //   icdName:' ',
  // };

  // const { schema, uischema, formData } = props;

  // const memoizedForm = useMemo(() => {
  //   return <Form schema={schema} uiSchema={uischema} formData={formData} />;
  // }, [schema, uiSchema, formData]);

  const procedureSchema = useSelector(
    (state) => state.doctorDetailsState.compositions
  );

  const [filteredIcd10, setFilterdIcd10] = useState();
  const [selectedIcdDescription, setSelectedIcdDescription] = useState();
  const [doctorNotes, setDoctorNotes] = useState();
  const [counsellorNotes, setCounsellorNotes] = useState();
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
  });

  const onError = (errors) => {
    // Handle errors here
    console.error("Form errors:", errors);
    // Display error messages to the user, etc.
  };
  const validateDiagnosis = (formData) => {
    console.log("formdata: ", formData);
    if (formData) {
      const allFieldsEmpty = Object.values(formData).every((value) => !value);

      if (allFieldsEmpty) {
        console.log("All fields in the form are empty!");
        return false;
      } else {
        console.log("At least one field in the form is filled.");
        return true;
      }
    } else {
      console.log("Form data is empty!");
      return false;
    }
  };

  const validateField = (fieldName) => {
    let fieldValidationErrors = formErrors;
    if (fieldName == null) {
      if (props.templateData == EPRESCRIPTION) {
        fieldValidationErrors.name = "Please select the Medicine Brand Name";
      } else if (props.templateData == DIAGNOSIS) {
        fieldValidationErrors.name =
          "Please select the Problem/diaognosis Name";
      }
    }
    setFormErrors({ ...fieldValidationErrors });
  };

  const [dFollowup, setDFollowup] = useState("");
  const [cFollowup, setCFollowup] = useState("");
  const [id, setId] = useState("");

  // const MemoizedExternalModule = memo(JSONSchemaForm);

  //  const memoizedFormSchema = useMemo(() => {
  //   if (formschema === null) {
  //     return null;
  //   }
  //   return jsonSchema.getSchemaFromCanonicalJson(
  //     formschema.resCb,
  //     formschema.props?.templateData,
  //     formschema.props?.flags,
  //     formschema.icd10
  //   );
  // }, [formschema,props,icd10]);

  useEffect(() => {
    console.log("props ::: ", props);
    // const MemoizedExternalModule = memo(JSONSchemaForm);
    const Form = JSONSchemaForm.default;
    var obj1 = {
      Diastolic: {
        type: "string",
        title: "Diastolic..",
        default: "A new task",
      },
    };
    var obj2 = {
      Sistolic: { type: "string", title: "Sistolic...", default: "A new task" },
    };
    console.log("Mode is : ", props.flags, props.templateData);
    if (props.mode == "edit")
      if (
        props.templateData == DFollowupNote ||
        props.templateData == CFollowupNote
      ) {
        setuiSchema();
        setFormschema();
        console.log("Mode is : ", props.flags);
        getFollowupNotes(
          props.selectedProcedure.patientId,
          props.selectedProcedure.visitId,
          props.templateData
        );
      }

    if (props.mode == "new")
      if (
        props.templateData == DFollowupNote ||
        props.templateData == CFollowupNote
      ) {
        setuiSchema();
        setFormschema();
        console.log("Mode is : ", props.flags);
        getFollowupNotes(
          props.patientInfo.id,
          props.patientInfo.visit.id,
          props.templateData
        );
      }
    var obj3 = Object.assign({}, obj1, obj2);
    console.log("procedureSchema :", procedureSchema);
    console.log("finalResult", obj3);
    console.log("props:", props);
    let patientId;
    let visitId;
    // console.log("tepmlateId",props.templateData);
    setTemplateId(props.templateData);
    for (let ids of props.patientData) {
      console.log("ids:", ids);
      patientId = ids.patientId;
      visitId = ids.visitId;
      setPatientId(patientId);
      setVisitId(visitId);
    }
    if (props.flags == "editComposition") {
      console.log("templateData :", props.templateData);
      let data = {};

      dispatch(
        ehrActions.getCompositionByVid(
          data,
          props.templateData,
          patientEhrid,
          (resCb) => {
            if (resCb) {
              let templateForUi;
              if (
                props.selectedProcedure.templateId ==
                resCb.archetype_details.template_id.value
              ) {
                templateForUi = resCb.archetype_details.template_id.value;
              } else {
                templateForUi = props.selectedProcedure.templateId;
              }
              if (templateForUi === "ePrescription (epSoS/Contsys)") {
                console.log("API Response :", resCb);
                console.log(
                  "data for prescription : ",
                  resCb.content[0].activities[0].description.items
                );
                let val = [];
                val = resCb.content[0].activities[0].description.items;
                setPresData(val);
              }
              console.log("resc :", templateForUi);
              // console.log("productBrand",productBrand.brandName);
              let form = jsonSchema.getSchemaFromCanonicalJson(
                resCb,
                templateForUi,
                props.flags,
                compositionPreviewValue
              );
              setCompositionPreviewValue(compositionPreviewValue);
              console.log(
                "form, compositionpreviewvalue: ",
                form,
                compositionPreviewValue
              );
              let frm = jsonSchema.getSchemaFromCanonicalJson(
                resCb,
                templateForUi,
                "editComposition"
              );
              console.log("frm ::: ", frm);
              seteprescription(frm);
              // console.log("")
              // productBrand,
              //   icd10Name
              // setproductBrand(productBrand);
              // setIcd10Name(icd10Name);
              // console.log("seticd10Name",icd10Name);
              // console.log("seticd10Name",icd10Name.icdName);
              // console.log("setProductBrand",productBrand);
              // console.log("productBrand",productBrand.brandName);

              console.log("templateforui: ", templateForUi);
              console.log(
                "setSelectProcedureTemplateId",
                props.selectedProcedure.templateId
              );
              setSelectProcedureTemplateId(props.selectedProcedure.templateId);
              console.log("formrrr", form);
              // dispatch(patientActions.setForm(form));
              setFormschema(form);
              setSchemajson(resCb);
              formUiSchema(templateForUi, true);
              console.log("formschema1", formschema, schemajson);
            }
          }
        )
      );
    } else {
      // let formschema = jsonSchema.getSchemaFromJson(schema)
      let data = {};
      let data1 = {};

      dispatch(
        ehrActions.getTemplateById(data, props.templateData, (resCb) => {
          if (resCb) {
            console.log("templateData : ", props.templateData);
            console.log("Diagnosis Master API Response:", resCb);
            console.log(
              "template data in gettemplate method:",
              props.templateData
            );
            if (props.templateData && props.templateData == DIAGNOSIS) {
              dispatch(
                ehrActions.getIcd10Values(data1, (resCb1) => {
                  if (resCb1) {
                    console.log("icd10 list is", resCb1.icd10List);
                    setIcd10([...resCb1.icd10List]);
                    let form = jsonSchema.getSchemaFromCanonicalJson(
                      resCb,
                      props.templateData,
                      props.flags,
                      resCb1.icd10List
                    );
                    let frm = jsonSchema.getSchemaFromCanonicalJson(
                      resCb,
                      props.templateData,
                      "editComposition"
                    );
                    console.log("frm ::: ", frm);
                    seteprescription(frm);
                    // setFormschema({
                    //   resCb: resCb,
                    //   props: props,
                    //   icd10: resCb1.icd10List,
                    // });
                    console.log("templateid: ", templateId);
                    console.log("form obtained:", form);
                    setFormschema(form);
                    setSchemajson(resCb);
                    formUiSchema(props.templateData, false);
                    console.log("formschema1", formschema);
                  }
                })
              );
            } else if (
              props.templateData &&
              props.templateData == EPRESCRIPTION
            ) {
              let productsearch = "dol";
              dispatch(
                doctorActions.getProductDetails(
                  productsearch,
                  PRODUCT_PHARMACY_LOCATION,
                  (productResponse) => {
                    if (productResponse) {
                      console.log(
                        "Response of searched product",
                        productResponse.erpProductList
                      );
                      console.log("schema is ", resCb);
                      const form = jsonSchema.getSchemaFromCanonicalJson(
                        resCb,
                        props.templateData,
                        props.flags,
                        productDetails
                      );
                      let frm = jsonSchema.getSchemaFromCanonicalJson(
                        resCb,
                        props.templateData,
                        "editComposition"
                      );
                      console.log("frm ::: ", frm);
                      seteprescription(frm);
                      console.log("form obtained:", form);
                      setFormschema(form);
                      setSchemajson(resCb);
                      formUiSchema(props.templateData, false);
                      console.log("formschema1", formschema);
                    }
                  }
                )
              );
            } else {
              console.log("entered else condition");
              let form = jsonSchema.getSchemaFromCanonicalJson(
                resCb,
                props.templateData,
                props.flags
              );

              console.log("form obtained:", form);
              console.log("template data", props.templateData);

              if (props.templateData == "Vital signs" && props.mode == "new") {
                console.log("templatedata", props.templateData);
                filterFormVitals(form);
              }

              setFormschema(form);
              setSchemajson(resCb);
              formUiSchema(props.templateData, false);
              console.log("formschema1", formschema);
            }
          }
        })
      );

      const filterFormVitals = (form) => {
        console.log("filterformvitals");
        console.log("form: ", form);

        for (let property in form.properties) {
          if (
            form.properties.hasOwnProperty(property) &&
            property === "Body mass index"
          ) {
            console.log("body mass", property);
            delete form.properties[property];
          }
        }
        console.log("form after deletion of bmi: ", form);
      };

      //     }
      // }))
      // let formschema = jsonSchema.getSchemaFromCanonicalJson(tem)
      // let formschema = jsonSchema.getSchemaFromPrescJson(schema)
      // let formschema = jsonSchema.getSchemaFromDiagnoJson(schema)

      // console.log("formschema2", formschema);
      // if (!memoizedFormSchema) {
      //   return null;
      // }

      // setFormschema(formschema)
    }
  }, [props]);

  const getFollowupNotes = (pid, vid, temp) => {
    dispatch(
      doctorActions.getFollowupNotes(pid, vid, temp, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb);
          console.log("resCbe : ", resCb);
          // patientDetail['scheduleddate'] = resCb.scheduleddate;
          if (temp == DFollowupNote) {
            setDFollowup(resCb.procedure.notes);
          } else {
            setCFollowup(resCb.procedure.notes);
          }
          console.log("followup note is : ", dFollowup, cFollowup);
          setId(resCb.procedure.id);
        }
      })
    );
  };

  const formUiSchema = (templateId, readOnly) => {
    console.log("DIAGNOSIS_OBJ:", SCHEMA_OBJ);
    console.log("props: ", props);

    Object.keys(SCHEMA_OBJ).forEach((key) => {
      console.log("key, templateid: ", templateId, key);
      if (key == templateId) {
        let schema = SCHEMA_OBJ[key];
        console.log("schema", schema);
        //console.log("enteredthis")
        Object.keys(schema).forEach((key) => {
          //console.log("entered inside this")
          schema[key]["ui:readonly"] = readOnly;

          // schema[key]["ui:widget"]="select"
        });
        console.log("filterd:", schema);
        setuiSchema({ ...schema });
        console.log("ui schema fromed:", schema);
        setuiSchema({ ...schema });
        console.log("ui schema fromed:", schema);
      }
    });
  };

  const onFormSubmit = (event) => {
    console.log("onFormSubmitevents", event);
    //console.log("jsonschemasss",jsonSchema)
    console.log("---Form Submited---", event.formData);
    // schema.archetype_details.template_id.value
    // schemajson
    if (schemajson.archetype_details.template_id.value === KESSLERTEST) {
      onConvertSchematoJsonkesslers(event.formData);
    } else if (
      schemajson.archetype_details.template_id.value === EPRESCRIPTION
    ) {
      validateField(productDetails);
      if (formErrors.name == "") {
        console.log("Converting schema to ePrescription format");
        // console.log("formErrors.name",formErrors.name);
        onConvertSchematoJsonprescription(event.formData);
        // event.formData['Local medicinal product brand']=productDetails;
      }
    } else if (schemajson.archetype_details.template_id.value === DIAGNOSIS) {
      // validateField(selectedIcdDescription);
      // if (formErrors.name == "") {
      if (!validateDiagnosis(event.formData)) {
        setShowError(true);
        return;
      } else {
        setShowError(false);
        onConvertSchematoJsonDiagnosis(event.formData);
      }
      // }
    } else if (schemajson.archetype_details.template_id.value === VITAL_SIGNS) {
      if (!validateDiagnosis(event.formData)) {
        setShowError(true);
        return;
      } else {
        setShowError(false);   
        onConvertSchematoJsonVitals(event.formData);
      }
    } else if (schemajson.archetype_details.template_id.value === "k_problem") {
      onConvertSchematoJsonk_problems(event.formData);
    } else if (
      schemajson.archetype_details.template_id.value === BLOODGLUCOSEPROFILE
    ) {
      console.log("imhere");
      onConvertSchematoJsonBloodSugarProfile(event.formData);
    } else if (
      schemajson.archetype_details.template_id.value === "Laboratory Report"
    ) {
      console.log("hehhe");
      onConvertSchematoJsonLabReports(event.formData);
    }
  };

  let saveFormData = {};
  const onChanges = (formData, newErrorSchema) => {
    console.log("Onchanges fired");
    console.log("formonchanges", formData.formData);

    saveFormData = formData.formData;
    console.log("saveformdata: ", saveFormData);
  };

  // const memoizedValue = useMemo(() => onChanges(), []);
  console.log(",saveFormDatasaveFormData", saveFormData);

  const saveAndAddNewSubmit = () => {
    let data = {};
    let productsearch = "dol";
    console.log("---saveAndAddNewSubmitForm---", saveFormData);
    if (schemajson.archetype_details.template_id.value === KESSLERTEST) {
      onConvertSchematoJsonkesslers(saveFormData);
    } else if (
      schemajson.archetype_details.template_id.value === EPRESCRIPTION
    ) {
      validateField(productDetails);
      if (formErrors.name == "") {
        console.log("Converting schema to ePrescription format");
        // console.log("formErrors.name",formErrors.name);
        onConvertSchematoJsonprescription(saveFormData, "add new");
        dispatch(
          ehrActions.getTemplateById(data, props.templateData, (resCb) => {
            if (resCb) {
              console.log("Diagnosis Master API Response:", resCb);
              console.log(
                "template data in gettemplate method:",
                props.templateData
              );
              dispatch(
                doctorActions.getProductDetails(
                  productsearch,
                  PRODUCT_PHARMACY_LOCATION,
                  (productResponse) => {
                    if (productResponse) {
                      console.log(
                        "Response of searched product",
                        productResponse.erpProductList
                      );
                      console.log("schema is ", resCb);
                      let form = jsonSchema.getSchemaFromCanonicalJson(
                        resCb,
                        props.templateData,
                        props.flags,
                        productDetails
                      );
                      console.log("form obtained:", form);
                      setFormschema(form);
                      setSchemajson(resCb);
                      formUiSchema(props.templateData, false);
                      console.log("formschema1", formschema);
                    }
                  }
                )
              );
            }
          })
        );
      }
      setProductDetails("");
    } else if (schemajson.archetype_details.template_id.value === DIAGNOSIS) {
      validateField(selectedIcdDescription);
      if (formErrors.name == "") {
        onConvertSchematoJsonDiagnosis(saveFormData);
      }
    } else if (schemajson.archetype_details.template_id.value === VITAL_SIGNS) {
      onConvertSchematoJsonVitals(saveFormData);
    }
  };

  const onConvertSchematoJsonDiagnosis = (data) => {
    console.log("jsonconvert:", data);

    console.log("schemajson:", schemajson);
    for (let content of schemajson.content) {
      console.log("content : ", content);
      for (let items of content.data.items) {
        console.log("items : ", items);
        if (items.name && items.name.value) {
          if (items.name.value == "Problem/Diagnosis name") {
            // modifyElement(items.name.value,selectedIcdDescription);
            // items.value.value=selectedIcdDescription;
            // data[items.name.value] = selectedIcdDescription;
            console.log("data[items.name.value]", data[items.name.value]);
            items.value.value = "Diagnosis";
            console.log(
              "modifyNotes.problemDiagnosis",
              modifyNotes.problemDiagnosis
            );
          } else if (items.name.value == "Clinical description") {
            items.value.value = data[items.name.value]
              ? data[items.name.value]
              : "NA";
            console.log("Diagnosis", items.value.value);
            modifyNotes.problemDiagnosis = items.value.value;
            console.log(
              "modifyNotes.problemDiagnosis",
              modifyNotes.problemDiagnosis
            );
          } else {
            console.log("log2:", data[items.name.value]);
            // items.value.value = data[items.name.value];
            items.value.value = data[items.name.value]
              ? data[items.name.value]
              : "NA";
          }
        } else {
          console.log("Unable to access item name or value");
        }
      }
    }
    onFormChange(schemajson);
  };

  const [bmipara, setbmipara] = useState({
    weight: "",
    height: "",
  });

  const onConvertSchematoJsonVitals = (data) => {
    console.log("jsonconvert:", data);
    
    if (data.hasOwnProperty("Height/Length")) {
      console.log("data height: ", data["Height/Length"])
      bmipara.height = data["Height/Length"] >=100  && data["Height/Length"]!=undefined ? data["Height/Length"] : 100
    }
    else{
      bmipara.height = 100
    }

    if (data.hasOwnProperty("Weight")) {
      console.log("data Weight: ", data["Weight"])
      bmipara.weight = data["Weight"] >=1 && data["Weight"]!=undefined ? data["Weight"] : 100
    }
    else{
      bmipara.weight   = 100
    }



    // for (let entry in data) {
    //   if (entry === "Height/Length") {
    //     console.log("data[entry]: ", data[entry])
    //     bmipara.height = data[entry] ? data[entry]:150 ;
    //     console.log(bmipara);
    //   }
    //   if (entry === "Weight") {
    //     bmipara.weight = data[entry]? data[entry]:60;
    //     console.log(bmipara);

    //   }
    //   else
  
    console.log(bmipara);

    console.log("schemajson:", schemajson);
    for (let content of schemajson.content) {
      console.log("content : ", content);
      for (let item of content.data.events) {
        for (let it of item.data.items) {
          console.log("it :", it);
          let elementName1 = it.name.value;
          console.log("elementName1 :", elementName1);
          // console.log("daya : ", data[elementName1]);
          // it.value.magnitude = data[elementName1];
          switch (elementName1) {
            case "Temperature":
              data[elementName1]=data[elementName1]?data[elementName1]:97;
              console.log("Temperature", data[elementName1])
              const celsius = parseFloat(data[elementName1]);
              const fahrenheit = convertToFahrenheit(celsius);
              console.log(
                "Temperature in Fahrenheit::",
                ` ${fahrenheit.toFixed(2)}`
              );
              data[elementName1] = fahrenheit.toFixed(2);
              it.value.magnitude = data[elementName1];
              modifyVitalNotes[elementName1] = it.value.magnitude;
              break;

            case "Systolic":

            case "Diastolic":

            case "Pulse Rate":

            case "Height/Length":

            case "Weight":

            case "Respiratory Rate":
              data[elementName1]=data[elementName1]?data[elementName1]:0;
              console.log("value:", data[elementName1]);
              it.value.magnitude = data[elementName1];
              modifyVitalNotes[elementName1] = it.value.magnitude;

              break;

            case "Oxygen Saturation Level (SpO₂)":
              data[elementName1]=data[elementName1]?data[elementName1]:0;
              console.log("value", data[elementName1]);
              it.value.numerator = data[elementName1];
              modifyVitalNotes[elementName1] = it.value.numerator;
              break;

            case "Body mass index":
              //data[elementName1]=data[elementName1]?data[elementName1]:17;
              //console.log(calculateBmi(bmipara))
              const bmi = calculateBmi(bmipara);
              console.log("bmi", bmi);
              data[elementName1] = bmi;
              it.value.magnitude = data[elementName1];
              modifyVitalNotes[elementName1] = it.value.magnitude;
              break;

            // other cases
          }
        }
      }
    }
    onFormChange(schemajson);
  };

  function convertToFahrenheit(fahrenheit) {
    const celsius = ((fahrenheit - 32) * 5) / 9;
    return celsius;
  }

  function calculateBmi(bmipara) {
    const height = parseFloat(bmipara.height) / 100;
    const weight = parseFloat(bmipara.weight);
    const bmi = (weight / (height * height)).toFixed(1);
    console.log(height, weight, bmi)
    return bmi;
  }

  const onConvertSchematoJsonk_problems = (data) => {
    console.log("jsonconvert:", data);
    console.log("schemajson:", schemajson);
    for (let content of schemajson.content) {
      console.log("content : ", content);
      for (let item of content.data.events) {
        //  console.log('item:',item)
        for (let it of item.data.items) {
          console.log("it :", it);
          let elementName1 = it.name.value;
          console.log("elementName1 :", elementName1);
          console.log("values :", data[elementName1]);
          // it.value.magnitude = data[elementName1];
        }
      }
    }
    onFormChange(schemajson);
  };

  const onConvertSchematoJsonkesslers = (data) => {
    console.log("jsonconvert:", data);
    console.log("schemajson:", schemajson);
    for (let content of schemajson.content) {
      console.log("content : ", content.data);
      for (let events of content.data.events) {
        console.log("events :", events);
        for (let items of events.data.items) {
          console.log("items:", items);
          items.value.value = data[items.name.value].split("-")[0];
          items.name.value = data[items.name.value].split("-")[1];
          console.log("items:", items);
          console.log("schemajson:", schemajson);
          setSchemajson(schemajson);
        }
      }
    }
    onFormChange(schemajson);
  };

  const onConvertSchematoJsonFastingBloodSugar = (data) => {
    console.log("jsonconvert:", data);
    console.log("schemajson:", schemajson);
    for (let content of schemajson.content) {
      console.log("content : ", content.data);
      for (let events of content.data.events) {
        console.log("events :", events);
        for (let items of events.data.items) {
          console.log("items:", items);
          // items.value.value = data[items.name.value].split("-")[0];
          // items.name.value = data[items.name.value].split("-")[1];
          console.log("items:", items);
          console.log("schemajson:", schemajson);
          setSchemajson(schemajson);
        }
      }
    }
    onFormChange(schemajson);
  };

  const onConvertSchematoJsonBloodSugarProfile = (data) => {
    let elements;
    let elementName1, elementName2;
    console.log("data:", data);
    console.log("jsonconvert:", data);
    console.log("schemajson:", schemajson);

    for (let content of schemajson.content) {
      console.log("content : ", content.data);
      for (let events of content.data.events) {
        console.log("events :", events);
        for (let items of events.data.items) {
          console.log("items:", items);
          elements = items.name.value;
          console.log("element: ", elements);
          switch (elements) {
            case "Test":
              elementName1 = items.value.value;
              console.log("elementName1: ", elementName1);
              break;

            case "Result Group":
              for (let i of items.items) {
                console.log("i: ", i);
                elementName2 = i.name.value;
                console.log("elementName2: ", elementName2);
                console.log("value:", data[elementName1][elementName2]);

                if (elementName2 === "Result") {
                  i.value.magnitude = data[elementName1][elementName2];
                } else if (elementName2 === "Comment") {
                  i.value.value = data[elementName1][elementName2];
                }
              }

              break;
          }
          console.log("schemajson:", schemajson);
        }
      }
    }
    onFormChange(schemajson);
  };

  const onConvertSchematoJsonLabReports = (data) => {
    let elements, testName;
    let elementName1, elementName2;
    console.log("data:", data);
    console.log("jsonconvert:", data);
    console.log("schemajson:", schemajson);

    for (let content of schemajson.content) {
      console.log("content : ", content.data);
      for (let events of content.data.events) {
        console.log("events :", events);
        for (let items of events.data.items) {
          console.log("items:", items);
          elements = items.name.value;
          console.log("element: ", elements);
          switch (elements) {
            case "Result Groups":
              for (let i of items.items) {
                console.log("i: ", i);
                elementName2 = i.name.value;
                console.log("elementName2: ", elementName2);
                //console.log("value:", data[elementName1][elementName2]);
                if (elementName2 === "Analyte Name") {
                  testName = i.value.value;
                  //console.log("testname: ",testName)
                  console.log("value: ", data[testName]);
                }
                if (elementName2 === "Result") {
                  i.value.magnitude = data[testName]["Result"];
                } else if (elementName2 === "Comment") {
                  i.value.value = data[testName]["Comment"];
                }
              }

              break;
          }
          console.log("schemajson:", schemajson);
        }
      }
    }
    onFormChange(schemajson);
  };

  const onConvertSchematoJsonprescription = (data, flag) => {
    console.log("jsonconvert:", data);
    // if(data["Instructions to patient"])
    console.log("schema :", schemajson);
    for (let content of schemajson.content) {
      for (let events of content.activities) {
        for (let items of events.description.items) {
          console.log("itms", items);
          if (items._type == "CLUSTER") {
            for (let item of items.items) {
              if (item._type === "CLUSTER") {
                for (let i of item.items) {
                  console.log("i: ", i);
                  let elementName1 = i.name.value;
                  i.value.value = data[elementName1];

                  modifyElement(elementName1, i.value.value);
                }
              } else {
                console.log("field", item.name.value, data[item.name.value]);
                modifyCluster(item.name.value, data[item.name.value]);
                item.value.value = data[item.name.value];
                console.log(
                  "data of advice to dispencer after",
                  item.name.value,
                  data[item.name.value]
                );
              }
            }
          }
          if (items._type == "ELEMENT") {
            console.log("element is", items._type);
            // console.log("items befoe", items.name.value,data[items.name.value])
            if (items.name.value == "Local medicinal product brand") {
              modifyElement(items.name.value, productDetails);
              data[items.name.value] = productDetails;
              items.value.value = productDetails;
            } else if (items.name.value == "Instructions to patient") {
              if (!data.hasOwnProperty("Instructions to patient")) {
                data["Instructions to patient"] = " ";
                items.value.value = data["Instructions to patient"];
                console.log("data: ", data);
              } else {
                items.value.value = data[items.value.value];
              }
            }
            modifyElement(items.name.value, data[items.name.value]);
            items.value.value = data[items.name.value];
            console.log("itemsafter", items.name.value, data[items.name.value]);
          }
        }
      }
    }
    console.log("onformchangeschemajson", schemajson);
    onFormChange(schemajson, flag);
  };

  const modifyCluster = (feildname, filedvalue) => {
    if (feildname == "Advice to dispenser") {
      modifyNotes.advicetoDispenser = filedvalue;
    }
  };

  const modifyElement = (feildname, filedvalue) => {
    console.log("feildname,filedvalue", feildname, filedvalue);
    if (feildname == "Local medicinal product brand") {
      modifyNotes.localMedicalBrand = filedvalue;
    } else if (feildname == "Instructions to patient") {
      if (filedvalue === null || filedvalue === "") {
        modifyNotes.instructionstoPatient = "";
      } else {
        modifyNotes.instructionstoPatient = filedvalue;
      }
    } else if (feildname == "Comment") {
      modifyNotes.comments = filedvalue;
    } else if (feildname == "Dose unit") {
      modifyNotes.dose = filedvalue;
    }
  };

  const onFormChange = (data, flag) => {
    console.log("---Form changed---", data);
    console.log("---patientEhrid---", patientEhrid);

    // console.log("prescription value",prescriptionValue);
    // console.log("modifynotes",modifyNotes);
    // const  prescriptionDetails=modifyNotes.localMedicalBrand+' '+' '+modifyNotes.advicetoDispenser+' '+modifyNotes.instructionstoPatient;
    // const prescriptionDetails=modifyNotes.problemDiagnosis;
    let notesDetails = " ";
    if (props.templateData == EPRESCRIPTION) {
      notesDetails =
        modifyNotes.localMedicalBrand +
        " " +
        " " +
        " " +
        modifyNotes.dose +
        " " +
        modifyNotes.advicetoDispenser +
        " " +
        modifyNotes.instructionstoPatient;
      console.log("prescriptionDetails", notesDetails);
    } else if (props.templateData == DIAGNOSIS) {
      notesDetails = modifyNotes.problemDiagnosis;
      console.log("prescriptionDetails", notesDetails);
    } else if (props.templateData == VITAL_SIGNS) {
      notesDetails =
        "Temperature: " +
        modifyVitalNotes["Temperature"] +
        " Diastolic: " +
        modifyVitalNotes["Diastolic"] +
        " Height/Length: " +
        modifyVitalNotes["Height/Length"] +
        " Pulse Rate :" +
        modifyVitalNotes["Pulse Rate"] +
        " Respiratory Rate:" +
        modifyVitalNotes["Respiratory Rate"] +
        " Systolic: " +
        modifyVitalNotes["Systolic"] +
        " Weight: " +
        modifyVitalNotes["Weight"] +
        " Oxygen Saturation Level: " +
        modifyVitalNotes["Oxygen Saturation Level (SpO₂)"];
      //+'Pulse Rate'+modifyVitalNotes["Pulse Rate"]+'Respiratory Rate'+modifyVitalNotes["Respiratory Rate"]+'Systolic'+modifyVitalNotes["Systolic"]+'Weight'+modifyVitalNotes["Weight"];
      console.log("prescriptionDetails", notesDetails);
    }

    //  console.log("prescriptionDetails",prescriptionDetails)
    // const prescriptionDetails='';
    dispatch(
      ehrActions.saveComposition(
        data,
        patientId,
        visitId,
        templateId,
        patientEhrid,
        notesDetails,
        (resCb) => {
          if (resCb) {
            console.log("Diagnosis Master API Response:", resCb);
            // setSelectedPatientInfo(resCb)
            console.log("forjjfnjgjgi", flag);
            if (resCb.successMessages) {
              if (flag == undefined && flag != "add new") {
                props.onModalClose({ success: resCb.successMessages[0] });
              }
            } else {
              if (resCb) {
                console.log("resCb  ::: ", resCb);
                if (flag == undefined && flag != "add new") {
                  let msg = templateId + " save failed";
                  console.log("msg  ::: ", msg);
                  props.onModalClose({ error: msg });
                }
              }
            }
          }
        }
      )
    );
  };

  const presSubmit = (event) => {
    props.onModalClose(event);
  };

  const productChange = (event) => {
    //  console.log("change values",event);
    console.log("change values", event.target.value);

    let searchKeyWord = event.target.value;
    setProductDetails(searchKeyWord);

    console.log("productkeyword:", productDetails);
    if (searchKeyWord && searchKeyWord.length > 2) {
      dispatch(
        doctorActions.getProductDetails(
          searchKeyWord,
          PRODUCT_PHARMACY_LOCATION,
          (productResponse) => {
            if (productResponse) {
              console.log(
                "Response of searched product",
                productResponse.erpProductList
              );
              setProductList([...productResponse.erpProductList]);
            }
          }
        )
      );
    } else {
      console.log("enters the else statement");
      setProductList([]);
    }
    // event.preventDefault();
    // event.stopPropagation();
    console.log("onDragOver:", event);
  };

  const selectProduct = (product) => {
    console.log("selectProduct", product);
    let productName = product.name;
    //product.preventDefault();
    setProductDetails(productName);
    console.log("productdetails: ", productDetails);
    setProductList("");
    formErrors.name = "";
    setFormErrors(formErrors);

    // setSelectedProduct(product);
  };

  const icd10Change = (event) => {
    //  console.log("change values",event);
    console.log("icd10changevalues", event.target.value);
    console.log("hhdcj", icd10);
    let searchKeyWord = event.target.value;
    setSelectedIcdDescription(searchKeyWord);
    if (searchKeyWord && searchKeyWord.length >= 1) {
      const filtered = icd10.filter((icd) =>
        icd.description.toLowerCase().startsWith(searchKeyWord.toLowerCase())
      );
      setFilterdIcd10(filtered);
      console.log("filteredIcd10", filteredIcd10);
    }
  };

  const selctedIcd = (icd) => {
    console.log("selectProduct", icd);
    let icdName = icd.description;
    setSelectedIcdDescription(icdName);
    setFilterdIcd10(" ");
    formErrors.name = "";
    setFormErrors(formErrors);
  };

  // const memoizedSchema = useMemo(() => formschema, [formschema]);
  // const memoizedUiSchema = useMemo(() => uiSchema, [uiSchema]);

  // const { schema, uischema} = props;
  // const memoizedForm = useMemo(() => (
  //   <JSONSchemaForm schema={schema} uiSchema={uischema} />
  //  ), [schema, uiSchema]);

  //  return memoizedForm;
  const handleSubmit = (e) => {
    if (cFollowup != null || dFollowup != null) {
      var proList = {};
      var note = dFollowup != "" ? (note = dFollowup) : (note = cFollowup);
      if (props.mode == "edit")
        proList = {
          id: props.selectedProcedure.id,
          templateId: templateId,
          notes: note,
          patientId: props.selectedProcedure.patientId,
          visitId: props.selectedProcedure.visitId,
        };

      if (props.mode == "new")
        proList = {
          id: id,
          templateId: templateId,
          notes: note,
          patientId: props.patientData[0].patientId,
          visitId: props.patientData[0].visitId,
        };
    }
    dispatch(
      doctorActions.addFollowupNotes(proList, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb);
          if (resCb.successMessages) {
            props.onModalClose({ success: "Notes added successfully" });
          } else {
            props.onModalClose({ error: "Failed to add notes" });
          }
          console.log("resCbe : ", resCb);
        }
      })
    );
  };

  return (
    <>
      <div>
        <div>
          <List
            component="nav"
            aria-label="main mailbox folders"
            className={props.mode === "edit" ? "hide-submit" : "template-form"}
          >
            <ListItem className="d-block py-3 px-0 bg-none">
              <div className="mt-2 shadow service-list-container">
                {productList &&
                  productList.length > 0 &&
                  productList.map((product, index) => (
                    <Paper
                      key={index}
                      className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                      onClick={(event) => selectProduct(product, index)}
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        {product.name}
                      </Typography>
                    </Paper>
                  ))}
              </div>

              {(templateId === DIAGNOSIS ||
                selectProcedureTemplateId === DIAGNOSIS) && (
                <>
                  {props.mode === "edit" &&
                    selectProcedureTemplateId === DIAGNOSIS && (
                      <Grid container style={{ marginBottom: "10px" }}>
                        {/*<Grid item sm={12} md={6}>
                  <Typography variant="body1" className="brand">
                    Problem/Diagnosis name
                  </Typography>
                  <TextField className="templateSearch"
                  fullWidth
                    value={compositionPreviewValue.icdName}
                    readOnly
                  />
                </Grid>*/}
                      </Grid>
                    )}

                  {(props.mode === "new" || templateId === DIAGNOSIS) && (
                    <Grid container style={{ marginBottom: "10px" }}>
                      {/* <Grid item sm={12} md={6}>
                  <Typography variant="body1" className="brand">
                    Problem/Diagnosis name
                  </Typography>
                  <TextField className="templateSearch"
                  fullWidth
                    value={selectedIcdDescription}
                    placeholder="Select the Problem/Diagnosis name"
                    required
                    autoComplete="off"
                    onChange={icd10Change}
                    error={formErrors.name !== ""}
                    helperText={formErrors.name}
                  />
                </Grid> */}
                    </Grid>
                  )}
                </>
              )}
              {(selectProcedureTemplateId == DFollowupNote ||
                templateId == DFollowupNote) && (
                <>
                  <Box py={3} pl={0} width="100%">
                    <label className="brand">Doctor's Followup Notes</label>
                    <TextField
                      fullWidth
                      multiline
                      className="p-2"
                      value={dFollowup}
                      placeholder="Followup Notes"
                      autoComplete="off"
                      type="text"
                      onChange={(event) => setDFollowup(event.target.value)}
                    />
                  </Box>
                  <Box p={3} width="100%" textAlign="right">
                    <Button
                      variant="contained"
                      color="primary"
                      className="p-2 border rounded"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              )}
              {(selectProcedureTemplateId == CFollowupNote ||
                templateId == CFollowupNote) && (
                <>
                  <Box py={3} pl={0} width="100%">
                    <label className="brand">Counsellor's Followup Notes</label>
                    <TextField
                      fullWidth
                      multiline
                      className="p-2"
                      value={cFollowup}
                      placeholder="Followup Notes"
                      autoComplete="off"
                      type="text"
                      onChange={(event) => setCFollowup(event.target.value)}
                    />
                  </Box>
                  <Box p={3} width="100%" textAlign="right">
                    <Button
                      variant="contained"
                      color="primary"
                      className="p-2 border rounded"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              )}

              <div>
                <Grid container>
                  <Grid item md="12" lg="12" className="px-0">
                    {formschema ? (
                      templateId === EPRESCRIPTION ||
                      selectProcedureTemplateId === EPRESCRIPTION ? (
                        <div className="">
                          <Components.Prescription
                            schema={formschema}
                            data={presData}
                            mode={props.mode}
                            patientId={patientId}
                            patientInfo={props.patientInfo}
                            visitId={visitId}
                            form={eprescription}
                            onModalClose={(event) => presSubmit(event)}
                          />
                        </div>
                      ) : props.mode === "new" || templateId === DIAGNOSIS ? (
                        <>
                          {showError && (
                            <div className="text-danger my-4">
                              All fields can not be empty!
                            </div>
                          )}
                          <JSONSchemaForm
                            onSubmit={onFormSubmit}
                            schema={formschema}
                            uiSchema={uiSchema}
                            onChange={onChanges}
                          />
                        </>
                      ) : (
                        <JSONSchemaForm
                          onSubmit={onFormSubmit}
                          schema={formschema}
                          uiSchema={uiSchema}
                          onChange={onChanges}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
            </ListItem>
          </List>
        </div>
      </div>
    </>
  );
}
export default PatientSchema;
// export default React.memo(PatientSchema);
