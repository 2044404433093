import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import BtnCellRenderer from '../../common/BtnCellRenderer';

import Select, { createFilter } from "react-select";
import Components from "../insurance/index";
import { insuranceActions } from '../../../core/actions';
// import { Row,FormInput,Button} from "shards-react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {  InputAdornment, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



function InsuranceMasterList(props){

const dispatch = useDispatch();    




const [searchText, setSearchText] = useState("");

const [insuranceSearchList, setinsuranceSearchList] = useState({});
const [insuranceMasterSearchFilterList, setinsuranceMasterSearchFilterList] = useState({});
const [addInsuranceMaster, setInsuranceMaster] = useState(false);




const handleServices = (service) => {
console.log("service selected :",service);
}
const clearSearch = (event) => {
  setSearchText("");
}

    useEffect(() => {
   
 
             dispatch(insuranceActions.getInsuranceMaster( (resCb) => {
          if (resCb) {
            console.log("getInsuranceMaster", resCb.masterinsurancelist);
            setinsuranceSearchList(resCb.masterinsurancelist);
            setinsuranceMasterSearchFilterList(resCb.masterinsurancelist);
            console.log("response from api : ", resCb);
       
          }
        })
      );

      //setting opening modal 
   




    },[]);
    const closeServiceModal = (event) =>{
        props.onModalClose(event)
      }

      const handleClick = (pDetail, isAddNew) => {
        setInsuranceMaster(!addInsuranceMaster);
        console.log("props.history",props);
        props.history.push("/insurancemaster");
      }

      const handleInputChange = (event) => {
        let emptymsg = {};
        // setMessageDetails({message: null});
        let value = event.target.value;
        validateAndChange(value);
        // sessionStorage.setItem("searchvalue", JSON.stringify(value));
        console.log("value...",value);

      };
    
      const validateAndChange = (value) => {
        let nullarray = [];
    
        if (value.length < 3) {
          // setinsuranceSearchList(nullarray);
          setSearchText(value);
        //   setSearchError("Search Text should contain atleast 3 letters");
          // messageDetails.messageStrip='';
        //   setShowMsgStrip(false);
          setinsuranceMasterSearchFilterList(insuranceSearchList);

        }
        if (!value) {
          // messageDetails.messageStrip='';
          // setinsuranceSearchList(nullarray);
          console.log(
            "insuranceSearchList null array !value : ",
            value,
            insuranceSearchList
          );
        //   setShowMsgStrip(false);
          setinsuranceMasterSearchFilterList(insuranceSearchList);

        }
        //  else {
        if (value.length >= 3) {
        //   setSearchError("");
          setSearchText(value);
          // handleSearch(value);
        //   setShowList(true);
          let search = { patient: value };
          console.log("search input : ", value);
          searchInsuranceResult(value);
        }
        // }
      };
      const getFullName = (fname, mname, lname) => {
        var fullName = "";
        console.log("fname : ", fname);
        console.log("mname : ", mname);
        console.log("lname : ", lname);
        if (!fname || fname == null) {
          fname = "";
        }
        if (!mname || mname == null) {
          mname = "";
        }
        if (!lname || lname == null) {
          lname = "";
        }
        var pfname = fname;
        console.log("pfname : ", pfname);
        //   .toLowerCase();
        var pmname = mname;
        console.log("pmname : ", pmname);
        //   .toLowerCase();
        var plname = lname;
        console.log("plname : ", plname);
        //   .toLowerCase();
        if (pfname == null) {
          pfname = "";
        }
        if (pmname == null) {
          pmname = "";
        }
        if (plname == null) {
          plname = "";
        }
        pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1);
        pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1);
        plname = plname.charAt(0).toUpperCase() + plname.slice(1);
        if (fname && lname) {
          fullName = pfname + " " + plname;
          console.log("fullName : ", fullName);
        } else if (fname && mname) {
          fullName = pfname + " " + pmname;
          console.log("fullName : ", fullName);
        } else if (fname && mname && lname) {
          fullName = pfname + " " + pmname + " " + plname;
          console.log("fullName : ", fullName);
        } else {
          fullName = pfname + "      ";
          console.log("fullName : ", fullName);
        }
        return fullName;
      };
      const searchInsuranceResult = (data) => {

        console.log("data searched ",data);
        // setMessageDetails({ message: "" });
    
      let filteredData = insuranceSearchList.filter((item) => {
      return (item.companyName)&&(item.companyName).includes(data)});

        setinsuranceMasterSearchFilterList(filteredData);


  console.log("filteredData",insuranceMasterSearchFilterList);
  
  return insuranceMasterSearchFilterList;
    // insuranceMasterSearchFilterList=filteredData;
    };
    const closeModal = (data) => {
      //saving the form doctor data and closing the modal  
      console.log("data ::",data);
      setInsuranceMaster(!addInsuranceMaster);

    

    }
    const callBackGrid = (data) => {
      console.log("datadata", data);
      dispatch(
        insuranceActions.getInsuranceMaster((resCb) => {
          if (resCb) {
            console.log("getInsuranceMaster", resCb.masterinsurancelist);
            setinsuranceSearchList(resCb.masterinsurancelist);
            setinsuranceMasterSearchFilterList(resCb.masterinsurancelist);
            console.log("response from api : ", resCb);
       
          }
        })
      );
    };
 return( 
    <>
    <div class="mainsection">
    <div >
        
          {/* border-left  */}
          <span className="topright d-flex justify-content-end pl-4 pt-3 mr-4"  onClick={(event)=>closeServiceModal(event)}>X</span>       
     </div>
     <div className="listTitles">
        {/* <Row noGutters className="page-header mb-3">
          <h5
            // subtitle="Dashboard"
            className="text-sm-left mb-0"
          >Insurances</h5>
          <Button
              type="submit"
              className="floatingbtn"
              onClick={(event) => handleClick("", true)}
            >
              Add New
          </Button>
        </Row> */}

        <Grid item xs={12} 
    className="page-header mb-3 doctorList">
         <h5
            // subtitle="Dashboard"
            className="text-sm-left mb-0"
          >
            Insurances
          </h5>
          <Button 
          variant="contained" 
          className="floatingbtn"  
          onClick={(event) => handleClick("", true)}
          style={{ textTransform: "none" }}>
        Add New
      </Button>

    </Grid>

        </div>
        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
        <div className="input-group input-group-seamless">
          <div className="input-group-prepend mb-2">
            <span className="input-group-text">
              <i style={{ color: `#111b21` }} className="material-icons">
                search
              </i>
            </span>
          </div>
          {/* <FormInput
            className="navbar-search mb-2 menu-search bg-white"
            placeholder="Search ComapanyName..."
            value={searchText}
            type="search"
            onChange={handleInputChange}
          /> */}

             <TextField 
               className="navbar-search mb-2 menu-search bg-white custom-text"
               placeholder="Search ComapanyName..."
               autoFocus
               value={searchText}
               type="search"
               onChange={handleInputChange}
               InputProps={{
                style: { paddingLeft: '15px',height:"35px"}, 
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && ( // Render the close icon only if searchText exists
                      <IconButton edge="end" onClick={clearSearch} tabIndex={-1} 
                      size="small" 
                style={{ padding: '6px' }}
                >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
               />

        </div>
      </form>

      {insuranceMasterSearchFilterList && insuranceMasterSearchFilterList.length > 0 &&
      <Components.insuranceMasterGrid  insurancemaster={insuranceMasterSearchFilterList}  
                onGridAction={(event) => callBackGrid(event)}

      />
      }
      {/* {addInsuranceMaster && ( */}
      {/* <div > */}


      {/* <Components.addInsuranceMaster onModalClose={(event) => closeModal(event)} /> */}


      {/* </div>)} */}

    </div>

      </>
    );
}
export default InsuranceMasterList;
