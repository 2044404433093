import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Components from "..";
import { doctorActions, tokenActions } from "../../core/actions";
import { getFormattedDate } from "./AppointmentScheduleModal";
import { helperServices } from "../../shared/helper";
import debounce from "lodash.debounce";
import Select, { createFilter } from "react-select";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { patientActions } from "../../core/actions";
import { Grid, Button } from "@mui/material";
import { DialogContent } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { addMonths, format, isBefore, isSameDay } from "date-fns";
import {
  APPT_MORNING_TIMINGS,
  APPT_EVENING_TIMINGS,
} from "../../shared/constants";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

function AdminAppointment(props) {
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [messageDetails, setMessageDetails] = useState({});
  const [currentWeek, setCurrentWeek] = useState([]);
  const [defaultweekDays, setDefaultweekDays] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [selecetdDate, setSelecetdDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [reloadModal, setReloadModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [timeSelected, setTimeSelected] = useState();
  const [collapseMoringTime, setCollapseMoringTime] = useState(true);
  const [collapseEveTime, setCollapseEveTime] = useState(false);
  const [bookedPatientSlots, setBookedPatientSlots] = useState([]);
  const [bookedCountAtTime, setBookedCountAtTime] = useState({});
  const [showAppointedPatients, setShowAppointedPatients] = useState(false);
  const [appointedPatient, setAppointedPatient] = useState([]);
  const [doctorCalender, setDoctorCalender] = useState({});
  const [getCal, setGetCal] = useState({});
  const [filteredTimeSlot, setfilteredTimeSlot] = useState({});
  const [timeslotsFromDb, setTimeslotsFromDb] = useState([]);
  const [leaveSolts, setLeaveSolts] = useState([]);
  const [updatedTimeSolt, setUpdatedTimeSolt] = useState([]);
  const [flag, setFlag] = useState({});
  const [addflag, setAddFlag] = useState(false);
  const [flagStatus, setFlagStaus] = useState(false);
  const [formErrors, setFormErrors] = useState({
    counselor: "",
    apptdate: "",
    type: "",
  });
  var startDate = "";
  var endDate = "";
  var docCalender;
  const [type, setType] = useState();
  const [calender, setCalender] = useState({});
  const [appBooked, setAppBooked] = useState(false);
  const [visitdetail, setVisitdetail] = useState({
    patientId: "",
    reqtime: "",
    reqtdate: "",
    reqthours: "",
    reqtminutes: "",
    counselor: "",
    apptdate: "",
    appthours: "",
    apptminutes: "",
    type: "Doctor",
    vaId: "",
    status: "",
    reId: "",
    gvid: "",
    calField: "",
    patientType: "OutPatient",
    timeslots: [],
    c: {},
  });
  const [appointmentDetail, setappointmentDetail] = useState({
    reqtime: "",
    reqtdate: "",
    reqthours: "",
    reqtminutes: "",
    apptdate: "",
    appthours: "",
    apptminutes: "",
    type: "Doctor",
    counselor: "",
    patientId: "",
    visitId: "",
    vaId: "",
    v: "",
    va: "",
    gvid: "",
    calField: "",
    timeslots: [],
    c: {},
    departmentId: "",
  });
  const [selectedDateForValidation, setSelectedDateForValidation] = useState(
    {}
  );
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [toCancelSlot, setToCancelSlot] = useState({});
  const [status, setStatus] = useState(undefined);
  const [patientListing, setPatientListing] = useState([]);
  const [proceduredata, setProceduredata] = useState([]);
  const [procdata, setProcdata] = useState([]);
  const [procedureSelectedDoctor, setProcedureSelectedDoctor] = useState();
  const [displaySearchPatient, setDisplaySearchPatient] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [alreadyAppoinmented, setAlreadyAppoinmented] = useState(false);
  const [requestedtime, setRequestedtime] = useState({ time: "" });
  const [bookAppointment, setBookAppointment] = useState(false);
  const [bookAppt, setBookAppt] = useState(false);
  const [cancelAppt, setCancelAppt] = useState(false);
  const [reloadData, setreloadData] = useState(false);
  const [appointedPatientList, setAppointedPatientList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [showPatientDetail, setshowPatientDetail] = useState(false);
  const [tokenNumber, setTokenNumber] = useState();
  const [onBoardmodal, setOnBoardModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Override");
  const [paymentOption, setPaymentOption] = useState("card");
  const [paymentDetails, setPaymentDetails] = useState({
    // customerName: props.match.params.name,
    totalAmount: 0,
    paymentType: paymentOption,
    remainingAmount: 0,
    // patientId: props.match.params.patientId,
    executive: localStorage.getItem("name"),
    paidAmount: 0,
  });
  var paymentLists = [];
  const cardPay = useSelector((state) => state.patientDetailsState.cardpayment);
  const cashPay = useSelector((state) => state.patientDetailsState.cashpayment);
  const upiPay = useSelector((state) => state.patientDetailsState.upipayment);
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const currentTime = format(new Date(), "HH:mm");
  const defaultDate = format(
    new Date().setDate(new Date().getDate() + 7),
    "yyyy-MM-dd"
  );
  const [patientProcedureList, setPatientProcedureList] = useState({});
  const [patientDetail, setPatientDetail] = useState({
    patientId: "",
    visitId: "",
    closecomment: "",
    closeScheduledTime: "",
    revisitdate: "",
  });
  const [cancelAppointmentDetails, setCancelAppointmentDetails] = useState([]);
  const [disableTimeSolt, setDisableTimeSolt] = useState([]);
  const [bookFlag, setBookFlag] = useState(false);
  const [pathParams, setPathParams] = useState(
    props?.location?.pathname.includes("/Appointments/")
      ? props?.location?.pathname?.replace("/Appointments/", "")
      : ""
  );
  const [revisitFlag, setRevisitFlag] = useState(
    pathParams && pathParams != "" ? true : false
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getWeekDays();
    patientListDetails();
    setSelectedDateForValidation(format(new Date(), "yyyy-MM-dd"));
    getDoctorsList();
    if (!revisitFlag) {
      defaultDateSelection();
    }
  }, []);

  const patientListDetails = () => {
    let data = {};
    dispatch(
      patientActions.ListVisits(data, (resCb) => {
        if (resCb) {
          resCb.patsearchreslt?.forEach((item) => {
            if (item.visit && item.visit.status === "open") {
              patientListing.push(item);
            }
          });
        }
      })
    );
  };

  const renderVisitLists = (data, searchType) => {
    let searchList = [];
    let search = {
      bodyParams: {
        searchString: data,
        flag: "FollowupDate",
      },
    };
    dispatch(
      patientActions.patientSearch(search, (resCb) => {
        if (resCb.messageStrip == "No patient found") {
        }
        if (resCb.state == "success") {
          let patientInfo = resCb;
          Object.keys(resCb.patientListCollection).forEach((key) => {
            if (resCb.patientListCollection[key] != null) {
              searchList.push(resCb.patientListCollection[key]);
              patientListing.push(resCb.patientListCollection);
            }
          });
        }
        if (searchType === "search") {
          if (searchList.length > 0) {
            setDisplaySearchPatient(searchList);
          } else {
            setDisplaySearchPatient(null);
          }
        } else if (searchType === "FollowupDate") {
          let curDate = format(new Date(), "yyyy-MM-dd");
          setDisplaySearchPatient(null);
          let isExits = searchList.filter((obj) => obj.id == data);
          let patientId = isExits[0]?.visit?.patientId;
          let visitId = isExits[0]?.visit?.id;
          let date;
          dispatch(
            patientActions.getrevisitbyId(patientId, visitId, (resCb) => {
              if (resCb) {
                console.log("resCbe : ", resCb);
                if (
                  resCb &&
                  resCb.scheduleddate &&
                  resCb.scheduleddate > curDate
                ) {
                  date = resCb.scheduleddate;
                } else {
                  date = curDate;
                }
                setSelecetdDate(date);
                selectDoctor(isExits[0]?.person);
                defaultDateSelection(date);
                handleSelect(date);
                patientSelected(isExits[0]);
              }
            })
          );
          setRevisitFlag(false);
        } else {
          setDisplaySearchPatient(null);
          let isExits = searchList.filter((obj) => obj.id === data);
          patientSelected(isExits[0]);
        }
      })
    );
  };

  const selectDoctor = (event) => {
    let doc = doctorList.filter((obj) => obj.gvid == event.personId);
    handleDeptChange(doc[0]);
  };
  const defaultDateSelection = (date) => {
    let formattedDays = [];
    let nonFormattedDays = [];
    let appdate;
    let currdate = new Date();
    console.log("visitdetail1", visitdetail.apptdate);
    const curdate = format(currdate, "yyyy-MM-dd");
    console.log("curdate", curdate);
    appdate = date ? date : curdate;
    console.log("visitdetail", appdate);
    if (appdate != null) {
      if (defaultweekDays.includes(appdate)) {
        console.log("defaultDateSelection appdate", appdate, defaultweekDays);
        setWeekDays(defaultweekDays);
        setCurrentWeek(currentWeek);
        setSelecetdDate(appdate);
      } else if (appdate > curdate) {
        console.log("defaultDateSelection appdate1", appdate);
        const next7Days = [...Array(7).keys()].map((index) => {
          const date = new Date(weekDays[weekDays.length - 1]);
          date.setDate(date.getDate() + (index + 1));
          const defDate = format(date, "yyyy-MM-dd");
          nonFormattedDays.push(defDate);
          let formatteddate = format(date, "dd MMM");
          formattedDays.push(formatteddate);
          return date;
        });
        console.log("next7Days", next7Days);
        setWeekDays(nonFormattedDays);
        setCurrentWeek(formattedDays);
        setSelecetdDate(appdate);
      } else {
        console.log("defaultDateSelection appdate2", appdate);
        const past7Days = [...Array(7).keys()].map((index) => {
          const date = new Date(weekDays[0]);
          date.setDate(date.getDate() - (index + 1));
          const defDate = format(date, "yyyy-MM-dd");
          nonFormattedDays.unshift(defDate);
          let formatteddate = format(date, "dd MMM");
          formattedDays.unshift(formatteddate);
          return date;
        });
        console.log("past7Days", past7Days);
        setWeekDays(nonFormattedDays);
        setCurrentWeek(formattedDays);
        setSelecetdDate(appdate);
        console.log("defaultDateSelection appdate2", appdate);
        if (appointmentDetail.counselor) {
          getCal.apptdate = appdate;
          getCal.gvid = appointmentDetail.counselor;
          getCalDoc(getCal);
        }
      }
    }
  };

  const getDoctorsList = (data) => {
    let gvdata;
    dispatch(
      doctorActions.getDoctorList(data, (resCb) => {
        if (resCb) {
          let list = [];
          console.log("rescb from getdoctorlist: ", resCb);
          for (let doc of resCb) {
            console.log("doc: ", doc);
            if (doc != null) {
              let opt = {};
              if (doc && !doc.first_name) {
                doc.first_name = "";
              }
              if (doc && !doc.last_name) {
                doc.last_name = "";
              }
              if (doc && doc.gvId) {
                opt["id"] = doc.personId;
                opt["gvid"] = doc.gvId;
              }
              const name = helperServices.getFullName(
                doc.first_name,
                null,
                doc.last_name
              );
              opt["role"] = doc.role;
              opt["type"] = doc.type;
              opt["value"] = doc.email;
              opt["department"] = doc.department;
              opt["firstName"] = doc.first_name;
              opt["lastName"] = doc.last_name;
              opt["departmentId"] = doc.departmentId;
              opt["label"] =
                doc.displaytitle +
                "." +
                " " +
                name +
                ", " +
                "Dep of " +
                doc.department +
                " (" +
                doc.qualification +
                " )";
              list.push(opt);
              gvdata = props.doctorInfo || props.selectedCousellerInfo;
              console.log("props.doctorinfo: ", props);
              console.log("gvdata and doc: ", gvdata, doc);
              let gvid;
              if (gvdata && gvdata.id) {
                gvid = gvdata.id;
              } else if (gvdata && gvdata.gvId) {
                gvid = gvdata.gvId;
              } else {
                gvid = "";
              }
              if (gvdata && gvid == doc.personId) {
                setSelectedDoctor(opt["label"]);
                console.log("selected doctor: ", selectedDoctor);
                let app = { ...appointmentDetail };
                app.counselor = doc.gvId;
                app.departmentId = doc.departmentId;
                console.log("app:", app);
                setappointmentDetail({ ...app });
              }
            }
          }
          console.log("list of doctors after filter:", list);
          console.log("pathParams:", revisitFlag, pathParams);
          if (revisitFlag) {
            list.forEach((element) => {
              doctorList.push(element);
            });
            renderVisitLists(pathParams, "FollowupDate");
          } else {
            console.log("list", list);
            setDoctorList([...list]);
          }
        }
      })
    );
  };

  const handleDeptChange = (event) => {
    setType(event.type);
    appointmentDetail.va = "";
    appointmentDetail.vaId = "";
    console.log("Selected OAtient", selectedPatient, event);
    setShowAppointedPatients(false);
    setAppointedPatient(null);
    console.log("handleDeptChange", event.gvid, event.label);
    console.log("event:", event);
    setType(event.type);
    setSelectedDoctor(event.label);
    if (selectedPatient != undefined && selectedPatient != null) {
      console.log("EVENT:", event, selectedPatient);
      selectedPatient.type = event.type;
      patientSelected(selectedPatient);
    }
    appointmentDetail.gvid = event.gvid;
    appointmentDetail.departmentId = event.departmentId;
    var docState = appointmentDetail;
    if (event != null) {
      docState["counselor"] = event.id;
      appointmentDetail.counselor = event.id;
      appointmentDetail.gvid = event.id;
    }
    console.log("D-appointmentdetails", appointmentDetail);
    let gvid = props?.doctorInfo?.id ?? appointmentDetail.gvid;
    getleaveCalender(gvid, format(new Date(), "yyyy-MM-dd"));
    getdoctorCalender(doctorCalender);
    if (appointmentDetail.counselor === "") {
    } else {
      getCal.apptdate = format(new Date(), "yyyy-MM-dd");
      getCal.gvid = appointmentDetail.counselor;
      getCalDoc(getCal);
    }

    if (disableTimeSolt.length == 1 || timeSlots.length > 0) {
      setDisableTimeSolt([]);
      let time = appointmentDetail.appthours;
      let time1 = appointmentDetail.apptminutes;
      let mergedTime = `${time}:${time1}`;
      console.log("time", mergedTime);
      addNewAppointment(mergedTime, disableTimeSolt[0]);
    }
    formErrors.counselor = "";
  };

  function removeCommaFromValues(obj) {
    const newObj = {};
    const newObj1 = {};
    for (let key in obj) {
      let ids = "";
      console.log(
        "key.split",
        obj[key],
        key,
        key.split(","),
        obj[key].split(",")
      );
      if (obj.hasOwnProperty(key)) {
        let data = obj[key].split(",");
        console.log("obj[key", obj[key]);
        for (let index = 0; index < data.length; index++) {
          console.log("data: ", data[index]);
          if (data[index] != "") {
            if (index != data.length - 1 && data[index + 1] != "") {
              ids = ids + data[index] + ",";
            } else {
              ids = ids + data[index];
            }
          }
        }
        console.log("ids", ids);
        newObj[key] = ids;
        console.log("ids", newObj);
      }
    }
    for (let key in newObj) {
      if (newObj[key] != "") {
        newObj1[key] = newObj[key];
      }
    }
    console.log("newObj1", newObj1);
    return newObj;
  }

  const getdoctorCalender = () => {
    getCal.apptdate =
      appointmentDetail.apptdate == format(new Date(), "yyyy-MM-dd")
        ? format(new Date(), "yyyy-MM-dd")
        : appointmentDetail.apptdate;

    getCal.gvid = appointmentDetail.counselor;
    if (!getCal.apptdate || !getCal.gvid) return;
    dispatch(
      patientActions.getDoctorCalenderByDate(getCal, (resCb) => {
        if (resCb) {
          console.log("foreacjhhfkvkfbkhvbkjrekuf", resCb);
          const filteredTimeSlot = { ...resCb.c };
          const keysToRemove = ["id", "gvid", "date"];
          keysToRemove.forEach((key) => delete filteredTimeSlot[key]);
          console.log("filteredTimeSlot", filteredTimeSlot);
          const updatedData = removeCommaFromValues(filteredTimeSlot);
          console.log(updatedData);
          setfilteredTimeSlot(updatedData);
          updatedTimeSolt.push(updatedData);
          let timeslotsFromDb = [];
          if (updatedData != null) {
            Object.keys(updatedData).forEach((key) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id"
              ) {
                timeslotsFromDb.push(key);
              }
            });
          }
          console.log("timeslotsFromDb", timeslotsFromDb);
          let bookedCountAtTimeloc = {};
          if (updatedData != null) {
            Object.entries(updatedData).map(([key, value]) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                bookedCountAtTimeloc[key] = value.split(",").length;
                bookedCountAtTimeloc["pateintId"] = value.split(",");
              }
            });
          }
          setBookedCountAtTime(bookedCountAtTimeloc);
          let bookedPatientSlot = [];
          let patientId = appointmentDetail.patientId;
          if (updatedData != null) {
            Object.entries(updatedData).forEach(([key, value]) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                if (
                  updatedData[key].includes(patientId) !=
                  updatedData[key].includes(key)
                ) {
                  console.log(
                    "updatedData[key].includes(patientId)",
                    updatedData[key].includes(patientId),
                    updatedData[key].includes(key)
                  );
                  bookedPatientSlot.push(key);
                }
              }
            });
          }
          if (flag != "bookvisit") {
            if (selectedPatient != null) {
              let result;
              result = Object.keys(updatedData).filter(
                (key) => updatedData[key] === selectedPatient.id.toString()
              );
              console.log("result,", result);
              setBookedPatientSlots(result);
            }
          }
          setDoctorCalender(timeslotsFromDb);
          setTimeslotsFromDb(timeslotsFromDb);
        }
      })
    );
  };

  const getCalDoc = (getCal, patient) => {
    dispatch(
      patientActions.getDoctorCalenderByDate(getCal, (resCb) => {
        if (resCb) {
          console.log("foreacjhhfkvkfbkhvbkjrekuf", resCb);
          const filteredTimeSlot = { ...resCb.c };
          const keysToRemove = ["id", "gvid", "date"];
          keysToRemove.forEach((key) => delete filteredTimeSlot[key]);
          console.log("filteredTimeSlot", filteredTimeSlot);
          const updatedData = removeCommaFromValues(filteredTimeSlot);
          console.log(updatedData);
          setfilteredTimeSlot(updatedData);
          updatedTimeSolt.push(updatedData);
          let timeslotsFromDb = [];
          if (updatedData != null) {
            Object.keys(updatedData).forEach((key) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id"
              ) {
                timeslotsFromDb.push(key);
              }
            });
          }
          console.log("timeslotsFromDb", timeslotsFromDb);
          let bookedCountAtTimeloc = {};
          if (updatedData != null) {
            Object.entries(updatedData).map(([key, value]) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                bookedCountAtTimeloc[key] = value.split(",").length;
                bookedCountAtTimeloc["pateintId"] = value.split(",");
              }
            });
          }
          setBookedCountAtTime(bookedCountAtTimeloc);
          let bookedPatientSlot = [];
          let patientId = appointmentDetail.patientId;
          if (updatedData != null) {
            Object.entries(updatedData).forEach(([key, value]) => {
              if (
                updatedData[key] != null &&
                updatedData[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                if (
                  updatedData[key].includes(patientId) !=
                  updatedData[key].includes(key)
                ) {
                  console.log(
                    "updatedData[key].includes(patientId)",
                    updatedData[key].includes(patientId),
                    updatedData[key].includes(key)
                  );
                  bookedPatientSlot.push(key);
                }
              }
            });
          }
          if (flag != "bookvisit") {
            if (selectedPatient != null) {
              let result;
              result = Object.keys(updatedData).filter(
                (key) => updatedData[key] === selectedPatient.id.toString()
              );
              console.log("result,", result);
              setBookedPatientSlots(result);
            }
          }
          setDoctorCalender(timeslotsFromDb);
          setTimeslotsFromDb(timeslotsFromDb);
        }
      })
    );
  };

  const getleaveCalender = (gvid, date) => {
    dispatch(
      patientActions.getleaveCalender(gvid, date, (resCb) => {
        if (resCb.successMessages) {
          console.log("resCb", resCb);
          let check = "NA";
          let slot = [];
          if (resCb.lc != null) {
            Object.entries(resCb.lc).forEach(([key, value]) => {
              if (value === check) {
                slot.push(key);
              }
            });
            console.log("leaveSolts 1065", leaveSolts, timeSlots);
          }
          setLeaveSolts(slot);
        }
      })
    );
    console.log("leaveSolts", leaveSolts, timeSlots);
  };

  const handleInputChange = (event) => {
    setAddFlag(true);
    setSelectedPatient();
    setBookAppointment(false);
    console.log("handleInputChange", event);
    console.log("Event is", event.target.value);
    if (event.target.value.length == 0) {
      setDisplaySearchPatient(null);
      setAddFlag(false);
    }
    if (event.target.value.length > 2) {
      getSearchedPatients(event.target.value, "search");
    } else {
      setDisplaySearchPatient(null);
    }
  };

  const getSearchedPatients = (data) => {
    setMessageDetails({ message: "" });
    debounceSearchPatientResult(data, "search");
  };

  const debounceSearchPatientResult = useCallback(
    debounce(renderVisitLists, 500),
    []
  );

  const handleSelect = (date) => {
    setLeaveSolts([]);
    setShowAppointedPatients(false);
    setAppointedPatient(null);
    setFlagStaus(true);
    let curdate = new Date();
    let curDate = format(new Date(), "yyyy-MM-dd");
    let checkdate = new Date(date);
    curdate.setHours(0, 0, 0, 0);
    curdate.setMinutes(0, 0, 0, 0);
    checkdate.setHours(0, 0, 0, 0);
    checkdate.setMinutes(0, 0, 0, 0);
    console.log("handleselect", checkdate, curdate);
    setshowPatientDetail(false);
    if (checkdate < curdate) {
      setBookFlag(true);
    } else {
      setBookFlag(false);
    }
    console.log("Selected date", date);
    let appdate = new Date(date);
    setreloadData(!reloadData);
    let gvid = props?.doctorInfo?.id ?? appointmentDetail.gvid;
    getleaveCalender(gvid, format(appdate, "yyyy-MM-dd"));
    validateField();
    validateFieldForApptDate(true);
    validateFieldTimeSlots(true);
    let counselorResult = validateField();
    if (!counselorResult) {
      return;
    }
    setSelecetdDate(date);
    appointmentDetail.apptdate = format(appdate, "yyyy-MM-dd");
    visitdetail.apptdate = format(appdate, "yyyy-MM-dd");
    setappointmentDetail(appointmentDetail);
    getdoctorCalender(doctorCalender);
    let apptdate = format(appdate, "yyyy-MM-dd");
    setSelectedDateForValidation(apptdate);
    console.log("appointedPatientListingcalled", selectedPatient);
    if (selectedPatient && selectedPatient != null) {
      let apDate =
        selectedPatient?.visit?.apptdate ??
        selectedPatient?.visit?.capptdate ??
        null;
      console.log("appointedPatientListingcalled", apptdate, curDate, apDate);
      if (appdate && appdate != null && apDate == apptdate) {
        setShowAppointedPatients(true);
        console.log("appointedPatientListingcalled", appdate);
        appointedPatientListing(selectedPatient.id.toString());
      } else {
        setShowAppointedPatients(false);
      }
      console.log("time:741", disableTimeSolt);
      if (disableTimeSolt.length == 1 || timeSlots.length > 0) {
        setDisableTimeSolt([]);
        let time = appointmentDetail.appthours;
        let time1 = appointmentDetail.apptminutes;

        let mergedTime = `${time}:${time1}`;

        console.log("time:741", mergedTime);

        addNewAppointment(mergedTime, disableTimeSolt[0]);
      }
    }
  };

  const validateField = () => {
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.counselor = "";
    if (flag != undefined || flag != "bookvisit") {
      if (appointmentDetail.counselor == "") {
        fieldValidationErrors.counselor = "Please Select Doctor";
        setFormErrors({ ...fieldValidationErrors });
        return false;
      }
      return true;
    } else {
      if (visitdetail.counselor == "") {
        fieldValidationErrors.counselor = "Please Select Doctor";
        setFormErrors(fieldValidationErrors);
        return false;
      }
      return true;
    }
  };

  const validateFieldForApptDate = (selectedTimeslot, type) => {
    let currentDate = new Date();
    let curDate = format(currentDate, "yyyy-MM-dd");
    let currentTime = new Date();
    currentTime.setHours(currentDate.getHours());
    currentTime.setMinutes(currentDate.getMinutes());
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.apptdate = "";
    console.log(
      "appointment selecrted",
      selectedDateForValidation,
      curDate,
      selectedTimeslot,
      selectedPatient
    );
    console.log("timeSelectedtime", currentTime, timeSelected);
    let isDoctorUnavailable = Object.entries(leaveSolts).some(
      ([key, value]) => value === type
    );
    if (isDoctorUnavailable) {
      fieldValidationErrors.type =
        "Doctor is not available on selected timeslot";
      setFormErrors(fieldValidationErrors);
      return false;
    }
    if (Object.keys(selectedDateForValidation).length === 0) {
      fieldValidationErrors.type = "Please select Date";
      setFormErrors(fieldValidationErrors);
      return false;
    } else if (selectedDateForValidation < curDate) {
      fieldValidationErrors.type =
        "You can not book an appointment for earlier date";
      setFormErrors(fieldValidationErrors);
      return false;
    } else if (
      (selectedPatient !== undefined || selectedPatient?.length > 0) &&
      selectedDateForValidation == curDate
    ) {
      console.log("inside If");
      if (selectedTimeslot < currentTime) {
        console.log(
          "timeSelectedtime if",
          currentTime,
          timeSelected,
          selectedTimeslot
        );
        fieldValidationErrors.type =
          "You can not book an appointment for earlier timeslot";
        setFormErrors(fieldValidationErrors);
        return false;
      }

      // if (selectedDateForValidation == curDate && selectedPatient.length>0) {
      //   //TC_01
      //   if (bookedPatientSlots != "") {
      //     console.log("timeSelectedtime", currentTime, timeSelected);
      //     fieldValidationErrors.type =
      //       "Patient already has appointment on different time slot. You may cancel it before assign new timeslot";
      //     setFormErrors(fieldValidationErrors);
      //     return false;
      //   }
      // }
    }
    return true;
  };

  const validateFieldTimeSlots = () => {
    let currentDate = new Date();
    let curDate = format(currentDate, "yyyy-MM-dd");
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.type = "";
    // if (
    //   bookedPatientSlots != "" &&
    //   selectedDateForValidation != " " &&
    //   Object.keys(selectedDateForValidation).length !== 0 &&
    //   selectedDateForValidation >= curDate
    // ) {
    //   fieldValidationErrors.type =
    //     "This doctor already has appointment on same date, Click cancel appointment to cancel it first.";
    //   setFormErrors(fieldValidationErrors);
    //   return false;
    // }
    return true;
  };

  const getWeekDays = () => {
    let curr = new Date();
    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      console.log("first:", first, day);
      let curDate = format(day, "yyyy-MM-dd");
      defaultweekDays.push(curDate);
      weekDays.push(day);
      let formatteddate = format(day, "dd MMM");
      currentWeek.push(formatteddate);
    }

    startDate = currentWeek[0];
    endDate = currentWeek[currentWeek.length - 1];
    setReloadModal(!reloadModal);
  };

  const prevSevenDays = () => {
    let formattedDays = [];
    let nonFormattedDays = [];
    const past7Days = [...Array(7).keys()].map((index) => {
      const date = new Date(weekDays[0]);
      date.setDate(date.getDate() - (index + 1));
      nonFormattedDays.unshift(date);
      let formatteddate = format(date, "dd MMM");
      formattedDays.unshift(formatteddate);
      return date;
    });
    console.log("past7Days", past7Days);
    setWeekDays(nonFormattedDays);
    setCurrentWeek(formattedDays);
  };

  const nextSevenDays = () => {
    let formattedDays = [];
    let nonFormattedDays = [];
    const next7Days = [...Array(7).keys()].map((index) => {
      const date = new Date(weekDays[weekDays.length - 1]);
      date.setDate(date.getDate() + (index + 1));
      nonFormattedDays.push(date);
      let formatteddate = format(date, "dd MMM");
      formattedDays.push(formatteddate);
      return date;
    });

    setWeekDays(nonFormattedDays);
    setCurrentWeek(formattedDays);
  };

  const toggleMoringTime = () => {
    setCollapseMoringTime(!collapseMoringTime);
    if (!collapseMoringTime && collapseEveTime) {
      setCollapseEveTime(false);
    }
  };

  const toggleEveTime = () => {
    setCollapseEveTime(!collapseEveTime);
    if (!collapseEveTime && collapseMoringTime) {
      setCollapseMoringTime(false);
    }
  };

  const checkTimeSolt = (type) => {
    console.log("checkTimeSolt", type);
    if (disableTimeSolt.length == 0) {
      disableTimeSolt.push(type);
    } else {
      let check = "";
      if (disableTimeSolt[0] === type) {
        disableTimeSolt.pop(type);
      } else {
        disableTimeSolt.pop(type);
      }
    }
    console.log("checkTimeSolt", disableTimeSolt);
  };

  const appointedPatientListing = (patientIds) => {
    let patDetails = [];
    let currentDate = new Date();
    let curDate = format(currentDate, "yyyy-MM-dd");
    console.log("filteredTimeSlot", patientIds);
    const patientIdArray = patientIds.split(",");
    console.log(patientIdArray);
    const filteredPatientIdArray = patientIdArray.filter(
      (id) => id.trim() !== ""
    );
    console.log(filteredPatientIdArray);
    if (patientListing && patientListing.length > 0) {
      console.log(typeof patientListing[0]?.id);
      console.log(typeof filteredPatientIdArray[0]);
    } else {
      console.log("patientListing is empty");
    }
    const filteredPatients = patientListing.reduce((acc, patient) => {
      if (
        patient &&
        patient.id !== undefined &&
        filteredPatientIdArray.includes(patient.id.toString()) &&
        !acc.some((p) => p.id === patient.id)
      ) {
        acc.push(patient);
      }
      return acc;
    }, []);
    console.log(filteredPatients);
    if (!filteredPatients.length > 0) {
    }
    filteredPatients.forEach((pa) => {
      let data = pa;
      for (let index = 0; index < data.visitAssignmentList.length; index++) {
        let i = data.visitAssignmentList[index];
        if (i && i.status == "open" && i.targetid != 0) {
          let temp = {};
          temp.main = pa.id;
          temp.name = `${data.fname} ${data.lname}(${data.id})`;
          temp.phno = data.phno;
          temp.address =
            data?.city !== undefined && data?.state !== undefined
              ? `${data.city}, ${data.state}`
              : "Karnataka"; // or handle the case where either city or state is undefined
          temp.type = i.type;
          let appointmentTime = "";

          if (i.type == "Doctor") {
            if (
              data &&
              data.visit?.apptdate &&
              data.visit?.apptdate >= curDate
            ) {
              appointmentTime = new Date(data.visit.apptdate);
              appointmentTime.setHours(data.visit.appthours);
              appointmentTime.setMinutes(data.visit.apptminutes);
              appointmentTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
            }
          } else if (i.type == "Counsellor") {
            if (
              data &&
              data.visit?.capptdate &&
              data.visit?.capptdate >= curDate
            ) {
              appointmentTime = new Date(data.visit.capptdate);
              appointmentTime.setHours(data.visit.cappthours);
              appointmentTime.setMinutes(data.visit.capptminutes);
              appointmentTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
            }
          }
          temp.time = appointmentTime;
          temp.visit = data.visit;
          if (temp.time != "") {
            patDetails.push(temp);
          }
        }
      }
    });
    console.log("appointedPatientList", patDetails);
    setAppointedPatientList(patDetails);
  };

  const addNewAppointment = (time, type, check) => {
    console.log("Already selected time solt", disableTimeSolt, type);
    // setTimeSlots([]);
    console.log("time and type: ", time, type);
    if (disableTimeSolt.length > 0) {
      checkTimeSolt(type);
    }
    const time1 = new Date();
    time1.setHours(time.split(":")[0]);
    time1.setMinutes(time.split(":")[1]);
    console.log("bookedPatientSlotshgghgggjyj", time1);
    // setTimeSelected(time1);
    let validation = validateField();
    let validationDate = validateFieldForApptDate(true);
    console.log("validation", validation, validationDate);
    setAppointedPatient([]);
    if (validation == true) {
      console.log(
        "filteredTimeSlot.hasOwnProperty(type)",
        filteredTimeSlot.hasOwnProperty(type)
      );
      if (filteredTimeSlot.hasOwnProperty(type)) {
        var patientIds = filteredTimeSlot[type];
        console.log("patientIds", patientIds);
        dispatch(
          patientActions.getAppointedPatientDetails(patientIds, (resCb) => {
            if (resCb) {
              setShowAppointedPatients(true);
              setAppointedPatient(resCb.patient);
            }
          })
        );
        if (patientIds && patientIds != null && patientIds != "") {
          console.log("appointedPatientListing", selectedPatient);
          appointedPatientListing(patientIds);
        }
      }
      if (check != undefined && check == "closeTimeSlot") {
        setAppointedPatientList([]);
        setShowAppointedPatients(false);
        console.log("SetFalse");
      }
      setreloadData(!reloadData);
      setReload(!reload);
      let apptdateResult = validateFieldForApptDate(time1, type);
      if (!apptdateResult) {
        return;
      }
      let result = validateFieldTimeSlots();
      if (!result) {
        return;
      }
      if (timeSlots.valueOf(0) == "") {
        console.log("filteredTimeSlot timeslot", timeSlots);
        appointmentDetail.appthours = time.split(":")[0];
        appointmentDetail.apptminutes = time.split(":")[1];
        visitdetail.appthours = time.split(":")[0];
        visitdetail.apptminutes = time.split(":")[1];
      }
      docCalender = calender;
      if (timeSlots.indexOf(type) > -1) {
        console.log(
          "filteredTimeSlot timeSlots.indexOf(type)",
          timeSlots.indexOf(type)
        );
        docCalender[type] = "";
        setCalender(docCalender);
        timeSlots.splice(timeSlots.indexOf(type), 1);
      } else {
        console.log(timeSlots);
        Object.keys(docCalender).forEach((key) => {
          if (docCalender[key] == appointmentDetail.patientId)
            delete docCalender[key];
          console.log("docCalender", docCalender, docCalender[key]);
        });
        if (type != undefined) {
          timeSlots[0] = type;
          docCalender[type] = appointmentDetail.patientId;
        }
        setCalender(docCalender);
        appointmentDetail.appthours = time.split(":")[0];
        appointmentDetail.apptminutes = time.split(":")[1];
        visitdetail.appthours = time.split(":")[0];
        visitdetail.apptminutes = time.split(":")[1];
      }
      setreloadData(!reloadData);
      setTimeSlots(timeSlots);
      appointmentDetail.timeslots = timeSlots;
      appointmentDetail.c = docCalender;
      visitdetail.timeslots = timeSlots;
      visitdetail.c = calender;
      setVisitdetail(visitdetail);
      setAppBooked(true);
    }
  };

  const cancel = (cancelScheduledAppointment) => {
    var ServerResVal = ServerRes;
    var folDate = new Date();
    console.log("cancelScheduledAppointment", cancelScheduledAppointment);
    if (cancelScheduledAppointment == "CancelVisit") {
      patientDetail.closecomment = "NotInSite";
      patientDetail.patientId = cancelAppointmentDetails[0].id;
      patientDetail.visitId = cancelAppointmentDetails[0].visit.id;
      patientDetail["closescheduleddate"] = "";
      let followUpDateVar = format(
        folDate.setDate(folDate.getDate()),
        "yyyy-MM-dd"
      );
      patientDetail["closescheduleddate"] = followUpDateVar;
      let temp = toCancelSlot.gvid;
      toCancelSlot.gvid = temp.toString();
      dispatch(
        patientActions.cancelDoctorCalenderByPatientId(
          toCancelSlot,
          (resCb) => {
            if (resCb && resCb.successMessages) {
              console.log("Appointment cancelled successfully");
              dispatch(
                patientActions.updateVAStatus(patientDetail, (resCb) => {
                  if (resCb) {
                    setShowAppointedPatients(false);
                    setStatus({ type: "success" });
                    ServerResVal.success = "Appointment cancelled successfully";
                    console.log("response close button:", resCb);
                    let data = {};
                    setTimeout(() => {
                      closeAlert();
                    }, 3000);
                    renderVisitLists(patientDetail.patientId);
                    dispatch(
                      patientActions.ListVisits(data, (resp) => {
                        if (resp) {
                          resp.patsearchreslt?.forEach((item) => {
                            if (item.visit && item.visit.status === "open") {
                              patientListing.push(item);
                            }
                          });
                          getdoctorCalender(doctorCalender);
                          getCal.apptdate = format(new Date(), "yyyy-MM-dd");
                          getCal.gvid = appointmentDetail.counselor;
                          getCalDoc(getCal);
                          console.log("Before resetting timeSlots:", timeSlots);
                          let time = appointmentDetail.appthours;
                          let time1 = appointmentDetail.apptminutes;
                          let mergedTime = `${time}:${time1}`;
                          console.log("time", mergedTime);
                          addNewAppointment(mergedTime, timeSlots[0]);
                          defaultDateSelection();
                          setShowAppointedPatients(false);
                          setFlagStaus(false);
                          setAppointedPatient([]);
                          setAppointedPatientList([]);
                          setCancelAppointmentDetails([]);
                        }
                      })
                    );
                  }
                })
              );
            }
          }
        )
      );
    }

    if (cancelScheduledAppointment == "cancelScheduledAppointment") {
      console.log("toCancelSlot", toCancelSlot);
      let temp = toCancelSlot.gvid;
      toCancelSlot.gvid = temp.toString();
      dispatch(
        patientActions.cancelDoctorCalenderByPatientId(
          toCancelSlot,
          (resCb) => {
            if (resCb && resCb.successMessages) {
              setStatus({ type: "success" });
              setTimeout(() => {
                closeAlert();
              }, 3000);
              submit();
            }
          }
        )
      );
    }
    setCancelAppt(false);
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };

  const cancelAppointment = (data, item) => {
    let curDate = format(new Date(), "yyyy-mm-dd");
    console.log("item: ", item);
    let doctor = "";
    let vaId = "";
    cancelAppointmentDetails.push(item);
    for (let i = 0; i < item.visitAssignmentList.length; i++) {
      let assignVisit = item.visitAssignmentList[i];
      if (type == "doctor") {
        if (
          "Doctor" === assignVisit.type &&
          assignVisit.targetid != 0 &&
          assignVisit.status === "open"
        ) {
          doctor = assignVisit.targetid;
          vaId = assignVisit.id;
          console.log("Doctor type", doctor);
          break;
        }
      } else if (type == "counsellor") {
        if (
          "Counsellor" === assignVisit.type &&
          assignVisit.targetid != 0 &&
          assignVisit.status === "open"
        ) {
          doctor = assignVisit.targetid;
          vaId = assignVisit.id;
          console.log("Counsellor type", doctor);
          break;
        }
      }
    }
    console.log("Visit Details", visitdetail, appointmentDetail);
    console.log("item", item);
    console.log("toCancelSlot", toCancelSlot, item, proceduredata);
    toCancelSlot["gvid"] = doctor;
    toCancelSlot["type"] = "Doctor";
    if (item && item.cancelExistingAppt && item.cancelExistingAppt != false) {
      toCancelSlot["patientId"] = item.id;
      if (item.visit.apptdate == undefined) {
        toCancelSlot["date"] = item.visit.capptdate;
        console.log("capptdate", item.visit.capptdate);
      } else {
        toCancelSlot["date"] = item.visit.apptdate;
      }
      toCancelSlot["vaid"] = vaId;
    } else {
      toCancelSlot["patientId"] = appointmentDetail.patientId;
      if (appointmentDetail.v.apptdate == undefined) {
        toCancelSlot["date"] = appointmentDetail.v.capptdate;
        console.log("capptdate", appointmentDetail.v.capptdate);
      } else {
        toCancelSlot["date"] = appointmentDetail.v.apptdate;
      }
      toCancelSlot["vaid"] = appointmentDetail.vaId;
    }
    patientDetail.revisitdate = defaultDate;
    console.log(
      "toCancelSlot, cancelAppointmentDetails",
      toCancelSlot,
      cancelAppointmentDetails
    );
    setShowAppointedPatients(false);
    setCancelAppt(true);
  };

  const appoinmentChecked = () => {
    let currentDate = new Date();
    let apptDate = new Date(selectedPatient.visit.apptdate);
    let capptDate = new Date(selectedPatient.visit.capptdate);
    currentDate.setHours(0, 0, 0, 0);
    apptDate.setHours(0, 0, 0, 0);
    capptDate.setHours(0, 0, 0, 0);
    let cancelScheduledAppointment = "cancelScheduledAppointment";
    let latestGvid;
    let cdDate = type === "doctor" ? apptDate : capptDate;
    console.log("Visit Details", currentDate, apptDate, capptDate, cdDate);
    if (
      selectedPatient &&
      selectedPatient.visit &&
      cdDate &&
      cdDate >= currentDate
    ) {
      console.log("selected Patient", selectedPatient.visit);
      let date = format(new Date(), "yyyy-mm-dd");
      console.log("selected Patient", selectedPatient.visit);
      cancelAppointment(timeSlots, selectedPatient);
      console.log("Calling Cancel");
      cancel(cancelScheduledAppointment);
      // submit();
    }
    console.log("Calling Submit");
  };

  const setVisitAssigmentList = (patient) => {
    let check = "";
    let doctor = "";
    let doctorl = "";
    let selectedType = "";
    appointmentDetail.va = "";
    appointmentDetail.vaId = "";
    console.log("selectedType", patient);
    for (let i = 0; i < patient.visitAssignmentList.length; i++) {
      let assignVisit = patient.visitAssignmentList[i];
      console.log("assignVisit: ", assignVisit);
      // Your logic here for each 'va' object
      if (
        patient.type &&
        patient.type != undefined &&
        patient.type != null &&
        patient.type != ""
      ) {
        selectedType = patient.type;
      } else {
        selectedType = type;
      }
      console.log("selectedType", selectedType);
      if (selectedType == "doctor") {
        if (
          "Doctor" === assignVisit.type &&
          assignVisit.targetid != 0 &&
          assignVisit.status === "open"
        ) {
          appointmentDetail.va = assignVisit;
          appointmentDetail.vaId = assignVisit.id;
          doctor = assignVisit.targetid;
          console.log("assignVisit", assignVisit);
          console.log("Doctor type", doctor);
          check = false;
          break;
        }
      } else if (selectedType == "counsellor") {
        if (
          "Counsellor" === assignVisit.type &&
          assignVisit.targetid != 0 &&
          assignVisit.status === "open"
        ) {
          appointmentDetail.va = assignVisit;
          appointmentDetail.vaId = assignVisit.id;
          doctor = assignVisit.targetid;
          console.log("Counsellor type", doctor);
          check = false;
          break;
        }
      }
    }
    if (check != false) {
      appointmentDetail.va = patient.visitAssignment;
      console.log("Vissitassinment", appointmentDetail);
    }
    if (doctor && doctor !== undefined && doctor !== null) {
      doctorl = doctorList.find((doc) => doc.gvid === doctor);
    }
    setProcedureSelectedDoctor(doctorl);
    console.log("Vissitassinment", appointmentDetail, check, type, doctor);
  };

  const bookAppointmentPat = (event, patient) => {
    console.log(
      "Visit Details",
      event,
      patient,
      visitdetail,
      appointmentDetail,
      alreadyAppoinmented,
      patient,
      new Date(patient.visit.apptdate),
      new Date()
    );
    if (flagStatus == false) {
      let currentDate = new Date();
      let curDate = format(currentDate, "yyyy-MM-dd");
      console.log("currentdate:", curDate);
      setSelecetdDate(curDate);
      appointmentDetail.apptdate = curDate;
      visitdetail.apptdate = curDate;
      setappointmentDetail(appointmentDetail);
      getdoctorCalender(doctorCalender);
    }
    let currentDate = new Date();
    let apptDate = new Date(patient.visit.apptdate);
    let capptDate = new Date(patient.visit.capptdate);
    currentDate.setHours(0, 0, 0, 0);
    apptDate.setHours(0, 0, 0, 0);
    capptDate.setHours(0, 0, 0, 0);

    console.log("Visit Details", currentDate, apptDate, capptDate);
    let typeV = "showAllVisits";
    let cdDate = type === "doctor" ? apptDate : capptDate;
    console.log("Visit Details", currentDate, apptDate, capptDate, cdDate);
    if (
      selectedPatient &&
      selectedPatient.visit &&
      cdDate &&
      cdDate >= currentDate &&
      selectedPatient.visit.status !== "close"
    ) {
      console.log("selected Patient", selectedPatient.visit);
      let typeV = "showAllVisits";
      let proDetails;
      dispatch(
        patientActions.getPatientProcedureInfo(
          selectedPatient.id,
          selectedPatient.visit.id,
          typeV,
          (resCb) => {
            if (resCb) {
              console.log(
                "procedurein cancel",
                resCb.patientresp.procedure.gvid
              );
              selectedPatient["latestGvid"] = resCb.patientresp.procedure.gvid;
              console.log("prodetails", proDetails);
            }
          }
        )
      );
      setVisitAssigmentList(selectedPatient);
      setAlreadyAppoinmented(true);
    }
    if (alreadyAppoinmented === false) {
      setBookAppt(true);
      console.log("prodetails", alreadyAppoinmented);
    } else {
      setBookAppt(false);
      console.log("prodetails", alreadyAppoinmented);
    }
  };

  const closePatientOnboard = () => {
    setPatientListing([]);
    let type = "OutPatient";
    let admissionDateTime = "";
    console.log("type in log:", type);
    let visitListDetails = patientProcedureList;
    visitListDetails.iStatus = "NotInSite";
    visitListDetails.onboardStatus = "reVisitPatient";
    console.log("visitlist details in onboard", visitListDetails);
    visitListDetails.appointmentFlag=true;
    let patientresp = [];
    dispatch(
      patientActions.onboardPatient(
        visitListDetails,
        type,
        admissionDateTime,
        (resCb) => {
          if (resCb) {
            console.log("onboardPatient response", resCb.patientresp);

            patientresp = resCb.patientresp;
            paymentLists.push(cardPay);
            paymentLists.push(cashPay);
            paymentLists.push(upiPay);
            let patientId;
            let visitId;
            if (resCb.patientresp.visit) {
              patientId = resCb.patientresp.visit.patientId;
              visitId = resCb.patientresp.visit.id;
            }
            let erpCustId = resCb.patientresp.erpCustId;
            let name = resCb.patientresp.fname;
            let type = "Override";
            setSelectedOption("Override");
            paymentDetails.remainingAmount = 0;
            paymentDetails.totalAmount = 0;
            dispatch(
              patientActions.registrationFees(
                erpCustId,
                paymentDetails.totalAmount,
                visitId,
                patientId,
                type,
                paymentLists,
                (resCb) => {
                  if (resCb) {
                    console.log("registration fees api response", resCb);
                    appointmentDetail.v = "";
                    appointmentDetail.va = "";
                    appointmentDetail.vaId = "";
                    let data = {};
                    dispatch(
                      patientActions.ListVisits(data, (resp) => {
                        if (resp) {
                          resp.patsearchreslt?.forEach((item) => {
                            if (item.id === patientId) {
                              console.log("item hehe:", item);
                            }
                            if (item.visit && item.visit.status === "open") {
                              patientListing.push(item);
                            }
                          });

                          patientSelected(
                            patientresp,
                            "ClosedPAtientAppointment"
                          );
                        }
                      })
                    );
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const closedPatientAppointment = (patientresp) => {
    var ServerResVal = ServerRes;
    let tokenNumber;
    let patientResp = [];
    let typeV = "showAllVisits";
    console.log(
      "selected docator",
      patientresp,
      patientresp.visit,
      appointmentDetail
    );
    if (appointmentDetail.apptdate) {
      console.log("appointmentDetail if: ", appointmentDetail);
      appointmentDetail.v.visitDate = appointmentDetail.apptdate;
    } else {
      console.log("appointmentDetail else: ", appointmentDetail);
      appointmentDetail.v.visitDate = appointmentDetail.v.visitDate;
    }
    // appointmentDetail.v.visitDate =  appointmentDetail?.apptdate ?? appointmentDetail?.v?.visitDate ?? selecetdDate;
    console.log("selected docator", selectedDoctor, selectedPatient);
    console.log("Visit Details", visitdetail, appointmentDetail);
    console.log("type", type);
    let validation = validateField();
    console.log("validation", validation);
    if (validation == true) {
      if (flagStatus == false) {
        let currentDate = new Date();
        let curDate = format(currentDate, "yyyy-MM-dd");
        console.log("currentdate:", curDate);
        setSelecetdDate(curDate);
        appointmentDetail.apptdate = curDate;
        visitdetail.apptdate = curDate;
        setappointmentDetail(appointmentDetail);
        getdoctorCalender(doctorCalender);
      }
      validateFieldForApptDate(true);
      validateFieldTimeSlots(true);
      let typeA = "";
      if (type == "doctor") {
        typeA = "Doctor";
      } else {
        typeA = "Counsellor";
      }
      console.log("Appointment details is", appointmentDetail);
      if (
        appointmentDetail.apptdate == null ||
        appointmentDetail.apptdate == undefined
      ) {
        appointmentDetail.apptdate = new Date();
        console.log("AppointmentDetails", appointmentDetail.apptdate);
      }
      dispatch(
        patientActions.addAppointment(appointmentDetail, typeA, (resCb) => {
          if (resCb) {
            setStatus({ type: "success" });
            ServerResVal.success = "Appointment Scheduled Successfully.";
            renderVisitLists(selectedPatient.id);
            let data = {};
            dispatch(
              patientActions.ListVisits(data, (resCb) => {
                if (resCb) {
                  let updatedPatient = [];
                  console.log("PatientList in 214", resCb);
                  updatedPatient = patientListing.map((item) => {
                    let match = resCb.patsearchreslt?.find(
                      (rItem) =>
                        rItem.id === item.id &&
                        rItem.visit &&
                        rItem.visit.status === "open"
                    );
                    return match ? match : item;
                  });
                  console.log("updatedPatient", updatedPatient);
                  setPatientListing(updatedPatient);
                  setServerRes({ ...ServerResVal });
                  setTimeout(() => {
                    closeAlert();
                    dispatch(
                      patientActions.getPatientProcedureInfo(
                        selectedPatient.id,
                        selectedPatient.visit.id,
                        typeV,
                        (resCb) => {
                          if (resCb) {
                            setProceduredata([]);
                            setProcdata([]);
                            console.log(
                              "procedure list in api: ",
                              resCb.patientresp
                            );
                            proceduredata.push(resCb.patientresp.procedure);
                            let proDetails = proceduredata.filter(
                              (doc) => doc.groupname === "Appointment"
                            );
                            console.log("proceduredata", proDetails);

                            console.log(
                              "proceduredata",
                              proceduredata,
                              doctorList
                            );
                            let doctorl = null;

                            if (
                              Array.isArray(proceduredata) &&
                              proceduredata.length > 0
                            ) {
                              doctorl = doctorList.find(
                                (doctor) =>
                                  doctor.gvid ===
                                  proceduredata[proceduredata.length - 1]?.gvid
                              );
                            }
                            procdata.push(doctorl);
                            console.log("doctor", doctorl);
                            setTokenNumber(resCb.patientresp.tokenNumber);
                          }
                        }
                      )
                    );
                  }, 3000);
                  // getdoctorCalender(doctorCalender);
                  console.log("Before resetting timeSlots:", timeSlots);
                  getCal.apptdate = format(new Date(), "yyyy-MM-dd");
                  getCal.gvid = appointmentDetail.counselor; // Change this line to getCal.gvid
                  getCalDoc(getCal);
                  defaultDateSelection();
                  let time = appointmentDetail.appthours;
                  let time1 = appointmentDetail.apptminutes;
                  // Merge the hh:mm format
                  let mergedTime = `${time}:${time1}`;
                  console.log("time", mergedTime);
                  addNewAppointment(mergedTime, timeSlots[0], "closeTimeSlot");
                  console.log("patientListing", patientListing);
                  setShowAppointedPatients(false);
                  setAppointedPatientList([]);
                }
              })
            );
          }
        })
      );
    }
    console.log("selectedpatient in book", selectedPatient);
    setAppointedPatientList([]);
    setBookAppt(false);
    setAlreadyAppoinmented(false);
  };
  const submit = () => {
    validateField();
    var ServerResVal = ServerRes;
    let tokenNumber;
    let patientResp = [];
    let typeV = "showAllVisits";
    if (
      selectedPatient &&
      selectedPatient.visit &&
      selectedPatient.visit.status === "close"
    ) {
      closePatientOnboard();
    } else {
      // appointmentDetail.v =
      //   patientResp.length > 0
      //     ? patientResp.visit
      //     : selectedPatient?.visit ?? null;
      if (appointmentDetail.apptdate) {
        console.log("appointmentDetail if: ", appointmentDetail);
        appointmentDetail.v.visitDate = appointmentDetail.apptdate;
      } else {
        console.log("appointmentDetail else: ", appointmentDetail);
        appointmentDetail.v.visitDate = appointmentDetail.v.visitDate;
      }
      // appointmentDetail.v.visitDate =
      //   appointmentDetail?.apptdate ?? appointmentDetail?.v?.visitDate;
      // appointmentDetail.v.visitDate.setHours= appointmentDetail?.appthours;
      // appointmentDetail.v.visitDate.setMinutes= appointmentDetail?.apptminutes;
      console.log("selected docator", selectedDoctor, selectedPatient);
      console.log("Visit Details", visitdetail, appointmentDetail);
      console.log("type", type);

      let validation = validateField();
      console.log("validation", validation, flagStatus);
      if (validation == true) {
        if (flagStatus == false) {
          let currentDate = new Date();
          let curDate = format(currentDate, "yyyy-MM-dd");
          console.log("currentdate:", curDate);
          setSelecetdDate(curDate);
          appointmentDetail.apptdate = curDate;
          visitdetail.apptdate = curDate;
          setappointmentDetail(appointmentDetail);
          getdoctorCalender(doctorCalender);
        }
        validateFieldForApptDate(true);
        validateFieldTimeSlots(true);
        // let type = props.type;
        let typeA = "";
        if (type == "doctor") {
          typeA = "Doctor";
        } else {
          typeA = "Counsellor";
        }

        console.log("Appointment details is", appointmentDetail);
        if (
          appointmentDetail.apptdate == null ||
          appointmentDetail.apptdate == undefined
        ) {
          appointmentDetail.apptdate = format(new Date(), "yyyy-MM-dd");
          console.log("AppointmentDetails", appointmentDetail.apptdate);
        }
        dispatch(
          patientActions.addAppointment(appointmentDetail, typeA, (resCb) => {
            if (resCb) {
              setStatus({ type: "success" });
              ServerResVal.success = "Appointment Scheduled Successfully.";
              renderVisitLists(selectedPatient.id);
              let data = {};
              dispatch(
                patientActions.ListVisits(data, (resCb) => {
                  if (resCb) {
                    let updatedPatient = [];
                    console.log("PatientList in 214", resCb);
                    updatedPatient = patientListing.map((item) => {
                      let match = resCb.patsearchreslt?.find(
                        (rItem) =>
                          rItem.id === item.id &&
                          rItem.visit &&
                          rItem.visit.status === "open"
                      );
                      return match ? match : item;
                    });
                    console.log("updatedPatient", updatedPatient);
                    setPatientListing(updatedPatient);
                    setServerRes({ ...ServerResVal });
                    setTimeout(() => {
                      closeAlert();
                      dispatch(
                        patientActions.getPatientProcedureInfo(
                          selectedPatient.id,
                          selectedPatient.visit.id,
                          typeV,
                          (resCb) => {
                            if (resCb) {
                              setProceduredata([]);
                              setProcdata([]);
                              console.log(
                                "procedure list in api: ",
                                resCb.patientresp
                              );
                              proceduredata.push(resCb.patientresp.procedure);
                              let proDetails = proceduredata.filter(
                                (doc) => doc.groupname === "Appointment"
                              );
                              console.log("proceduredata", proDetails);

                              console.log(
                                "proceduredata",
                                proceduredata,
                                doctorList
                              );
                              let doctorl = null;

                              if (
                                Array.isArray(proceduredata) &&
                                proceduredata.length > 0
                              ) {
                                doctorl = doctorList.find(
                                  (doctor) =>
                                    doctor.gvid ===
                                    proceduredata[proceduredata.length - 1]
                                      ?.gvid
                                );
                              }
                              procdata.push(doctorl);
                              console.log("doctor", doctorl);
                              setTokenNumber(resCb.patientresp.tokenNumber);
                            }
                          }
                        )
                      );
                    }, 3000);
                    // getdoctorCalender(docCalender);
                    getCal.apptdate = format(new Date(), "yyyy-MM-dd");
                    getCal.gvid = appointmentDetail.counselor; // Change this line to getCal.gvid
                    getCalDoc(getCal);
                    console.log("Before resetting timeSlots:", timeSlots);
                    defaultDateSelection();
                    let time = appointmentDetail.appthours;
                    let time1 = appointmentDetail.apptminutes;
                    // Merge the hh:mm format
                    let mergedTime = `${time}:${time1}`;
                    addNewAppointment(
                      mergedTime,
                      timeSlots[0],
                      "closeTimeSlot"
                    );
                    console.log("time", mergedTime);
                    console.log("patientListing", patientListing);
                    setShowAppointedPatients(false);
                    setAppointedPatientList([]);
                  }
                })
              );
            }
          })
        );
      }
      console.log("selectedpatient in book", selectedPatient);
      setAppointedPatientList([]);
      setBookAppt(false);
      setAlreadyAppoinmented(false);
    }
  };

  const tokenChange = (visitId, gvId, typeaa, params) => {
    console.log("tokenChange Called", selectedPatient);
    console.log("tokenNumber", tokenNumber, type);
    let patient = selectedPatient !== undefined ? selectedPatient : params;
    console.log("toekn", patient);
    if (
      patient &&
      patient.visit &&
      patient.visit.type == "OutPatient" &&
      patient.visit.status == "open" &&
      typeaa == "doctor"
    ) {
      console.log("insideTokenChange");
      if (parseInt(tokenNumber) > 0) {
        dispatch(
          tokenActions.recorrectToken(visitId, gvId, (resCb) => {
            if (resCb) {
              console.log("token responses", resCb.token);
            }
          })
        );
      } else {
        dispatch(
          tokenActions.tokenGenerate(visitId, gvId, (resCb) => {
            if (resCb) {
              console.log("token responses", resCb.token);
            }
          })
        );
      }
    }
  };

  const patientSelected = (patient, data) => {
    let check = "";
    let doctor = "";
    let sPatient = [];
    let typeV = "showAllVisits";
    setBookedPatientSlots([]);
    setAppointedPatientList([]);
    setShowAppointedPatients(false);
    console.log("Patient:", patient);

    let curDate = format(new Date(), "yyyy-MM-dd");
    if (
      data !== "ClosedPAtientAppointment" &&
      (disableTimeSolt.length == 1 || timeSlots.length > 0)
    ) {
      setDisableTimeSolt([]);
      let time = appointmentDetail.appthours;
      let time1 = appointmentDetail.apptminutes;
      let mergedTime = `${time}:${time1}`;
      console.log("time", mergedTime);
      addNewAppointment(mergedTime, disableTimeSolt[0]);
    }
    console.log("updatedTimesolt", filteredTimeSlot);
    // let fDate=filteredTimeSlot.map()
    setshowPatientDetail(false);
    setAddFlag(false);
    setInputValue(patient.fname);
    setInputValue(patient);
    if (patient != null) {
      setBookAppointment(true);
    }
    setDisplaySearchPatient(null);
    dispatch(
      patientActions.getVisitListById(patient.visit.id, typeV, (resCb) => {
        if (resCb) {
          console.log("procedurelist", resCb.patsearchreslt[0]);
          setSelectedPatient(resCb.patsearchreslt[0]);
          sPatient = resCb.patsearchreslt[0];
          appointmentDetail.v = resCb.patsearchreslt[0].visit;
          if (
            resCb.patsearchreslt[0] &&
            resCb.patsearchreslt[0].visitAssignmentList
          ) {
            sPatient.type = patient.type;
            setVisitAssigmentList(sPatient);
          }
          if (
            appointmentDetail.va === undefined ||
            appointmentDetail.va === ""
          ) {
            appointmentDetail.va =
              resCb.patsearchreslt[0]?.visitAssignment ?? null;
          }
          if (
            appointmentDetail.vaId === undefined ||
            appointmentDetail.vaId === ""
          ) {
            appointmentDetail.vaId =
              resCb.patsearchreslt[0]?.visitAssignment?.id ?? null;
          }
          appointmentDetail.patientId = resCb.patsearchreslt[0].id;
          appointmentDetail.visitId = resCb.patsearchreslt[0].visit.id;

          dispatch(
            patientActions.getPatientProcedureInfo(
              patient.id,
              patient.visit.id,
              typeV,
              (resp) => {
                if (resp) {
                  console.log(
                    "procedurein cancel",
                    resp.patientresp.procedure.gvid
                  );
                  setPatientProcedureList({ ...resp.patientresp });
                }
              }
            )
          );
          if (data && data === "ClosedPAtientAppointment") {
            closedPatientAppointment(patient);
          }
        }
        if (!revisitFlag) {
          const formattedDate = format(new Date(selecetdDate), "yyyy-MM-dd");
          getCal.apptdate = formattedDate;
          getCal.gvid = appointmentDetail.counselor; // Change this line to getCal.gvid
          getCalDoc(getCal, patient);
        }
      })
    );
    console.log("selected Patient", selectedPatient);
    console.log("selected Patient last", selectedPatient);
    console.log("appointmentDetail", appointmentDetail);
    console.log(
      "Vissitassinment",
      appointmentDetail,
      check,
      type,
      doctor,
      data
    );
    if (patient && patient != null) {
      let appdate =
        patient?.visit?.apptdate ?? patient?.visit?.capptdate ?? null;
      console.log(
        "appointedPatientListingcalled",
        appdate,
        curDate,
        selecetdDate
      );
      if (appdate != null && curDate == appdate && appdate == selecetdDate) {
        setShowAppointedPatients(true);
        console.log("appointedPatientListingcalled", appdate);
        appointedPatientListing(patient.id.toString());
      }
    }
    if (bookedPatientSlots.length > 0) {
      console.log("bookedPatientSlots", bookedPatientSlots);
    }
  };

  const formatdatentime = (date) => {
    if (!date) return;
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    setRequestedtime({
      time: formatteddate,
    });
    return formatteddate;
  };

  const toggle = (event) => {
    setBookAppt(false);
    setAlreadyAppoinmented(false);
  };

  const toggleCancel = (event) => {
    setCancelAppt(false);
    setAlreadyAppoinmented(false);
  };

  const inSitePatient = (params) => {
    let doctor;
    let state = "";
    let visitDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    const dateTimeString = `${visitDate}`;
    if (params.visit.internalStatus == "notInSite") {
      dispatch(
        patientActions.UpdateToInSitePatient(
          params.visit.id,
          dateTimeString,
          (resCb) => {
            if (resCb) {
              state = resCb.paymentStatus;
              if (params && params.visitAssignmentList.length > 0) {
                for (let i = 0; i < params.visitAssignmentList.length; i++) {
                  let assignVisit = params.visitAssignmentList[i];
                  console.log("assignVisit: ", assignVisit);
                  if (
                    "Doctor" === assignVisit.type &&
                    assignVisit.targetid != 0 &&
                    assignVisit.status === "open"
                  ) {
                    doctor = assignVisit.targetid;
                    console.log("Doctor type", doctor);
                    break;
                  }
                }
              }
              if (doctor && doctor != null && doctor != undefined) {
                tokenChange(params.visit.id, doctor, "doctor", params);
              }
              console.log("procedurelist", resCb);
              let lastVisit = sixMonthsPrior(params.lastRegisteredOn);
              console.log("lastRegisteredOn", lastVisit);
              if (state == "Payment") {
                history.push({
                  pathname: `/Payment/${params.fname}/${params.id}/${params.visit.id}/${params.erpCustId}`,
                });
              } else {
                history.push({
                  pathname: `/PatientSections/${params.id}/${params.visit.id}`,
                });
              }
            }
          }
        )
      );
    }
  };

  const sixMonthsPrior = (date) => {
    let v;
    var d = new Date(date);
    var t = new Date();
    const addmonths = addMonths(d, 6);
    let diff = isBefore(addmonths, t) || isSameDay(addmonths, t);
    if (diff === true) {
      v = true;
    }
    return v;
  };

  const closeAndOnboard = (data, type) => {
    let isExits = patientListing.find((obj) => obj.id === data.visit.patientId);
    console.log(isExits);
    isExits.type = data.type;
    if (type === "insite") {
      inSitePatient(isExits);
    } else if (type === "close") {
      isExits.cancelExistingAppt = true;
      cancelAppointment(timeSlots, isExits);
    }
  };
  const columns = [
    {
      headerName: "Name(MAIN)",
      field: "name",
      width: 180,
      maxWidth: 250,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
      },
      valueFormatter: ({ data }) => `${data.name}`,
    },
    {
      headerName: "Phone No",
      field: "phno",
      width: 200,
      maxWidth: 230,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
      },
      valueFormatter: ({ data }) => `${data.phno}`,
    },
    {
      headerName: "Address",
      field: "city",
      width: 180,
      maxWidth: 200,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
      },
      valueFormatter: ({ data }) => `${data.address}`,
    },
    {
      headerName: "Type",
      field: "state",
      width: 150,
      maxWidth: 150,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
      },
      valueFormatter: ({ data }) => `${data.type}`,
    },
    {
      headerName: "Appointment Time",
      field: "time",
      width: 180,
      maxWidth: 250,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
      },
      valueFormatter: ({ data }) => {
        if (data && data.time) {
          const date = new Date(data.time);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          const formattedTime = `${hours}:${minutes} ${ampm}`;
          return formattedTime;
        }
        return "";
      },
    },
    {
      headerName: "Actions",
      width: 220,
      maxWidth: 250,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
        textTransform: "capitalize",
      },
      cellRendererFramework: (params) => (
        <div>
          {console.log("action", params)}
          {console.log("params.data.visit.internalStatus", params)}
          {params.data.visit.internalStatus &&
            params.data.visit.internalStatus === "notInSite" && (
              <>
                <a title="Onboard">
                  <i
                    className="material-icons mr-1 "
                    style={{
                      color: "#a32424",
                      marginLeft: "10px",
                      marginTop: "3px",
                    }}
                    onClick={(event) => closeAndOnboard(params.data, "insite")}
                  >
                    add
                  </i>
                </a>
              </>
            )}
          {params.data.visit.internalStatus && (
            <>
              <a title="Cancel Appoinment">
                <i
                  className={`material-icons mr-1 ${
                    flag ? "save-disabled" : ""
                  }`}
                  style={{
                    color: "#a32424",
                    cursor:
                      params.data.visit.internalStatus !== "notInSite"
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={
                    params.data.visit.internalStatus !== "notInSite"
                      ? undefined
                      : (event) => closeAndOnboard(params.data, "close")
                  }
                >
                  delete
                </i>
              </a>
            </>
          )}
        </div>
      ),
    },
  ];

  const getPatientFormattedDate = (date, patient) => {
    const reqdate = new Date(date);
    console.log("getPatientFormattedDate", reqdate, date, patient);
    let formatteddate = format(reqdate, "dd-MMM-yyyy");
    return formatteddate;
  };

  const closePatientFormModal = () => {
    setOpenAddPatientModal(false);
    setAddNew(false);
  };

  const updatePatientDetails = (event, payment) => {
    setPatientListing([]);
    console.log("patient added successfully:", event);
    if (event) {
      if (event.successMessages) {
        setMessageDetails({
          message: event.successMessages[0],
          success: "success",
        });
        setTimeout(() => {
          paymentLists.push(cardPay);
          paymentLists.push(cashPay);
          paymentLists.push(upiPay);
          let patientId;
          let visitId;
          if (event.patientresp.visit) {
            patientId = event.patientresp.visit.patientId;
            visitId = event.patientresp.visit.id;
          }
          let erpCustId = event.patientresp.erpCustId;
          let name = event.patientresp.fname;
          let type = "Override";
          setSelectedOption("Override");
          paymentDetails.remainingAmount = 0;
          paymentDetails.totalAmount = 0;
          dispatch(
            patientActions.registrationFees(
              erpCustId,
              paymentDetails.totalAmount,
              visitId,
              patientId,
              type,
              paymentLists,
              (resCb) => {
                if (resCb) {
                  console.log("registration fees api response", resCb);
                  patientSelected(event.patientresp);
                  patientListDetails();
                }
              }
            )
          );
        }, 3000);
      } else {
        setMessageDetails({
          message: event.successMessages[0],
          error: "error",
        });
      }
    }
  };

  const handleClick = (pDetail, isAddNew) => {
    setAddFlag(false);
    console.log("selected patient:", pDetail);

    if (isAddNew === true) {
      console.log("isAddNew:", isAddNew);
      setAddNew(true);
      setOpenAddPatientModal(true);
      setshowPatientDetail(true);
      dispatch(patientActions.setPatientDetails(pDetail));
    }
  };

  const handleOnChange = (value, event) => {
    if (value === "revisit") {
      patientDetail.revisitdate = event.target.value;
      console.log("Seleected Date is", event.target.value, event);
      console.log("Seleected Date is", typeof selecteddate);
    }
  };

  return (
    <>
      {ServerRes.success && (
        <Alert severity="success">{ServerRes.success}</Alert>
      )}
      {ServerRes.error && <Alert severity="error">{ServerRes.error}</Alert>}

      <main className="h-100 m-4">
        <div id="admin-appointment" className="ad-app appointment-modal ">
          <div className="d-flex justify-content-between">
            <DialogTitle className="" style={{ marginLeft: "15px" }}>
              Schedule Appointment
            </DialogTitle>
            {addNew && (
              <Components.PatientForm
                patientInfo={[]}
                openPatientForm={openAddPatientModal}
                type="adminAppoinment"
                closePatientForm={() => closePatientFormModal()}
                onSuccess={(event) => updatePatientDetails(event, "payment")}
              />
            )}
          </div>

          <div className="ad-app-search d-flex border-right px-3 py-2">
            <div className="pr-4 py-0 w-50">
              <label className="label">Doctor/Counsellor: </label>
              {console.log("doctorList", doctorList)}
              <Select
                {...doctorList}
                className="select-tag"
                classNamePrefix="select-options w-100"
                name="Search Doctor"
                value={
                  doctorList && doctorList.length > 0
                    ? doctorList.filter(
                        (option) => option.label === selectedDoctor
                      )
                    : null
                }
                onChange={handleDeptChange}
                isClearable
                isSearchable
                options={doctorList}
                filterOption={createFilter(doctorList)}
                defaultValue={selectedDoctor}
              />
              <div style={{ color: "#EC4147" }}>
                <span className="err-msg">{formErrors.counselor}</span>
              </div>
            </div>
            <div className="w-50 position-relative mx-2" id="ad-ap-patient">
              <label className="label">Patients: </label>
              <TextField
                className="navbar-search mb-2 menu-search bg-white custom-text"
                placeholder="Search Patients by Name/ MAIN..."
                autoFocus
                type="text"
                value={selectedPatient?.fname}
                disabled={!selectedDoctor}
                onClick={() => setAddFlag(true)}
                onChange={(event) => {
                  handleInputChange(event);
                }}
                InputProps={{
                  style: { paddingLeft: "15px", height: "38px" },
                }}
              />

              <div className="mt-2 shadow product-list-container position-absolute w-100">
                {console.log("displaySearchPatient", displaySearchPatient)}
                {addflag && (
                  <>
                    <div
                      className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column fw-bold"
                      onClick={(event) => handleClick("", true)}
                    >
                      + Add New Patient
                    </div>
                    {displaySearchPatient &&
                      displaySearchPatient.length > 0 &&
                      displaySearchPatient.map(
                        (patient, index) =>
                          patient.visit &&
                          (patient.visit.status == "close" ||
                            patient.visit.internalStatus === "notInSite") && (
                            <div
                              key={index}
                              className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                              onClick={(event) => patientSelected(patient)}
                            >
                              <div className="d-flex flex-wrap justify-content-between">
                                <h6 className="mb-0 pr-2">{patient.fname}</h6>
                                {patient.visit.status === "close" && (
                                  <span>
                                    Last Visit Date:{" "}
                                    {getPatientFormattedDate(
                                      patient.visit.visitDate,
                                      patient
                                    )}
                                  </span>
                                )}
                                <h6 className="text-muted">{patient.id}</h6>
                              </div>
                            </div>
                          )
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" onClick={() => setAddFlag(false)}>
            <div className="my-2 d-flex justify-content-between align-items-center">
              <span className="btn" onClick={(event) => prevSevenDays()}>
                <div className="material-icons arr-prev">arrow_back_ios</div>
              </span>
              {weekDays.map((item, idx) => (
                <div
                  key={idx}
                  className={
                    selecetdDate == item
                      ? "btn btn-date active"
                      : "btn btn-date"
                  }
                  onClick={(event) => handleSelect(item)}
                >
                  {getFormattedDate(item)}
                </div>
              ))}
              <span className="btn" onClick={(event) => nextSevenDays()}>
                <div className="material-icons arr-next">arrow_forward_ios</div>
              </span>
            </div>
            <DialogContent
              id="appointment-body"
              className="py-1 justify-content-between h-100"
            >
              <div className="w-100">
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={toggleMoringTime}
                  className="mb-2"
                  style={{ textAlign: `center` }}
                  size="sm"
                >
                  8:00 AM - 12:45 PM{" "}
                </Button>
                <Collapse className="mt-n2 border py-1" in={collapseMoringTime}>
                  <Table className="w-100 time-table">
                    <TableBody>
                      {APPT_MORNING_TIMINGS &&
                        APPT_MORNING_TIMINGS.map((item, idx) => (
                          <TableRow key={idx}>
                            {[
                              item.type1,
                              item.type2,
                              item.type3,
                              item.type4,
                            ].map((type, colIdx) => (
                              <TableCell
                                key={colIdx}
                                className={`${
                                  timeSlots.indexOf(type) !== -1
                                    ? "slot-booked"
                                    : bookedPatientSlots.includes(type)
                                    ? "slot-alreadybooked"
                                    : ""
                                } `}
                                onClick={(event) =>
                                  addNewAppointment(
                                    item[`time${colIdx + 1}`],
                                    type,
                                    console.log(
                                      timeSlots.indexOf(type),
                                      bookedPatientSlots.includes(type)
                                    )
                                  )
                                }
                              >
                                {item[`time${colIdx + 1}`]}
                                <span> (</span>
                                {bookedCountAtTime &&
                                bookedCountAtTime[type] > 0
                                  ? bookedCountAtTime[type]
                                  : 0}
                                <span>)</span>
                                {selecetdDate &&
                                  leaveSolts.indexOf(type) !== -1 && (
                                    <CloseIcon
                                      sx={{
                                        color: "red",
                                        fontSize: "15px",
                                        padding: "1px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <div style={{ color: "#EC4147" }}>
                    <span className="err-msg">
                      {formErrors.type}
                      {formErrors.apptdate}
                    </span>
                  </div>
                </Collapse>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={toggleEveTime}
                  className="mb-2 mt-3"
                  style={{ textAlign: `center` }}
                  size="sm"
                >
                  1:00 PM - 8:45 PM{" "}
                </Button>
                <Collapse className="mt-n2 border py-1" in={collapseEveTime}>
                  <Paper>
                    <Table className="w-100 time-table">
                      <TableBody>
                        {!formErrors.counselor ? (
                          <>
                            {APPT_EVENING_TIMINGS &&
                              APPT_EVENING_TIMINGS.map((item, idx) => (
                                <TableRow key={idx}>
                                  {[
                                    item.type1,
                                    item.type2,
                                    item.type3,
                                    item.type4,
                                  ].map((type, i) => (
                                    <TableCell
                                      key={i}
                                      className={`${
                                        timeSlots.indexOf(type) !== -1
                                          ? "slot-booked"
                                          : bookedPatientSlots.includes(type)
                                          ? "slot-alreadybooked"
                                          : ""
                                      } 
                                      `}
                                      onClick={(event) =>
                                        addNewAppointment(
                                          item[`time${i + 1}`],
                                          type
                                        )
                                      }
                                    >
                                      {item[`time${i + 1}`]}
                                      <span> (</span>
                                      {bookedCountAtTime &&
                                      bookedCountAtTime[type] > 0
                                        ? bookedCountAtTime[type]
                                        : 0}
                                      <span>)</span>
                                      {selecetdDate &&
                                        leaveSolts.indexOf(type) !== -1 && (
                                          <CloseIcon
                                            sx={{
                                              color: "red",
                                              fontSize: "15px",
                                              padding: "1px",
                                              marginLeft: "5px",
                                            }}
                                          />
                                        )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                    <div style={{ color: "#EC4147" }}>
                      <span className="err-msg">
                        {formErrors.type}
                        {formErrors.apptdate}
                      </span>
                    </div>
                  </Paper>
                </Collapse>
              </div>
            </DialogContent>
            {console.log(
              "length",
              appointedPatientList,
              appointedPatientList.length
            )}
            {showAppointedPatients === true &&
              appointedPatientList?.length > 0 && (
                // timeSlots?.length > 0
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "96%", marginLeft: "25px" }}
                >
                  <label
                    className="label pt-2"
                    style={{ marginBottom: "10px" }}
                  >
                    Patient details with appointment on{" "}
                    {getPatientFormattedDate(selecetdDate)}{" "}
                  </label>
                  <AgGridReact
                    rowData={appointedPatientList}
                    defaultColDef={{
                      sortable: true,
                      filter: true,
                      flex: 1,
                      minWidth: 100,
                    }}
                    columnDefs={columns}
                  />
                </div>
              )}
          </div>
          <>
            <DialogActions className="pt-3">
              {bookAppointment === true && (
                <div className={bookFlag ? "save-diabled" : ""}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    color="primary"
                    style={{ float: "inline-end", marginTop: "60px" }}
                    onClick={(event) =>
                      bookAppointmentPat(event, selectedPatient)
                    }
                    disabled={bookFlag}
                  >
                    Book Appointment
                  </Button>
                </div>
              )}
            </DialogActions>
          </>
          {alreadyAppoinmented ? (
            <Dialog
              open={bookAppt}
              onClose={toggle}
              className="d-flex w-95 h-90 flex-column-reverse modalDialog"
            >
              <div>
                <DialogTitle>
                  Confirmation..
                  <IconButton
                    aria-label="close"
                    onClick={(event) => toggle(event)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent className="dealocate">
                  <span>
                    {selectedPatient?.fname} ({selectedPatient?.id}) already has
                    an appointment with {procedureSelectedDoctor.label} on{" "}
                    {type === "doctor" ? (
                      <>
                        {selectedPatient?.visit?.apptdate}{" "}
                        {selectedPatient?.visit?.appthours
                          ? // selectedPatient?.visit?.apptminutes
                            `at ${selectedPatient?.visit?.appthours}:${
                              selectedPatient?.visit?.apptminutes
                            }${
                              selectedPatient?.visit?.appthours >= 12
                                ? " PM"
                                : " AM"
                            }`
                          : ""}
                      </>
                    ) : (
                      <>
                        {selectedPatient?.visit?.capptdate}{" "}
                        {selectedPatient?.visit?.cappthours
                          ? // selectedPatient?.visit?.capptminutes
                            `at ${selectedPatient?.visit?.cappthours}:${
                              selectedPatient?.visit?.capptminutes
                            }${
                              selectedPatient?.visit?.cappthours >= 12
                                ? " PM"
                                : " AM"
                            }`
                          : ""}
                      </>
                    )}
                    . Do you want to schedule the new appointment on{" "}
                    {appointmentDetail?.apptdate}{" "}
                    {appointmentDetail?.appthours
                      ? // appointmentDetail?.apptminutes
                        `at ${appointmentDetail?.appthours}:${
                          appointmentDetail?.apptminutes
                        }${appointmentDetail?.appthours >= 12 ? " PM" : " AM"}`
                      : ""}
                    ?
                  </span>
                  <br />
                  <span className="small">
                    *if you continue, old appoinment will get cancelled.*
                  </span>
                </DialogContent>

                <DialogActions
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-style bottomright"
                    onClick={(event) => toggle(event)}
                    style={{ textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-style bottomleft"
                    onClick={(event) => appoinmentChecked()}
                    style={{ textTransform: "none" }}
                  >
                    Cancel & Book Appointment
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          ) : (
            <Dialog
              open={bookAppt}
              onClose={toggle}
              className="d-flex w-95 h-90 flex-column-reverse modalDialog"
            >
              <div>
                <DialogTitle>
                  Booking Confirmation..
                  <IconButton
                    aria-label="close"
                    onClick={(event) => toggle(event)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent className="dealocate">
                  {console.log("selectedPatient in dialog", selectedPatient)}
                  <span>
                    You are about to book appointment for{" "}
                    {selectedPatient?.fname} ({selectedPatient?.id}) with{" "}
                    {selectedDoctor} on {appointmentDetail?.apptdate}{" "}
                    {appointmentDetail?.appthours
                      ? // appointmentDetail?.apptminutes
                        `at ${appointmentDetail?.appthours}:${
                          appointmentDetail?.apptminutes
                        }${appointmentDetail?.appthours >= 12 ? " PM" : " AM"}`
                      : ""}
                    .
                  </span>
                </DialogContent>

                <DialogActions
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-style bottomright"
                    onClick={(event) => toggle(event)}
                    style={{ textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-style bottomleft"
                    onClick={(event) => submit(event)}
                    style={{ textTransform: "none" }}
                  >
                    Book Appointment
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          )}
          <Dialog
            open={cancelAppt && !alreadyAppoinmented}
            onClose={toggleCancel}
            className="d-flex w-95 h-90 flex-column-reverse modalDialog"
          >
            <div>
              <DialogTitle>
                Cancel Confirmation
                <IconButton
                  aria-label="close"
                  onClick={(event) => toggleCancel(event)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent className="dealocate">
                <span>
                  You are about to cancel appoinment for{" "}
                  {cancelAppointmentDetails[0]?.fname} (
                  {cancelAppointmentDetails[0]?.id}) with {selectedDoctor} on{" "}
                  {cancelAppointmentDetails[0]?.visit?.apptdate}{" "}
                  {cancelAppointmentDetails[0]?.visit?.appthours !== 0
                    ? `at ${cancelAppointmentDetails[0]?.visit?.appthours}:${
                        cancelAppointmentDetails[0]?.visit?.apptminutes
                      }${
                        cancelAppointmentDetails[0]?.visit?.appthours >= 12
                          ? " PM"
                          : " AM"
                      }`
                    : ""}
                  .
                </span>
                <span style={{ paddingTop: "10px" }}>
                  <br />
                  <label>Select Followup date</label>
                  <div className="d-inline-block pl-0 col-lg-7 col-xl-7 col-md-12 col-12">
                    {/* Date Input */}
                    <div className="mr-2" style={{ marginLeft: "10px" }}>
                      <input
                        name="admissionDate"
                        id="admissionDate1"
                        className="form-control filterin Quantity"
                        placeholder="Enter Scheduled Date"
                        required
                        type="date"
                        defaultValue={patientDetail["revisitdate"]}
                        onChange={(event) => handleOnChange("revisit", event)}
                      />
                    </div>
                  </div>
                </span>
              </DialogContent>

              <DialogActions
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-style bottomleft"
                  onClick={(event) => toggleCancel(event)}
                  style={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-style bottomright"
                  onClick={(event) => cancel("CancelVisit")}
                  style={{ textTransform: "none" }}
                >
                  Cancel Appointment
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </main>
    </>
  );
}

export default AdminAppointment;
