import { BED_DETAILS } from "../../shared/constants";

const bedDetailsState = {
  bedDetails: [],
};

function setBedDetailsState(state = bedDetailsState, action) {
  switch (action.type) {
    case BED_DETAILS:
      return Object.assign({}, state, {
        bedDetails: action.payload,
      });

    default:
      return state;
  }
}
export default setBedDetailsState;
