import {CATEGORY_DETAILS,SERVICE_SAVE_SUCCESS,SERVICE_SAVE_FAILURE} from "../../shared/constants";
import {categoryServices} from '../services';

export function getAllServiceCategory(resCb){
    return (dispatch) => {
        dispatch(request(CATEGORY_DETAILS, {}));
        categoryServices.getAllServiceCategory().then(
            (res) => {
              console.log(res);
              if (res) {
                dispatch(success(CATEGORY_DETAILS, res.data));
              }
              resCb(res.data);
            },
            (error) => {
              resCb(error.toString);
            }
        );
    };

}

export function addService(data, resCb) {
    return (dispatch) => {
        categoryServices.addService(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(SERVICE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(SERVICE_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

function success(type, data){
    return { type: type, payload : data }
}
function request(type, value) {
    return { type: type, payload: value };
}
function failure(type, value) {
    return { type: type, payload : value };
}
const categoryActions = {
    getAllServiceCategory,
    addService
}

export default categoryActions;
