import React, { useState, useEffect } from 'react';
import Select, { createFilter } from "react-select";
import {useDispatch, useSelector} from "react-redux";
import { patientActions } from "../../core/actions";

function UpiPayment(props) {
    const dispatch = useDispatch();
    const[bankList, setbankList] = useState([
        {value:'Karnataka Bank', label:'Karnataka Bank'},
        // {value:'Bank Of Baroda' ,label:'Bank Of Baroda'},
        // {value:'Bank Of India' , label:'Bank Of India'},
        // {value:'Bank Of Maharashtra' ,label:'Bank Of Maharashtra'},
        // {value:'Canara Bank' ,label:'Canara Bank'},
        // {value:'Central Bank of India' ,label : 'Central Bank of India'}
    ]);
    const[selectedBank, setselectedBank] = useState();
    const storedUpipayment = useSelector((state) => state.patientDetailsState.upipayment);
    const[upiDetails,setUpiDetails]=useState({
        type:"upi",
        amount:"",
        bankName:"",
        authorisationCode:""
    })

    useEffect(() =>{
       // let upiPay = JSON.parse(sessionStorage.getItem('upiPayment'));
       // console.log("upiPay:",upiPay);
       if (Object.keys(storedUpipayment).length !== 0) {
        console.log("storedCardpayment", storedUpipayment);
        storedUpipayment.amount=storedUpipayment.amount !=""?"":storedUpipayment.amount
        setUpiDetails(storedUpipayment);
        // setUpiDetails(prevState => ({ 
        //     ...prevState, // spread the previous state object
        //     amount: storedUpipayment.amount// update the amount value
        //   }));
      }
      
    },[])
    
    const handleBankChange = (event) =>{
        setselectedBank(event.label);
        let details = upiDetails;
        details.bankName = event.label;
        setUpiDetails({...details});
        sessionStorage.setItem('upiPayment',JSON.stringify(details))

    }  

    // const ConfirmPayment = () => {
     
    //     }
    

    const handleInputChange = (name,value) => {
        let details = upiDetails;
        details[name]=value;
        setUpiDetails({...details});
       // sessionStorage.setItem('upiPayment',JSON.stringify(details))
       dispatch(patientActions.upiPayment(upiDetails));
       if(name === "amount"){
        props.onClose(value, 'upi');
       }
    };


    return (
        <>
            <form >
                <div className >
                    <div className="form-group">
                        <label for="NameOnCard">Amount</label>
                        <input id="NameOnCard" className="form-control" type="number"
                        value={upiDetails.amount }
                         onChange={(event) => {
                            handleInputChange('amount',event.target.value);
                          }}></input>
                    </div>
                </div>
                <div className >
                    <div className="form-group">
                        <label for="CreditCardNumber">Bank</label>
                        <Select
                            className="select-tag"
                            classNamePrefix='select-options'
                            name="Search Bank"
                            value = {
                                bankList.filter(option => 
                                option.label === selectedBank)
                            }
                            onChange={(event) => {
                                handleBankChange(event);
                              }}
                            isClearable
                            isSearchable
                            options={bankList}
                            filterOption={createFilter(bankList)}
                            defaultValue={selectedBank}
                            >
                        </Select>                    
                    </div>
                </div>
                <div className >
                    <div className="form-group">
                        <label  className="label"for="code">Authorization Code</label>
                        <input id="code" className="null card-image form-control" type="text"
                        value={upiDetails.authorisationCode}
                            onChange={(event) => {
                                handleInputChange('authorisationCode',event.target.value);
                              }}>

                        </input>
                    </div>
                </div>
            </form>
            {/* <button id="PayButton" className="btn btn-primary submit-button" type="submit" onClick={() => { ConfirmPayment() }}>
                        <span className="align-middle">Confirm</span>
                    </button> */}
        </>
    )

}


export default UpiPayment;
