import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { patientActions } from "../../core/actions";
import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
function PatientEditForm(props) {
  const [patientDetail, setPatientDetail] = useState({
    basicinfo: {
      id: "",
      patientId: "",
      fname: "",
      mname: "",
      lname: "",
      age: "",
      adhar: "",
      phno: "",
      area: "",
      city: "",
      state: "",
      pincode: "",
      gender: "",
      bloodgroup: "",
      occupation: "",
      address: "",
      dob: "",
      email: "",
      imgUrl: "",
      udt: "",
    },
    visitinfo: [],
  });

  const [patientProfile, setPatientProfile] = useState({});
  const [formErrors, setFormErrors] = useState(
    {
      fname: "",
      gender: "",
      dob: "",
      adhar: "",
    },
    PatientEditForm
  );
  const [imgSrc, setImgSrc] = useState(null);
  const [showWebCam, setShowWebCam] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [callType, setCallType] = useState([]);
  const [showWebCamModal, setShowWebCamModal] = useState(false);
  const [selectedFilterDate,setSelectedFilterDate] = useState()
  const [showPatientProcedure,setShowPatientProcedure] = useState(true);


  const [videoConstraintsSelfie, setVideoConstraintsSelfie] = useState({
    width: 220,
    height: 200,
    facingMode: "user",
  });
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: { exact: "environment" },
  });
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const dispatch = useDispatch();

  //const webcamRef = React.createRef();
  const webcamRef = React.useRef(null);
  //this.capture = this.capture.bind(this);
  const patient = useSelector(
    (state) => state.patientDetailsState.patientBasic
  );
  const patientLastVisitedInfo = useSelector(
    (state) => state.patientDetailsState.patientLastVisitedInfo
  );

  /*  const videoConstraintsSelfie = {
    facingMode: "user",
  };
  const videoConstraints = {
    facingMode: { exact: "environment" },
  }; */

  useEffect(() => {
    console.log(
      "videoConstraints and videoConstraints",
      patientLastVisitedInfo
    );
    console.log("In componentDidMount of PatientForm : ", patient);
    if (patient && patient.basicinfo) {
      setPatientDetail(patient);
    }
    if (patient === "" || patient === undefined) {
      setCallType("Add");
    } else {
      setCallType("Edit");
    }
  }, []);

  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value);
    var pDetail = patientDetail;
    console.log('pDetail["basicinfo"][name]', pDetail["basicinfo"][name]);
    pDetail["basicinfo"][name] = value;
    setPatientDetail({ ...pDetail });
    console.log("pDetail", pDetail);
    console.log("patientDetail", patientDetail);
  };

  const onValueChange = (e) => {
    e = "selfie";
    //setSelectedOption(e.target.value);
    setSelectedOption(e);
    setShowWebCamModal(true);
    console.log("camera option selected event.target.value", e, "..", e);
    console.log("camera option selected", selectedOption);
  };

  const capture = (event) => {
    event.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc : ", imageSrc);
    setImgSrc(imageSrc);
    setShowWebCam(true);
  };

  const onImageSubmit = (event) => {
    event.preventDefault();
    const blob = fetch(imgSrc).then((res) => res.blob());
    formData.append("image", blob);
    const formData = new FormData();
    formData.append("image", blob);
    console.log("formData", formData);
  };

  const onFileCancel = (event) => {
    event.preventDefault();
    setImgSrc(null);
    setShowWebCam(false);
  };

  const toggleModal = () => {
    setShowWebCamModal(!showWebCamModal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    Object.keys(patientDetail.basicinfo).forEach((key) => {
      console.log("key", key);
      console.log("key", patientDetail.basicinfo[key]);
      validateField(key, patientDetail.basicinfo[key]);
    });
    validateForm(event);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = patientDetail;
    console.log("Basic Info", basicInfo);
    console.log("FieldName", fieldName, "Value", value);
    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);
    switch (fieldName) {
      case "fname":
        fieldValidationErrors.fname =
          value !== "" ? "" : "Please Enter First Name";
        basicInfo.fname = value !== "" ? value : "";
        break;
      case "gender":
        fieldValidationErrors.gender =
          value !== "" ? "" : "Please Enter Gender";
        basicInfo.gender = value !== "" ? value : "";
        break;
      case "dob":
        fieldValidationErrors.dob =
          value !== "" ? "" : "Please Select Date of Birth";
        basicInfo.dob = value !== "" ? value : "";
        break;
      case "adhar":
        fieldValidationErrors.adhar =
          value !== "" ? "" : "Please Enter Adhar Number";
        basicInfo.adhar = value !== "" ? value : "";
        fieldValidationErrors.adhar = !pattern.test(value)
          ? "Please enter valid Adhar Number"
          : "";
        basicInfo.adhar = value !== "" ? value : "";
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
  };

  const validateForm = (event) => {
    event.preventDefault();
    var ServerResVal = ServerRes;

    var pDetail = patientDetail;
    var age = getAge(patientDetail.basicinfo.dob);
    console.log("Age", age);
    pDetail["basicinfo"]["age"] = age;
    setPatientDetail(pDetail);

    console.log("PDetails", patientDetail);

    let formValid =
      formErrors.fname === "" &&
      formErrors.gender === "" &&
      formErrors.dob === "" &&
      formErrors.adhar === "";

    if (formValid) {
      if (callType === "Add") {
        dispatch(
          patientActions.addPatient(patientDetail.basicinfo, (resCb) => {
            if (resCb) {
              console.log("Rescb", resCb.state, resCb.message);
              if (resCb.state === "success") {
                ServerResVal.success =
                  resCb.message + " [ " + resCb.data.patientId + " ]";
                // resCb.data.id
              } else if (resCb.state === "error") {
                ServerResVal.error = resCb.messages.message;
              }
              setServerRes({ ...ServerResVal });
            }
          })
        );
      } else {
        dispatch(
          patientActions.updatePatient(patientDetail.basicinfo, (resCb) => {
            if (resCb) {
              console.log("Rescb", resCb.state, resCb.message);
              if (resCb.state === "success") {
                ServerResVal.success = resCb.message;
              } else if (resCb.state === "error") {
                ServerResVal.error = resCb.messages.message;
              }
              setServerRes({ ...ServerResVal });
            }
          })
        );
      }
    }
  };

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getFormattedDate = (date) => {
    const visitDate = new Date(date);
    // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min;
    let formatteddate = format(visitDate, "MMM dd yyyy' 'HH:mm");

    return formatteddate;
  };

  return (
    <>
      <div className="main-content-container container-fluid p-0">
        <div className="mb-12 col-md-12 hpcol bg menu-head">
          <div className="blog-comments__item d-flex cpointer  pt-3 menu-content">
            <div style={{ display: `flex` }} className="px-3 mb-3">
              {/* <div
                className="basic-close"
                onClick={(event) => props.onPatientBasic()}
              >
                X1
              </div> */}
              <div>
              <IconButton
                                aria-label="close"
                                onClick={(event) => props.onPatientBasic()}
                                sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                         <CloseIcon />
                    </IconButton>
                    </div>
              <div className="text-secondary pname basic-header-title text-center pl-3" style={{paddingLeft:'40px'}}>
                Contact Info
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="main-content-container container-fluid cont"
        style={{ marginTop: `2px` }}
      >
        <div className="row">
          <div className="mb-12 col-sm-12 col-md-12 hpcol bg" style={{ backgroundColor: '#f5f6f8' }}>
            <div className="blog-comments card pcard">
              <div className="blog-comments__item d-flex p-3 cpointer patient-cont-sec">
                <div className="blog-comments__content">
                  <div className="blog-comments__meta text-mutes">
                    <div className="text-secondary pname d-flex patient-cont-img-name">
                      {/* {helperServices.getLoggedUserName()} */}
                      {patient.imgurl ? (
                        <img
                          src={patient.imgurl}
                          className="img-disp"
                          style={{ width: `100px`, height: `100px` }}
                        ></img>
                      ) : (
                        <img
                          src="loggedinuser.png"
                          className="img-disp"
                          style={{ width: `100px`, height: `100px` }}
                        ></img>
                      )}
                      <span className="px-2">{patient.fname}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="mb-12 col-sm-12 col-md-12 hpcol bg" style={{ backgroundColor: '#f5f6f8' }}>
            <div className="blog-comments card pcard">
              <div className="blog-comments__item p-3 cpointer patient-cont-sec">
                <div className="blog-comments__content">
                  <div className="blog-comments__meta text-mutes">
                    {/*   <div className="text-secondary pname d-flex patient-cont-img-name">
                                     
                                      <img src='loggedinuser.png' className='img-disp' ></img>
                                      <span className='px-2'>Logged in User</span>
                                      
                                    </div> */}
                    <span>About</span>
                    <p className="m-0 text-muted">
                      Age {patient.age} years <br /> Phone {patient.phno}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="mb-12 col-sm-12 col-md-12 hpcol bg" style={{ backgroundColor: '#f5f6f8' }}>
            <div className="blog-comments card pcard">
              <div className="blog-comments__item p-3 cpointer patient-cont-sec">
                <div className="blog-comments__content">
                  <div className="blog-comments__meta text-mutes">
                    <span>Last Visited</span>
                    <ul style={{ listStyle: `none` }} className="p-0">
                      {patient.visitList && patient.visitList.map((lastVisited, idx) => (
                        <li>{getFormattedDate(lastVisited.visitDate)} </li>
                      ))}
                    </ul>
                    {/* onClick={(event) => getLastVisitDate(patient.visitList,event)} */}
                    
                    {/* <p className="m-0 text-muted">
                            Age : 10 Years
                            &nbsp;Phone: 9999
                          </p>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <div className="mainsection" style={{marginTop:`109px`}}>
       
        </div> */}
    </>
  );
}



export default PatientEditForm;
