import {TOKEN_GENERATE_SUCCESS,TOKEN_GENERATE_FAILURE,RUNNING_TOKEN_SUCCESS,RUNNING_TOKEN_FAILURE,TOKEN_DETAILS,TOKEN_RECORRECT_SUCCESS,TOKEN_RECORRECT_FAILURE,COMPLETE_TOKEN_SUCCESS,COMPLETE_TOKEN_FAILURE,GET_DESKDETAILS_SUCCESS,GET_DESKDETAILS_FAILURE}from "../../shared/constants";
import {tokenServices} from "../services";



export function tokenGenerate(visitId,gvId,resCb) {
    console.log("token generate" ,visitId,gvId);
    return (dispatch) => {
        tokenServices.tokenGenerate(visitId,gvId)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(TOKEN_GENERATE_SUCCESS, user.visitId,user.gvId));
                    resCb(user);
                },
                error => {
                    dispatch(failure(TOKEN_GENERATE_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function runningToken(resCb) {
    return (dispatch) => {
        tokenServices.runningToken()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(RUNNING_TOKEN_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(RUNNING_TOKEN_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function recorrectToken(visitId,gvId,resCb) {
    return (dispatch) => {
        tokenServices.recorrectToken(visitId,gvId)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(TOKEN_RECORRECT_SUCCESS, user.visitId,user.gvId));
                    resCb(user);
                },
                error => {
                    dispatch(failure(TOKEN_RECORRECT_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function deskDetails(resCb) {
    return (dispatch) => {
        tokenServices.deskDetails()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(GET_DESKDETAILS_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_DESKDETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function completeToken(patientId,visitId,resCb) {
    return (dispatch) => {
        tokenServices.completeToken(patientId,visitId)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(COMPLETE_TOKEN_SUCCESS, user.visitId));
                    resCb(user);
                },
                error => {
                    dispatch(failure(COMPLETE_TOKEN_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


function setToken(value) {
    console.log("action file set token",value);
    return { type: TOKEN_DETAILS, payload : value };
  }

function success(type, data){
    return { type: type, payload : data }
}
function failure(type, value) {
    return { type: type, payload : value };
}

const tokenActions = {
    tokenGenerate,
    setToken,
    runningToken,
    recorrectToken,
    completeToken,
    deskDetails

}

export default tokenActions;

