import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect } from "react";
import { MANASWINI_LOGO, MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";

function TokenPrint(props){ 
const now = new Date();
const day = now.getDate();
const month = now.toLocaleString('default', { month: 'short' });
const year = now.getFullYear();
const suffix = getNumberWithSuffix(day);
const formattedDate = `${suffix} ${month} ${year}`;

function getNumberWithSuffix(number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const suffix = number % 100;
  return number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0]);
}

    useEffect(() => {
      formatData();
    }, []);

    const formatData= ()=>{
      let age = props.tokenDetails.age>0 ? props.tokenDetails.age : '';
      let gender = props.tokenDetails.gender  ? props.tokenDetails.gender : '';
      let deskString = props.tokenDetails.deskNumber;
      const deskNo = deskString.replace(/\D/g, "");
      createToken(age,gender);
    }
    function createToken(age,gender) {
     const docDefinition = {
      pageSize: {
        width: 	302.36 ,
        height:	302.36 ,
      },
      defaultStyle: {
        fontSize: 10,
      },
      pageMargins: [2, 2, 2, 2],
      content: [
        {columns: [
          {
            image: MANASWINI_LOGO,
            fit: [30, 30], // set the width and height of the image
            alignment: "left",
            margin: [20, 0, 0, 0],
            width: 50, // set the top and bottom margin of the image
          },
          [
            {
              text: HOSPITAL_NAME,
              style: "header",
              margin: [0, 5, 0, 0], 
            },
            {
              stack: [
                {
                  text: HOSPITAL_DESC,
                  style: "subheader",
                },
              ],
            },
          ],
        ]},
        {
          text: "Token ID: "+ (props.tokenDetails.tokenNumber ? props.tokenDetails.tokenNumber : ' -'),
          style: "heading",
          width: "auto",
          alignment:'center',
          margin: [0, 60, 0, 10], 
        },
        {
          text: "Desk Number: "+ (props.tokenDetails.deskNumber ?props.tokenDetails.deskNumber : ' -'),
          style: "subtext",
          width: "auto",
          alignment:'center',
          margin: [0, 0, 0, 0], 
        },
        {
          text:"Patient Name: "+ props.tokenDetails.fname+ " "+ (props.tokenDetails.lname ? props.tokenDetails.lname : ' -'),
          style: "subtext",
          width: 90,
          alignment:'center',
        },
        {
          text: "Issue Date: "+(formattedDate ? formattedDate : ' -'),
          style: "subtext",
          width: "auto",
          alignment:'center',
        },
        {columns :[
        {
          text: "Age: "+(age ? age : ' -'),
          style: "subtext",
          margin: [80, 0, 0, 0], 
        },
        {
          text: "Gender: "+ (gender ?gender :' -') ,
          style: "subtext",
        }]}, 
         {
            text: HOSPITAL_NAME,
            alignment: "left",
            fontSize: 6,
            color: "#176fad",
            bold: true,
            margin: [25, 90, 0, 0],
          },
          {
            text: HOSPITAL_DESC,
            alignment: "left",
            fontSize: 6,
            color: "#176fad",
            bold: true,
            margin: [25, 0, 0, 0],
          },
          {
            text: HOSPITAL_ADDRESS,
            alignment: "left",
            fontSize: 4,
            margin: [25, 0, 0, 0],
          },
          {
            text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
            alignment: "left",
            fontSize: 4,
            margin: [25, 0, 0, 5],
          }, 
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          color: "#176fad",
        },
        subheader: {
          fontSize: 8,
          bold: true,
          color: "#176fad",
        },
        heading:{
          fontSize: 14,
          bold: true,
        },
        subtext:{
          fontSize: 12,
        }
      },
    };
    
    PdfPrinter.createPdf(docDefinition).open();
    }

    return (<></>);

}

export default TokenPrint;

