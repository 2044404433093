import {DIAGNOSIS_SAVE_SUCCESS,DIAGNOSIS_SAVE_FAILURE,DIAGNOSIS_DETAILS,
    DIAGNOSIS_DETAILS_FAILURE,SET_DIAGNOSIS_DETAILS,DIAGNOSIS_MASTER_DETAILS,
    DIAGNOSIS_MASTER_DETAILS_FAILURE,DIAGNOSIS_MASTER_SAVE_SUCCESS,DIAGNOSIS_MASTER_SAVE_FAILURE} from "../../shared/constants";
import {diagnosisServices} from "../services";

export function getAllDiagnosis(data,resCb) {
    return (dispatch) => {
        diagnosisServices.getAllDiagnosis(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DIAGNOSIS_DETAILS, user.data));
                    resCb(user.data);
                },
                error => {
                    dispatch(failure(DIAGNOSIS_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addDiagnosisMaster(data, resCb) {
    return (dispatch) => {
        diagnosisServices.addDiagnosisMaster(data)
            .then(
                user => { 
                    console.log("Insert Diagnosis",user)
                    dispatch(success(DIAGNOSIS_MASTER_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DIAGNOSIS_MASTER_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDiagnosisMaster(resCb) {
    return (dispatch) => {
        diagnosisServices.getDiagnosisMaster()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DIAGNOSIS_MASTER_DETAILS, user.data));
                    resCb(user.data);
                },
                error => {
                    dispatch(failure(DIAGNOSIS_MASTER_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addDiagnosis(data, resCb) {
    return (dispatch) => {
        diagnosisServices.addDiagnosis(data)
            .then(
                user => { 
                    dispatch(success(DIAGNOSIS_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DIAGNOSIS_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
// export function updateDoctor(data, resCb) {
//     return (dispatch) => {
//         doctorServices.updateDoctor(data)
//             .then(
//                 user => { 
//                     console.log("Update Doctor",user)
//                     dispatch(success(DOCTOR_UPDATE_SUCCESS, user));
//                     resCb(user);
//                 },
//                 error => {
//                     dispatch(failure(DOCTOR_UPDATE_FAILURE, error.toString()));
//                    resCb(error.toString);
//                 }
//             );
//     };
// }

function success(type, data){
    return { type: type, payload : data }
}
function failure(type, value) {
    return { type: type, payload : value };
}
function setDiagnosisPatientDetail(value) {
    return { type: SET_DIAGNOSIS_DETAILS, payload : value };
}
const diagnosisActions = {
    getAllDiagnosis,
    addDiagnosisMaster,
    setDiagnosisPatientDetail,
    getDiagnosisMaster,
    addDiagnosis
}

export default diagnosisActions;