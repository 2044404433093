import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getDoctorsByDept() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(),
    };
    const url = basePath + `/master/doctor`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
}

function getDoctorInfo(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/doctors`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addDoctor(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/doctor`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateDoctor(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/doctor`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getDoctorList() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/person`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getDepartmentList() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/departments`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getSalaryTypeList() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/salarytype`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getFloorList() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/allFloors`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}



function generateZoomToken() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/zoom`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function addtags(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/editProcedure`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function addProcedure(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/procedure`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function reviewNotes(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/getMeetingNotes?patientId=${data.patientId}&groupId=${data.groupid}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((resCb) => {

      return resCb;
    } );
}

function listTemplates() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/ehr/template/active`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
console.log("data:",data);
      return data;
    } );
}

function getAssignedDoctor(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/ipd/person`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function assignDoctor(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/ipd/person`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateMeetingStatus(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  const url = basePath + `/v1/meetingStatus?visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getServiceList(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/packageService`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getPackageList(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/package`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function addService(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/packageService`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function addPackage(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/addPackage`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function updateService(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/packageService`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getRoomList(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/rooms?facilityId=${data.facilityId}&roomStatus=${data.roomStatus}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getAssignedRoom(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/room/assignedroom?visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function assignRoom(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/room/roomBook?visitId=${data.visitid}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function roomCheckout(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service",data);
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/room/roomCheckout?visitId=${data.visitid}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}


function getProductIdList(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/packageService/productId`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function getProductDetails(data,location) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service", data);
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/searchProduct?searchvalue=${data}&location=${location}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
          
      return data;
    } );
  }
    function getStaffList() {
      var accessToken;
      if (helperServices.getAuthorization()) {
        accessToken = helperServices.getAuthorization();
      }
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        // body: JSON.stringify(),
      };
      const url = basePath + `/v1/staff`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((data) => {
    
          return data;
        } );
    }


    function getRoleList() {
      var accessToken;
      if (helperServices.getAuthorization()) {
        accessToken = helperServices.getAuthorization();
      }
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        // body: JSON.stringify(),
      };
      const url = basePath + `/v1/role`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((data) => {
    
          return data;
        } );
    }

    















function getDoctorListByType(type) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/doctors?type=${type}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function saveDoctor(data) {
  console.log("data inside service",data);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/doctoradd`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}


function modifyDoctor(data) {
  console.log("data inside service editdoctor",data);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/doctoredit?id=${data.personId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}


function deletedoctor(data) {
  console.log("data inside service removetpa",data);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/doctorremove?id=${data.personId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}


function addDoctorAppointments(data){
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/doctorAppointment`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
//Added on 02-03-3023
function getDeskList() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/deskLists`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}
//Added Ends Here

function addConsultant(data,type,flag){
  console.log("data inside service editdoctor",data);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  console.log("inside service");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/addConsultant?type=${type}&flag=${flag}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {

      return data;
    } );
}

function addCaseRecord(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/caserecord`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addFollowupNotes(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: data,
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/saveFollowupNotes`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getFollowupNotes(pid, vid, temp, rescb) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/getFollowupNotes?patientId=${pid}&visitId=${vid}&templateId=${temp}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log("data:", data);
      return data;
    });
}

const doctorServices = {
    handleResponse,
    logout,
    getDoctorsByDept,
    getDoctorInfo,
    addDoctor,
    updateDoctor,
    getDoctorList,
    generateZoomToken,
    addtags,
    addProcedure,
    reviewNotes,
    listTemplates,
    assignDoctor,
    updateMeetingStatus,
    getAssignedDoctor,
    getServiceList,
    addService,
    updateService,
    getRoomList,
    getAssignedRoom,
    assignRoom,
    roomCheckout,
    getProductIdList,
    getDoctorListByType,
    getStaffList,
    saveDoctor,
    modifyDoctor,
    addDoctorAppointments,
    deletedoctor,
    addConsultant,
    getDepartmentList,
    getSalaryTypeList,
    getFloorList,
    getDeskList,
    getRoleList,
    getProductDetails,
    getPackageList,
    addPackage,
    addCaseRecord,
    addFollowupNotes,
    getFollowupNotes
  };
  export default doctorServices;
  
