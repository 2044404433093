import {
  PATIENT_DETAILS,
  SET_PATIENT_DETAILS,
  SET_VISIT_ID,
  SET_PROCEDURE_ID,
  LIST_PATIENTS,
  PATIENTS_INFO,
  LIST_FLOORS,
  PATIENTS_PROCEDURE_INFO,
  PATIENTS_LAST_VISITED_INFO,
  SET_UPLOAD,
  LIST_VISTS,
  SAVE_APPIONTMENT,
  SET_PAGE_HEADER,
  LIST_REVISTS,
  SAVE_REVISIT,
  SAVE_VISIT,
  UPDATE_VA,
  DISCHARGE_SUMMARY,
  RESET,
  PATIENT_VISIT_LISTS,
  PATIENT_ID,
  CURRENT_NOTES,
  PATIENT_SECTION,
  EHRID,
  TOKENLIST,
  E_PRISCIPTION,CARD_PAYMENT,CASH_PAYMENT,UPI_PAYMENT
} from "../../shared/constants";

const patientDetailsState = {
  patientDetail: [],
  patient: [],
  patientInfo: [],
  floorsList: [],
  patientBasic: [],
  patientLastVisitedInfo: [],
  procedureuploadinfo: [],
  visitsList: [],
  revisitsList: [],
  revisitSave: [],
  visitSave: [],
  visitUpdate: [],
  appointmentSave: [],
  pageheader: [],
  dispatchSummary: [],
  patientVisitLists: [],
  tokenlist:[],
  selectedPatientId: "",
  page: "",
  ehrid: "",
  ePresc: {},
  cardpayment:{},
  cashpayment:{},
  upipayment:{},

};

function setPatientDetailsState(state = patientDetailsState, action) {
  switch (action.type) {
    case PATIENT_DETAILS:
      console.log("PATIENT_DETAIL", action.payload);
      return Object.assign({}, state, {
        patientDetail: action.payload,
      });
    case SET_PATIENT_DETAILS:
      console.log("SET_PATIENT_DETAILS", action.payload);
      return Object.assign({}, state, {
        patient: action.payload,
      });
    case SET_VISIT_ID:
      console.log("SET_VISIT_ID", action.payload);
      return Object.assign({}, state, {
        visitId: action.payload,
      });
    case SET_PROCEDURE_ID:
      console.log("SET_PROCEDURE_ID", action.payload);
      return Object.assign({}, state, {
        procedureId: action.payload,
      });


      case LIST_PATIENTS:
        console.log("LIST_PATIENTS", action.payload);
        return Object.assign({}, state, {
          patientsList: action.payload,
        });
      case LIST_VISTS:
        console.log("VISIT_DETAIL", action.payload);
        return Object.assign({}, state, {
          visitsList: action.payload,
        });

      case LIST_REVISTS:
        console.log("REVISIT_DETAIL", action.payload);
        return Object.assign({}, state, {
          revisitsList: action.payload,
        });
      case SAVE_REVISIT:
        console.log("SAVE_REVISIT", action.payload);
        return Object.assign({}, state, {
          revisitSave: action.payload,
        }); 
      case SAVE_VISIT:
        console.log("SAVE_REVISIT", action.payload);
        return Object.assign({}, state, {
          visitSave: action.payload,
        });
      case UPDATE_VA:
        console.log("UPDATE_VA", action.payload);
        return Object.assign({}, state, {
          visitUpdate: action.payload,
        });
      case SAVE_APPIONTMENT:
        console.log("SAVE_APPIONTMENT", action.payload);
        return Object.assign({}, state, {
          appointmentSave: action.payload,
        });  
      case PATIENTS_INFO:
        console.log("PATIENT_DETAIL", action.payload);
        return Object.assign({}, state, {
          patientBasic: action.payload,
        });
      case LIST_FLOORS:
        return Object.assign({}, state, {
          floorsList: action.payload,
        });
      case PATIENTS_PROCEDURE_INFO:
        return Object.assign({}, state, {
          patientInfo: action.payload,
        });
      case PATIENTS_LAST_VISITED_INFO:
        return Object.assign({}, state, {
          patientLastVisitedInfo: action.payload,
        });
      case SET_UPLOAD:
        console.log("setupload 1", action.payload)
        if (action.payload === RESET) {
          return Object.assign({}, state, {
            procedureuploadinfo: [],
          });
        }else {
          return Object.assign({}, state, {
            procedureuploadinfo: action.payload,
          });
        }
      case SET_PAGE_HEADER:
        console.log("set page header : ",action.payload)
        return Object.assign({} , state, {
        pageheader:action.payload,
      });
      case DISCHARGE_SUMMARY:
        return Object.assign({}, state, {
          dispatchSummary: action.payload,
        });
      case PATIENT_VISIT_LISTS:
        return Object.assign({}, state, {
          patientVisitLists: action.payload,
      });
      case  PATIENT_ID:
        return Object.assign({}, state, {
          selectedPatientId: action.payload,
      });
      case  CURRENT_NOTES:
        return Object.assign({}, state, {
          selectedNotes: action.payload,
      });
      case PATIENT_SECTION:
        return Object.assign({}, state, {
          page: action.payload,
        });
      case CARD_PAYMENT:
        return Object.assign({}, state, {
          cardpayment: action.payload,
        });
        case UPI_PAYMENT:
          return Object.assign({}, state, {
            upipayment: action.payload,
          });
          case CASH_PAYMENT:
            return Object.assign({}, state, {
              cashpayment: action.payload,
            });
            case TOKENLIST:
            return Object.assign({}, state, {
              tokenlist: action.payload,
            });


      case EHRID:
      return Object.assign({}, state, {
        ehrid: action.payload,
      });
      case E_PRISCIPTION:
        return Object.assign({}, state, {
          ePresc: action.payload,
        });
    default:
      return state;
  }
}

export default setPatientDetailsState;
