import React, { useState } from "react";
import Dropzone from "react-dropzone";
import {helperServices} from '../../shared/helper';
import diagnosisComponents from "../diagnosis";


export default function DropZone({childToParent}) {
  // const [fileNames, setFileNames] = useState([]);
  // const handleDrop = (acceptedFiles) =>
  //   setFileNames(acceptedFiles.map((file) => file));

  const resizeMaxFileSize = 100000000000000; //1MB Maximum file size (in bytes)
  const maxFileSize = 20000000000000000000; //20MB Maximum file size (in bytes)
  // const acceptedFileTypes = "image/*,text/*"; //	 accept only files,image file types
  const acceptedFileTypes = "image/*,text/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf";
  const maxFilesAccepted = 100; //Maximum accepted number of files
  let rejectedFiles = []
const [showcomment, setShowComment] = useState(false);
  const handleFileSubmit=(event,acceptedFiles) => {
    event.preventDefault();
    console.log("AcceptedFiles",acceptedFiles)
    childToParent({acceptedFiles:acceptedFiles})
  }
  
  const startUpload=(files,fileRejections) =>{
    console.log("event in drop zone : ",files,rejectedFiles)
    let resizedFiles = [];
    childToParent({acceptedFiles:files})
    setShowComment(true);
    
    files.map((file,index)=>{
      // if(file.type == 'image/jpg' || file.type == 'image/jpeg'){
        var reader = new FileReader();
        reader.filename = file.name;
        if(file.name!=null){
        }
            // console.log("event in drop zone : ",file.name)
             
             /* reader.onload = function (e) {
                const img = new Image();
                console.log("e.target.result; : ",e.target.result)
                img.src = e.target.result;
                img.onload = () => {
                var resizedImage = helperServices.resizeImage(img,resizeMaxFileSize)
                    resizedImage.name = this.filename;
                    console.log("#resizedImage ",resizedImage)
                    childToParent({acceptedFiles:[resizedImage]})
                }
            }; */
            //reader.readAsDataURL(file);
        // }
        // else{
         //   childToParent({acceptedFiles:[file]})
        // }
      }
      )
      
      //dropRejected(files)
  }

  const dropRejected=(files,acceptedFiles,fileRejections) =>{
    console.log("event in drop zone 1: ",files,)
    let accepted = []
    rejectedFiles = files
    childToParent({rejectedFiles:rejectedFiles})
    //startUpload(accepted,files)
    //childToParent({dropRejected:files,})
  }

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log("Upload status", status, "Upload meta" , meta,"Upload meta", file)
  }

  return (
    <div className="App">
      <Dropzone onDrop={files => startUpload(files)}
        onDropRejected={files => dropRejected(files)}
        // onDrop={handleDrop}
        accept={acceptedFileTypes}
        multiple={true}
        maxSize={maxFileSize}
        maxFiles={maxFilesAccepted}
        // autoProcessQueue={true}
        onChangeStatus={handleChangeStatus}
        >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          fileRejections,
        }) => {
          const additionalClass = isDragAccept
          ? "accept"
          : isDragReject
          ? "reject"
          : "";
          const acceptedFileItems = acceptedFiles.map((file) => (
            <li key={file.path}>
              {file.path} - {file.size} bytes
            </li>
          ));

          console.log("Accepted Files", acceptedFiles);
          acceptedFiles.map((file) => (console.log("Accepted file type", file.type)))
          const fileRejectionItems = fileRejections.map(({ file, errors }) => (

            <li key={file.path}>
              {file.path} - {file.size} bytes
              <ul>
                {errors.map((e) => (
                  <li key={e.code}>{e.message}</li>
                ))}
              </ul>
            </li>
          ));

          return (
            <>
              <div
                {...getRootProps({ className: `dropzone ${additionalClass}` })}
              >
                <input {...getInputProps()} />
                <span>
                  {isDragActive ? (
                    <i class="fa fa-folder-open" aria-hidden="false"></i>
                  ) : (
                    <i class="icon-folder-close" aria-hidden="false"></i>
                  )}
                </span>
          
                  <p>
                  {/* Drag 'n' drop some files here, or click to select files */}
                  </p>
                  {/* {showcomment? */}
                     
                     <em> 
                     <i class="form-inline .custom-control">Drag 'n' drop some files here, or click to select files</i>


                     </em>
                    
              
                    {/* :'' */}
                  {/* } */}
                </div>
                  
           

              {/* {isDragReject ? 
            <div style={{ color: "#EC4147" }}>
              <span>Some files could not be uploaded.Please check the files and retry</span>
            </div>
            : ''} */}

             {/*  {acceptedFileItems.length > 0 ? (
                <div className="acceptedFiles">
                  <strong>Accepted files</strong>
                  <ul>{acceptedFileItems}</ul>
                </div>
              ) : (
                ""
              )}
              {fileRejectionItems.length > 0 ? (
                <div className="rejectedFiles">
                  <strong>Rejected files</strong>
                  <ul>{fileRejectionItems}</ul>
                </div>
              ) : (
                ""
              )} */}
              
         {/*      <button className="btn btn-theme btn-warning fw-bold font-lato text-uppercase"
                style={{marginTop:`5px`,float:`right`}}
                onClick={(event) => handleFileSubmit(event,acceptedFiles)}>Submit</button> */}
              {/* {acceptedFileItems.length >= 10  ? 
            <div style={{ color: "#EC4147" }}>
              <span>You can upload or drag Maximum 10 Files</span>
            </div>
            : ''} */}
            </>
          );
        }}
      </Dropzone>
      {/* <div>
        <strong>Files:</strong>
        <ul>
          {fileNames.map((fileName) => (
            <li key={fileName}>
              {fileName.name}- {fileName.size} bytes
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}
