import React, { useMemo } from 'react';

function CustomTooltip (props){
    console.log("props",props);
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}>
      <p>
        <span>{data.comment}</span>
      </p>
      {/* <p>
        <span>Country: </span> {data.country}
      </p>
      <p>
        <span>Total: </span> {data.total}
      </p> */}
    </div>
  );
};

export default CustomTooltip;