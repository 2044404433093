import AdminHomeView from './AdminHomeView';
import PatientForm from './PatientForm';
import PatientSections from "./PatientSections";
import Attachments from './Attachments';
import FileUploadPreview from './FileUploadPreview';
import DoctorsList from './DoctorsList';
import SearchDocList from '../appointmentList/SearchDocList'
import References from './References';
import Complaints from './Complaints';
import Barcode from './Barcode';
import DoctorHomeView from './DoctorHomeView';
import PrescriptionLayoutModel from './PrescriptionLayoutModel';
import PatientSchema from './PatientSchema.js';
import PackageService from './PackageService';
import SearchPackageService from './SearchPackageService';
import Room from '../Room/Room';
import PatientListing from '../appointmentList/PatientListing';
import ProcedureListing from './ProcedureListing';
import InsuranceProcedureListing from '../Insurance/InsuranceProcedureListing';
import InsuranceProcedureInput from '../Insurance/InsuranceProcedureInput';
import AppointmentScheduleModal from '../appointmentList/AppointmentScheduleModal';
import AdminAppointment from '../appointmentList/AdminAppointment.js';
import InsuranceProcedureSettlement from '../Insurance/InsuranceProcedureSettlement';
import VisitsByType from '../nurse/VisitsByType';
import Payment from '../payment/Payment';
import CreditCardPayment from '../payment/CreditCardPayment';
import CashPayment from '../payment/CashPayment';
import UpiPayment from '../payment/UpiPayment';
import Counsellor from '../appointmentList/Counsellor';
import TokenDashBoardDetails from '../TokenDashBoardDetails/tokendashboarddetails';
import TokenDashBoard from '../TokenDashBoard/tokendashboard';
import TokenPrint from './TokenPrint';
import RegistrationCard from './RegistrationCard';
import DischargePrint from './DischargePrint';
import LabReportPrint from '../../shared/schema/LabReportPrint';
import Vouchers from './Vouchers';
import VouchersModal from './VoucherModal';
import MergePdf from './MergePdf';
import SeparatePdf from  './SeparatePdf';
import VisitList from './VisitList';

const pcomponents = {
    AdminHomeView,
    PatientForm,
    PatientSections,
    Attachments,
    FileUploadPreview,
    DoctorsList,
    SearchDocList,
    References,
    Complaints,
    Barcode,
    DoctorHomeView,
    PrescriptionLayoutModel,
    PatientSchema,
    PackageService,
    SearchPackageService,
    Room,
    PatientListing,
    ProcedureListing,
    InsuranceProcedureListing,
    InsuranceProcedureInput,
    AppointmentScheduleModal,
    AdminAppointment,
    InsuranceProcedureSettlement,
    VisitsByType,
    Payment,
    CashPayment,
    CreditCardPayment,
    UpiPayment,
    Counsellor,
    TokenDashBoardDetails,
    TokenDashBoard,
    TokenPrint,
    DischargePrint,
    Vouchers,
    RegistrationCard,
    MergePdf,
    SeparatePdf,
    VisitList
   
}

export default pcomponents;
