import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
 function getRoomList() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/roomlist`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
   
        return data;
      } );
  }
  function addRoom(data) {
    console.log("data inside service editdoctor",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    console.log("addInsurance service");
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/roomAdd`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }

  function modifyRoom(data) {
    console.log("data inside service modifyRoom",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/roomChange?id=${data.id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }
  function roomDelete(data) {
    console.log("data inside service removetpa",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/roomDelete?id=${data.id}&status=${data.status}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }
const roomMasterServices = {
    handleResponse,
    getRoomList,
    addRoom,
    modifyRoom,
    roomDelete
  };
  export default roomMasterServices;
