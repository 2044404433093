import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import { connect } from "react-redux";
import { doctorActions } from "../../core/actions";
import { helperServices } from "../../shared/helper";
import { patientActions } from "../../core/actions";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Components from "../index";
import { DOCTOR_ROLE, RESET } from "../../shared/constants/index";
import { format } from "date-fns";

function SearchDocList(props) {
  const [selectedOption, setSelectedOption] = useState();
  const [deptId, setDeptId] = useState("");
  const [options, setOption] = useState("");
  const dispatch = useDispatch();

  // const doctor = useSelector(state => state.doctorDetailsState.doctor)

  //var viewDoctorList = false;
  const [reload, setReload] = useState(false);
  const [viewDoctorDetails, setviewDoctorDetails] = useState(false);
  //const patientProceduresList = useSelector(state => state.doctorDetailsState.patientprocedurelist);
  //const visitList =  useSelector(state => state.patientDetailsState.patientVisitLists);
  //let assignedDoctor = "";
  const [assignedDoctor, setassignedDoctor] = useState();
  const [doctorList, setdoctorList] = useState([]);
  const [showAppointmentModal, setShowAppointmentModal] = useState();
  const [patientCalender, setPatientCalender]=useState([]);
  const patientVisitList = useSelector(
    (state) => state.patientDetailsState.patientVisitLists
  );
  const doctors = useSelector(
    (state) => state.doctorDetailsState.doctorsDetail
  );
  console.log("patientVisitList in doctor:", patientVisitList);

  useEffect(() => {
    console.log("props in doctor:", props);
    console.log("props of patientinfo:",  patientVisitList);
    console.log("props in Patientsection:", props.patientCalenderInfo);
    getPatientCalender();
    setassignedDoctor();
    // let gv=patient?.gv?.['id'];
    let gv =props?.docInfo?.['gvId'];

    
    if (props.docInfo.gvId && props.docInfo.gvId != "") {
      setviewDoctorDetails(true);
    } else {
      setviewDoctorDetails(false);
    }
    getassignedDoctorDetails();
    if (helperServices.CheckAccessLevel("InsurancePatients")) {
      console.log("role is:", localStorage.getItem("role"));
      // setviewDoctorDetails(true)
    }
    

    if(props.patientInfo && props.patientInfo.status && props.patientInfo.status === "close"){
      setviewDoctorDetails(true);
    }
   
  }, [props]);

const getPatientCalender = () => {
  let arr = [];
  let patientId = props.patientInfo.patientId ? props.patientInfo.patientId : '';
  let patCalender = [];

  console.log("For patient details:", props.patientCalenderInfo);

  if (Array.isArray(props.patientCalenderInfo) && props.patientCalenderInfo.length > 0) {
      props.patientCalenderInfo.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
              console.log(`Value `, value);
              if (key !== 'id' && key !== 'gvid' && key !== 'date') {
                  if (value !== "") {
                    if(value === patientId.toString()){
                      console.log("typeof patientId, typeof value",typeof patientId, typeof value);
                      console.log(`Value  `, value);
                      arr.push(obj);
                      console.log(`Value in if`, arr);
                    }
                  }
              }
            
          });
      });

      // Set patCalender after filtering
      patCalender = [...arr];
      console.log('Patient calendar found:', patCalender);
  } else {
      console.log('props.patientCalenderInfo is not an array or has length 0');
  }

  setPatientCalender([...patCalender]);
  console.log('Patient calendar found:', patientCalender);
};
 

  const getassignedDoctorDetails = () => {
    console.log("doctor list obtained:", doctors);
    let doctorList = doctors;

    if (doctorList) {

     doctorList= doctorList.filter(
        (doc) => {
  
            if(doc!=null){
                 return doc["type"] === "Doctor" || doc["type"] === "doctor"              
               }    
            return false;
          }
            
            
      );
      console.log("doctor list before loop",doctorList);
      let deptOptions = [];
      for (let det of doctorList) {
        console.log("det:", det);

        let opt = {};
        if (!det.first_name) {
          det.first_name = "";
        }
        if (!det.last_name) {
          det.last_name = "";
        }
        opt["id"] = det.personId;
        opt["value"] = det.email;
        opt["role"] = DOCTOR_ROLE;
        opt["department"] = det.department;
        opt["firstName"] = det.first_name;
        opt["lastName"] = det.last_name;
        opt["departmentId"] = det.departmentId;
        opt["gvId"] = det.gvId;
        const name = helperServices.getFullName(
          det.first_name,
          null,
          det.last_name
        );
        console.log("Camel case:", name);
        opt["label"] =
          det.displaytitle +
          "." +
          " " +
          name +
          ", " +
          "Dep of " +
          det.department +
          " (" +
          det.qualification +
          " )";

        deptOptions.push(opt);
        console.log("docinfo:", props.docInfo.gvId);
        // console.log("personId:",det.personId);
        console.log("gvid is:", det.gvId);
        let docname = "";
        if (props.docInfo && props.docInfo.gvId == det.gvId) {
          console.log("props.docinfo", props.docInfo.gvId);
          setSelectedOption(opt);
          if (props.docInfo.type === "edit") {
            docname = opt["label"];
            setassignedDoctor(docname);
            console.log("assigned doctor", docname);
          }
          console.log("matching counsilor id", props.docInfo.gvId);
          setReload(!reload);
        }
        console.log("assignedDoctor:", assignedDoctor);
      }

      setOption(deptOptions);
      console.log(" deptOptions Response:", deptOptions);
    }
  };

  const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
    console.log("select option 2", selectedOption);
    if (props.from == "appointment") {
      // props.parentCallBack(selectedOption);
    }
  };

  const toggle = () => {
    setviewDoctorDetails(!viewDoctorDetails);
    //setReload(!reload);
  };

  const handleSubmit = () => {
    console.log("selected option:", selectedOption);

    if (selectedOption != null) {
      props.parentCallBack(selectedOption);
    }
  };

  const openAppointment = () => {
    setShowAppointmentModal(true);
  };

  const closeAppntmentModal = (event) => {
    console.log("close appointment modal:", event);
    props.onModalClose(event);
    setShowAppointmentModal(false);
  };

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy");
    return formatteddate;
  };

  return (
    <main key={reload}>
      {/* {props.from != "appointment" && (
        <>
          <h5 className="">
            Assign Psychiatrist
            {props.patientInfo && props.patientInfo.status && props.patientInfo.status === "open" ?
           <>
            {viewDoctorDetails ? (
              <span
                className="ml-3"
                onClick={(event) => toggle()}
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons" title="Edit">
                  {setviewDoctorDetails == false ? "edit" : "visibility"}
                </i>
              </span>
            ) : (
              <span
                className="ml-3"
                onClick={(event) => toggle()}
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons" title="View">
                  {setviewDoctorDetails == true ? "visibility" : "edit"}
                </i>
              </span>
            )}
              </>
            :''}
          </h5>
          {props.lastassigned && props.lastassigned.doctor ? (
            <label>
              Last Assigned Psychiatrist :&nbsp;{props.lastassigned.doctor}
            </label>
          ) : (
            ""
          )}
        </>
      )} */}
{props && props.patientInfo && props.patientInfo.status != 'preReg' ?
<Card className="mb-1 p-2 text-center">
        <h6 className="mt-2">Doctor Appointment Details</h6>
        {assignedDoctor ? (
          <>
            <label className="mb-1"> <span className="text-muted">{assignedDoctor}</span></label>
            {patientCalender.map((calenderItem) => (
            <span style={{display:'block'}} className="text-muted">{calenderItem.date}</span>
            ))}
            
          {props.lastassigned && props.lastassigned.doctor ? (
            <label>
             <span className="text-muted"> Last Assigned Psychiatrist :&nbsp;{props.lastassigned.doctor}</span>
            </label>
          ) : (
            ""
          )}
                
            </>
        )
            :
            <label className="text-center">No doctors assigned.</label>
            }
          
          
          


      {props && props.patientInfo && props.patientInfo.status != 'close' ?

            <Button className="btn-primary btn-sm m-auto" variant="contained"
            color="primary" sx={{textTransform:'capitalize'}} onClick={(event) => openAppointment(event)}>
            Schedule Appointment
          </Button>
      :''}
        </Card>
          :''}
        {showAppointmentModal && (
            <>
              <Components.AppointmentScheduleModal
                patientData={patientVisitList[0]}
                type="Doctor"
                doctorInfo={props.docInfo}
                onModalClose={(event) => closeAppntmentModal(event)}
              />
            </>
          )}
      {/* {viewDoctorDetails ? (
        <div>
          <Card className="border">
            <CardBody className="p-3">
              {assignedDoctor != "" ? (
                <>
                  <label>Psychiatrist :&nbsp;{assignedDoctor}</label>
                  {patientVisitList.length > 0 &&
                    patientVisitList[0].visit.apptdate && (
                      <>
                        <br />
                        <label>
                          Appointment Date :&nbsp;
                          {getFormattedDate(patientVisitList[0].visit.apptdate)}
                        </label>
                        <br />
                        <label>
                          Appointment Time :&nbsp;
                          {patientVisitList[0].visit.appthours}:
                          {patientVisitList[0].visit.apptminutes}
                        </label>
                      </>
                    )}
                </>
              ) : (
                <label className="text-center">No doctors assigned.</label>
              )}
            </CardBody>
          </Card>
        </div>
       

        
      ) 
      
      
      : (
        <div
          className={
            props.docInfo.type && props.from != "appointment" ? "card p-4" : ""
          }
        >
          <div>
            <Select
              className="select-tag"
              name="Search Doctor"
              value={selectedOption}
              onChange={handleChange}
              isClearable
              isSearchable
              options={options}
              filterOption={createFilter(options)}
              defaultValue={selectedOption}
            ></Select>
          </div>

          <div className=" mt-4 fbtn justify-content-between">
            {patientVisitList &&
              patientVisitList.length > 0 &&
              patientVisitList[0].visit &&
              patientVisitList[0].visit.type != "InPatient" && (
                <Button
                  type="submit"
                  onClick={(event) => openAppointment(event)}
                  style={{ float: "left" }}
                >
                  Schedule Appointment
                </Button>
              )}
            <Button
              type="submit"
              onClick={handleSubmit}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </div>

          {showAppointmentModal && (
            <>
              <Components.AppointmentScheduleModal
                patientData={patientVisitList[0]}
                type="Doctor"
                doctorInfo={props.docInfo}
                onModalClose={(event) => closeAppntmentModal(event)}
              />
            </>
          )}
        </div>
      )} */}
    </main>
  );
}

export default SearchDocList;
