import React, { useState, useEffect } from "react";
import { setState } from "react-jsonschema-form/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
function CashPayment(props) {
  const dispatch = useDispatch();
  const storedCashpayment = useSelector((state) => state.patientDetailsState.cashpayment);
  const [cashPaymentDetails, setCashPaymentDetails] = useState({
    type: "cash",
    amount: "",
    bankName: "",
    authorisationCode: "",
  });
  const [savetoDB, setSaveToDb] = useState("");

  useEffect(() => {
    //let cashPay = JSON.parse(sessionStorage.getItem('cashPayment'));
    // console.log("cashPay:",cashPay);
    //if(cashPay != null)
    //updateChangeDue();
    //setCashPaymentDetails({...cashPay});
    if (Object.keys(storedCashpayment).length !== 0) {
      console.log("storedCashpayment", storedCashpayment);
      storedCashpayment.amount=storedCashpayment.amount !=""?"":storedCashpayment.amount
      setCashPaymentDetails(storedCashpayment);
      // setCashPaymentDetails(prevState => ({ 
      //   ...prevState, // spread the previous state object
      //   amount: storedCashpayment.amount// update the amount value
      // }));
    }
    // cashPaymentDetails.amount = props.paid;
    // setCashPaymentDetails(cashPaymentDetails);
  }, []);

  const handleInputChange = (value) => {
    console.log("------cash payemnt-------", value);
    let cash = cashPaymentDetails;
    cash.amount = value;
    debounce(setSaveToDb(value), 5000);
    setCashPaymentDetails({ ...cash });
    props.onClose(value, "cash");
    console.log("Calling API with input value", value);

    console.log("save to props", savetoDB);
    // sessionStorage.setItem('cashPayment',JSON.stringify(cash))
    dispatch(patientActions.cashPayment(cashPaymentDetails));
    //props.onClose(savetoDB);
    // updateChangeDue();
  };
  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  console.log("savetoDBsavetoDB", savetoDB);
  const updateChangeDue = () => {
    let details = cashPaymentDetails;
    console.log("total given amount:", details);
    console.log("total amount:", props.details.totalAmount);

    //details.amount = cashGiven;
    setCashPaymentDetails({ ...details });
  };

  // let paymentList = [];
  // const ConfirmPayment = () => {
  //    // paymentList.push(props.payment);
  //    // props.payment.push(cashPaymentDetails);
  //    // props.parentCashCallback('cash',props.payment);

  //     }

  return (
    <>
      <form>
        <div className>
          <div className="form-group">
            <label for="givenAmt">Enter Amount</label>
            <input
              id="givenAmt"
              className="form-control"
              type="number"
              min={0.1}
              step="1"
              value={cashPaymentDetails.amount}
              onChange={(event) => {
                handleInputChange(event.target.value);
              }}
            ></input>
          </div>
        </div>
        {/* <div className >
                    <div className="form-group">
                        <label for="change">Change Due</label>
                        <input id="change" className="null card-image form-control" type="number" value={cashPaymentDetails.changeDue}></input>
                    </div>
                </div> */}
      </form>
      {/* <button id="PayButton" className="btn btn-primary submit-button" type="submit" onClick={(e) => { ConfirmPayment(e) }}>
                        <span className="align-middle">Confirm</span>
                    </button> */}
    </>
  );
}

export default CashPayment;
