import React, { useState, useEffect } from 'react';
import { helperServices } from "../../shared/helper";
import Select, { createFilter } from "react-select";
import {useDispatch} from "react-redux";
// import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { doctorActions} from '../../core/actions';
import { transform } from 'lodash';
function AddConsultant(props) {
    const dispatch = useDispatch();
    const[doctorList, setdoctorList] = useState([]);
    const [selectedDoctor, setselectedDoctor] = useState([]);
    const [description,setDescription] = useState('');
    const[ServerRes, setServerRes] = useState({success : '', error : ''})
    const[summary, setSummary] = useState(
        {   
            patientId:'',
            visitId:'',
            groupname:'',
            shortdesc:'',
            longdesc:'',
            targetid:'',
            role:''
           
        })
        const [formErrors, setFormErrors] = useState(
            {
                longdesc:''
            }, 
          )
    useEffect(() => {
        console.log("add consultant screen",props);
        if(props.mode == 'edit'){
            if(props.details){
                setDescription(props.details.longdesc);
            }
        }
        getDoctorsList();
    },[])

    const getDoctorsList = (data) =>{
        dispatch(doctorActions.getDoctorList(data,resCb=>{
         if(resCb){
            let list = [];
             for(let doc of resCb){        
              let opt = {}
              if(doc!=null){
              console.log("DocList", resCb)
              if(doc.type=="doctor"){
              if (!doc.first_name){doc.first_name = "" }
              if(!doc.last_name){doc.last_name = ""}
              opt['id'] = doc.personId
              const name=  helperServices.getFullName(doc.first_name,null,doc.last_name);
              opt['role'] = doc.type;
              opt['value'] = doc.email;
              opt['department'] = doc.department;
              opt['firstName'] = doc.first_name;
              opt['lastName'] = doc.last_name;
              opt['label'] = doc.displaytitle+"."+" "+name +", "+"Dep of "+doc.department +" ("+doc.qualification+" )";

              list.push(opt);
              if(props.patientData.person && props.patientData.person.personId==doc.personId){
                setselectedDoctor(opt['label']);  
  }
              }
            }
              console.log("list doctor Response:", list)
            }
            setdoctorList([...list])
  
          }
         }))
     }
    const closeModal = () => {
        props.onModalClose()
        // window.location.href = `/patient/:patientid/:visitid/:groupid`;
    }

    const handleDeptChange = (event) =>{  
        console.log("event:",event);
        if (event && event.id) {
            summary.targetid = event.id;
            summary.role = event.role;
            setSummary({ ...summary });
            setselectedDoctor(event.label);
          }else{
            setselectedDoctor(null);
          }
        }

    const handleInputChange = (value) => {

       // summary.longdesc = value;
       if(value !=""){
        formErrors.longdesc="";
        setFormErrors({...formErrors}); 
       }
       console.log("setDescription",value)
       setDescription(value);

    }

    const submit = (e) => {
        e.preventDefault();  
        let details = {...summary};
    
          details.shortdesc = "Added "+ selectedDoctor +" "+"as consultant";
          details.longdesc = description;
          details.groupname = "Consultant";
          details.visitId = props.patientData.visit.id;
          details.patientId = props.patientData.id; 
          setSummary({...details});
          var ServerResVal = ServerRes;

          console.log("details details to be sent in addconsultant component:",details);
          let flag = '';
          
          validateField('longdesc',details['longdesc']);
          let validation=validateForm(e); 

          let consultant = summary.role;
          consultant = consultant.charAt(0).toUpperCase() + consultant.slice(1);

          console.log("Enter if",summary)
          if(validation){
            
          dispatch(doctorActions.addConsultant(details,consultant,flag,resCb=>{
            if(resCb){
              console.log("addConsultant Response:", resCb)
                      
            } 
            if(resCb)
                if(resCb.successMessages){
                    ServerResVal.success = "Counsellor Assigned Successfully."
                    props.onModalClose(ServerResVal);
                }
                else{
                    ServerResVal.error = "Failed to Assign Doctor."
                    props.onModalClose(ServerResVal);
                
                }
                setServerRes({...ServerResVal});
                console.log('resCbe : ',resCb);
            }
            ))
        e.preventDefault();
        }
    }


    const validateField = (fieldName, value) => {
        let fieldValidationErrors = formErrors;
        let basicInfo = summary;
        fieldValidationErrors.longdesc = value !== '' ? '' : 'Please Enter Description';
        basicInfo.longdesc = value !== '' ? value : '';
        setFormErrors({...fieldValidationErrors}); 
    }

    const validateForm = (event) => {
        event.preventDefault();
        let flag = '';
        console.log("formErrors.::",formErrors);
        let formValid = formErrors.longdesc === "";
        console.log("formValid",formValid)
        if(formValid){
            return true
        }
        else{
        console.log("invalid");
        }
    }

    return (  
        <>  
   
        <div className=" bg-none nav-wrapper h-100 d-flex flex-column conultant-view">
        
            <div className="d-flex">                  
            <IconButton
                                aria-label="close"
                                onClick={(e) => closeModal(e)}
                                sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                         <CloseIcon />
                    </IconButton>   
            </div>

            <div className="d-flex flex-column p-4">
                <div className='my-5'>
                <label className="service-lable">Consultant:</label>
                <Select
                    {...doctorList}
                    className="select-tag"
                    name="Search Doctor"
                    value = {
                    doctorList.filter(option => 
                        option.label === selectedDoctor)
                    }
                    onChange={handleDeptChange}
                    isClearable
                    isSearchable
                    options={doctorList}
                    filterOption={createFilter(doctorList)}
                    defaultValue={selectedDoctor}
                    >

                </Select>
                </div>
                <div className='my-3'  id='addconsultant_Description'>
                      <label className="service-lable">Description :</label>
                      <TextField
                            className="p-2"
                            value={description}
                            placeholder="Type here..."
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={5}
                            onChange={(event) => handleInputChange(event.target.value)}
                        />
                        <div style={{ color: "#EC4147" }}>
                            <span>{formErrors.longdesc}</span>
                        </div>
                            {/* <TextField
                                className='p-2'
                                multiline
                                minRows={5}
                                variant="outlined"
                              
                                placeholder="Type here..."
                                autoComplete="off"
                                value={description}
                                onChange={(event) => handleInputChange("description", event.target.value)}
                                /> */}
                </div>
                

                <div className='d-flex justify-content-end my-5'>
                    <Button color="primary"  variant="contained" sx={{textTransform:'none'}} type="submit"  className="px-7" onClick={(event)=>submit(event)}>Save</Button>
                </div>
              
            </div>

        </div>
        </>
    )
}

export default AddConsultant;
