import React, { useState, useEffect } from 'react';

function Reports(){

useEffect(() => {
    sessionStorage.removeItem('searchvalue');
},[])
return( <>

<div className="container vh-100">
    <div className="h_iframe h-100">
        {/* <iframe  src="https://super.prolake.in/login/egaSSO/embed/https://super.prolake.in/superset/dashboard/1/?standalone=true" frameborder="0" allowfullscreen ></iframe> */}
        <iframe  src="https://super.prolake.in/superset/dashboard/1/" frameborder="0" allowfullscreen ></iframe>

    </div>
</div>
 </>
    );
}
export default Reports;
