import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      let token = response.headers.get('Authorization');
      if(token != null && token != undefined){
        localStorage.setItem('token', JSON.stringify(token));
      }
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
  }
  
  function saveZoomNotes(data) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken
       // Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlc2xpc3QiOlsiTnVyc2UiXSwiaXNzIjoiUFJPM0xBS0VBIiwicHJvZmlsZVJvbGUiOiJOdXJzZSIsImV4cCI6MTY0NzU5NjYxNSwiaWF0IjoxNjQ3NTk0ODE1LCJlbWFpbCI6IjkxMTM5MzQ2NDUifQ.MU0WRviscfUm75sOZwRnMTGWSkC34tPqFlhF2DpVx2U",
      },
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/procedure`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

   function updateZoomNotes(data) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/updateZoomNotes`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function listZoomNotes(data) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    ///patientProfile?patientId=${patientId}
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/listZoomNotes?patientId=${data.patientId}&visitId=${data.visitId}&groupId=${data.patientId}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

 /*  function listZoomNotes(data) {
    console.log( " listZoomNotes service", data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/listZoomNotes`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        console.log( " listZoomNotes data", data);
        return data;
      });
  } */

const zoomServices = {
  logout,
  saveZoomNotes,
  updateZoomNotes,
  listZoomNotes
};
export default zoomServices;
