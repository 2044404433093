import { format } from "date-fns";
import React, { useState, useEffect } from 'react';

function getDate(date){
    console.log("Helper -Date",date)
    var dateinfo = new Date(date);
    var finalDate = new Date(dateinfo.getTime() + dateinfo.getTimezoneOffset() * 60000);
    //console.log("Helper--",finalDate.toLocaleDateString())
    return finalDate.toLocaleDateString()      
}

function formatDate(d) {
    console.log("H-Date",d)
    let date = new Date(d)
    var dd = date.getDate(); 
    var mm = date.getMonth()+1;
    var yyyy = date.getFullYear();
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    var newDate = yyyy + "-" + mm + "-" + dd + " " + h + ":" + m + ":" + s
    //2021-06-01 07:03:42
    return newDate
    
}

function getDateFromTimestamp(d) {
    let date = new Date(d)
    var dd = date.getDate(); 
    var mm = date.getMonth()+1;
    mm = mm.length === 2 ? mm : '0'+mm;
    var yyyy = date.getFullYear();
    
    var dt =  yyyy + "-" + mm + "-" + dd
    //2021-06-01 07:03:42
    return dt
    
}

function getFullName(fname,mname,lname){
   var fullName = '';

              if (!fname || fname==null){fname=''}
              if(!mname || mname==null){mname=''}
              if(!lname || lname==null){lname=''}
              var pfname = fname
              console.log("pfname : ",pfname);
            //   .toLowerCase();
              var pmname = mname
              console.log("pmname : ",pmname);
            //   .toLowerCase();
              var plname = lname
              console.log("plname : ",plname);
            //   .toLowerCase();
              if(pfname==null){pfname=''}
              if(pmname==null){pmname=''}
              if(plname==null){plname=''}
              pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1)
              pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1)
              plname = plname.charAt(0).toUpperCase() + plname.slice(1)
              if(fname && lname){
                  fullName = pfname + ' ' + plname;
                  console.log("fullName : ",fullName);
                }else if(fname && mname){
                    fullName = pfname + ' ' + pmname;
                    console.log("fullName : ",fullName);
                }else if(fname && mname && lname){
                    fullName = pfname + ' ' + pmname + ' ' + plname;
                    console.log("fullName : ",fullName);
                }else{
                    fullName = pfname + "      ";
                    console.log("fullName : ",fullName);
                }
    return fullName;
} 

function getToken() {
    var token = JSON.parse(localStorage.getItem('token'));
    
    return token; 
}

function getAuthorization() {

    var token = getToken();
    var accessToken = "Bearer "
    if(token) {
        accessToken = accessToken + token;
    }
    
    return accessToken;
}

function getRole(){
    var role = JSON.parse(localStorage.getItem("role"));
    let roles = ''
    if (role) {
        // roles = role.split(",");
        if (roles && roles.length > 0) {
            if (roles.indexOf("Admin") !== -1) {
                return 'Admin'
            }
            } else if (roles.indexOf("doctor") !== -1) {
                return 'doctor'
            }
            else if (roles.indexOf("serviceDesk") !== -1) {
                return 'serviceDesk'
            }
            else if (roles.indexOf("Nurse") !== -1){
                return 'Nurse'
            }
    }
    return roles[0]
}

function getLoggedUserName(){
    var name = JSON.parse(localStorage.getItem("user"));
    console.log('Logged in User ',name)
    return name
}

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }
 
 //Function for resizing image
 function resizeImage(image, max_size) {
     // Resize the image
     var canvas = document.createElement('canvas'),
     //max_size = 544
     width = image.width,
     height = image.height;
     if (width > height) {
         if (width > max_size) {
             height *= max_size / width;
             width = max_size;
         }
     } else {
         if (height > max_size) {
         width *= max_size / height;
         height = max_size;
         }
     }
     canvas.width = width;
     canvas.height = height;
     canvas.getContext('2d').drawImage(image, 0, 0, width, height);
     var dataUrl = canvas.toDataURL('image/jpeg');
     var resizedImage = dataURLToBlob(dataUrl);
     //console.log("ResizedImage",resizedImage)
     return resizedImage;
 }

var patientsid = function (patientId){
    var patientid;
    patientid = patientId;
}

 /* Utility function to convert a canvas to a BLOB */
 var dataURLToBlob = function (dataURL) {
     var BASE64_MARKER = ';base64,';
     if (dataURL.indexOf(BASE64_MARKER) === -1) {
         var parts = dataURL.split(',');
         var contentType = parts[0].split(':')[1];
         var raw = parts[1];
 
         return new Blob([raw], { type: contentType });
     }
 
     //var
    parts = dataURL.split(BASE64_MARKER);
    // var
      contentType = parts[0].split(':')[1];
   //  var
      raw = window.atob(parts[1]);
     var rawLength = raw.length;
 
     var uInt8Array = new Uint8Array(rawLength);
 
     for (var i = 0; i < rawLength; ++i) {
         uInt8Array[i] = raw.charCodeAt(i);
     }
 
     return new Blob([uInt8Array], { type: contentType });
 }
 /* End Utility function to convert a canvas to a BLOB      */

 const readToDisplayImage = (image) => {
    var file = image;
    var reader = new FileReader();
    var url = null;
    // reader.readAsDataURL(file);
    var objurl = URL.createObjectURL(file);
    return objurl;
 }
 const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm"); 
    return formatteddate;
}

const logout = () => {
    console.log('Clicked on logout ');
    localStorage.clear();
    sessionStorage.clear();
  }

  const roles = {
    "Admin" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom","TokenDetails","NonRedeemableVouchers"],
    "Admin2" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom","TokenDetails","NonRedeemableVouchers"],
    "FrontDesk" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom","TokenDetails","NonRedeemableVouchers"],
    "RegistrationDesk" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom","TokenDetails","NonRedeemableVouchers"],
    "InsuranceDesk" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom","Insurance"] ,
    "ServiceDesk" : ["PatientForm","Complaints","AssignDoctor","Attachments","BarCode","AllocateRoom"],
    "Doctor" : ["diagnosis","PatientForm","NonRedeemableVouchers"]
    
  }
  
  function getRoles(){
    return roles;
  }


 const CheckAccessLevel = (access) => {
 
 let AccessLevel = JSON.parse(localStorage.getItem("AccessLevel"));
 console.log("AccessLevel",AccessLevel);
 let accessSplit = AccessLevel.split(',');
 console.log("accessSplit",accessSplit);
 console.log("sent string is",access);
 
 let matchedAccess = accessSplit.filter((item)=>{
    console.log("Itemn is",item);
    if(item === access){
        console.log("Itemn inside if",item);
        return true;
    }else {
        return false;
    }
  });
  if(matchedAccess.length > 0 ){
    return true;
  }else{
    return false;
  }
 
 }
const helperServices = {
    getDate,
    formatDate,
    getToken,
    getAuthorization,
    getDateFromTimestamp,
    getRole,
    getLoggedUserName,
    resizeImage,
    bytesToSize,
    dataURLToBlob,
    patientsid,
    getFormattedDate,
    getFullName,
    logout,
    readToDisplayImage,
    getRoles,
    CheckAccessLevel
}
export default helperServices
