import Components from "./index";
function VouchersModal(props) {
    const closeVoucherModal = (event) =>{
      
        props.closeVoucher(false);
        props.onModalClose(event);
    }
    return (
      <div className="vochermodal">
        <div className='"d-flex'>
          <span
            className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
            onClick={(event) => closeVoucherModal(event)}
          >
            X
          </span>
        </div>

        <h4 className="applyVoucher">Apply Vouchers</h4>
        <Components.Vouchers
        pateintInfo = {props.pateintInfo}
        redeemVoucher={(event) => closeVoucherModal(event)}
        />
      </div>
    );
  }
  export default VouchersModal;
