import { SET_PATIENT_MEETING_INFO,ZOOM_NOTES_LIST} from "../../shared/constants";

const zoomDetailsState = {
  meetingInfo: [],
  zoomNotesList: [],
};

function setZoomDetailsState(state = zoomDetailsState, action) {
  switch (action.type) {
    case SET_PATIENT_MEETING_INFO:
      console.log('meetingInfo',action.payload)
      return Object.assign({}, state, {
        meetingInfo: action.payload,
      });
    case ZOOM_NOTES_LIST:
      console.log('meetingInfo',action.payload)
      return Object.assign({}, state, {
        zoomNotesList: action.payload,
      });
    default:
      return state;
  }
}
export default setZoomDetailsState;
