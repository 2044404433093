import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { Navbar, NavbarBrand } from "shards-react";
import {  ADMIN_PAGE_HEADER } from '../../../shared/constants';
import { Dispatcher, Constants } from "../../../flux";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'

function SidebarMainNavbar(props) {
  const [heading, setHeading] = useState('');
   
  const handleToggleSidebar=()=> {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  useEffect(()=>{
    var role = JSON.parse(localStorage.getItem("role"));
      console.log("Role from session : ", role);
      if (role) {
        const roles = role; 
        // role.split(",");
        if (roles && roles.length > 0) {
          if (roles.indexOf("Admin") !== -1) {
            setHeading('Admin');
          }
          else if (roles.indexOf("Doctor") !== -1) {
            setHeading('Doctor');
          }
          else if (roles.indexOf("serviceDesk") !== -1){
            setHeading('Service Desk');
          }
        }
      }
  },[]);
 
    const { hideLogoText } = props;
    return (
      <div className="main-navbar">
        {/* <div
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <div
            className="w-100 mr-0"
            style={{ lineHeight: "25px" }}

          >
            <div className="d-table m-auto">
              
              {!hideLogoText && (
                <span className="d-md-inline ml-1 navlabel">
                  {ADMIN_PAGE_HEADER}
        
   
                </span>
              )}
              
            </div>
          </div>
          <img src="Manaswini_Logo.jpg" ></img>

        </div> */}
         <AppBar position="fixed" className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0">
      <Toolbar className="w-100 mr-0">
        <Typography variant="h6" className="w-100 mr-0" style={{ lineHeight: "25px" }}>
          <Box sx={{ display: 'table', margin: 'auto' }}>
          <img
            alt="Logo"
            src="Manaswini_Logo.jpg"
            className="d-none d-md-inline"
            style={{ width: 55, height: 53 }}
          />
          </Box>
        </Typography>
        {/* <img src="Manaswini_Logo.jpg" alt="Logo" className="px-2 d-none d-md-block" /> */}
      </Toolbar>
    </AppBar>
      </div>

    );
  }
{/* <AppBar position="static" className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0">
        <Toolbar className="w-100 mr-0">
          <img
            alt="Logo"
            src="Manaswini_Logo.jpg"
            className="d-none d-md-inline"
            style={{ width: 59, height: 59 }}
          />
        </Toolbar>
      </AppBar> */}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
