import { combineReducers } from 'redux';
import patientDetailsState from '../reducers/patientDetails';
import bedDetailsState from '../reducers/bedDetails';
import formDetailsState from '../reducers/formDetails';
import categoryDetailsState from '../reducers/categoryDetails';
import doctorDetailsState from '../reducers/doctorDetails';
import diagnosisDetailsState from '../reducers/diagnosisDetails';
import zoomDetailsState from '../reducers/zoomDetails';
import ehrDetailsState from '../reducers/zoomDetails';
import TokenDetailsState from '../reducers/tokenDetails';

const appReducer = combineReducers({
  patientDetailsState,
  bedDetailsState,
  formDetailsState,
  categoryDetailsState,
  doctorDetailsState,
  diagnosisDetailsState,
  zoomDetailsState,
  ehrDetailsState,
  TokenDetailsState
,})

const rootReducer = (state, action) => {
   
    return appReducer(state, action)
}
export default rootReducer
