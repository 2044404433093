import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getAllDiagnosis(data) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(data),
    };
    const url = basePath + `/master/patientdiagnosis`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
}

function addDiagnosisMaster(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/diagnosisMaster`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getDiagnosisMaster() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(),
  };
  const url = basePath + `/master/diagnosisMaster`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addDiagnosis(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/diagnosis`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

// function updateDoctor(data) {
//   var accessToken;
//   if (helperServices.getAuthorization()) {
//     accessToken = helperServices.getAuthorization();
//   }
//   const requestOptions = {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: accessToken,
//     },
//     body: JSON.stringify(data),
//   };
//   const url = basePath + `/master/doctor`;
//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data;
//     });
// }

const diagnosisServices = {
    handleResponse,
    logout,
    getAllDiagnosis,
    addDiagnosisMaster,
    getDiagnosisMaster,
    addDiagnosis
  };
  export default diagnosisServices;
  
