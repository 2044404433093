import DocSetup from './docSetup';
import DocVisitFee from './docVisitFee';
import ListDoctor from './ListDoctor';
import ListPatient from './ListPatient';
import Dashboard from './Dashboard';
import SearchDept from './SearchDept';
import DoctorListGrid from '../master/doctor/DoctorListGrid';
import DoctorRegister from '../master/doctor/DoctorRegister';
import DoctorList from '../master/doctor/DoctorList';
import addDoctor from '../master/doctor/addDoctor';




const dcomponents = {
    DocSetup,
    DocVisitFee,
    ListDoctor,
    ListPatient,
    Dashboard,
    SearchDept,
    DoctorListGrid,
    DoctorRegister,
    DoctorList,
    addDoctor
}

export default dcomponents;
