import React, { useState, useEffect } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PdfPrinter from "pdfmake";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";
import { MANASWINI_LOGO } from "../../shared/constants/manaswinilogo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export const DISCHARGE = "Discharge Summary";

const qz = require("qz-tray");
const printerName = process.env.REACT_APP_PRINTER_NAME;
const EMERGENCY_CONTACT_NO = process.env.REACT_APP_EMERGENCY_NUNBER;

function NotesPrint(props) {
  console.log("data to get ... :", props);
  const file_number = props.patientdetails.fileNo
    ? props.patientdetails.fileNo
    : "";
  const name =
    props.patientdetails.fname ||
    props.patientdetails.mname ||
    props.patientdetails.lname
      ? props.patientdetails.fname +
        " " +
        props.patientdetails.mname +
        " " +
        props.patientdetails.lname
      : "";
  const age =
    props.patientdetails.age || props.patientdetails.gender
      ? props.patientdetails.age + "/" + props.patientdetails.gender
      : "";
  const contact_number = props.patientdetails.phno
    ? props.patientdetails.phno
    : "";
  // const address = props.patientdetails.address || props.patientdetails.city || props.patientdetails.pincode ? props.patientdetails.address + " , " + props.patientdetails.city + " , " + props.patientdetails.pincode : '';
  const city = props.patientdetails.city;
  const state = props.patientdetails.state;
  const pincode = props.patientdetails.pincode;
  const address =
    (props.patientdetails.address !== null &&
    props.patientdetails.address !== "" &&
    props.patientdetails.address !== undefined
      ? props.patientdetails.address + ", "
      : "") +
    (city ? city : "Arkula") +
    (state ? ", " + state : ", Karnataka") +
    (pincode ? " " + pincode : "");
  const referral = props.patientdetails.srcref
    ? props.patientdetails.srcref
    : "";
  const language = props.patientdetails.language
    ? props.patientdetails.language
    : "";
  const marital_status = props.patientdetails.sociostatus
    ? props.patientdetails.sociostatus
    : "";
  const education = props.patientdetails.education
    ? props.patientdetails.education
    : "";
  const occupation = props.patientdetails.occupation
    ? props.patientdetails.occupation
    : "";
  const case_worked_up_by = "";
  const psychiatrist = props.patientdetails.doctor
    ? props.patientdetails.doctor
    : "";
  const social_worker = "";
  const chief_complaints = props.notes["Chief Complaints"];
  const onset = props.notes.Onset;
  const duration = props.notes.duration;
  const progression = props.notes.progression;
  const precipitating_factor = props.notes["Precipitating factor"];
  const clinical_summary = props.notes["Clinical Summary"];
  const treatment_history = props.notes["Past and Treatment History"];
  const medical_history = props.notes["Relevant Medical History"];
  const family_history = props.notes["Relevant Family History"];
  const personal_history = props.notes["Personal History"];
  const premorbid_personality = props.notes["Premorbid Personality"];
  const psycho_social_factor = props.notes["Psycho-social Factors"];
  const pulse = props.notes.pulse;
  const bp = props.notes.bp;
  const weight = props.notes.weight;
  const bmi = props.notes.bmi;
  const mental_state = props.notes["Mental State Examination"];
  const provisional_diagnosis = props.notes["Provisional Diagnosis"];
  const final_diagnosis = props.notes["Final Diagnosis"];
  const consultant_notes = props.notes["Consultant Notes and Management Plan"];
  const val = new Date();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(val);

  const date = formattedDate ? formattedDate : "";
  useEffect(() => {
    props.parentCallBack();
  }, []);
  dischargeSummaryPrint();

  function dischargeSummaryPrint() {
    const docDefinition = {
      pageMargins: [40, 60, 40, 60],
      header: [
        {
          columns: [
            {
              image: MANASWINI_LOGO,
              fit: [120, 100], // set the width and height of the image
              margin: [60, 5, 0, 60],
              width: 90, // set the top and bottom margin of the image
            },
            [
              {
                text: HOSPITAL_NAME,
                width: "auto",
                bold: "true",
                margin: [90, 10, 0, 0], //Add margin to separate header from content
              },
              {
                stack: [
                  {
                    text: HOSPITAL_DESC,
                    width: "auto",
                    margin: [90, 0, 0, 20],
                    border: [false, false, false, true],
                  },
                ],
              },
            ],
          ],
        },
      ],
      content: [
        {
          text: "OUT-PATIENT CASE RECORD",
          style: "pageTitle",
          alignment: "center",
        },

        {
          margin: [0, 0, 0, 10],
          table: {
            widths: [170, 100, 120, 100],
            body: [
              [
                { text: "File number", fillColor: "#CCCCCC" },
                file_number,
                { text: "Date", fillColor: "#CCCCCC" },
                date,
              ],
              [
                { text: "Name", fillColor: "#CCCCCC" },
                { text: name, colSpan: 3 },
                "",
                "",
              ],
              [
                { text: "Age/Gender", fillColor: "#CCCCCC" },
                age,
                { text: "Contact number", fillColor: "#CCCCCC" },
                contact_number,
              ],
              [
                { text: "Address", fillColor: "#CCCCCC" },
                { text: address, colSpan: 3 },
                "",
                "",
              ],
              [
                { text: "Source of referral", fillColor: "#CCCCCC" },
                { text: referral, colSpan: 3 },
                "",
                "",
              ],
              [
                { text: "Language known", fillColor: "#CCCCCC" },
                language,
                { text: "Marital status", fillColor: "#CCCCCC" },
                marital_status,
              ],
              [
                { text: "Education", fillColor: "#CCCCCC" },
                education,
                { text: "Occupation", fillColor: "#CCCCCC" },
                occupation,
              ],
              [
                { text: "Case worked up by", fillColor: "#CCCCCC" },
                { text: case_worked_up_by, colSpan: 3 },
                "",
                "",
              ],
              [
                { text: "Psychiatrist", fillColor: "#CCCCCC" },
                { text: psychiatrist, colSpan: 3 },
                "",
                "",
              ],
              [
                { text: "Social worker", fillColor: "#CCCCCC" },
                { text: social_worker, colSpan: 3 },
                "",
                "",
              ],
            ],
          },
        },

        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [80],
            widths: [170, 338],
            body: [
              [
                { text: "Chief Complaints", fillColor: "lightgray" },
                chief_complaints,
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [20, 20, 20, 20],
            widths: [170, 338],
            body: [
              [{ text: "Onset", style: "top", fillColor: "lightgray" }, onset],
              [
                {
                  text: "Duration",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                duration,
              ],
              [
                {
                  text: "Progression",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                progression,
              ],
              [
                {
                  text: "Precipitating factor",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                precipitating_factor,
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [120],
            widths: [170, 338],
            body: [
              [
                { text: "Clinical Summary", fillColor: "lightgray" },
                clinical_summary,
              ],
            ],
          },
        },
        { text: "", pageBreak: "after" },
        {
          style: "fontsize",
          margin: [0, 10, 0, 10],
          table: {
            heights: [40, 20, 20, 20, 20],
            widths: [170, 338],
            body: [
              [
                {
                  text: "Past and treatment history",
                  style: "top",
                  fillColor: "lightgray",
                },
                treatment_history,
              ],
              [
                {
                  text: "Relevant medical history",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                medical_history,
              ],
              [
                {
                  text: "Relevant family history",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                family_history,
              ],
              [
                {
                  text: "Personal history",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                personal_history,
              ],
              [
                {
                  text: "Premorbid personality",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                premorbid_personality,
              ],
            ],
          },
        },

        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [80],
            widths: [170, 338],
            body: [
              [
                { text: "Psycho-social factor", fillColor: "lightgray" },
                psycho_social_factor,
              ],
            ],
          },
        },

        {
          style: "fontsize",
          margin: [0, 0, 0, 0],
          table: {
            heights: [20],
            widths: [517],
            body: [
              [
                {
                  text: "Relevant examination findings:",
                  fillColor: "lightgray",
                },
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [20, 20, 20, 20, 20],
            widths: [170, 338],
            body: [
              [{ text: "Pulse", style: "top", fillColor: "lightgray" }, pulse],
              [{ text: "BP", alignment: "start", fillColor: "lightgray" }, bp],
              [
                { text: "Weight", alignment: "start", fillColor: "lightgray" },
                weight,
              ],
              [
                { text: "BMI", alignment: "start", fillColor: "lightgray" },
                bmi,
              ],
            ],
          },
        },

        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [60],
            widths: [170, 338],
            body: [
              [
                { text: "Mental State Examination", fillColor: "lightgray" },
                mental_state,
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [20, 20],
            widths: [170, 338],
            body: [
              [
                {
                  text: "Provisional diagnosis",
                  style: "top",
                  fillColor: "lightgray",
                },
                provisional_diagnosis,
              ],
              [
                {
                  text: "Final diagnosis",
                  style: "top",
                  fillColor: "lightgray",
                },
                final_diagnosis,
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [100],
            widths: [170, 338],
            body: [
              [
                {
                  text: "Consultant notes and management plan",
                  fillColor: "lightgray",
                },
                consultant_notes,
              ],
            ],
          },
        },
        {
          text: [
            {
              text: "Signature of case recorder:\t\t\t\t\t\t\t\t\t\t\t\tSignature of consultant:",
              style: "text",
            },
          ],
        },
      ],
      footer: function (currentPage, pageCount) {
        return [
          {
            text: HOSPITAL_NAME,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [40, 0, 0, 0],
          },
          {
            text: HOSPITAL_DESC,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [40, 0, 0, 0],
          },
          {
            text: HOSPITAL_ADDRESS,
            alignment: "left",
            fontSize: 8,
            margin: [40, 0, 0, 0],
          },

          {
            // text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
            text: "Mob:" + EMERGENCY_CONTACT_NO + "| Email:" + EMAIL_ID,
            alignment: "left",
            fontSize: 8,
            margin: [40, 0, 0, 5],
          },
          {
            text: currentPage.toString() + " of " + pageCount.toString(),
            alignment: "right",
            fontSize: 8,
            margin: [0, 0, 40, 0],
          },
        ];
      },
      styles: {
        pageTitle: {
          fontSize: 13,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
    };
    // PdfPrinter.createPdf(docDefinition).open();
    printPriscription(docDefinition);
  }

  function printPriscription(docDefinition) {
    PdfPrinter.createPdf(docDefinition).open();
    let pdf;
    const pdfDocGenerator = PdfPrinter.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      pdf = data;
    });
    console.log("pdfdocument is", pdfDocGenerator);
    console.log("printer status prop check else case connecting");
    qz.websocket
      .connect()
      .then(() => {
        console.log("connecting");
        console.log("connected");
        return qz.printers.find();
      })
      .then((printers) => {
        console.log("The Printers are ", printers);
        printers.filter((el) => {
          if (el.includes(printerName)) {
            // if (el.includes('HP DeskJet 1200 series')) {

            let config = qz.configs.create(el, {
              size: { width: 148, height: 210 },
              units: "mm",
              scaleContent: false,
            });
            console.log("config is", config);
            return qz
              .print(config, [
                {
                  type: "pixel",
                  format: "pdf",
                  flavor: "base64",
                  data: pdf,
                },
              ])
              .then(() => {
                console.log("print complete");
                return qz.websocket.disconnect();
              });
          }
        });
      })
      .then(() => {
        console.log("Disconnected");
        qz.websocket.disconnect();
      })
      .then(() => {})
      .catch((err) => {});
  }

  return <></>;
}
export default NotesPrint;
