import {LOGIN_REQUEST} from "../../shared/constants";
import {loginServices} from "../services";


export function login(code,resCb)
     {
        return dispatch => {
            dispatch(request(
                LOGIN_REQUEST, code
                ));
      
            loginServices.login(
                code, resCb
                )
                .then(
                    user => { 
                      if(user && user.successmsg !== '') {
                        localStorage.setItem('user', JSON.stringify(user.email));
                        localStorage.setItem('name', user.name);
                        localStorage.setItem('id', JSON.stringify(user.id));
                        localStorage.setItem('roles', JSON.stringify(user.roles));
                        localStorage.setItem('role', JSON.stringify(user.role));
                        localStorage.setItem('token', JSON.stringify(user.authToken));
                        localStorage.setItem('AccessLevel', JSON.stringify(user.access));
                        if(user.role === 'Doctor')
                            localStorage.setItem('doctorInformation',JSON.stringify(user.person));
                    //    dispatch(success(SET_LOGGED_IN_USER, user));
                    //    let loadPage = helperServices.getStartUpPage(user.serverResponse.authData.role)
                    //    window.location.href = props.history.push(loadPage);
    
                
                    }
                    //   dispatch(setLoggedIn(SET_LOGGED_IN_STATUS, true));
                    console.log("resCb in login actions:"+resCb);
                    if(resCb){
                        resCb(user);
                    }
                   
                    },
                    error => {
                        // dispatch(failure(LOGIN_FAILURE, error.toString()));
                    //    dispatch(alertActions.error(error.toString()));
                       resCb(error.toString);
                
                }
                );
        };
  }

function success(type, data){
    return { type: type, payload : data }
}

function request(type, value) {
    return { type: type, payload: value };
}

function failure(type, value) {
    return { type: type, payload : value };
  }

const bActions = {
    login,
    success,
    failure
}

export default bActions;
