import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import doctorActions from "../../../core/actions/doctorActions";
import MessageStrip from "../../../shared/messagestrip/MessageStrips";
import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import  {Grid}  from '@mui/material';
import { List} from '@mui/material';
import { Radio, RadioGroup, FormControlLabel,FormControl ,Select,FormHelperText,MenuItem} from '@mui/material';
import Button from "@mui/material/Button";
import { Alert} from "@mui/material";




function AddDoctor(props) {
  const dispatch = useDispatch();

  const [addFlag, setTextFlag] = useState(false);

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    dob: "",
    phoneno: "",
    email: "",
    type: "",
    qualification: "",
    displaytitle: "",
    desk:"",
    salarytype:"",
    department:"",
    gender:""


  });

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const [submitted, setSubmitted] = useState([]);

  const [doctorDetail, setdoctorDetail] = useState({
    id: "",
    patientId: "",
    first_name: "",
    mname: "",
    last_name: "",
    age: "",
    adhar: "",
    phoneno: "",
    area: "",
    city: "",
    state: "",
    pincode: "",
    gender: "",
    bloodgroup: "",
    occupation: "",
    address: "",
    dob: "",
    email: "",
    imgUrl: "",
    udt: "",
    department: "",
    salarytype: "",
    type: "",
    qualification: "",
    pincode: "",
    displaytitle: "",
    visit: {},
    upload: {},
    desk: "",
  });
  const [selectedGender, setSelectedGender] = useState();
  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [departmentList, setdepartmentList] = useState([]);
  const [deskList, setDeskList] = useState([]);
  const [salaryTypeList, setsalaryTypeList] = useState([]);
  const [rolesList, serrolesList] = useState([{ key: 'Doctor', value: 'doctor' }, { key: 'Counsellor', value: 'counsellor' }]);
  const handleInputChange = (name, value) => {
    formErrors[name] = "";
    console.log("In handleinput change", name, value);
    var pDetail = doctorDetail;
    console.log('pDetail["basicinfo"][name]', pDetail[name]);
    pDetail[name] = value;
    setdoctorDetail({ ...pDetail });
    console.log("pDetail", pDetail);
    console.log("doctorDetail is", doctorDetail);
  };
  let errormsg="Email is Invalid";
  const handleSubmit = (event) => {
    console.log("event");
    event.preventDefault();
    setSubmitted(true);

    // if (props && props.location.rowData) {
    //   doctorDetail["dob"] = new Date(doctorDetail.dob);
    //   console.log("edit case");
    //   dispatch(
    //     doctorActions.modifyDoctor(doctorDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("modifyDoctor", resCb);
    //         // props.onModalClose();
    //         props.history.push("/doctorlist");
    //       }
    //     })
    //   );
    // } else {
      // console.log("save case", doctorDetail);

    //   dispatch(
    //     doctorActions.saveDoctor(doctorDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("saveDoctor", resCb);
    //         // props.onModalClose();
    //         props.history.push("/doctorlist");
    //       }
    //     })
    //   );
    // }

    Object.keys(doctorDetail).forEach((key) => {
      console.log("key", key)
      console.log("key", doctorDetail[key])
      validateField(key, doctorDetail[key])
    })
    validateForm(event);
  };

  useEffect(() => {
    getSalaryTypeList()
    getDepartmentList()
    getDeskList()
    console.log("dr action props", props);
    if (props.location && props.location.rowData) {
      let docData = props.location.rowData;
      console.log("doctor data:", docData);
      setSelectedGender(docData.gender);
      setTextFlag(!addFlag);

      let date = new Date(props.location.rowData.dob);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      let formattedNumber = mm.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var yyyy = date.getFullYear();
      var newDate = yyyy + "-" + formattedNumber + "-" + dd;
      docData["dob"] = newDate;
      // docData['dob'] = helperServices.formatDate(props.doctorData.dob);
      // console.log(" newDate,", newDate.toISOString().slice(0,10));

      console.log(" docData,", docData);
      console.log(" formattedNumber,", formattedNumber);

      // let formatteddate = format(docData['dob'], "YYYY-MM-DD' 'HH:mm");
      // console.log(" formatteddate,", formatteddate);
      // getFormattedDate()
      // console.log("date converted" , format(props.doctorData.dob, 'dd/MM/yyyy'))

      setdoctorDetail(docData);
    } else {
      setTextFlag(addFlag);

      // setdoctorDetail(docData)
    }
  }, []);
  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  };
  const toggle = (event) => {
    props.history.push("/doctorlist");
  };


  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = doctorDetail;

    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    var currentDate = new Date().getDate;
    switch (fieldName) {
      case "first_name":
        console.log("first_name check");

        fieldValidationErrors.first_name = value !== '' ? '' : 'Please Enter First Name';
        basicInfo.first_name = value !== '' ? value : '';
        break;

      case "qualification":
        console.log("qualification check");
        fieldValidationErrors.qualification = value !== '' ? '' : 'Please Enter Qualification';
        basicInfo.qualification = value !== '' ? value : '';
        break;

      case "dob":
        fieldValidationErrors.dob = value !== '' ? '' : 'Please Select Date of Birth';
        basicInfo.dob = value <= currentDate ? value : '';
        break;

      case "phoneno":
        // fieldValidationErrors.first_name = value !== '' ? '' : 'Please Enter First Name';
        // fieldValidationErrors.phoneno = value.length == 10? '' : 'Please Enter Valid Phone Number';
        // basicInfo.phoneno = value !== '' ? value : '';
        // break;  
        if (fieldValidationErrors.phoneno = value.length == 0) {
          fieldValidationErrors.phoneno = value.length == 0 ? 'Please Enter Phone Number' : '';
          basicInfo.phoneno = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.phoneno = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.phoneno = value !== '' ? value : '';
          break;
        };

      case "email":
        if (!isEmail(value)) {
          fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Email';
          basicInfo.email = value !== '' ? value : '';
        };
        break;


      case "displaytitle":
        console.log("title check");
        fieldValidationErrors.displaytitle = value !== '' ? '' : 'Please Enter Display Title';
        basicInfo.displaytitle = value !== '' ? value : '';
        break;
      case "salarytype":
        console.log("title check");
        fieldValidationErrors.salarytype = value !== '' ? '' : 'Please Select Salary ';
        basicInfo.salarytype = value !== '' ? value : '';
        break;
      case "department":
        console.log("title check");
        fieldValidationErrors.department = value !== '' ? '' : 'Please Select Department ';
        basicInfo.department = value !== '' ? value : '';
        break;
      case "type":
        console.log("type check");
        fieldValidationErrors.type = value !== '' ? '' : 'Please Select Type';
        basicInfo.type = value !== '' ? value : '';
        break;
      case "gender":
        fieldValidationErrors.gender = value.length > 0 ? '' : 'Please Select Gender';
        basicInfo.gender = value !== '' ? value : '';
        break;

      default:
        break;
    }
    setFormErrors({ ...fieldValidationErrors });
  }



  var d = new Date();
  const todayDate = format(d.setDate(d.getDate() - 6570), "yyyy-MM-dd");

  const onSelectedGender = (value) => {
    setSelectedGender(value)
    console.log("setSelectedGender", value);
    doctorDetail.gender = value;
    formErrors.gender="";
  }

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }
  const validateForm = (event) => {
    event.preventDefault();

    // var Detail = doctorDetail;
    // var age = getAge(doctorDetail.dob)
    // setdoctorDetail(Detail);
    console.log("formErrors.::", formErrors);
    let formValid =
      formErrors.first_name === "" &&
      formErrors.dob === "" &&
      formErrors.email === "" &&
      formErrors.qualification === "" &&
      formErrors.displaytitle === "" &&
      formErrors.phoneno === "" &&
      formErrors.type === "" &&
      formErrors.desk==="" &&
      formErrors.displaytitle ==="" &&
      formErrors.salarytype ==="" &&
      formErrors.department ==="" &&
     formErrors.gender ==="";
    console.log("formValid", formValid);

    if (formValid) {

      if (props && props.location.rowData) {
        doctorDetail["dob"] = new Date(doctorDetail.dob);
        console.log("edit case", doctorDetail);
        dispatch(
          doctorActions.modifyDoctor(doctorDetail, (resCb) => {
            if (resCb) {
              console.log("modifyDoctor", resCb);
              // props.onModalClose();
              props.history.push("/doctorlist");
            }
          })
        );
      } else {
        console.log("save case", doctorDetail);
        if (doctorDetail.gender == "") {
          doctorDetail.gender = 'Male';
        }
        console.log("save case", doctorDetail);

        dispatch(
          doctorActions.saveDoctor(doctorDetail, (resCb) => {
            console.log("doctor details", resCb);
            
            if (resCb.accresp) {
                var ServerResVal=ServerRes;
                console.log("saveDoctor", resCb);
                ServerResVal.success = "Doctor added Successfully";
                setServerRes({...ServerResVal});
                setTimeout(() => {
                   props.history.push("/doctorlist");
                   //setServerRes({...ServerResVal});
                    
                   closeAlert();
                //     ServerResVal.success = "Doctor added Successfully";
                  }, 5000);
                 
           //   props.history.push("/doctorlist");
            }
            else {
              console.log("saveDoctor error", resCb);
              setShowMsgStrip(true);
              setMessageDetails({error: 'error',
              message: resCb});
            }
          })
        );
      }
    }
    else {
      console.log("invalid");
    }
  }

  const getDepartmentList = (data) => {
    dispatch(doctorActions.getDepartmentList(data, resCb => {
      if (resCb) {
        let deptlist = [];
        for (let dept of resCb) {
          let deptopt = {}
          deptopt['id'] = dept.id;
          deptopt['name'] = dept.name;
          deptlist.push(deptopt);
        }
        setdepartmentList([...deptlist])
        console.log("departmentLists:", departmentList);
      }
    }))
  }

  const getSalaryTypeList = () => {
    dispatch(doctorActions.getSalaryTypeList(resCb => {
      if (resCb) {
        
        setsalaryTypeList(resCb)
        console.log("SalaryTypeList:", salaryTypeList);
      }
    }))
  }

  const getDeskList = (data) => {
    dispatch(doctorActions.getDeskList(data,resCb => {
      if(resCb) {
        console.log("desklist response",resCb);
        let deskList = [];
        for(let desk of resCb){
          let deskopt = {}
          deskopt['id'] = desk;
          deskopt['name'] = desk;
          deskList.push(deskopt);
        }
        setDeskList([...deskList])
        console.log("deskLists:", deskList);
      }
    }))
  }
  const closeMessageStrip = () => {
    console.log("closing msg strip fn in doctorlist");
    setShowMsgStrip(!showMsgStrip);
  }

  return (
    <>
    {showMsgStrip ?
        <MessageStrip
          msgStrip={messageDetails}
          closeMessageStrip={(event) => closeMessageStrip(event)}
        />
        : ''}
         {ServerRes.success && (
                      // <Alert theme="success">{ServerRes.success}</Alert>
                      <Alert severity="success">{ServerRes.success}</Alert>
                    )}
                    {ServerRes.error && (
                      // <Alert theme="danger">{ServerRes.error}</Alert>
                      <Alert severity="error">{ServerRes.error}</Alert>
                    )}
      <div className="insuranceFormContainer p-3">
        <div >
          <h5> {addFlag ? "Edit" : "Add"} Doctor / Counsellor </h5>{" "}
        </div>

        <main className="mainsection">
          {/* <ListGroup flush className="mb-3 "> */}
          <List>
            <div className="p-2 bg-none">
              {/* <Row>
                <Col>
                  <Form>
                    <Row form>
                      <Col md="4" className="form-group"> */}
                      <Grid container className="d-block">
      <Grid item>
        <form>
          <Grid container spacing={2}>
            <Grid item md={4} id='addDoctor1'>
                        {/* <FormInput
                          id="first_name"
                          value={doctorDetail.first_name}
                          placeholder="First Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("first_name", event.target.value);
                          }}
                          invalid={formErrors.first_name === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.first_name}</span>
                        </div> */}
                        <TextField
                        className="p-2"
                        label="First Name"
                         id="first_name"
                         value={doctorDetail.first_name}
                         placeholder="First Name"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("first_name", event.target.value);
                         }}
                        //  error={formErrors.first_name !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.first_name}</span>}
                         variant="outlined"
                         fullWidth
                        
                      />
                        </Grid>
                      {/* <Col md="4" className="form-group">
                        <label htmlFor="mname">Middle Name</label>
                        <FormInput
                          id="mname"
                          value={doctorDetail.mname}
                          placeholder="Middle Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("mname", event.target.value);
                          }}
                        />
                      </Col> */}
                      <Grid item md={4} id='addDoctor1'>
                      {/* <label htmlFor="mname">Middle Name</label> */}
                      <TextField
                      className="p-2"
                      label="Middle Name"
                         id="mname"
                         value={doctorDetail.mname}
                         placeholder="Middle Name"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("mname", event.target.value);
                        }}
                         variant="outlined"
                         fullWidth
                        
                      />

                      </Grid>

                      {/* <Col md="4" className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <FormInput
                          id="last_name"
                          value={doctorDetail.last_name}
                          placeholder="Last Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("last_name", event.target.value);
                          }}
                        />
                      </Col> */}
                      <Grid item md={4} id='addDoctor1'>
                      {/* <label htmlFor="mname">Last Name</label> */}
                      <TextField 
                      className="p-2"
                      label="Last Name"
                         id="last_name"
                         value={doctorDetail.last_name}
                         placeholder="Last Name"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("last_name", event.target.value);
                        }}
                         variant="outlined"
                         fullWidth
                       
                      />

                      </Grid>
                      </Grid>

                    {/* <Row form>
                      <Col md="4" className="form-group">
                        <label htmlFor="gender">Gender</label> */}
                        {/*<FormInput
                          id="gender"
                          value={doctorDetail.gender}
                          placeholder="Gender"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("gender", event.target.value);
                          }} */}
                        {/* <Col md="12 d-flex justify-content-between">
                          <FormRadio
                            name="Male"
                            checked={selectedGender === "Male"}
                            onChange={(event) => { onSelectedGender('Male') }}
                          >Male{" "}
                          </FormRadio>
                          <FormRadio

                            name="Female"
                            checked={selectedGender === "Female"}
                            onChange={(event) => { onSelectedGender('Female') }}
                          >
                            Female{" "}
                          </FormRadio>
                          <FormRadio

                            name="Others"
                            checked={selectedGender === "Others"}
                            onChange={(event) => { onSelectedGender('Others') }}
                          >
                            Others{" "}
                          </FormRadio>
                        </Col>
                        <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.gender}</span>
                          </div>

                      </Col> */}

                      <Grid container spacing={1}>
                      <Grid item md={4}>
                      {/* <label htmlFor="gender">Gender</label> */}
      <RadioGroup
        row
        className="p-2"
        aria-label="gender"
        name="gender"
        value={selectedGender}
        onChange={(event) => onSelectedGender(event.target.value)}
        // error={formErrors.gender !== ''}
        //  helperText={<span style={{ color: '#EC4147' }}>{formErrors.gender}</span>}
      >
        <FormControlLabel value="Male" className="radioButon" control={<Radio />} label="Male" />
        <FormControlLabel value="Female" className="radioButon" control={<Radio />} label="Female" />
        <FormControlLabel value="Others" className="radioButon othersRadio" control={<Radio />} label="Others" />
      </RadioGroup>
          <div style={{ color: '#EC4147' }}>
          {formErrors.gender !== "" && (
        <FormHelperText style={{ color: "#EC4147" }}>{formErrors.gender}</FormHelperText>
          )}
        </div>

     
    </Grid>


                      {/* <Col md="4" className="form-group">
                        <label htmlFor="birthdetails">Date Of Birth</label>
                        <FormInput
                          type="date"
                          id="dob"
                          value={doctorDetail.dob}
                          placeholder="Date Of Birth"
                          max={todayDate}
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("dob", event.target.value);
                          }}
                          invalid={formErrors.dob === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.dob}</span>
                        </div>
                      </Col> */}

                      <Grid item md={4} id='addDoctor1'>
                      {/* <label htmlFor="birthdetails">Date Of Birth</label> */}
                      <TextField
                      className="p-2"
                         type="date"
                         id="dob"
                         value={doctorDetail.dob}
                         placeholder="Date Of Birth"
                         max={todayDate}
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("dob", event.target.value);
                        }}
                        //  error={formErrors.dob !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.dob}</span>}
                         variant="outlined"
                         fullWidth
                        
                      />
                      </Grid>

                      {/* <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Blood Group</label>
                        <br /> */}
                        {/* <FormInput
                          id="bloodgroup"
                          type="type"
                          value={doctorDetail.bloodgroup}
                          placeholder="Blood Group"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("bloodgroup", event.target.value);
                          }} />*/}
                        {/* <select name="selectrole" className='w-100 form-control'
                          value={doctorDetail.bloodgroup}
                          onChange={(event) => { handleInputChange("bloodgroup", event.target.value); }} >
                          <option disabled={true} value="">
                            --Choose an option--
                          </option>
                          <option value="A +ve">A +ve</option>
                          <option value="A -ve">A -ve</option>
                          <option value="B +ve">B +ve</option>
                          <option value="B -ve">B -ve</option>
                          <option value="AB +ve">AB +ve</option>
                          <option value="AB -ve">AB -ve</option>
                          <option value="O +ve">O +ve</option>
                          <option value="O-ve">O -ve</option>
                        </select>

                      </Col> */}
                      <Grid item md={4} className="form-group" style={{paddingTop:'0px'}}>
      {/* <label htmlFor="bgproup">Blood Group</label> */}
      <br />
      <FormControl fullWidth class="bloodGroup" className="p-2">
        <Select
        id="bloodgroup"
        displayEmpty
        name="selectrole" 
        className='w-100 '
          value={doctorDetail.bloodgroup}
          onChange={(event) => { handleInputChange("bloodgroup", event.target.value); }}
        >
        <MenuItem value="" >
        --Choose an Blood Group option--
      </MenuItem>
          <MenuItem value="A +ve">A +ve</MenuItem>
          <MenuItem value="A -ve">A -ve</MenuItem>
          <MenuItem value="B +ve">B +ve</MenuItem>
          <MenuItem value="B -ve">B -ve</MenuItem>
          <MenuItem value="AB +ve">AB +ve</MenuItem>
          <MenuItem value="AB -ve">AB -ve</MenuItem>
          <MenuItem value="O +ve">O +ve</MenuItem>
          <MenuItem value="O-ve">O -ve</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  </Grid>

  <Grid container spacing={2}>
  <Grid item md={4} id='addDoctor1'>

                    {/* <Row form>
                      <Col md="4" className="form-group"> */}
                        {/* <label htmlFor="gender">Qualification</label> */}
                        {/* <FormInput
                          id="qualification"
                          value={doctorDetail.qualification}
                          placeholder="Qualification"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "qualification",
                              event.target.value
                            );
                          }}
                          invalid={formErrors.qualification === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.qualification}</span>
                        </div> */}

                        
                      {/* <label htmlFor="mname">Qualification</label> */}
                      <TextField 
                      className="p-2"
                      label="Qualification"
                         id="qualification"
                         value={doctorDetail.qualification}
                         placeholder="Qualification"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange(
                            "qualification",
                            event.target.value
                          );
                        }}
                        variant="outlined"
                        fullWidth
                        // error={formErrors.qualification !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.qualification}</span>}
                      />
                        </Grid>
                        <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="birthdetails">Display Title</label> */}
                        {/* <FormInput
                          type="type"
                          id="displaytitle"
                          value={doctorDetail.displaytitle}
                          placeholder="Display Title"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "displaytitle",
                              event.target.value
                            );
                          }}
                          invalid={formErrors.displaytitle === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.displaytitle}</span>
                        </div> */}
                        <TextField 
                        className="p-2"
                        label="Display Title"
                         type="type"
                         id="displaytitle"
                         value={doctorDetail.displaytitle}
                         placeholder="Display Title"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange(
                            "displaytitle",
                            event.target.value
                          );
                        }}
                        // error={formErrors.displaytitle !== ''}
                        helperText={<span style={{ color: '#EC4147' }}>{formErrors.displaytitle}</span>}
                        variant="outlined"
                        fullWidth
                      />


                        </Grid>
                        <Grid item md={4} >
                        {/* <label htmlFor="email">Department</label> */}
                        {/* <FormInput
                          id="department"
                          value={doctorDetail.department}
                          placeholder="Department"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("department", event.target.value);
                          }}
                        /> */}

                        {/* <select value={doctorDetail.department} name="selectfloor" className='w-100 form-control'
                          onChange={(event) => { handleInputChange("department", event.target.value); }}> */}
                          {/* <option selected disabled hidden>--Select Department--</option> */}
                          {/* {departmentList.map((options, index) => (
                            <option key={index} value={options.name}>{options.name}</option>
                          ))}
                        </select> */}
               <FormControl fullWidth  className="p-2">
                    <Select
                     displayEmpty
                    className='w-100 p-2  selectDepartment'
                         labelId="department-label"
                         id="select-department"
                         value={doctorDetail.department}
                         onChange={(event) => { handleInputChange("department", event.target.value); }}
                        
                    >
                      <MenuItem value="" >
                      --Select Department--
                     </MenuItem>
                        {departmentList.map((options, index) => (
                        <MenuItem key={index} value={options.name}>
                        {options.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.department !== "" && (
        <FormHelperText style={{ color: "#EC4147" }}>{formErrors.department}</FormHelperText>
        
      )}
    </FormControl>
      </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                       <Grid item md={4} id='addDoctor1'>
                    {/* <Row form>
                      <Col md="4" className="form-group"> */}

                        {/* <label htmlFor="feInputAddress">Address</label> */}
                        {/* <FormInput
                          id="feInputAddress"
                          placeholder="address"
                          value={doctorDetail.address}
                          onChange={(event) => {
                            handleInputChange("address", event.target.value);
                          }}
                        /> */}

                       <TextField 
                       className="p-2"
                       label="Address"
                        id="feInputAddress"
                        placeholder="address"
                        value={doctorDetail.address}
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("address", event.target.value);
                        }}
                        variant="outlined"
                        fullWidth
               
                      />
                      </Grid>

                      <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="email">Email Id</label> */}
                        {/* <FormInput
                          id="email"
                          value={doctorDetail.email}
                          placeholder="Email Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("email", event.target.value);
                          }}
                          invalid={formErrors.email === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.email}</span>
                        </div> */}
                        <TextField 
                        className="p-2"
                        label="Email Id"
                          id="email"
                          value={doctorDetail.email}
                          placeholder="Email Id"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("email", event.target.value);
                        }}
                        // error={formErrors.email !== ''}
                        helperText={<span style={{ color: '#EC4147' }}>{formErrors.email}</span>}
                        variant="outlined"
                        fullWidth
                      />
                        </Grid>

                        <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="phoneno">Phone Number</label> */}
                        {/* <FormInput
                          id="phoneno"
                          type="number"
                          value={doctorDetail.phoneno}
                          placeholder="Phone Number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phoneno", event.target.value);
                          }}
                          invalid={formErrors.phoneno === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.phoneno}</span>
                        </div> */}
                        <TextField 
                        className="p-2"
                        label="Phone Number"
                          id="phoneno"
                          type="number"
                          value={doctorDetail.phoneno}
                          placeholder="Phone Number"
                         autoComplete="off"
                         onChange={(event) => {
                          handleInputChange("phoneno", event.target.value);
                        }}
                        // error={formErrors.phoneno !== ''}
                        helperText={<span style={{ color: '#EC4147' }}>{formErrors.phoneno}</span>}
                        variant="outlined"
                        fullWidth
                      />


                        </Grid>

                      </Grid>

                      <Grid container spacing={2}>
                       <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="feInputCity">City</label> */}
                        {/* <FormInput
                          id="feInputCity"
                          placeholder="City"
                          autoComplete="off"
                          value={doctorDetail.city}
                          onChange={(event) => {
                            handleInputChange("city", event.target.value);
                          }}
                        /> */}

                       <TextField 
                       label="City"
                       className="p-2"
                          id="feInputCity"
                          placeholder="City"
                         autoComplete="off"
                         value={doctorDetail.city}
                          onChange={(event) => {
                            handleInputChange("city", event.target.value);
                          }}
                        variant="outlined"
                        fullWidth
                      />

                      </Grid>
                      <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="feInputState">State</label> */}
                        {/* <FormInput
                          id="feInputState"
                          placeholder="State"
                          autoComplete="off"
                          value={doctorDetail.state}
                          onChange={(event) => {
                            handleInputChange("state", event.target.value);
                          }}
                        ></FormInput> */}
                        <TextField 
                         label="State"
                         className="p-2"
                          id="feInputState"
                          placeholder="State"
                          autoComplete="off"
                          value={doctorDetail.state}
                          onChange={(event) => {
                            handleInputChange("state", event.target.value);
                          }}
                        variant="outlined"
                        fullWidth
                      />
                      </Grid>
                      <Grid item md={4} id='addDoctor1'>
                        {/* <label htmlFor="phoneno">Pincode</label> */}
                        {/* <FormInput
                          id="pincode"
                          type="number"
                          value={doctorDetail.pincode}
                          placeholder="Pincode"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("pincode", event.target.value);
                          }}
                        /> */}

                        <TextField 
                        label="Pincode"
                        className="p-2"
                          id="pincode"
                          type="number"
                          value={doctorDetail.pincode}
                          placeholder="Pincode"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("pincode", event.target.value);
                          }}
                        variant="outlined"
                        fullWidth
                      />
                     </Grid>
                     </Grid>
                     <Grid container spacing={2} style={{padding:'10px 0px'}}>
                     <Grid item md={4}>
                        {/* <label htmlFor="phoneno">Type</label> */}
                        {/* <select value={doctorDetail.type} name="selectrole" className={formErrors.type === "" ? 'w-100 form-control' : 'w-100 form-control is-invalid'}

                          onChange={(event) => { handleInputChange("type", event.target.value); }}>
                          <option value="" selected="selected" disabled hidden>--Select Role--</option>
                          {rolesList.map((options, index) => (
                            <option key={index} value={options.value}>{options.key}</option>
                          ))}
                        </select>

                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.type}</span>
                        </div> */}
                        <FormControl fullWidth 
                        >
                        {/* <label htmlFor="phoneno">Type</label> */}
      <Select
       displayEmpty
        labelId="selectrole-label"
        id="selectrole"
        value={doctorDetail.type}
        name="selectrole"
        onChange={(event) => { handleInputChange("type", event.target.value); }}
        className={formErrors.type === "" ? 'w-100 ' : 'w-100  is-invalid'}
        
          sx= {{ height:"35px" }}
         
      >
        <MenuItem value="" >
          --Select Role--
        </MenuItem>
        {rolesList.map((options, index) => (
          <MenuItem key={index} value={options.value}>{options.key}</MenuItem>
        ))}
      </Select>
      {formErrors.type !== "" && (
        <FormHelperText style={{ color: "#EC4147" }}>{formErrors.type}</FormHelperText>
      )}
    </FormControl>


                        </Grid>


                     <Grid item md={4}>
                        {/* <label htmlFor="email">Salary Type</label>



                        <select value={doctorDetail.salarytype} name="selectfloor" className={formErrors.salarytype === "" ? 'w-100 form-control' : 'w-100 form-control is-invalid'}
                          onChange={(event) => { handleInputChange("salarytype", event.target.value); }}
                          invalid={formErrors.salarytype === "" ? false : true}

                          >
                          <option value="" selected="selected" disabled hidden>--Select Option--</option>
                          {salaryTypeList.map((options, index) => (

                        <select value={doctorDetail.salarytype} name="selectfloor" className='w-100 form-control'
                          onChange={(event) => { handleInputChange("salarytype", event.target.value); }}> */}
                          {/* <option selected disabled hidden>--Select Options--</option> */}
                          {/* {salaryTypeList.map((options, index) => (

                            <option key={index} value={options}>{options}</option>
                            // invalid={formErrors.phoneno === "" ? false : true}
                          ))}

                        </select>
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.salarytype}</span>
                        </div>
                      </Col>
                    <Col md="4" className="form-group">
                        <label htmlFor="email">Desk</label>

                        </select> */}




                        <FormControl fullWidth 
                        >
                       {/* <label htmlFor="email">Salary Type</label> */}
      <Select
      displayEmpty
              labelId="selectfloor-label"
        id="selectfloor"
        value={doctorDetail.salarytype}
        name="selectfloor"
        onChange={(event) => { handleInputChange("salarytype", event.target.value); }}        
          sx= {{ height:"35px" }}
         
      >
         <MenuItem value="" >
         --Select Salary Type Options--
        </MenuItem>
        {salaryTypeList.map((options, index) => (
          <MenuItem key={index} value={options}>{options}</MenuItem>
        ))}
      </Select>
      {formErrors.salarytype !== "" && (
        <FormHelperText style={{ color: "#EC4147" }}>{formErrors.salarytype}</FormHelperText>
      )}
    </FormControl>








                        </Grid>
                        <Grid item md={4}>
                        {/* <label htmlFor="email">Desk</label>


                        <select value={doctorDetail.desk} name="selectfloor" className='w-100 form-control'
                          onChange={(event) => { handleInputChange("desk", event.target.value); }}>
                          <option value="" selected="selected" disabled hidden>--Select Desk--</option>
                          {deskList.map((options, index) => (
                            <option key={index} value={options.name}>{options.name}</option>
                          ))}
                        </select> */}

                        {/* <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.desk}</span>
                        </div> */}
                         <FormControl fullWidth 
                        >
                       {/* <label htmlFor="email">Desk</label> */}
      <Select
      displayEmpty
              labelId="selectfloor-label"
        id="selectfloor"
        value={doctorDetail.desk}
        name="selectfloor"
        onChange={(event) => { handleInputChange("desk", event.target.value); }}        
          sx= {{ height:"35px" }}
         
      >
        
        <MenuItem value="" >
        --Select Desk--
        </MenuItem>
        {deskList.map((options, index) => (
          <MenuItem key={index} value={options.name}>{options.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
                        
      </Grid>


                      </Grid>

{/* 
                    {ServerRes.success && (
                      <Alert theme="success">{ServerRes.success}</Alert>
                    )} */}
                    {ServerRes.error && (
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    )}
                  </form>
                  <div className="buttonsetconfirm">
                    <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                  >
                  Cancel
                </Button>                    
                
                    <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Save
                </Button>
                  </div>
                  <br />
                  </Grid>
                  </Grid>
            </div>
            </List>
        </main>
      </div>
    </>
  );
}
export default AddDoctor;
