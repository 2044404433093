import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );
  const toggleSidebar=()=> {
  //  document.body.classList.toggle("sidebar-hidden");
   document.getElementById('sidebar').classList.toggle('sidebar-hidden')
   document.getElementById('mainpage').classList.toggle('sidebar-hidden')
  }

  return (
    <div className={classes}>
    <AppBar position="static" color="default" elevation={0}>
      <Container fluid className="pl-3 d-flex justify-content-between align-items-center">
        <IconButton color="inherit" edge="start" onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>

        <Toolbar className="align-items-stretch flex-md-nowrap p-0 jce">
          {/* <NavbarSearch /> */}
          <NavbarNav />
          <NavbarToggle />
        </Toolbar>
      </Container>
    </AppBar>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
