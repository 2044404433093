import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doctorActions, patientActions } from "../../../core/actions";
import { insuranceActions } from '../../../core/actions';
import TextField from "@mui/material/TextField";
import  {Grid}  from '@mui/material';
import { List} from '@mui/material';
import { Alert} from "@mui/material";
import Button from "@mui/material/Button";
function Tpaadd(props) {
  const dispatch = useDispatch();
  const [addFlag, setTextFlag] = useState(false);
  const [submitted, setSubmitted] = useState([]);


  const [formErrors, setFormErrors] = useState(
    {
      email: '',
      phone1: '',
    },
  )

  const [tpaDetail, setTpaDetail] = useState(
    {
      id: "",
      companyName: "",
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      description: ""



    }
  );
  const [ServerRes, setServerRes] = useState(
    { success: '', error: '', }
  );
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value);
    var pDetail = tpaDetail;
    console.log('pDetail["basicinfo"][name]', pDetail[name])
    pDetail[name] = value;
    formErrors[name] = "";
    setTpaDetail({ ...pDetail });

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // if(props.location.state&&props.location.state.rowData){
    //   console.log("edit case");
    //   //to change the label

    //   dispatch(
    //     insuranceActions.modifyTpa(tpaDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("modifyTpa", resCb);
    //         props.history.push("/tpamasterlist");

    //       }
    //     })
    //   );
    //   event.preventDefault();

    // }
    // else{
    //   console.log("save case");

    //   dispatch(
    //     insuranceActions.addTpaMaster(tpaDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("tpasave", resCb);
    //         props.history.push("/tpamasterlist");

    //       }
    //     })
    //   );
    // }
    Object.keys(tpaDetail).forEach((key) => {
      console.log("key", key)
      console.log("key", tpaDetail[key])
      validateField(key, tpaDetail[key])
    })
    validateForm(event);
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = tpaDetail;

    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    var currentDate = new Date().getDate;
    switch (fieldName) {

      case "phone1":
        if (fieldValidationErrors.phone1 = value.length == 0) {
          fieldValidationErrors.phone1 = value.length == 0 ? 'Please Enter Phone Number' : '';
          basicInfo.phone1 = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.phone1 = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.phone1 = value !== '' ? value : '';
          break;
        };
      case "email":
        if (!isEmail(value)) {
          fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Valid Email';
          basicInfo.email = value !== '' ? value : '';
          break;
        }

      default:
        break
    }
    setFormErrors({ ...fieldValidationErrors });
  }

  const validateForm = (event) => {
    event.preventDefault();

    // var Detail = doctorDetail;
    // var age = getAge(doctorDetail.dob)
    // setdoctorDetail(Detail);
    console.log("formErrors.::", formErrors);
    let formValid =

      formErrors.email === "" &&
      formErrors.phone1 === "";
    console.log("formValid", formValid);

    if (formValid) {
      if (props.location.state && props.location.state.rowData) {
        console.log("edit case");
        //to change the label

        dispatch(
          insuranceActions.modifyTpa(tpaDetail, (resCb) => {
            if (resCb) {
              console.log("modifyTpa", resCb);
              props.history.push("/tpamasterlist");

            }
          })
        );
        event.preventDefault();

      }
      else {
        console.log("save case");

        dispatch(
          insuranceActions.addTpaMaster(tpaDetail, (resCb) => {
            if (resCb) {
              console.log("tpasave", resCb);
              props.history.push("/tpamasterlist");

            }
          })
        );
      }

    }
    else {
      console.log("invalid");
    }
  }



  useEffect(() => {


    console.log("propsprops", props);

    if (props.location.state && props.location.state.rowData) {
      setTextFlag(!addFlag);
      let tpaData = props.location.state.rowData
      setTpaDetail(tpaData)
    } else {
      setTextFlag(addFlag);

      setTpaDetail(tpaDetail)
    }

  }, []);

  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  }
  const toggle = (event) => {
    // props.onModalClose();
    props.history.push("/tpamasterlist")
  }

  //  const getFormattedDate = (date) => {
  //       const reqdate = new Date(date);
  //       // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min; 
  //       let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");

  //   return formatteddate;
  //   }
  return (
    <>
        <div class="mainsection">
           <div ><h5> {addFlag ? "Edit" :"Add"}  Third Party Administrator </h5> </div>
         

            <main className="mainsection " id="mainsectioninsurence1">
            <List>
            <div className="p-2 bg-none">
            <Grid >
      <Grid item>
        <form>
          <Grid container spacing={2}>
            <Grid item md={6}>
                    <TextField
                      id="companyname"
                      fullWidth
                      className="p-2 tpa"
                      value={tpaDetail.companyName}
                      placeholder="Company Name"
                      label="Company Name"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "companyName",
                          event.target.value
                        );
                      }}
                    />
                       
                      </Grid>
                      <Grid item md={6}>
                      <TextField
                      id="email"
                      fullWidth
                      className="p-2 tpa"
                      value={tpaDetail.email}
                      placeholder="Email Id"
                      label="Email Id"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("email", event.target.value);
                             }}
                            //  error={formErrors.email !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.email}</span>}
                      
                    />

                      </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item md={6}>
                      {/* <label htmlFor="feInputAddress">Phone Number 1</label>
                      <FormInput
                        id="phoneno"
                        type="number"
                        max="10"
                        maxlength="4"
                        placeholder="Phone Number 1"
                        value={tpaDetail.phone1}
                        onChange={(event) => {
                          handleInputChange("phone1", event.target.value);
                        }}
                        invalid={formErrors.phone1 === "" ? false : true}

                      />
                       <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.phone1}</span>
                      </div> */}

                      <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="phoneno"
                        type="number"
                        max="10"
                        maxlength="4"
                        placeholder="Phone Number 1"
                        value={tpaDetail.phone1}
                      label="Phone Number 1"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("phone1", event.target.value);
                      }}
                            //  error={formErrors.phone1 !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.phone1}</span>}
                      
                    />

                  </Grid>

                  

                  <Grid item md={6}>
                        {/* <label htmlFor="phoneno">Phone Number 2</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          value={tpaDetail.phone2}
                          placeholder="Phone Number 2"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phone2", event.target.value);
                          }}
                        /> */}

                    <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="phoneno"
                          type="number"
                          value={tpaDetail.phone2}
                          placeholder="Phone Number 2"
                      label="Phone Number 2"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("phone2", event.target.value);
                      }}
                    />


                      </Grid>
                    </Grid>
                   
                   

                    <Grid container spacing={2}>
                    <Grid item md={6}>
                        {/* <label htmlFor="feInputDescription">Description</label>
                        <FormTextarea

                          id="feInputDescription"
                          placeholder="Description"
                          autoComplete="off"
                          type="textarea"

                          value={tpaDetail.description}
                          onChange={(event) => {
                            handleInputChange("description", event.target.value);
                          }}
                        /> */}

                    <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="feInputDescription"
                          placeholder="Description"
                          autoComplete="off"
                          type="textarea"
                         value={tpaDetail.description}
                      label="Description"
                      variant="outlined"
                      onChange={(event) => {
                        handleInputChange("description", event.target.value);
                      }}
                     
                      multiline  
                      minRows={1}  
                      maxRows={10}
                    />


                      </Grid>
                    </Grid>

                    {ServerRes.success &&
                      // <Alert theme="success">{ServerRes.success}</Alert>
                      <Alert severity="success" >
                       {ServerRes.success}{" "} </Alert>
                    }
                    {ServerRes.error &&
                      // <Alert theme="danger">{ServerRes.error}</Alert>
                      <Alert severity="error" >
                       {ServerRes.error}{" "} </Alert>
                    }
                  </form>
                  <div className="buttonsetconfirm">               
                        <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                  >
                  Cancel
                </Button> 
                     
                      <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Save
                </Button>
                    </div>
                    <br/>
                </Grid>
              </Grid>
            </div>
          </List>
          
        
          </main>
     
          
          </div>
    </>
  );
}
export default Tpaadd;
