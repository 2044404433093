import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import doctorActions from "../../../core/actions/doctorActions";
import Components from "../../doctor/index";
import { patientActions } from '../../../core/actions';
import { Alert} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";


function DoctorListGrid(props) {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [patientVisit, setPatientVisit] = useState();
  const [showPatientVisitModal, setshowPatientVisitModal] = useState(false);
  const [showPatientRevisitModal, setshowPatientRevisitModal] = useState(false);
  const [selectPatientData, setselectPatientData] = useState();
  const [selectedPatientRowData, setSelectedDoctorRowData] = useState();
  const [selectedPatientSummary, setSummary] = useState();
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [showPatientSection, setShowPatientSection] = useState(false);
  const [doctorForm, setDoctorData] = useState(false);
  const [doctorRowData, setdoctorRowData] = useState({});

  const [confirm, setConfirm] = useState(false);
  const [onmodal, setOnModal] = useState(true);




  const [summary, setSummaryData] = useState({
    preparedBy: "",
    approver: "",
    patientId: "",
    visitId: "",
    notes: "",
    groupname: "",
    notes: "",
    subgroup: "",
    type: "",
  });
  const dispatch = useDispatch();

  const [doctorList, setdoctorList] = useState({
    doctors: "",
  });

 

  const [columnDefs] = useState([
    {
      headerName: "Display Title ",
      field: "displaytitle",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Name",
      field: "first_name",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
      {
      headerName: "Department",
      field: "department",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Contact",
      field: "phoneno",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "SalaryType",
      field: "salarytype",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    { headerName:"Actions",field: "Actions", sortable: true, filter: true, flex: 1,
    resizable: true, width: 150, cellRenderer: "BtnCellRenderer" },
   
  ]);

  const [AddPatientmodel, setaddPatientModel] = useState(false);

  const patientVisitClick = () => {
    setshowPatientVisitModal(true);
  };

  const patientCloseClick = () => {
    setshowPatientRevisitModal(true);
  };

  const Addpatient = () => {
    setaddPatientModel(true);
  };

  const closeModal = (event) => {
    setDoctorData(!doctorForm);

  };

  const handleRendererCall = (props) => {
    console.log("props", props.visitsList);
    if (props.visitsList.typeFlag == "patientClose") {
      setselectPatientData(props.visitsList);

      patientCloseClick(props.visitsList);
    } else {
      setselectPatientData(props.visitsList);
      patientVisitClick(props.visitsList);
    }
    console.log("patient data", selectPatientData, props.visitsList);
  };

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    return formatteddate;
  };

  const getFormatedReqtDate = (date) => {
    const reqtdate = new Date(date);
    // const apphours=new Date(date);
    //  const appminites= new Date(date);
    let formatedreqtdate = format(reqtdate, "MMM dd yyyy");
    //  let formatedappdate= format(appdate, "MMM dd yyyy",apphours,"HH",appminites,"MM" );
    return formatedreqtdate;
  };
  const getFormatedApptDate = (date) => {
    const apptdate = new Date(date);
    let formatedapptdate = format(apptdate, "MMM dd yyyy");
    return formatedapptdate;
  };

  const visitsList = useSelector(
    (state) => state.patientDetailsState.visitsList
  );
  console.log("visitsList", visitsList);

  const doctorlist = (data) => {
    dispatch(
      doctorActions.getDoctorsBasednId(data, (resCb) => {
        if (resCb) {
          setdoctorList(resCb);
          doctorList.doctors = resCb;
          console.log("list doctor Response:", doctorList);
        }
      })
    );
  };
const closeAlert = (e) => {
    setServerRes({ ..."" });
  }
  const getAggriddata = (e) => {

  console.log("getAggriddata",e.data);
    console.log("ACTIONS:", props);

    //   setDoctorData(!doctorForm)

    if(e.colDef.field=="Actions"){
      setConfirm(!confirm);
      console.log("delete rowdata:",e.data);
        setTimeout(() => {
          setdoctorRowData(e.data);
    }, 1000);
    }else{
    history.push({
      pathname: '/adddoctor',
      rowData:e.data,
      label:"edit"
     }); 
    } 
  };

  useEffect(() => {
    dispatch(patientActions.setPageHeader("doctor"));

    let data = {};
    console.log("props are:", props.doctorSearchList);
    if (props && props.doctorSearchList && props.doctorSearchList.length > 0) {
      let pslist = props.doctorSearchList;
      for (let item of pslist) {
       
      }
      console.log("patientlist::", pslist);
      setPatientVisit(pslist);
    }
  }, [props]);


  const rowStyle = { background: "white" };


  const handleSubmit = (event) => {
    console.log("event", event);
    dispatch(
    doctorActions.deletedoctor(doctorRowData, (resCb) => {
      if (resCb) {
        console.log("deletedoctor", resCb);
        // props.history.push("/tpamasterlist");
        props.onGridAction();
        setConfirm(!confirm);
         var ServerResVal=ServerRes;
        ServerResVal.success = "Doctor deleted Successfully";
        setServerRes({...ServerResVal});
        setTimeout(() => {
           closeAlert();
          }, 3000);

      }
    }));
  };
  const toggle = (event) => {
    setConfirm(!confirm);
  };

  return (
    <>
     {ServerRes.success && (
                      // <Alert theme="success">{ServerRes.success}</Alert>
                      <Alert severity="success" >
                       {ServerRes.success}{" "} </Alert>
                    )}
                    {ServerRes.error && (
                      // <Alert theme="danger">{ServerRes.error}</Alert>
                      <Alert severity="error" >
                       {ServerRes.error}{" "} </Alert>
                    )}
      {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
      <div>
        <div className="p-0 preview">
          <div className="ag-theme-balham ag-style card rounded table mb-0 tcol table-head"style={{fontSize:'14px', height: 500, width: `98%` }}>
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
            //   context={context}
              rowData={patientVisit}
              columnDefs={columnDefs}
              // onRowClicked={(e) => getAggriddata(e)}
              rowStyle={rowStyle}
            //   getRowStyle={getRowStyle}
            onCellClicked={(e) => {
              console.log("event onCellclicked::",e);
              const field = e.colDef.field;
              const colIndex = e.columnApi
                .getAllColumns()
                ?.findIndex((col) => col.getColDef().field === field);
          
              console.log("cell clicked ",field, colIndex);
              getAggriddata(e)
            }}
            ></AgGridReact>
           
          </div>
          {/* {doctorForm && (
      <div >
      <Components.addDoctor onModalClose={(event) => closeModal(event)} doctorData={doctorRowData}/>
      </div>)} */}

{confirm && (
      <div >
      {/* <Modal
        className="d-flex w-95 h-90 flex-column-reverse "
        id="doctor-modal"
        open={onmodal}
      > */}
      <Dialog
           open={onmodal}
           id="doctor-modal"
           className="d-flex w-95 h-90 flex-column-reverse"
           >

        <div>
          {/* <ModalHeader>
            Confirm Operation
            <span className="close-model" onClick={(event) => toggle(event)}>
              X
            </span>
          </ModalHeader> */}
          <DialogTitle className="py-2">
          Confirm Operation
      <IconButton
      className="close-model"
        aria-label="close"
        onClick={(event) => toggle(event)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>

         <DialogContent>
            <div className="confirmationheader">
              <h6>This operation cannot be undo! Do you want to continue? </h6>
            </div>
              {/* <h6>Do you want to continue?</h6> */}
            <main className="mainsection">
              <div className="buttonsetconfirm">

                {/* <Button className='btn-danger'type="submit" onClick={(event) => toggle(event)}>
                  Cancel
                </Button> */}
                <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                >
                  Cancel
               </Button>
                {/* <Button type="submit" onClick={(event) => handleSubmit(event)}>
                  Delete
                </Button> */}
                 <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Delete
                </Button>


              </div>
       
         
            </main>
            </DialogContent>
        </div>
        </Dialog>

      </div>)}
         
        </div>
      </div>
    </>
  );
}
export default DoctorListGrid;
