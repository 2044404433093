    import React, { useState, useEffect } from 'react';
    import {useDispatch, useSelector} from "react-redux";
    import Select, { createFilter } from "react-select";
    import { connect } from "react-redux";
    import { doctorActions} from "../../core/actions";

    function SearchDept(props) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [deptId, setDeptId] = useState('');
    const [options, setOption] = useState('');
    const dispatch = useDispatch();
    const doctor = useSelector(state => state.doctorDetailsState.doctor)

    useEffect(() => {
        console.log('Doctor- props',doctor)
        if(doctor){
        setSelectedOption(doctor.deptName)
        }
        // Get All Doctors by Dept
        dispatch(doctorActions.getDoctorsByDept((resCb) => {
        console.log("Doctor By Dept", resCb);
        if (resCb) {
            //if (doctorsDetail) {
            //let doctorsInfo = doctorsDetail;
            /* Object.keys(doctorsInfo).forEach((key) => {
                if (!doctorsInfo[key]) {
                doctorsInfo[key] = "";
                }                
            }); */
            let deptOptions = []
            for(let det of resCb){
                let opt = {}
                opt['id'] = det.departments.id
                opt['value'] = det.departments.name
                opt['label'] = det.departments.name
                deptOptions.push(opt)
            }
            setOption(deptOptions)
            //}
        }
        }));
    },[])

    
    //const doctorsDetail = useSelector(state => state.doctorDetailsState.doctorsDetail)

    const handleChange = (selectedOption) => {
        console.log("event", selectedOption);
        setSelectedOption(selectedOption);
        if (selectedOption != null) {
        // props.parentCallBack(selectedOption.value,selectedOption.id);
        } 
    }

        return (
        <div>
            <Select
            name="serviceCategory"
            value={selectedOption}
            onChange={handleChange}
            isClearable
            isSearchable
            options={options}
            filterOption={createFilter(options)}
            defaultValue={selectedOption}
            ></Select>
        </div>
        );
    }

    /* function mapStateToProps(state) {
    return {
        doctorsDetail: state.doctorDetailsState.doctorsDetail,
        doctor: state.doctorDetailsState.doctor,
    };
    }

    function mapDispatchToProps(dispatch) {
    return {
        getDoctorsByDept : (resCb) => dispatch(doctorActions.getDoctorsByDept(resCb))
    };
    }

    const searchDeptWithProps = connect(mapStateToProps, mapDispatchToProps)(searchDept); */

    export default SearchDept;
