import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Select, { createFilter } from "react-select";
  import { doctorActions} from "../../../core/actions";
  import {PRODUCT_HMS_LOCATION} from "../../../shared/constants";
import PackageService from "../../patient/PackageService";
import { update } from "lodash";
import TextField from "@mui/material/TextField";
import  {Grid}  from '@mui/material';
import { List} from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Button from "@mui/material/Button";
import { Alert} from "@mui/material";



function PackageMasterAdd (props){
  const dispatch = useDispatch();
  const [packageDetail, setPackageDetail] = useState({
    name:'',
    productId: '',
    packageid: ''
  })

  const[packageService,setPackageService] = useState({
    id:0,
    name:"",
    productId: "",
    productName: "",
    packageid: "",
    packagetype: "",
    description: "",
    amount: "",
    billable: true,
    isDoctor: "",
    gvId:0,
    SpecificDoctor: false,
  });
  
  const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
    );

  const [formErrors, setFormErrors] = useState(
    {
      name:'',
      type:'',
      productName:'',
      description:''
    }, 
  )  

  const [packageList,setPackageList] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [isReadonly, setIsReadonly] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [options, setOption] = useState('');
  const [editMode,setEditMode] = useState(false);
  const [stateMode, setStateMode] = useState();
  const [defaultDoctor, setDefaultDoctor] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => { 
      console.log("props in packagemasteradd component", props);
      getDoctorList();
      getPackages(); 
      if(props && props.location && props.location.state && props.location.state.rowData){
        let packageServiceData = props.location.state.rowData;
        setPackageService(packageServiceData);
        setIsReadonly(true)
        setEditMode(true)
        console.log('editmode: ', editMode)

      } 
    },[]);

    const getPackages = () => {
      let data = {};
        dispatch(doctorActions.getPackageList(data, (resCb) => {
            if(resCb && resCb.packageList){
               console.log("response of packages",resCb)
                 setPackageList(resCb.packageList); 
                 let packageArray = [];
                 for (let pack of resCb.packageList){
                  let packages = {}
                  packages['name'] = pack.name;

                    console.log("packages is", pack)
                    packageArray.push(packages)
                    
                 }
                 setOption(packageArray)
                 console.log('option', setOption)
                 console.log(packageList)
                 
            }
            })
        );
    }
    const getDoctorList = () => {
      let data = {};
      let docList = [];
      dispatch(
        doctorActions.getDoctorList(data, (resCb) => {
          if (resCb) {
            console.log("doctorlist in packageservice", resCb);
            for (let doctorList of resCb) {
              if (doctorList != null){
              if(doctorList.type == "doctor" || doctorList.type=="counsellor") {
                docList.push(doctorList);
              }
            }
            }
            setDoctorList([...docList]);
            
          }
        })
      );
  
      console.log("doctorlist", doctorList)
      };




  const handleInputChange = (name, value) => {
      console.log("In handleinput change",name, value);
      var pDetail = packageService;
      console.log('pDetail["basicinfo"][name]',pDetail[name])
      if(name == 'amount'){
        if (pDetail.billable == true){

          pDetail.amount = null
        }
      }
      else if(name === 'gvId'){
        pDetail[name] = value;
        setDefaultDoctor(...value);
      }
      pDetail[name] = value;
      formErrors[name] = "";

      

      console.log("p detail value",pDetail);
      setPackageService({...pDetail});
      console.log(packageService)
      if (name === "productName") {
        if (value.length > 2) {
          searchProduct(value)
        }else{
          setDisplayProducts(null);
        }
      }
    }

    const packageDetailInput = (name, value) => {
      console.log("In handleinput change",name, value);
      var pDetail = packageDetail;
      console.log('pDetail["basicinfo"][name]',pDetail[name])
      pDetail[name] = value;
      console.log("p detail value",pDetail);
      setPackageDetail({...pDetail});
      
    }


  const toggle =(event)=>{
    props.history.push("/packageList")
  }
  
  const handleSubmit = ()=>{
    console.log("package service in submit", packageService);
    console.log("package details in submit", packageDetail);
    Object.keys(packageService).forEach((key)=>{
      console.log("key",key)
      console.log("key",packageService[key])
      validateField(key,packageService[key])
    })
    //let formValid = validateForm();
    let formValid = (formErrors.name === "" && formErrors.productName === "" && formErrors.description === "" && formErrors.packagetype==="") ;
    console.log("inside formvalid",formValid);
   

    console.log("formvalid: ", formValid)
    
   
  if(formValid){
    if(packageService && packageService.id > 0){ 
      console.log('in if block');
      let data = [];
      data.push(packageService)
      dispatch(doctorActions.updateService(data, (resCb) => {
        if (resCb) {
          // props.history.push("/packageList")
        }
      })
    );
    }else{
      console.log('in else block')
      console.log("package service inside add servicce", packageService);
      setShowErrorMsg(true);
      if(packageService && packageService.name != null && packageService.name != '' && packageService.packagetype != null && packageService.packagetype != '' && 
       packageService.description != null && packageService.description != '' /*&& packageService.amount != null && packageService.amount != ''*/ ){
        console.log("package service inside add servicce", packageService);
        let data = [];
        data.push(packageService)
        dispatch(doctorActions.addService(data, (resCb) => {
          if (resCb) {
            // props.history.push("/packageList")
          }
        })
      );
      }

      if(packageDetail && packageDetail.name != null && packageDetail.name != '' &&  packageDetail.type != null && packageDetail.type != '' && packageDetail['Package Service Type']!=''){
        console.log("packageDetail inside add package", packageDetail);
        dispatch(doctorActions.addPackage(packageDetail, (resCb) => {

       })
       );
      }
      
    }
    var ServerResVal = ServerRes;
    console.log("saveStaff");
    
    ServerResVal.success = "Service added Successfully";
    setServerRes({ ...ServerResVal });
    
    setTimeout(() => {
      props.history.push("/packageList");
      closeAlert();
    }, 3000);
    // Object.keys(packageDetail).forEach((key)=>{
    //   console.log("key",key)
    //   console.log("key",packageDetail[key])
    //   validateField(key,packageDetail[key])
    // })
  // 
  }
  //validateForm(); 
  } 

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let packageInfo = packageService;

    switch(fieldName){

      case "name":
        fieldValidationErrors.name = value !== '' ? '' : 'Please Enter Package Name';
        packageInfo.name = value !== '' ? value : '';
        break;

      case "type":
        console.log("package type check");
        fieldValidationErrors.type = value !== '' ? '' : 'Please Enter Package Type';
        packageInfo.type = value !== '' ? value : '';
        break;
      case "productName":
          fieldValidationErrors.productName = value !== '' ? '' : 'Please Enter Product Name';
          packageInfo.productName = value !== '' ? value : '';
          break;
      case "packagetype":
          console.log("package type check");
          fieldValidationErrors.packagetype= value !== '' ? '' : 'Please Enter Package Service Type';
          packageInfo.packagetype = value !== '' ? value : '';
          break;

          
      case "description":
        fieldValidationErrors.description = value !== '' ? '' : 'Please Enter description';
        packageInfo.description = value !== '' ? value : '';
        break;

  
    default:
    break;
  }
  setFormErrors({...fieldValidationErrors}); 
  
  }

  const validateForm = () => {
    console.log("validateForm method called",formErrors.name,formErrors.productName,formErrors.description);
    let formValid = (formErrors.name !== "" && formErrors.productName !== "" && formErrors.description !== "" && formErrors.packagetype!=="") ;
    console.log("inside formvalid",formValid);
    if (formValid){
      console.log("inside formvalid");
      return false;
    
    }
    else{
      return true;
    }
  }

  const handleInputCheckbox = (value) => {
    console.log("value:", value);
    let isBillable = value;
    if(isBillable == false){
      packageService.amount = ''

    }
    
    const updatedItem = { ...packageService, billable: value };
    
    console.log('updatedItem: ', updatedItem)
    setPackageService(updatedItem)
    console.log('package in billable: ', packageService)
  };  

  // const isDoctorCheckbox = (value) =>{
  //   packageService.isDoctor = value;
  //   setPackageService(packageService)
  //   console.log("value: ", value )
  //   console.log(packageService)
  // }

  const isDoctorCheckbox = (value) =>{
    let item = packageService;
    const isChecked = value;
    console.log(isChecked)
    //const updatedPackageService = { ...packageService, isDoctor: isChecked };
    console.log(value)
    console.log('item is: ',item)

    const updatedItem = { ...item, isDoctor: value };
    console.log('updatedItem: ', updatedItem)
    setPackageService(updatedItem)
    console.log('package in checkbox: ', packageService)

  }
  
const SpecificDoctorcheckbox = (value) =>{
  let item = packageService;
    const isChecked = value;
    console.log(isChecked)
    //const updatedPackageService = { ...packageService, isDoctor: isChecked };
    console.log(value)
    console.log('item is: ',item)

    const updatedItem = { ...item, SpecificDoctor: value };
    console.log('updatedItem: ', updatedItem)
    setPackageService(updatedItem)
    console.log('package in checkbox: ', packageService)

}


  const productSelected = (data,index) => {
    console.log("selected product is",data);
     packageService.productId = data.productId;
    packageService.productName = data.productName;  
    setDisplayProducts(null);

  }

  const searchProduct = (value) => {
    let productsearch = value;
    console.log("searchedvalue", productsearch);
    dispatch(doctorActions.getProductDetails(productsearch, PRODUCT_HMS_LOCATION, (resCb) => {
        if (resCb && resCb.erpProductList) {
          console.log("Response of searched product", resCb.erpProductList);
          setDisplayProducts([...resCb.erpProductList]);
        }
      })
    );
  };

  const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
   
    
}
const closeAlert = (e) => {
  setServerRes({ ..."" });
}

    return (
    <>
         {ServerRes.success && (
  <Alert severity="success">{ServerRes.success}</Alert>
)}
{ServerRes.error && (
  <Alert severity="error">{ServerRes.error}</Alert>
)}
      <main className="mainsection" id={showErrorMsg}>
      {/* <span>{formErrors.name}</span>  */}
      {!editMode ?
      <div ><h5>Add Packages</h5> </div>
      :''}
   <List>
            <div className="p-2 bg-none">
            <Grid >
      <Grid item>
        <form>
 
    {!editMode ?
    <Grid container spacing={2} id=''>
    <Grid item md={6} id='packagemasteradd'>


                  <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="name"
                      value={packageDetail.name}
                      placeholder="Package Name"
                      label="Package Name"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {packageDetailInput("name",event.target.value)}}
                    />

    </Grid>
    <Grid item md={6} id='packagemasteradd'>

   
                   <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="type"
                      value={packageDetail.type}
                      placeholder="Package Type"
                      label="Package Type"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {packageDetailInput("type", event.target.value)}}
                    />

       </Grid>
  </Grid>
:''}
  <div ><h5>{!editMode ? "Add Service" : "Edit Service"}</h5> </div> 
  <Grid container spacing={2}>
    <Grid item md={4} id='packagemasteradd'>
      {/* <div style={{ color: "#EC4147" }}>
      <span>{formErrors.name}</span>  */}
    {/* </div> */}

                    <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="packageServiceName"
                      value={packageService.name}
                      placeholder="Package Service Name"
                      label="Package Service Name"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {handleInputChange("name",event.target.value)}}
                            //  error={formErrors.name !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.name}</span>}
                      
                    />



    </Grid>
    <Grid item md={4} id='packagemasteradd'>



        <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="serviceType"
                       value={packageService.packagetype}
                       placeholder="Package Service Type"
                      label="Package Service Type"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {handleInputChange("packagetype", event.target.value)}}
                      // error={formErrors.packagetype !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.packagetype}</span>}
                      
                    />


       </Grid>

       <Grid item md={4} id='packagemasteradd'>
  

                   <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="productName"
                      value={packageService.productName}
                      placeholder="Product Name"
                      label="Product Name"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {handleInputChange("productName", event.target.value)}}
                      helperText={<span style={{ color: '#EC4147' }}>{formErrors.productName}</span>}
                      
                    />

        <div className=" mt-2 shadow product-list-container position-absolute">
                                {displayProducts &&
                                  displayProducts.length > 0 &&
                                  displayProducts.map((product, index) => (
                                    <div
                                      className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                                      onClick={(event) =>
                                        productSelected(product, index)
                                      }
                                    >
                                      <h6 className="mb-0">
                                        {" "}
                                        {product.productName}
                                      </h6>
                                      <h6 className="text-muted">
                                        {product.productId}
                                      </h6>
                                    </div>
                                  ))}
                              </div>
        {/* <div style={{ color: "#EC4147" }}>
        <span>{formErrors.productName}</span>
        </div>
         */}
       </Grid>

  </Grid>
  <Grid container spacing={2}>
    <Grid item md={4} id='packagemasteradd'>
      {/* <div style={{ color: "#EC4147" }}>
      <span>{formErrors.description}</span> 
    </div> */}

                   <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="description"
                      value={packageService.description}
                      placeholder="Description"
                      label="Description"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {handleInputChange("description", event.target.value)}}
                            //  error={formErrors.description !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.description}</span>}
                      
                    />


    </Grid>
    <Grid item md={4} id='packagemasteradd'>

                    <TextField
                      fullWidth
                      className="p-2 tpa"
                      id="charges"
                      value={packageService.amount}
                      disabled = {isReadonly == true ? true : (packageService.billable == true ? false : true)}
                      placeholder="Fee/Charges: "
                      label="Charges/Fee"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => {handleInputChange("amount", event.target.value)}}
                             error={formErrors.type !== ''}
                         helperText={<span style={{ color: '#EC4147' }}>{formErrors.type}</span>}
                      
                    />


       </Grid>
       <Grid item md={4}>
       <FormControl fullWidth >
             {/* <label htmlFor="roomCode">Package</label> */}
    
                     <Select
                            displayEmpty
                            labelId="roomCode"
                            id="roomCode"
                            name="category"
                            className="form-control filterin Quantity mt-2"
                            value={packageService.packageid}
                            onChange={(event) => {
                              handleInputChange('packageid', event.target.value);
                            }}
                            sx= {{ height:"35px" }}
                          >
                            <MenuItem value="" >
                              ---Select Packages---
                            </MenuItem>
                            {packageList.map((pack, index) => (
                              <MenuItem key={index} value={pack.id}>
                                {pack.name}
                              </MenuItem>
                            ))}
                     </Select>
      </FormControl>


      </Grid>

  </Grid>

  <Grid container spacing={2}>
    <Grid item md={4}>
           <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-6 col-xl-6 col-md-6 col-12" title = {props.location.state ? 'No changes Allowed' : 'No changes allowed Once Saved'}>

                              <input
                                className="p-2"
                                value={packageService.billable}
                                checked={packageService.billable}
                                type="checkbox"
                                id="billable"
                                placeholder="billable"
                                autoComplete="off"
                                disabled ={isReadonly}
                                style = {{ cursor: isReadonly ? "not-allowed" : "default" }}
                                onChange={(event) =>
                                  handleInputCheckbox(
                                    event.target.checked
                                  )
                                }
                              />
                              <span className="ml-2">Billable</span>
                            </div>
                            <div className="d-flex justify-content-end col-12"></div>
    </Grid>
    
     <Grid item md={4}>
    
                     <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-6 col-xl-6 col-md-6 col-12" title = { props.location.state ? 'No changes allowed': 'Changes cannot be made once saved.'}>
                                
                            {packageService.billable == true ? 
                              <input
                                className="p-2"
                                value={packageService.isDoctor}
                                
                                checked={packageService.isDoctor == "true" || packageService.isDoctor == true ? true :  false}          
                                type="checkbox"
                                id="isDoctor"
                                placeholder="billable"
                                autoComplete="off"
                                disabled={isReadonly}
                                style = {{ cursor: isReadonly ? "not-allowed" : "default" }}
                                onChange={(event) =>
                                  isDoctorCheckbox(
                                    event.target.checked

                                  )
                                }
                                
                              /> : '' }
                              
                              {packageService.billable == true ?  <span className="ml-2">IsDoctor </span>  : ''}
                            

                              
                            
                  </div>
                            
                            <div className="d-flex justify-content-end col-12"></div>

                            
      </Grid>

       <Grid item md={4} >
  
    
                           <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-6 col-xl-6 col-md-6 col-12" title = { props.location.state ? 'No changes allowed': 'Changes cannot be made once saved.'}>
        
                              {packageService.isDoctor == true &&  packageService.billable == true? 
                              <input
                                className="p-2"
                                value={packageService.SpecificDoctor}
                                
                                checked={packageService.SpecificDoctor == "true" || packageService.SpecificDoctor == true ? true :  false}          
                                type="checkbox"
                                id="SpecificDoctor"
                                placeholder="billable"
                                autoComplete="off"
                                disabled={isReadonly}
                                style = {{ cursor: isReadonly ? "not-allowed" : "default" }}
                                onChange={(event) =>
                                  SpecificDoctorcheckbox(
                                    event.target.checked

                                  )
                                }
                                
                              /> : '' }
                              
                              {packageService.isDoctor == true && packageService.billable == true ?  <span className="ml-2">SpecificDoctor </span>  : ''}
                              </div>
      </Grid>

        <Grid item md={4}>
    
            <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-6 col-xl-6 col-md-6 col-12" title = { props.location.state ? 'No changes allowed': 'Changes cannot be made once saved.'}>

                        {packageService.SpecificDoctor == true || packageService.SpecificDoctor == "true" ?
                            <div className="">
                            <label className="">Doctor/Counsellor</label>
                            <select
                              name="category"
                              
                              className="form-control filterin Quantity"
                              style={{width: "fit-content"}}
                              value={packageService.gvId}
                              //defaultValue={defaultDoctor}
                              disabled = {isReadonly}
                              onChange={((event) => {
                                handleInputChange(
                                  "gvId",
                                  event.target.value,
                                  
                                );
                              } ) }
                            >
                              <option defaultValue={"selected Type"}>
                                ---Select Doctor---
                              </option>

                              {doctorList.map((doctor, index) => (
                                <option key={index} value={doctor.gvId} className = "w-100" defaultValue={"select doctor "}>
                                  {doctor.displaytitle}.{doctor.first_name}-{doctor.qualification}-{doctor.department}
                                  
                                </option>))} 
                              

                            </select>

                           
                          </div>
                         :'' }                                        
                         </div>
         </Grid>
<div className="d-flex justify-content-end col-12"></div>

  </Grid>
  </form>
  <div className="buttonsetconfirm">
                 <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                >
                  Cancel
               </Button>
    {/* <Button type="submit" onClick={(event) => handleSubmit(event)}>Save</Button> */}
                 <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Save
                </Button>
  </div>
  </Grid>
  </Grid>
  </div>
  </List>
  </main>
  </>
    )
}
export default PackageMasterAdd;
