import React, { useState, useEffect } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PdfPrinter from "pdfmake";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";
import { MANASWINI_LOGO } from "../../shared/constants/manaswinilogo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const qz = require("qz-tray");

function PrintDischargeSummary(props) {
  console.log("data to get ... :", props, props.summary.dischargedt);
  console.log("doctorList : ", props.doctorList);
  var patient_name =
    props.patientdetails.resCb.fname ||
    props.patientdetails.resCb.mname ||
    props.patientdetails.resCb.lname
      ? props.patientdetails.resCb.fname +
        " " +
        props.patientdetails.resCb.mname +
        " " +
        props.patientdetails.resCb.lname
      : "";
  var IP_number = props.patientdetails.resCb.id
    ? props.patientdetails.resCb.id
    : "";
  var Admission_type = props.summary["Admission Type"];
  var Age = props.patientdetails.resCb.age
    ? props.patientdetails.resCb.age
    : "";
  var gender = props.patientdetails.resCb.gender
    ? props.patientdetails.resCb.gender
    : "";
  var DATE_of_discharge = "";
  var KSMHA_intimation_number = props.summary["Ksmha Intimation Number"];
  var Nominated_Representive = props.summary["Nominated Representative"];
  // var Address = props.patientdetails.resCb.address || props.patientdetails.resCb.city || props.patientdetails.resCb.pincode ? props.patientdetails.resCb.address + " , " + props.patientdetails.resCb.city + " , " + props.patientdetails.resCb.pincode : '';
  const city = props.patientdetails.resCb.city;
  const state = props.patientdetails.resCb.state;
  const pincode = props.patientdetails.resCb.pincode;
  var Address =
    (props.patientdetails.address !== null &&
    props.patientdetails.resCb.address !== "" &&
    props.patientdetails.resCb.address !== undefined
      ? props.patientdetails.resCb.address + ", "
      : "") +
    (city ? city : "Arkula") +
    (state ? ", " + state : ", Karnataka") +
    (pincode ? " " + pincode : "");
  var CnfrmNumber = props.summary["Appointment Confirmation Number"];
  // var contctNumber = '1876827658'
  const contctNumber = "+91 " + process.env.REACT_APP_EMERGENCY_NUNBER;
  const printerName = process.env.REACT_APP_PRINTER_NAME;
  const EMERGENCY_CONTACT_NO = process.env.REACT_APP_EMERGENCY_NUNBER;
  var folloUp = "";
  if (props.revisitDetails) {
    const followupDate = new Date(props.revisitDetails);
    console.log("props.revisitDetails : ", props.revisitDetails);

    const formattedfollowupDate = followupDate.toLocaleDateString("en-GB");

    folloUp = formattedfollowupDate;
    console.log("folloUp : ", folloUp);
  } else {
    folloUp = "Weekly";
  }
  if (props.summary.dischargedt) {
    const date = new Date(props.summary.dischargedt);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);

    DATE_of_discharge = formattedDate ? formattedDate : "";
    console.log("discharge : ", DATE_of_discharge);
  } else {
    const date = new Date();

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
    DATE_of_discharge = formattedDate ? formattedDate : "";
  }
  const inputDate = new Date(props.patientdetails.resCb.registeredOn);
  const formattedDate = inputDate.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  var Date_of_addmission = formattedDate ? formattedDate : "";

  useEffect(() => {
    props.parentCallBack();
  }, []);

  dischargeSummaryPrint();
  function dischargeSummaryPrint() {
    const docDefinition = {
      pageMargins: [40, 60, 40, 60],
      header: [
        {
          columns: [
            {
              image: MANASWINI_LOGO,
              fit: [120, 100], // set the width and height of the image
              margin: [60, 5, 0, 0],
              width: 90, // set the top and bottom margin of the image
            },
            [
              {
                text: HOSPITAL_NAME,
                style: "header",
                width: "auto",
                bold: "true",
                margin: [90, 10, 0, 0], //Add margin to separate header from content
              },
              {
                stack: [
                  {
                    text: HOSPITAL_DESC,
                    style: "subheader",
                    width: "auto",
                    margin: [90, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                ],
              },
            ],
          ],
        },
      ],
      content: [
        {
          text: "DISCHARGE SUMMARY",
          style: "pageTitle",
          alignment: "center",
          margin: [0, 10, 0, 3],
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            widths: [170, 100, 120, 100],
            body: [
              [
                {
                  text: "PATIENT NAME",
                  style: "center",
                  fillColor: "lightgray",
                },
                {
                  colSpan: 3,
                  text: patient_name,
                },
                "",
                "",
              ],
              [
                {
                  text: "I P. NUMBER",
                  style: "center",
                  fillColor: "lightgray",
                },
                IP_number,
                { text: "ADMISSION TYPE", fillColor: "lightgray" },
                Admission_type,
              ],
              [
                { text: "AGE", style: "center", fillColor: "lightgray" },
                Age,
                { text: "DATE OF ADMISSION", fillColor: "lightgray" },
                Date_of_addmission,
              ],
              [
                { text: "GENDER", style: "center", fillColor: "lightgray" },
                gender,
                { text: "DATE OF DISCHARGE", fillColor: "lightgray" },
                DATE_of_discharge,
              ],
              [
                {
                  text: "KSMHA INTIMATION NUMBER",
                  style: "center",
                  fillColor: "lightgray",
                },
                KSMHA_intimation_number,
                { text: "NOMINATED REPRESENTIVE", fillColor: "lightgray" },
                Nominated_Representive,
              ],
              [
                { text: "ADDRESS", style: "center", fillColor: "lightgray" },
                { colSpan: 3, text: Address },
                "",
                "",
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            widths: [170, 338],
            body: [
              [
                {
                  text: "DIAGNOSIS WITH ICD-10/11 CODE",
                  alignment: "center",
                  fillColor: "lightgray",
                },
                props.summary["Final Diagnosis"],
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [10, 130, 70],
            widths: [170, 338],
            body: [
              [
                { text: "CHIEF COMPLAINTS", fillColor: "lightgray" },
                {
                  text: "CLINICAL SUMMARY",
                  alignment: "center",
                  fillColor: "lightgray",
                },
              ],
              [
                props.summary["Chief Complaints"],
                props.summary["Clinical Summary"],
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [40, 20, 20, 20, 20],
            widths: [170, 338],
            body: [
              [
                { text: "PAST HISTORY", style: "top", fillColor: "lightgray" },
                props.summary["Past and Treatment History"],
              ],
              [
                {
                  text: "MEDICAL HISTORY",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                props.summary["Relevant Medical History"],
              ],
              [
                {
                  text: "FAMILY HISTORY",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                props.summary["Relevant Family History"],
              ],
              [
                {
                  text: "PERSONAL HISTORY",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                props.summary["Personal History"],
              ],
              [
                {
                  text: "PREMORBID PERSONALITY",
                  alignment: "start",
                  fillColor: "lightgray",
                },
                props.summary["Premorbid Personality"],
              ],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [15, 40, 15, 70],
            widths: [250, 250, 250],
            body: [
              [
                {
                  text: "MENTAL STATE EXAMINATION AND CAPACITY ASSESSMENT",
                  fillColor: "lightgray",
                },
                {
                  text: "GENERAL PHYSICAL EXAMINATION",
                  fillColor: "lightgray",
                },
              ],
              [
                { rowSpan: 3, text: props.summary["Mental State Examination"] },
                props.summary["General physical examination"],
              ],
              [
                { text: "" },
                { text: "CLINICAL INVESTIGATIONS", fillColor: "lightgray" },
              ],
              ["", props.summary["Clinical Investigations"]],
            ],
          },
        },
        { text: "", pageBreak: "after" },
        {
          style: "fontsize",
          margin: [0, 12, 0, 10],
          table: {
            heights: [15, 80],
            widths: [508],
            body: [
              [
                {
                  text: "COURSE OF TREATMENT IN HOSPITAL",
                  style: "center",
                  fillColor: "lightgray",
                },
              ],
              [props.summary["Course of treatment in hospital"]],
            ],
          },
        },
        {
          style: "fontsize",
          margin: [0, 0, 0, 10],
          table: {
            heights: [15, 100],
            widths: [250, 250],
            body: [
              [
                {
                  text: "ADVICE ON DISCHARGE",
                  style: "center",
                  fillColor: "lightgray",
                },
                {
                  text: "PLANNED PSYCHO-SOCIAL INTERVENTION",
                  style: "center",
                  fillColor: "lightgray",
                },
              ],
              [
                props.summary["Advice on discharge"],
                props.summary["Psycho-social Factors"],
              ],
            ],
          },
        },

        { text: "FOLLOW UP:", style: "fontsize" },
        folloUp,
        { text: "\n", style: "fontsize" },
        { text: "APPOINTMENT CONFIRMATION NUMBER:", style: "fontsize" },
        CnfrmNumber,
        { text: "\n", style: "fontsize" },
        { text: "EMERGENCY CONTACT NUMBER:", style: "fontsize" },
        contctNumber,
        { text: "\n", style: "fontsize" },
        {
          text: "Review in Casualty in case of emergency - relapse of symptoms,harm to self or others, suicidal ideas, sleep disturbances or side-effects to medication.\n\n",
          style: "fontsize",
        },
        {
          text: "I/We have understood the instructions given about the medication dosage and post discharge care\n\n\n",
          style: "fontsize",
        },
        {
          text: "                                               _______________________________",
          style: "fontsize",
        },
        { text: "Patient/NR/Relatives Signature\n\n\n", style: "fontsize" },
        [
          {
            text: "TREATING DOCTOR:                                                                     SOCIAL WORKER",
            style: "fontsize",
          },
          // {
          //     ul: props.doctorList.map(doctor => ({ text: doctor })), style: 'fontsize'
          // }
          // {
          //     ul: props.doctorList.map(doctor => ({
          //         text: doctor,
          //         style: 'fontsize',
          //         margin: [0, 5]  // Add margin between each list item
          //     }))
          // }
          {
            columns: [
              // Left column (for doctorList)
              {
                width: "50%", // Set the width of the left column
                ul: props.doctorList.map((doctor) => ({
                  text: doctor,
                  style: "fontsize",
                  margin: [0, 5], // Add margin between each list item
                })),
              },
              // Right column (for doctorList1)
              {
                width: "50%", // Set the width of the right column
                ul: props.consellorlist.map((counsellor) => ({
                  text: counsellor,
                  style: "fontsize",
                  margin: [0, 5], // Add margin between each list item
                })),
              },
            ],
          },
        ],
      ],
      footer: function (currentPage, pageCount) {
        return [
          {
            text: HOSPITAL_NAME,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [40, 0, 0, 0],
          },
          {
            text: HOSPITAL_DESC,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [40, 0, 0, 0],
          },
          {
            text: HOSPITAL_ADDRESS,
            alignment: "left",
            fontSize: 8,
            margin: [40, 0, 0, 0],
          },
        ];
      },
      styles: {
        fontsize: {
          fontSize: 10,
        },
        pageTitle: {
          fontSize: 13,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {},
    };
    printPriscription(docDefinition);
  }
  function printPriscription(docDefinition) {
    PdfPrinter.createPdf(docDefinition).open();
    let pdf;
    const pdfDocGenerator = PdfPrinter.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      pdf = data;
    });
    console.log("pdfdocument is", pdfDocGenerator);
    console.log("printer status prop check else case connecting");
    qz.websocket
      .connect()
      .then(() => {
        console.log("connecting");
        console.log("connected");
        return qz.printers.find();
      })
      .then((printers) => {
        console.log("The Printers are ", printers);
        printers.filter((el) => {
          if (el.includes(printerName)) {
            // if (el.includes('HP DeskJet 1200 series')) {

            let config = qz.configs.create(el, {
              size: { width: 148, height: 210 },
              units: "mm",
              scaleContent: false,
            });
            console.log("config is", config);
            return qz
              .print(config, [
                {
                  type: "pixel",
                  format: "pdf",
                  flavor: "base64",
                  data: pdf,
                },
              ])
              .then(() => {
                console.log("print complete");
                return qz.websocket.disconnect();
              });
          }
        });
      })
      .then(() => {
        console.log("Disconnected");
        qz.websocket.disconnect();
      })
      .then(() => {})
      .catch((err) => {});
  }
  return <></>;
}
export default PrintDischargeSummary;
