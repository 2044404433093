// import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function MessageStrip(props) {
  let horizontal = "center";
  let vertical = "top";
  const [open, setOpen] = React.useState(false);
  useEffect(() =>{
    setOpen(true);
    console.log("Snackbar data messageDetails :", props);
    console.log("Snackbar open :", open);
    setTimeout(() => {
      // props.msgStrip.message = "";
      // setOpen(false);
      closeMessageStrip();
    }, 1000);
        });
//   setOpen(true);
  const closeMessageStrip = (event) => {
    console.log("Patientlist messageDetails error :", event);

    console.log("Patientlist messageDetails error :", props);
    setTimeout(() => {
      props.msgStrip.message = "";
    }, 2);
    props.closeMessageStrip(event);
    setOpen(false);
  };
/* 
  const Alert = React.forwardRef<HTMLDivElement>(function Alert(
    props,
    ref,
  ) {
      
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }); */
  // const msgStrip = useSelector(state => state.patientDetailsState.patient);

  // return() {
  console.log("message nav", props.msgStrip);
  return (
    <>
      {console.log("message nav", props.msgStrip)}
     
      {/* {buttons} */}

      <div>
              <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      open={open}
                    
                      onClose={(event) => closeMessageStrip(event)}
                      message= {props.msgStrip.message}
                      key={vertical + horizontal}
                      action={
                        
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              sx={{ p: 0.5 }}
                              onClick={ (event) => closeMessageStrip(event)}
                            >
                              <CloseIcon />
                            </IconButton>
                }/>
          </div>

      
    </>
  );
  // }
}
export default MessageStrip;
