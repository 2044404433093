import React, { useState, useEffect } from 'react';
import {APIKEY}  from "../../shared/constants";
import { helperServices } from "../../shared/helper";
import { patientActions,doctorActions,zoomActions } from '../../core/actions';
import {useDispatch, useSelector} from "react-redux";

declare var ZoomMtg;

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.2.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');
const basePath = "/api";
function Zoommeeting(props) {
  console.log("props",props)

if(props && props.meetingInfo){
  console.log("inside zoommeeting",props.match)
  console.log("zoommeeting meetingInfo meetingId : ",props.meetingInfo.meetingId)
}

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  var signatureEndpoint = 'http://localhost:4000'
  var apiKey = APIKEY
  var sdkKey = APIKEY
  // props.meetingInfo.meetingId
  // props.match.params.meetingId
  if (props && props.meetingInfo ) {
    sessionStorage.setItem('meetindid', props.meetingInfo.meetingId)
    sessionStorage.setItem('patientid', props.meetingInfo.patientId)
    sessionStorage.setItem('visitid', props.meetingInfo.visitId)
    sessionStorage.setItem('groupid', props.meetingInfo.groupId)
  }
  var meetingNumber =''
  var patientid =''
  var visitId =''
  var groupId =''

  if (sessionStorage.getItem('meetindid')) {
    
    meetingNumber = sessionStorage.getItem('meetindid');

  }
  if (sessionStorage.getItem('patientid')) {

     patientid = sessionStorage.getItem('patientid')

  }
  if (sessionStorage.getItem('visitid')) {
    
     visitId =sessionStorage.getItem('visitid')

  }
  if (sessionStorage.getItem('groupid')) {
    

     groupId =sessionStorage.getItem('groupid')

  }
  var role = 1
  // var leaveUrl = 'http://localhost:3000/patient?meeting=completed'
  //var leaveUrl = `http://localhost:3000/notes/${patientid}/${visitId}/${groupId}`;
   var leaveUrl = `https://madmin.prolake.in/notes/${patientid}/${visitId}/${groupId}`;
  var userName = 'manaswini.health@gmail.com'
  var userEmail = ''
  var passWord = '12345678'


  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = ''
 console.log("inside zoommeeting meetingNumber : ",meetingNumber)

 const iat = Math.round((new Date().getTime() - 30000) / 1000)
 const exp = iat + 60 * 60 * 2

 const dispatch = useDispatch();




  function getSignature() {
    console.log("inside signature")
    //e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
      .then(response => {

        startMeeting(response.signature)
        console.log("signature value : ", response)
      }).catch(error => {
        console.error(error)
      })
  }






let signature = '';
  const KJUR = require('jsrsasign')
let SdkKey = 'SuhhYe61dVU0eXgIwTOuRmpwxh8xwEQLGcAc'
let sdkSecret = 'hR5Koo7Z0xEVvrHaI6RElFKy2kR2kUI06ISK'
function generateSignature(
  ) {

  const isuuetime = Math.round((new Date().getTime() - 30000) / 1000)
  const exptime = isuuetime + 60 * 60 * 2
  const oHeader = { alg: 'HS256', typ: 'JWT' }

  const oPayload = {
    sdkKey: SdkKey,
    mn: meetingNumber,
    role: role,
    iat: isuuetime,
    exp: exptime,
    appKey: SdkKey,
    tokenExp: exptime
  }
  const sHeader = JSON.stringify(oHeader)
      var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
   const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role})
    };
  const url = basePath + `/v1/zoomSignature`;
  fetch(url, requestOptions)
  .then(res => res.json())
      .then(response => {
        console.log("sign resp value : ", response)
        startMeeting(response.signatureresp.signature)
      }).catch(error => {
        console.error(error)
      })


   const sPayload = JSON.stringify(oPayload
     )
     console.log("payload  : ", sPayload)
     console.log("header : ", sHeader)
    var signgenerat =  KJUR.jws.JWS.sign('HS256', oHeader, oPayload, sdkSecret)
  const signv = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
  var check = (signgenerat.split(".")[3])
  var checkout = JSON.stringify(check)
  console.log("signgeanrated : ", signgenerat)
  


}
  function startMeeting(signature) {
    console.log('document.getElementById')
    console.log('document.getElementById',document.getElementById('zmmtg-root'))
    document.getElementById('zmmtg-root').style.display = 'block'


  ZoomMtg.init({
    leaveUrl: leaveUrl,
    success: (success) => {
      console.log(success)
      ZoomMtg.join({
        signature: signature,
        meetingNumber: meetingNumber,
        userName: userName,
        sdkKey: sdkKey,
        userEmail: userEmail,
        passWord: passWord,
        tk: registrantToken,
        success: (success) => {
          console.log(success)
          // meetinginfo has patientid,visitid,meetingid and groupid send these to patientlayout
          // props.meetingInfo.visitId
          // dispatch(doctorActions.reviewZoomNotes(success,resCb=>{
          //   if(resCb){
          //     console.log("reviewZoomNotes API Response:",resCb)          
          //    // setSelectedPatientInfo(resCb)
          //     }
          // }))

          
        },
        error: (error) => {
          console.log(error)
        }
      })
      
    },
    error: (error) => {
      console.log(error)
    }
  })
  }

  return (
    <div className="App">
      <main>

     <button onClick={generateSignature()}>Join Meeting</button> 
      </main>
    </div>
  );
}

export default Zoommeeting;
