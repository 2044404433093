import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { useHistory } from "react-router-dom";
import { doctorActions, patientActions } from "../../../core/actions";
import { Alert} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import {  InputAdornment, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


function Package (props){
    const dispatch = useDispatch();
    const history = useHistory();
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    const [ServerRes, setServerRes] = useState(
        {success:'',error:'',}
        );

    const [pagination, setPagination] = useState(true);
    const [paginationPageSize, setPaginationPageSize] = useState(14);
    const [columnDefs] = useState([
        {headerName: "Package Service Name ",field: "name",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,comparator: customComparator,},
        {headerName: "Package Name",field: "packagename",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,},
        {headerName: "Amount",field: "amount",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,},
        {headerName: "Doctor",field: "isDoctor",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,},  
        {headerName: "Actions",field: "Actions",sortable: true,filter: true, flex: 1,resizable: true,width: 150,cellRenderer: "BtnCellRenderer",}, 
    ])
    const [packageList,setPackageList] = useState();
    const [packageServiceList,setPackageServiceList] = useState();
    const [searchText, setSearchText] = useState("");
    const [confirmModal, setConfirmMOdal] = useState(false);
    const [pServiceRowData,setpServiceRowData] = useState({});
    const [status, setStatus] = useState(undefined);

    const clearSearch = (event) => {
      setSearchText("");
    }

    useEffect(() => { 
      console.log('useeffect')
      dispatch(patientActions.setPageHeader("packageService"));
      getServices()
    },[]);

    const getServices = () => {
      let data = {};
        dispatch(doctorActions.getServiceList(data, (resCb) => {
            if(resCb && resCb.packageServiceList){
                // setPackageList(resCb.packageList);
                console.log(resCb)
                setPackageServiceList(resCb.packageServiceList);
                setPackageList(resCb.packageServiceList);
                console.log("packagelist", packageList)
                
            }
            })
        );
    }

    const closeAlert = (e) => {
        setServerRes({...""});
    }

    const addPackage = (event) => {
      props.history.push("/packageAdd");
    }

    const getAggriddata = (e) => {
      console.log("clicked package service is", e);
      if (e.colDef.field == "Actions") {
        setConfirmMOdal(!confirmModal);
        console.log("delete rowdata:", e.data);
          setTimeout(() => {
           setpServiceRowData(e.data);
  
          }, 1000);
      }else {
        history.push("/packageAdd",{
        rowData:e.data,
        label:"edit"
        });
       }
    }

    const handleInputChange = (event) => {
      let searchedValue = event.target.value;
      setSearchText(searchedValue);
      if(searchedValue.length > 2){
        let serviceList = packageList.filter((item)=>(item.name).includes(searchedValue) || (item.name).toLowerCase().includes(searchedValue) || (item.name).toUpperCase().includes(searchedValue))
        setPackageList(serviceList);
      }else{
        setPackageList(packageServiceList);
      }
    }

    const toggle = (event) => {
      setConfirmMOdal(!confirmModal);
    };

    const handleSubmit = () => {
      var ServerResVal = ServerRes;
      console.log("selected pservice row data is:", pServiceRowData); 
      dispatch(patientActions.deletePackageService(pServiceRowData.id, (resCb) => {
        if(resCb){
          console.log("Response of delete package service Api", resCb);
          setStatus({ type: "success" });
          ServerResVal.success = "Deleted Successfully";
          setServerRes({...ServerResVal}); 
          toggle(resCb);
          getServices();
          setTimeout(() => {
            closeAlert();
          }, 3000);
        }
      }
      ));
    }

    return (
        <>
          {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
          {ServerRes.error && (
                        // <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
                        <Alert severity="error" className="alert-div">
                        {ServerRes.error}{" "}
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={(event) => closeAlert(event)}
                          
                          
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </Alert>
                    )}
    
       {ServerRes.success && (
      //  <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
      <Alert severity="success" className="alert-div">
      {ServerRes.success}{" "}
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={(event) => closeAlert(event)}
        
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Alert>
        )}
        <div class="mainsection">
        <div className="listTitles">
         <Grid item xs={12} className="page-header mb-3 doctorList" >
            <h5
              // subtitle="Dashboard"
              className="text-sm-left mb-0"
            >Packages</h5>
            <Button 
          variant="contained" 
          className="floatingbtn"  
          onClick={(event) => addPackage(event)}
          style={{ textTransform: "none" }}>
        Add New
      </Button>
          </Grid>
          </div>
          <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{ color: `#111b21` }} className="material-icons">
                  search
                </i>
              </span>
            </div>
            {/* <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search Package..."
              value={searchText}
              type="search"
              onChange={handleInputChange}
            /> */}
            <TextField 
               className="navbar-search mb-2 menu-search bg-white custom-text"
               placeholder="Search Package..."
               autoFocus
               value={searchText}
               type="search"
               onChange={handleInputChange}
               InputProps={{
                style: { paddingLeft: '15px',height:"35px"}, 
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && ( // Render the close icon only if searchText exists
                      <IconButton edge="end" onClick={clearSearch} tabIndex={-1} 
                      size="small" 
                style={{ padding: '6px' }}
                >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
               />
          </div>
        </form>
          <div>
            <div className="p-0 preview">
              <div
                className="ag-theme-balham ag-style card rounded table mb-0 tcol"
                style={{ height: 500, width: `98%` }}
              >
                <AgGridReact
                  frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                   rowData={packageList}
                   columnDefs={columnDefs}
                   onCellClicked={(e) => {
                    console.log("event onCellclicked::",e);
                    const field = e.colDef.field;
                    const colIndex = e.columnApi
                      .getAllColumns()
                      ?.findIndex((col) => col.getColDef().field === field);
                
                    console.log("cell clicked ",field, colIndex);
                    getAggriddata(e)
                  }}
                //   rowStyle={rowStyle}                               
                ></AgGridReact>
              </div>
    
              
            </div>
            {confirmModal && (
            <div>
              {/* <Components.Confirmation onModalClose={(event) => closeModal(event)} /> */}
              {/* <Modal
                className="d-flex w-95 h-90 flex-column-reverse "
                id="doctor-modal"
                open={confirmModal}
                toggle={toggle}
              > */}
              <Dialog
           toggle={toggle}
           id="doctor-modal"
           className="d-flex w-95 h-90 flex-column-reverse"
           open={confirmModal}
           >
                <div>
                <DialogTitle className="py-2">
          Confirm Operation
      <IconButton
      className="close-model"
        aria-label="close"
        onClick={(event) => toggle(event)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
             <DialogContent>
                  <div className="confirmationheader">
              <h6>This operation cannot be undo! Do you want to continue? </h6>
            </div>
                    <main className="mainsection">
                      <div className="buttonsetconfirm">
                       
                        <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                >
                  Cancel
               </Button>
                       
                        <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Delete
                </Button>
                      </div>
                    </main>
                  </DialogContent>
                </div>
              </Dialog>
            </div>
          )}
          </div>

          </div>
        </>
      );
}
export default Package;
