import { ElectricalServices } from "@mui/icons-material";
import { stringToArray } from "ag-grid-community";
import { each, property } from "lodash";
/* 
  Lab Report Format (UI):
  Major Test Title 1
  Test Name 1
    Results---Units---Ref. Range---Comment
  Test Name 2
    Result---Units---Ref. Range---Comment
  .
  .
  .

  Major Test Title 2
  Test Name 1
    Result--Units--Ref. Range---Comment
  Test Name 2
    Result--Units--Ref.Range---Comment
  .
  .
  .
  Major Test ...


*/
export function getschemafromLabReportsDefault(templateId,schema,flags){

    console.log("flags", flags)
    console.log(templateId)
    console.log('schema : ',schema);
  
    let jsonSchema = {}
    let majorTest;
    let obj1 = {};
    let obj2 = {}
    let unit;
    let obj3 = {};
    let elementName2;
    let testname;
    let elementName3;
    let elementName4;
    jsonSchema['properties'] = {}
    //jsonSchema[elementName1] = {}
  
    for (let content of schema.content){
      console.log("content: ", content)
  
      for (let datas of content.data.events){
        console.log("content data: ", datas)
  
        for (let item of datas.data.items){
          console.log("inside data items: ", item)
                
          if(item._type === "ELEMENT"){
            majorTest = item.value.value
            console.log("Major Test: ", majorTest)
            obj1 = {type : "object"}
                    
            jsonSchema.properties[majorTest] = {
              type: "object",
              title: majorTest,
              properties: {},
            }; 
                  //jsonSchema['properties'][elementName1] ={}
                  // jsonSchema['properties'][elementName2]['properties'] = {}
          }
          else if (item._type === "CLUSTER"){  
            /*
             in edit mode: items are arranged according to the archetype node id, so analyte name(at0024) comes at last.
             This leads to an error. This code snippet ensures that "Analyte Name " 
             always comes first irrespective of the order of items.items
            */

            item.items.sort((a, b) => {
              if (a.name.value === "Analyte Name") {
                return -1; // Move "Analyte Name" to the front
              } else if (b.name.value === "Analyte Name") {
                return 1; // Move "Analyte Name" after other items
              } else {
                return b.name.value.localeCompare(a.name.value); // Sort remaining items in descending order
              }
            });
            for (let i = 0; i < item.items.length; i++){
           
              console.log("i", item.items[i])  
              let eachElement = item.items[i];
              let value = eachElement.name.value;
              console.log('eachElement value: ', value);
              
              if (value == "Analyte Name"){
                elementName2 = eachElement.value.value
                console.log("Analyte Name: ", elementName2)
                jsonSchema.properties[elementName2] = { type: "object", properties: {}}
                console.log('jsonschema1: ', jsonSchema)
              }

              else {
                console.log("jsonschema2: ",jsonSchema)
                if (value === "Result"){
                  unit = eachElement.value.units
                    obj2 = (flags === "editComposition" ?  {type : "string", default: eachElement.value.magnitude}:{ type : "string", default: "-1"})
                    //obj2 = {type : "string", default: eachElement.value.magnitude}
                    jsonSchema['properties'][elementName2]['properties'][value] = obj2
                    jsonSchema['properties'][elementName2]['properties']['Units'] = {type : 'string', default:unit}
                    //jsonSchema['properties'][elementName2]['properties'][value] = {type : "string"}
                    }
                    
                else if (value === "Comment"){
                  let defaultValue = (flags === "editComposition" ? eachElement.value.value : "No Option Selected")
                  let commentenum = {
                    type: 'string',
                    enum: ['High', 'Normal', 'Low', defaultValue],
                    enumNames: ['High', 'Normal', 'Low', defaultValue],
                    default: defaultValue
                  }; 

                  jsonSchema['properties'][elementName2]['properties'][value] =  commentenum
                }

                else if (value === "Ref. Range"){
                  obj2 = {type : "string", default: eachElement.value.value }
                  jsonSchema['properties'][elementName2]['properties'][value] = obj2

                  //jsonSchema['properties'][elementName2]['properties'][value] = {type : 'string', default:eachElement.value.value}
                }
              }  
            }
          }
        }            
      }
    }
  console.log("Jsonschema: ", jsonSchema);
  return jsonSchema
}

  

  const LabReportsSchema = {
    getschemafromLabReportsDefault,
  }


  export default LabReportsSchema;







