import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';


const SidebarNavItem = ({ item }) => (
  
//   <NavItem>
//     <NavLink tag={RouteNavLink} to={item.to} title={item.title}>
//       {item.htmlBefore && (
//         <div
//           className="d-inline-block item-icon-wrapper"
//           dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
//         />
//       )}
//       {item.title && <span >{item.title}</span>}
//       {item.htmlAfter && (
//         <div
//           className="d-inline-block item-icon-wrapper"
//           dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
//         />
//       )}
//     </NavLink>
//   </NavItem>
      <ListItem button component={RouteNavLink} to={item.to} title={item.title} className="listItem">
      {item.htmlBefore && (
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
        />
      )}
      {item.title && <span>{item.title}</span> }
      {item.htmlAfter && (
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
        />
      )}
    </ListItem>

);

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
