import {DEPT_DOCTOR_DETAILS,DEPT_DOCTOR_DETAILS_FAILURE,DOCTOR_SAVE_SUCCESS,DOCTOR_SAVE_FAILURE,
    DOCTOR_UPDATE_FAILURE,DOCTOR_UPDATE_SUCCESS,SET_DOCTOR_DETAILS,
    ZOOM_REVIEW_NOTES,NEWPROCEDURE_SAVE_SUCCESS,NEWPROCEDURE_SAVE_FAILURE,
    TEMPLATE_DETAILS,TEMPLATE_DETAILS_FAILURE,MEETING_STATUS_UPDATE,MEETING_STATUS_UPDATE_FAILURE,SET_PATIENT_PROCEDURELIST,
    SET_PAGE_HEADER,DOCTOR_CALENDER_DETAILS,DEPT_ROLE_DETAIL,DOCTOR_CALENDER_DETAILS_FAILURE, DOC_INFO,PATIENTS_LAST_VISITED_INFO,
    SERVICE_LIST,DEPT_LIST_DETAILS,DEPT_LIST_DETAILS_FAILURE,SALTYPE_LIST_DETAILS,SALTYPE_LIST_DETAILS_FAILURE,FLOOR_LIST,FLOOR_LIST_FAILURE,DESK_LIST_DETAILS,DESK_LIST_DETAILS_FAILURE} from "../../shared/constants";
import {doctorServices} from "../services";
import review from '../../shared/config/Review.json';
import patientLastVisited from '../../shared/config/PatientVisited.json';
import ProductResponse from '../../shared/config/ProductResponse.json';

export function getDoctorsByDept(resCb) {
    return (dispatch) => {
        doctorServices.getDoctorsByDept()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.data));
                    resCb(user.data);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDoctorInfo(data,resCb) {
    return (dispatch) => {
        doctorServices.getDoctorInfo(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.personmap));
                    resCb(user.personmap);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addDoctor(data, resCb) {
    return (dispatch) => {
        doctorServices.addDoctor(data)
            .then(
                user => { 
                    console.log("Insert Doctor",user)
                    dispatch(success(DOCTOR_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DOCTOR_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function updateDoctor(data, resCb) {
    return (dispatch) => {
        doctorServices.updateDoctor(data)
            .then(
                user => { 
                    console.log("Update Doctor",user)
                    dispatch(success(DOCTOR_UPDATE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DOCTOR_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function reviewNotes(data, resCb) {
    // return (dispatch) => {
    //     dispatch({ type: ZOOM_REVIEW_NOTES, payload: review });   
    //     resCb(review)
    // }
    console.log("reviewNotes Doctor action",data)
    return (dispatch) => {

        doctorServices.reviewNotes(data)
        .then(
            user => { 
                console.log("Update Doctor",user)
                dispatch(success(ZOOM_REVIEW_NOTES, user.procedureList));
                resCb(user);
            },
            error => {
                console.log("Update Doctor",error.toString())
                dispatch(failure(DOCTOR_UPDATE_FAILURE, error.toString()));
                resCb(error.toString);
            }
        );
    }; 
}

export function getDoctorList(data,resCb) {
    return (dispatch) => {
        doctorServices.getDoctorList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.personList));
                    resCb(user.personList);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDepartmentList(data,resCb) {
    return (dispatch) => {
        doctorServices.getDepartmentList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_LIST_DETAILS, user.department));
                    resCb(user.department);

                },
                error => {
                    dispatch(failure(DEPT_LIST_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDeskList(data,resCb) {
    return (dispatch) => {
        doctorServices.getDeskList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DESK_LIST_DETAILS, user.deskList));
                    resCb(user.deskList);

                },
                error => {
                    dispatch(failure(DESK_LIST_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function  getSalaryTypeList(resCb) {
    return (dispatch) => {
        doctorServices. getSalaryTypeList()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(SALTYPE_LIST_DETAILS, user));
                    resCb(user);

                },
                error => {
                    dispatch(failure(SALTYPE_LIST_DETAILS_FAILURE, error.toString()));
                   resCb(error);
                }
            );
    };
}

export function getFloorList(data,resCb) {
    return (dispatch) => {
        doctorServices.getFloorList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(FLOOR_LIST, user.roomList));
                    resCb(user.roomList);

                },
                error => {
                    dispatch(failure(FLOOR_LIST_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function generateZoomToken(resCb) {
    return (dispatch) => {
        doctorServices.generateZoomToken()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDoctorsBasednId(data,resCb) {
    return (dispatch) => {
        doctorServices.getDoctorsBasednId(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.gvlist));
                    resCb(user.gvlist);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function addtags(data, resCb) {
    return (dispatch) => {
        doctorServices.addtags(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addProcedure(data, resCb) {
    return (dispatch) => {
        doctorServices.addProcedure(data)
            .then(
                user => { 
                    console.log("Insert Doctor",user)
                    dispatch(success(NEWPROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(NEWPROCEDURE_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function listTemplates(data,resCb) {
    return (dispatch) => {
        doctorServices.listTemplates()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(TEMPLATE_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(TEMPLATE_DETAILS_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function getAssignedDoctor(data,resCb) {
    return (dispatch) => {
        doctorServices.getAssignedDoctor(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.gvlist));
                    resCb(user.gvlist);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function assignDoctor(data,resCb) {
    return (dispatch) => {
        doctorServices.assignDoctor(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user.gvlist));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function updateMeetingStatus(data, resCb) {
    return (dispatch) => {
        doctorServices.updateMeetingStatus(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function getServiceList(data, resCb) {
    console.log("inside action");
    return (dispatch) => {
        doctorServices.getServiceList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getPackageList(data, resCb) {
    console.log("inside action");
    return (dispatch) => {
        doctorServices.getPackageList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addService(data, resCb) {
    console.log("inside action");
    return (dispatch) => {
        doctorServices.addService(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addPackage(data, resCb) {
    console.log("inside action");
    return (dispatch) => {
        doctorServices.addPackage(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function updateService(data, resCb) {
    console.log("inside updateService action :",data);
    return (dispatch) => {
        doctorServices.updateService(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getRoomList(data, resCb) {
    console.log("inside action room");
    return (dispatch) => {
        doctorServices.getRoomList(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getAssignedRoom(data, resCb) {
    console.log("inside action room");
    return (dispatch) => {
        doctorServices.getAssignedRoom(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function assignRoom(data, resCb) {
    console.log("inside action assignRoom: ",data);
    return (dispatch) => {
        doctorServices.assignRoom(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function roomCheckout(data, resCb) {
    console.log("inside action room");
    return (dispatch) => {
        doctorServices.roomCheckout(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getProductIdList(data, resCb) {
    console.log("inside action room");
    return (dispatch) => {
        doctorServices.getProductIdList(data)
            .then(
                resp => { 
                    console.log("Res",resp)
                    dispatch(success(SERVICE_LIST, resp));
                    resCb(resp);
                },
                error => {

                   resCb(error.toString);
                }
            );
    };
}

export function getProductDetails(data,location,resCb) {
    console.log("product input", data);

    // return (dispatch) => {
    //     console.log('ProductResponse',ProductResponse)
    //     dispatch({ type: PATIENTS_LAST_VISITED_INFO, payload: ProductResponse});   
    //     resCb(ProductResponse)
    // }
    return (dispatch) => {
        doctorServices.getProductDetails(data,location)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(MEETING_STATUS_UPDATE, user));
                    resCb(user);
                },
                error => {
                   dispatch(failure(MEETING_STATUS_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}



export function getStaffList(resCb) {
    return (dispatch) => {
        doctorServices.getStaffList()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                //     dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                //    resCb(error.toString);
                }
            );
    };
}



export function getRoleList(resCb) {
    return (dispatch) => {
        doctorServices.getRoleList()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_ROLE_DETAIL, user));
                    resCb(user);
                    console.log("user",user);
                },
                error => {
                //     dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                //    resCb(error.toString);
                }
            );
    };
}



















export function getDoctorListByType(data,resCb) {
    return (dispatch) => {
        doctorServices.getDoctorListByType(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function saveDoctor(data,resCb) {
    console.log("action saveDoctor(data",data);
    return (dispatch) => {
        doctorServices.saveDoctor(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                    console.log("DEPT_DOCTOR_DETAILS_FAILURE",error);
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error);
                }
            );
    };
}


export function modifyDoctor(data,resCb) {
    console.log("action modifyDoctor(data",data);
    return (dispatch) => {
        doctorServices.modifyDoctor(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DEPT_DOCTOR_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DEPT_DOCTOR_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function deletedoctor(data, resCb) {
    console.log("action deletedoctor(data", data);
    return () => {
      console.log("action return (dispatch)", data);
  
      doctorServices.deletedoctor(data).then(
        (user) => {
          console.log("Res", user);
          resCb(user);
        },
        (error) => {
          resCb(error.toString);
        }
      );
    };
  }
export function addDoctorAppointments(data,resCb){
    console.log("doctor calender data",data);
    return (dispatch) => {
        doctorServices.addDoctorAppointments(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(DOCTOR_CALENDER_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(DOCTOR_CALENDER_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addConsultant(summary,type,flag,resCb){
    return (dispatch) => {
        doctorServices.addConsultant(summary,type,flag)
            .then(
                resp => { 
                    console.log("Res",resp)
                    //dispatch(success(DOCTOR_CALENDER_DETAILS, user));
                    resCb(resp);
                },
                error => {
                   // dispatch(failure(DOCTOR_CALENDER_DETAILS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
























export function addCaseRecord(data, resCb) {
    return (dispatch) => {
        doctorServices.addCaseRecord(data)
            .then(
                user => {
                    console.log("Insert Doctor", user)
                    dispatch(success(NEWPROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(NEWPROCEDURE_SAVE_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function addFollowupNotes(data, resCb) {
    return (dispatch) => {
        doctorServices.addFollowupNotes(data)
            .then(
                user => {
                    console.log("Insert Followup Notes", user)
                    dispatch(success(NEWPROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(NEWPROCEDURE_SAVE_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function getFollowupNotes(pid,vid,temp,resCb) {
    return (dispatch) => {
        doctorServices.getFollowupNotes(pid,vid, temp, resCb)
            .then(
                user => { 
                    console.log("Insert Doctor",user)
                    dispatch(success(NEWPROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(NEWPROCEDURE_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

function success(type, data){
    return { type: type, payload : data }
}
function failure(type, value) {
    return { type: type, payload : value };
}
function setDoctorDetails(value) {
    return { type: SET_DOCTOR_DETAILS, payload : value };
}
function setPatientProcedureList(value) {
    return { type: SET_PATIENT_PROCEDURELIST, payload : value };
}

function setDoctorInfo(value) {
    console.log("setdoctorinfo:",value);
    return {type : DOC_INFO,payload: value}
}


export function setPageHeader(value){
    console.log("Room SET_PAGE_HEADER : ",value)
    return {type: SET_PAGE_HEADER, payload: value}
}


const doctorActions = {
    getDoctorsByDept,
    getDoctorInfo,
    addDoctor,
    updateDoctor,
    setDoctorDetails,
    reviewNotes,
    getDoctorList,
    getDoctorsBasednId,
    generateZoomToken,
    addtags,
    getRoleList,
    addProcedure,
    listTemplates,
    getStaffList,
    assignDoctor,
    updateMeetingStatus,
    setPatientProcedureList,
    getServiceList,
    addService,
    updateService,
    getRoomList,
    setPageHeader,
    getAssignedRoom,
    assignRoom,
    roomCheckout,
    getProductIdList,
    getDoctorListByType,
    saveDoctor,
    modifyDoctor,
    addDoctorAppointments,
    deletedoctor,
    setDoctorInfo,
    addConsultant,
    getProductDetails,
    getDepartmentList,
    getSalaryTypeList,
    getFloorList,
    getDeskList,
    getFloorList,
    getPackageList,
    addPackage,
    addCaseRecord,
    addFollowupNotes,
    getFollowupNotes
}

export default doctorActions;
