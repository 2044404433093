import * as React from "react";
import PdfPrinter from "pdfmake";

import { useEffect, useState } from "react";
import { ehrActions } from "../../core/actions";
import { jsonSchema } from "../../shared/schema";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";
import {
  MANASWINI_LOGO,
  MANASWINI_BACKGROUND,
} from "../../shared/constants/manaswinilogo";
import { useDispatch } from "react-redux";
import { isEmpty, template } from "lodash";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
var fonts = require("pdfmake/build/vfs_fonts");

function LabReportPrint(props) {
  const dispatch = useDispatch();
  const [patientInfo, setpatientInfo] = useState(props.selectedPatient);
  const [count, setCount] = useState(0);
  const [doctorDetails, setDoctorDetails] = useState(
    localStorage.getItem("doctorInformation")
      ? JSON.parse(localStorage.getItem("doctorInformation"))
      : props.doctorInfo
  );

  // const[prescriptionList,setPrescriptionList]=useState(props.prescriptionList);

  let rows = [[]];
  rows[0] = ["Test ", "Result", "Units", "Ref. Range", "Comment"];
  //rows[1] = ["Main Test Name"]

  console.log("props: ", props);
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString("default", { month: "short" });
  const year = now.getFullYear();
  const suffix = getNumberWithSuffix(day);
  const formattedDate = `${suffix} ${month} ${year}`;
  function getNumberWithSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = number % 100;
    return (
      number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0])
    );
  }

  useEffect(() => {
    console.log("props lab report summary", props);
    let currIndex = 0;

    props.prescriptionList &&
      props.prescriptionList.forEach((item, index) => {
        console.log("indexesss", index);
        console.log("item.compositionId", item.compositionId);
        dispatch(
          ehrActions.getCompositionByVid(
            {},
            item.compositionId,
            props.ehrid,
            (resCb) => {
              if (resCb) {
                currIndex++;
                console.log("API Response getCompositionByVid :", currIndex);
                console.log("resc :", resCb.archetype_details);
                let template_id_value;
                if (
                  item.templateId === resCb.archetype_details.template_id.value
                ) {
                  template_id_value = resCb.archetype_details.template_id.value;
                } else {
                  template_id_value = item.templateId;
                }
                console.log(template_id_value);
                let form = jsonSchema.getSchemaFromCanonicalJson(
                  resCb,
                  template_id_value,
                  "editComposition"
                );
                console.log("pdfprintform", form);
                formatLabReport(form);
              }
              if (currIndex == props.prescriptionList.length) {
                console.log("index values", index);
                console.log("reportArray", reportArray);
                for (let entry in reportArray) {
                  //console.log("repORT ENTRY", entry)
                  rows.push([entry, "", "", "", ""]);
                  for (let ent of reportArray[entry]) {
                    console.log("ent", ent);
                    console.log(
                      ent.testName,
                      ent.result,
                      ent.units,
                      ent.refRange,
                      ent.comment
                    );
                    rows.push([
                      ent.testName,
                      ent.result,
                      ent.units,
                      ent.refRange,
                      ent.comment,
                    ]);
                  }
                  rows.push(["", " ", " ", " ", " "]); // Add an empty row
                }

                // reportArray.forEach((row) => {
                //     console.log("adjdfgfhj",row)
                //     rows.push([row.testName[0], row.result[0], row.units[0], row.refrange[0], row.comment[0], row.majorTestName]);
                // });
                addReport();
              }
            }
          )
        );
      });
  }, []);
  let reportArray = [];
  let testArray = [];
  function formatLabReport(form) {
    console.log("form for print: ", form);
    console.log("form properties", form.properties);

    let reportObject = form.properties;
    console.log("reportObject: ", reportObject);
    let reports = Object.keys(form.properties);
    console.log("reports: ", reports);
    //console.log("reportObject: ", reportObject.ESR.properties)
    let majorTestName = "";
    let testName = "";
    let result = "";
    let units = "";
    let refRange = "";
    let comment = "";

    for (let report of reports) {
      console.log("report: ", report);
      let reportProps = reportObject[report].properties;
      console.log("reportObject.properties: ", reportProps);

      if (isObjectEmpty(reportProps)) {
        console.log(report, "properties is empty");
        majorTestName = report.toUpperCase();
        //reportArray.push({[majorTestName] : {}})
        testArray = [];
      } else {
        for (let it in reportProps) {
          console.log("it", it);
          console.log("it items: ", reportProps[it]);
          // if(it === "Result" && reportProps[it].default === -1) {
          //   break;
          // }
          testName = report;
          if (it === "Result") {
            if (reportProps[it].default === -1) {
              result = "--";
            } else {
              result = reportProps[it].default;
            }
          } else if (it === "Units") {
            units = reportProps[it].default;
          } else if (it === "Comment") {
            if (reportProps[it].default === "No Option Selected") {
              comment = "--";
            } else {
              comment = reportProps[it].default;
            }
          } else if (it === "Ref. Range") {
            refRange = reportProps[it].default;
          }
        }

        testArray.push({
          testName: testName,
          result: result,
          units: units,
          refRange: refRange,
          comment: comment,
        });
        console.log("in for loop report array: ", testArray);
      }
      reportArray[majorTestName] = testArray;
    }

    console.log("fINAL sreportArray: ", reportArray);
  }

  function isObjectEmpty(obj) {
    return JSON.stringify(obj) === "{}";
  }

  const dd = [];
  function addReport() {
    console.log("prescriptionrowsibsdh in addReport", rows);
    console.log(dd);
    var head = {};

    head["alignment"] = "justify";
    head["columns"] = [];

    var headerColumn = {};
    headerColumn["text"] = [
      {
        text: "",
        bold: "true",
        alignment: "center",
        margin: [20, 20, 20, 20],
      },
    ];

    var subhead = {};
    subhead["columns"] = [];
    var PnoColumn = {};
    var PdateColumn = {};
    PdateColumn["text"] = [
      {
        text: "Prescription Date:",
        alignment: "left",
        float: "right",
        color: "#176fad",
      },
      {
        text: `\n${formattedDate}`,
        alignment: "left",
        float: "right",
        fontSize: 6,
      },
    ];

    var subhead2 = {};
    subhead2["columns"] = [];
    var PatientInformation = {};
    PatientInformation["text"] = [
      {
        text: "\nPatient Information:\n",
        color: "#176fad",
        fontSize: 11,
        lineHeight: 1.25,
      },
      { text: "Name: ", fontSize: 10 },
      {
        text: `${
          patientInfo.fullName !== null &&
          patientInfo.fullName !== "" &&
          patientInfo.fullName !== undefined
            ? patientInfo.fullName
            : "- -"
        }`,
        fontSize: 10,
      },
      { text: "\nPhone number:", fontSize: 10, lineHeight: 1.25 },
      {
        text: `${
          patientInfo.phno !== null &&
          patientInfo.phno !== "" &&
          patientInfo.phno !== undefined
            ? patientInfo.phno
            : "- -"
        }\n`,
        fontSize: 10,
      },
      { text: "Address: ", fontSize: 10, lineHeight: 1.25 },
      {
        text: `\n${
          (patientInfo.address !== null &&
          patientInfo.address !== "" &&
          patientInfo.address !== undefined
            ? patientInfo.address + ","
            : "" )+ (patientInfo.city !== null &&
              patientInfo.city !== "" &&
              patientInfo.city !== undefined
            ? patientInfo.city
            : "Arkula")+ ", " + (patientInfo.state !== null &&
              patientInfo.state !== "" &&
              patientInfo.state !== undefined ?
              patientInfo.state : "Karnataka" )+
              (patientInfo.pincode !== null &&
                patientInfo.pincode !== "" &&
                patientInfo.pincode !== undefined
                  ? +" "+patientInfo.pincode
                  : "")
        }`,
        fontSize: 10,
      },
    ];

    var PatientInformation2 = {};
    PatientInformation2["text"] = [
      { text: "\n\nAge: ", fontSize: 10, lineHeight: 1.25 },
      {
        text: `${
          patientInfo.age !== null &&
          patientInfo.age !== "" &&
          patientInfo.age !== undefined
            ? patientInfo.age
            : "- -"
        }\n`,
        fontSize: 10,
      },
      { text: "Gender: ", fontSize: 10, lineHeight: 1.25 },
      {
        text: `${
          patientInfo.gender !== null &&
          patientInfo.gender !== "" &&
          patientInfo.gender !== undefined
            ? patientInfo.gender
            : "- -"
        }\n`,
        fontSize: 10,
      },
      { text: "Aadhar: ", fontSize: 10, lineHeight: 1.25 },
      {
        text: `${
          patientInfo.adhar !== null &&
          patientInfo.adhar !== "" &&
          patientInfo.adhar !== undefined
            ? patientInfo.adhar
            : "- -"
        }\n`,
        fontSize: 10,
      },
    ];

    var reportDate = {};
    subhead["columns"] = [];
    //var PnoColumn = {}
    var reportDateColumn = {};
    reportDateColumn["text"] = [
      {
        text: "Report Generated on: ",
        alignment: "left",
        float: "right",
        fontSize: 10,
        lineHeight: 1.25,
        //color: "#000000",
      },
      {
        text: `${formattedDate}`,
        alignment: "left",
        float: "right",
        fontSize: 10,
        lineHeight: 1.25,
      },
    ];

    // var PM = {};
    // PM["columns"] = [];
    // var Pmhead = {};
    // Pmhead["text"] = [
    //   { text: "List of Prescribed Medications:", color: "#176fad" },
    // ];

    rows[0] = rows[0].map(function (cell) {
      return {
        text: cell,
        fontSize: 11,
        bold: true /* decoration: 'underline' */,
      };
    });

    rows.forEach(function (row, index) {
      if (row[1] === "" && row[2] === "" && row[3] === "" && row[4] === "") {
        rows[index] = row.map(function (cell) {
          return { text: cell, bold: true, fontSize: 9 };
        });
      } else {
        rows[index] = row.map(function (cell) {
          return { text: cell, fontSize: 9 };
        });
      }
    });

    var generatedReport = {};
    generatedReport["columns"] = [];
    var generatedReportData = {};
    generatedReportData = [
      {
        table: {
          headerRows: 1,
          widths: [175, "*", "*", "*", "auto"],
          alignment: "center",
          body: rows,
          //layout: { defaultBorder: false,}
        },
        layout: {
          hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return 0.5;
            }
            return i === node.table.headerRows ? 0.5 : 0;
          },
          vLineWidth: function (i) {
            return 0;
          },
          hLineColor: function (i) {
            return i === 0.5 ? "#aaa" : "black";
          },
        },
        styles: {
          cellStyle: {
            noWrap: false, // Enable text wrapping for all cells
          },
        },
      },
    ];
    console.log("report Data: ", generatedReportData);

    var DoctorInfo = {};
    DoctorInfo["columns"] = [];
    var doctInfo = {};
    doctInfo["stack"] = [
      { text: "\n\n", fontSize: 10 },
      {
        text:
          `${doctorDetails.displaytitle}. ` +
          `${doctorDetails.first_name} ` +
          `${doctorDetails.last_name ? doctorDetails.last_name : ""}\n`,
        fontSize: 10,
        bold: true,
      },
      { text: "Consulting Physician\n", fontSize: 9, bold: true },

      {
        text: [
          { text: "Mob. No. :", fontSize: 9, bold: true },
          {
            text: `${doctorDetails.phoneno ? doctorDetails.phoneno : "- -"}\n`,
            fontSize: 9,
            bold: true,
          },
        ],
        alignment: "left",
      },
      //   { text: "Mob. No. :" ,fontSize: 9, bold: true },
      //   { text: `${doctorDetails.phoneno ? doctorDetails.phoneno : '- -'}\n` , fontSize: 9, bold: true},
      {
        text: [
          { text: "email: ", fontSize: 9, bold: true },
          {
            text: `${doctorDetails.email ? doctorDetails.email : "- -"}`,
            fontSize: 9,
            bold: true,
          },
        ],
        alignment: "left",
      },
      //   { text: "Email:", fontSize: 9 , bold: true, alignment: 'left'},
      //   { text: ` ${doctorDetails.email ? doctorDetails.email:'- -'}`,alignment: 'left', fontSize: 9 , bold: true},
      //{ text: "\nSignature:" },
    ];

    var doctInfo2 = {};
    doctInfo2["stack"] = [
      { text: "\n\n", fontSize: 10 },
      { text: "\n", fontSize: 10 },
      { text: "Lab Technician", fontSize: 9, alignment: "right", bold: true },
      { text: "Mob. No.", fontSize: 9, alignment: "right", bold: true },
    ];

    DoctorInfo["columns"].push(doctInfo);
    DoctorInfo["columns"].push(doctInfo2);

    head.columns.push(headerColumn);
    subhead.columns.push(reportDateColumn);
    subhead2.columns.push(PatientInformation, "\t", PatientInformation2);
    //PM.columns.push(Pmhead);
    generatedReport.columns.push(generatedReportData);
    //DoctorInfo.columns.push(doctInfo, "\t", doctInfo2);
    // hehe.columns.push(subhead2, "\n\n\n", subhead)
    // hehe.columns.push(
    //     { text: '\n' }, // Add a newline to create space between subhead2 and subhead
    //     { stack: [subhead2], style: 'borderContainer' },
    //     { stack: [subhead], style: 'borderContainer' }
    //   );

    dd.content = [];
    dd.background = [
      {
        image: MANASWINI_BACKGROUND,
        width: 600,
        height: 800,
        color: "#ff0000",
      },
    ];
    dd.header = [
      {
        columns: [
          {
            image: MANASWINI_LOGO,
            fit: [60, 60], // set the width and height of the image
            alignment: "left",
            margin: [25, 0, 0, 20],
            width: 90, // set the top and bottom margin of the image
          },
          [
            {
              text: HOSPITAL_NAME,
              style: "header",
              width: "auto",
              margin: [5, 10, 0, 0], // Add margin to separate header from content
            },
            {
              stack: [
                {
                  text: HOSPITAL_DESC,
                  style: "subheader",
                  width: "auto",
                  margin: [5, 0, 0, 20],
                  border: [false, false, false, true],
                },
              ],
              //margin: [0, 0, 10, 20], // Add margin to separate header from content
            },
          ],
        ],
      },
    ];
    dd.content.push(
      //   head, (prescription title in the middle)
      //   "\n",
      //   subhead,(prescription time)
      //   "\n\n",
      subhead2,
      "\n",
      subhead,
      "\n",
      //   PM,
      //   "\n",
      generatedReportData,
      "\n\n",
      [DoctorInfo]
      //doctInfo2,
      //doctInfo
    );

    dd.styles = {
      header: {
        fontSize: 20,
        bold: true,
        color: "#176fad",
      },
      subheader: {
        fontSize: 14,
        bold: true,
        color: "#176fad",
      },
    };
    dd.pageMargins = [40, 60, 40, 60];
    dd.footer = function (currentPage, pageCount) {
      return [
        {
          text: HOSPITAL_NAME,
          alignment: "left",
          fontSize: 8,
          color: "#176fad",
          bold: true,
          margin: [20, 7, 0, 0],
        },
        {
          text: HOSPITAL_DESC,
          alignment: "left",
          fontSize: 8,
          color: "#176fad",
          bold: true,
          margin: [20, 0, 0, 0],
        },
        {
          text: HOSPITAL_ADDRESS,
          alignment: "left",
          fontSize: 8,
          margin: [20, 0, 0, 0],
        },
        {
          text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
          alignment: "left",
          fontSize: 8,
          margin: [20, 0, 0, 5],
        },
        {
          text: currentPage.toString() + " of " + pageCount.toString(),
          alignment: "center",
          fontSize: 8,
        },
      ];
    };
    PdfPrinter.createPdf(dd, null, null, fonts.pdfMake.vfs).open();
  }

  // function addReport() {
  //     console.log("prescriptionrowsibsdh in addReport", rows);
  //     console.log(dd);
  //     var head = {};

  //     head["alignment"] = "justify";
  //     head["columns"] = [];

  //     var headerColumn = {};
  //     headerColumn["text"] = [
  //       {
  //         text: "",
  //         bold: "true",
  //         alignment: "center",
  //         margin: [20, 20,20, 20],
  //       },
  //     ];

  //     var subhead = {};
  //     subhead["columns"] = [];
  //     var PnoColumn = {}
  //     var PdateColumn = {};
  //     PdateColumn["text"] = [
  //       {
  //         text: "Prescription Date:",
  //         alignment: "left",
  //         float: "right",
  //         color: "#176fad",
  //       },
  //       { text: `\n${formattedDate}`, alignment: "left", float: "right" },
  //     ];

  //     var subhead2 = {};
  //     subhead2["columns"] = [];
  //     var PatientInformation = {};
  //     PatientInformation["text"] = [
  //       { text: "Patient Information:\n", color: "#176fad"},
  //       { text: "\nName:\n" },
  //       {
  //         text: `${
  //           patientInfo.fullName !== null && patientInfo.fullName !== "" && patientInfo.fullName !== undefined
  //             ? patientInfo.fullName
  //             : "- -"
  //         }\n`,
  //       },
  //       { text: "\nPhone number:" },
  //       {
  //         text: `\n${
  //           patientInfo.phno !== null && patientInfo.phno !== "" && patientInfo.phno !== undefined
  //             ? patientInfo.phno
  //             : "- -"
  //         }\n`,
  //       },
  //       { text: "\nAddress:" },
  //       {
  //         text: `\n${
  //           patientInfo.address !== null && patientInfo.address !== "" && patientInfo.address !== undefined
  //             ? patientInfo.address
  //             : "- -"
  //         }`,
  //       },
  //     ];

  //     var PatientInformation2 = {};
  //     PatientInformation2["text"] = [
  //       { text: "\nAge:\n" },
  //       {
  //         text: `${
  //           patientInfo.age !== null && patientInfo.age !== "" && patientInfo.age !== undefined
  //             ? patientInfo.age
  //             : "- -"
  //         }\n`,
  //       },
  //       { text: "\nGender:\n" },
  //       {
  //         text: `${
  //           patientInfo.gender !== null && patientInfo.gender !== "" && patientInfo.gender !== undefined
  //             ? patientInfo.gender
  //             : "- -"
  //         }\n`,
  //       },
  //       { text: "\nAdhar:\n" },
  //       {
  //         text: `${
  //           patientInfo.adhar !== null && patientInfo.adhar !== "" && patientInfo.adhar !== undefined
  //             ? patientInfo.adhar
  //             : "- -"
  //         }\n`,
  //       },
  //     ];

  //     var PM = {};
  //     PM["columns"] = [];
  //     var Pmhead = {};
  //     Pmhead["text"] = [
  //       { text: "List of Prescribed Medications:", color: "#176fad" },
  //     ];

  //     var PrescribedMedicationTable = {};
  //     PrescribedMedicationTable["columns"] = [];
  //     var PmTableData = {};
  //     PmTableData = [
  //       {
  //         table: {
  //           headerRows: 1,
  //           width: "100," * ",100",
  //           body: rows,
  //         },
  //       },
  //     ];
  //     console.log('pmdata: ', PmTableData)
  //     var DoctorInfo = {};
  //     DoctorInfo["columns"] = [];
  //     var doctInfo = {};
  //     doctInfo["text"] = [
  //       { text: "Physician Name:\n" },
  //       {
  //         text:
  //           `${doctorDetails.displaytitle}.` +
  //           `${doctorDetails.first_name}` +
  //           `${doctorDetails.last_name ? doctorDetails.last_name : ''}\n`,
  //       },
  //       { text: "\nSignature:" },
  //     ];

  //     var doctInfo2 = {};
  //     doctInfo2["text"] = [
  //       { text: "Phone Number:\n" },
  //       { text: `${doctorDetails.phoneno ? doctorDetails.phoneno : '- -'}\n` },
  //       { text: "\nEmail:" },
  //       { text: `\n${doctorDetails.email ? doctorDetails.email:'- -'}` },
  //     ];

  //     head.columns.push(headerColumn);
  //     subhead.columns.push(PnoColumn, "\t", PdateColumn);
  //     subhead2.columns.push(PatientInformation, "\t", PatientInformation2);
  //     PM.columns.push(Pmhead);
  //     PrescribedMedicationTable.columns.push(PmTableData);
  //     DoctorInfo.columns.push(doctInfo, "\t", doctInfo2);
  //     dd.content = [];
  //     dd.background = [ {
  //       image: MANASWINI_BACKGROUND,
  //       width: 600,
  //       height: 800,
  //       color: '#ff0000'
  //     }];
  //     dd.header = [
  //       {
  //         columns: [
  //           {
  //             image: MANASWINI_LOGO,
  //             fit: [60, 60], // set the width and height of the image
  //             alignment: "left",
  //             margin: [25, 0, 0, 20],
  //             width: 90, // set the top and bottom margin of the image
  //           },
  //           [
  //             {
  //               text: HOSPITAL_NAME,
  //               style: "header",
  //               width: "auto",
  //               margin: [5, 10, 0, 0], // Add margin to separate header from content
  //             },
  //             {
  //               stack: [
  //                 {
  //                   text: HOSPITAL_DESC,
  //                   style: "subheader",
  //                   width: "auto",
  //                   margin: [5, 0, 0, 20],
  //                   border: [false, false, false, true],
  //                 },
  //               ],
  //               //margin: [0, 0, 10, 20], // Add margin to separate header from content
  //             },
  //           ],
  //         ],
  //       },
  //     ];
  //     dd.content.push(
  //     //   head, (prescription title in the middle)
  //     //   "\n",
  //     //   subhead,(prescription time)
  //     //   "\n\n",
  //       subhead2,
  //       "\n\n",
  //       PM,
  //       "\n",
  //       PrescribedMedicationTable,
  //       "\n\n",
  //       DoctorInfo
  //     );
  //     dd.styles = {
  //       header: {
  //         fontSize: 20,
  //         bold: true,
  //         color: "#176fad",
  //       },
  //       subheader: {
  //         fontSize: 14,
  //         bold: true,
  //         color: "#176fad",
  //       },
  //     };
  //     dd.pageMargins = [40, 60, 40, 60];
  //     dd.footer = function (currentPage, pageCount) {
  //       return [
  //         {
  //           text: HOSPITAL_NAME,
  //           alignment: "left",
  //           fontSize: 8,
  //           color: "#176fad",
  //           bold: true,
  //           margin: [20, 7, 0, 0],
  //         },
  //         {
  //           text: HOSPITAL_DESC,
  //           alignment: "left",
  //           fontSize: 8,
  //           color: "#176fad",
  //           bold: true,
  //           margin: [20, 0, 0, 0],
  //         },
  //         {
  //           text: HOSPITAL_ADDRESS,
  //           alignment: "left",
  //           fontSize: 8,
  //           margin: [20, 0, 0, 0],
  //         },
  //         {
  //           text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
  //           alignment: "left",
  //           fontSize: 8,
  //           margin: [20, 0, 0, 5],
  //         },
  //         {
  //           text: currentPage.toString() + " of " + pageCount.toString(),
  //           alignment: "center",
  //           fontSize: 8,
  //         },
  //       ];
  //     };
  //     PdfPrinter.createPdf(dd, null, null, fonts.pdfMake.vfs).open();
  //   }

  return <></>;
}
export default LabReportPrint;
