import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ZOOMNOTESLABEL} from "../../shared/config";
import Components from '..';
import { cleanup } from '@testing-library/react';
import { circularProgressClasses } from '@mui/material';
import { doctorActions, patientActions } from '../../core/actions';
import Select, { createFilter } from "react-select";
import { EPRESCRIPTION, LAB_ADMIN } from '../../shared/constants';
import CardContent from '@mui/material/CardContent';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function TemplatePreview(props) {
    
    const [status, setStatus] = useState(undefined);
    const [templateId,setTemplateId] = useState();
    const [pData,setPData] = useState([]);
    const [templateList,setTemplateList] = useState();
    const dispatch = useDispatch();
    const [deptoptions, setDeptOption] = useState([]);
    const [catoptions, setCatOption] = useState([]);
    const [subcatoptions, setSubCatOption] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [flag, setFlag] = useState(false);
    const [comflag, setComflag] = useState();
    const [comid, setComid] = useState();
    const[procedure,setProcedure]=useState();

    const templates = useSelector(state => state.doctorDetailsState.templates)
    let placeholdertext = "Select value";
    useEffect(() => {
        var role_name = JSON.parse(localStorage.getItem("role"));
        console.log("role",role_name);
        if(props.mode=="editComposition"){
            console.log("props:",props);
            setComid(props.templateId);
            // console.log("proceduretemplateid",props.templateId)
            setComflag(props.mode);
            console.log("procedure",props.selectedProcedure);
            setProcedure(props.selectedProcedure);
            
            // put console here 
        }else{
            console.log("props:",props);
            let patientId = props.mode == 'new' ? props.fileUploadcontent.visit.patientId : props.fileUploadcontent.patientId;
            let visitId = props.mode == 'new' ? props.fileUploadcontent.visit.id : props.fileUploadcontent.visitId;
            let patientDetails = []
            let pOpt = {}
            pOpt['patientId'] = patientId;
            pOpt['visitId'] = visitId;
            patientDetails.push(pOpt);
            setPData([...patientDetails]);
            console.log("pData:",patientDetails);
            console.log("pData:",pData);
            console.log("templates:",templates);
            setSelectedOption([...templates.templateMasterList]);
            // setTemplateList(templates.templateMasterList);
            console.log("templates:",templates.templateMasterList);
            // console.log("templateList:",templateList);
            console.log("props:",props);
            console.log("fileUploadcontent",props.fileUploadcontent);
            let deptOptions = []
            let catOptions = []
            let subCatOptions = []
            if(templates && templates.templateMasterList.length>0){
                if(role_name==LAB_ADMIN){
                    for(let temp of templates.templateMasterList){
                        if(temp.department =="Laboratory"){
                            let dOpt = {},cOpt = {},scOpt = {}
                                dOpt['id'] = temp.id;
                                dOpt['value'] = temp.templateId;
                                // dOpt['label'] = temp.templateId;
                                dOpt['label'] = temp.name;
                                cOpt['id'] = temp.id;
                                cOpt['value'] = temp.templateId+temp.category;
                                cOpt['label'] = temp.templateId+'(' + temp.category + ')';
                                scOpt['id'] = temp.id;
                                scOpt['value'] = temp.templateId+temp.subcategory;
                                scOpt['label'] = temp.templateId+'(' + temp.subcategory + ')';
                                deptOptions.push(dOpt);
                                catOptions.push(cOpt);
                                subCatOptions.push(scOpt);
                                console.log("opt:",dOpt,cOpt,scOpt);
                                console.log("Options:",deptOptions,catOptions,subCatOptions);
                        }
                    }
                }
                else{
                    console.log("inside tem if con",templates.templateMasterList);
                    for(let temp of templates.templateMasterList){
                        let dOpt = {},cOpt = {},scOpt = {}
                            dOpt['id'] = temp.id;
                            dOpt['value'] = temp.templateId;
                            // dOpt['label'] = temp.templateId;
                            dOpt['label'] = temp.name;
                            cOpt['id'] = temp.id;
                            cOpt['value'] = temp.templateId+temp.category;
                            cOpt['label'] = temp.templateId+'(' + temp.category + ')';
                            scOpt['id'] = temp.id;
                            scOpt['value'] = temp.templateId+temp.subcategory;
                            scOpt['label'] = temp.templateId+'(' + temp.subcategory + ')';
                            deptOptions.push(dOpt);
                            catOptions.push(cOpt);
                            subCatOptions.push(scOpt);
                            console.log("opt:",dOpt,cOpt,scOpt);
                            console.log("Options:",deptOptions,catOptions,subCatOptions);
                    }
                }
            }
            console.log("options1:",deptoptions,catoptions,subcatoptions);
            setDeptOption([...deptOptions]);
            setCatOption([...catOptions]);
            setSubCatOption([...subCatOptions]);
            console.log("options2:",deptoptions,catoptions,subcatoptions);
        }

    },[])
  
    const closeModal = () => {
        props.ontemplatePreviewModalClose()
        // window.location.href = `/patient/:patientid/:visitid/:groupid`;
    }

    const handleChange = (selectedOption) => {
        console.log("selectedOption",selectedOption);
        console.log("event:", selectedOption);
        setTemplateId(selectedOption.value);
        setFlag(true);
        setSelectedOption(selectedOption);
    }

    const submit = () => {
        // console.log("procedureDetail submit:",procedureDetail);
        // dispatch(doctorActions.addProcedure(procedureDetail,(resCb) => {
        //     console.log("procedureDetail:",procedureDetail);
        //     if(resCb){
        //         setStatus({ type: 'success' });
        //     }
        // }));
    }

    const closeFormSchema = (event) =>{
        setFlag(false);
        props.ontemplatePreviewModalClose(event);
    }

    return (  
        <>  
         
        <div className=" bg-none nav-wrapper h-100 d-flex flex-column template-view">
        
            <div className="d-flex">                  
                    {/* <span className="topright d-flex justify-content-end pl-4 pt-3 mr-4"  onClick={(event)=>closeModal(event)}>X</span>        */}
                    <IconButton
                                aria-label="close"
                                onClick={(e) => closeModal(e)}
                                sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                         <CloseIcon />
                    </IconButton>
            </div>
        
            <div className="d-flex" style={{overflowY:'auto'}}>
                <CardContent className="w-100">
                    <div className="search-container">
                                 {/* {selectedOption && 
                                 <h5>{selectedOption}</h5> 
                                } */}
                                {comflag == "editComposition" ?(                                       
                                        
                                        ""

                                    ) : (
                                        <Select  
                                            name="template"
                                            placeholder={"Search template...."}
                                            // value={selectedOption}
                                            onChange={handleChange}
                                            isClearable
                                            isSearchable
                                            options={deptoptions}
                                            filterOption={createFilter(deptoptions)}
                                            patientInfo={props.patientInfo}
                                            // defaultValue={selectedOption}
                                            // style={{ padding: `0px 30px` }} 
                                        ></Select>
                                    
                                      )}
                                
                                <div>    
                                {comflag == "editComposition" &&(
                                    <div className='py-3 px-0'>
                                            <Components.PatientSchema
                                            templateData={comid}
                                            patientData={pData}
                                            selectedProcedure={procedure}
                                            flags={comflag}
                                            patientInfo={props.patientInfo}
                                            mode = "edit"
                                            onModalClose={(event) => closeFormSchema(event)}
                                            />
                                    </div>
                                )}
                                {flag==true && (
                                    <div className='py-3 px-0'>
                                            <Components.PatientSchema
                                            templateData={templateId}
                                            patientData={pData}
                                            patientInfo={props.patientInfo}
                                            mode = "new"
                                            onModalClose={(event) => closeFormSchema(event)}
                                            />
                                    </div>
                                )}
                                </div>
                                
                    </div>       
                </CardContent>
                   
            </div>
        </div>
        </>

    );
}

export default TemplatePreview;
