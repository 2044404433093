import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";
const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

  function tokenGenerate(visitid,gvid) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      //body: JSON.stringify(data),
    };
    const url = basePath + `/v1/token/generate?visitId=${visitid}&gvId=${gvid}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }


  function runningToken() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },  
    };
    const url = basePath + `/v1/runningtoken`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  

  function recorrectToken(visitid,gvid) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      //body: JSON.stringify(data),
    };
    const url = basePath + `/v1/token/recorrect?visitId=${visitid}&gvId=${gvid}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function deskDetails() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },  
    };
    const url = basePath + `/v1/deskdetails`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  

  function completeToken(patientId,visitid) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },  
    };
    const url = basePath + `/v1/token/complete?patientId=${patientId}&visitId=${visitid}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  
  
  const tokenServices = {
    handleResponse,
    runningToken,
    tokenGenerate,
    recorrectToken,
    completeToken,
    deskDetails

  }

  export default tokenServices;  
