import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doctorActions, patientActions, ehrActions } from "../../core/actions";
import TextField from "@mui/material/TextField";
import { InputLabel, FormControl, makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { CASERECORD } from "../../shared/constants";
import Components from "..";
import { format } from "date-fns";
import { contextType } from "react-datetime";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function Notes(props) {
  const dispatch = useDispatch();
  const [followUpDateFlag, setFollowUpDateFlag] = useState(false);
  const inputRefs = useRef([]);
  const [status, setStatus] = useState(undefined);
  const [todayDate, setTodayDate] = useState(new Date());
  const [revisitDate, setRevisitDate] = useState(
    format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd")
  );
  const [diagnosistemplate, setDiagnosistemplate] = useState();
  const [followUpdateFlag, setFollowUpdateFlag] = useState(true);
  const [patientDetail, setPatientDetail] = useState({
    patientId: "",
    lastvisitId: "",
    scheduleddate: "",
  });
  const [notePrint, setNotePrint] = useState(false);
  const [notes, setNotes] = useState({
    "Chief Complaints": "",
    Onset: "",
    duration: "",
    progression: "",
    "Precipitating factor": "",
    "Clinical Summary": "",
    "Past and Treatment History": "",
    "Relevant Medical History": "",
    "Relevant Family History": "",
    "Personal History": "",
    "Premorbid Personality": "",
    "Psycho-social Factors": "",
    pulse: "",
    bp: "",
    weight: "",
    bmi: "",
    "Mental State Examination": "",
    "Provisional Diagnosis": "",
    "Final Diagnosis": "",
    "Consultant Notes and Management Plan": "",
    "Follow Up Date": "",
  });
  const [showError, setShowError] = useState(false);
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [loadsummary, setLoadSummary] = useState(false);
  const patientEhrid = useSelector((state) => state.patientDetailsState.ehrid);
  const [formErrors, setFormErrors] = useState({
    "Final Diagnosis": "",
    "Clinical Summary": "",
    "Consultant Notes and Management Plan": "",
  });
  const [filterdProList, setfilterdProList] = useState();

  const useStyles = makeStyles((theme) => ({
    redAsterisk: {
      color: "red",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    console.log("props data is : ", props);
    let patientId = props.patientData.id;
    let visitId = props.patientData.visit.id;
    dispatch(
      patientActions.getrevisitbyId(patientId, visitId, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb);
          console.log("resCbe : ", resCb);
          patientDetail["scheduleddate"] = resCb.scheduleddate;
          if(patientDetail['scheduleddate']!==" " && patientDetail['scheduleddate']!==undefined && patientDetail['scheduleddate']!==null){
            setRevisitDate(format(new Date(patientDetail['scheduleddate']), "yyyy-MM-dd"));
            console.log("resCbe : ", revisitDate, patientDetail["scheduleddate"]);
          }else{
            setRevisitDate(format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd"));
                    }
        }
      })
    );

    console.log("diagnosis template:", patientDetail);
    //if(patientDetail['scheduleddate']===" " || patientDetail['scheduleddate']===undefined || patientDetail['scheduleddate']===null){
    //var followUpDateVar;
    // var folDate = new Date();
    // format(folDate.setDate(folDate.getDate() + 14), "yyyy-MM-dd");
    //   //  console.log("diagnosis template:", patientDetail);
    //   // patientDetail['scheduleddate'] = revisitDate;
    //   // setRevisitDate(revisitDate);
    // }

    getNotesDetails();
    getDiagnosistemplateById();
    console.log("diagnosis template:", diagnosistemplate);
  }, []);

  const getNotesDetails = () => {
    props.patientData.patientId = props.patientData.id;
    props.patientData.visitId = props.patientData.visit.id;
    props.patientData.groupname = CASERECORD;
    console.log("details :: ", props.patientData);
    dispatch(
      patientActions.getDischargeSummary(notes, props.patientData, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb);
          if (resCb.procedureList) {
            const filterList = resCb.procedureList.filter(
              (items) => items.groupname === CASERECORD
            );
            const keys = Object.keys(notes);
            var patientNotes = { ...notes };
            var filteredProList = [];
            filterList.forEach((item) => {
              for (let key of keys) {
                if (item.claSS == key) {
                  patientNotes[key] = item.notes;
                  filteredProList.push(item);
                  setNotes({ ...patientNotes });
                }
              }
              setfilterdProList([...filteredProList]);
            });
          }
        }
      })
    );
  };
  const closeServiceModal = (event) => {
    props.onModalClose(event);
  };
  //save Diagnosis to EHR
  const saveDiagnosisinEHR = (finaldiagnosis) => {
    let responseresCb = {};

    console.log("---Form changed---", diagnosistemplate);
    console.log("---patientEhrid---", patientEhrid);

    let templateId = "Diagnosis";
    let patientId = props.patientData.id;
    let visitId = props.patientData.visit.id;

    console.log("patientidddd", patientId);
    console.log("visitIdddd", visitId);
    console.log("Templateiddddd", diagnosistemplate.content[0].data);
    for (let item of diagnosistemplate.content[0].data.items) {
      console.log("ii", item);
      let element = item.name.value;
      console.log("Elementtttooo", element);
      const time = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      ).toISOString();
      switch (element) {
        case "Problem/Diagnosis name":
          item.value.value = "Diagnosis";
          break;
        case "Clinical description":
          item.value.value = finaldiagnosis.notes;
          break;

        case "Date/time of onset":
          item.value.value = time;
          break;

        case "Date/time of resolution":
          item.value.value = time;
          break;
        case "Date/time clinically recognised":
          item.value.value = time;
          break;

        default:
      }
      console.log("---Form changed---", diagnosistemplate);
    }
    // console.log("prescription value",prescriptionValue);
    // console.log("modifynotes",modifyNotes);
    // const  prescriptionDetails=modifyNotes.localMedicalBrand+' '+' '+modifyNotes.advicetoDispenser+' '+modifyNotes.instructionstoPatient;
    // const prescriptionDetails=modifyNotes.problemDiagnosis;
    let notesDetails = finaldiagnosis.notes?finaldiagnosis.notes:"NA";

    dispatch(
      ehrActions.saveComposition(
        diagnosistemplate,
        patientId,
        visitId,
        templateId,
        patientEhrid,
        notesDetails,
        (resCb) => {
          if (resCb) {
            console.log("Diagnosis Master API Response:", resCb);
            console.log("Templateiddddd", templateId);
            // setSelectedPatientInfo(resCb)
            if (resCb.successMessages) {
              //  if(flag == undefined && flag != "add new"){
              props.onModalClose({ success: resCb.successMessages[0] });
              // }
            } else {
              if (resCb.errorMessages[0]) {
                //if(flag == undefined && flag != "add new"){
                props.onModalClose({ error: resCb.errorMessages[0] });
                //}
              }
            }
          }
        }
      )
    );
  };
  const getDiagnosistemplateById = () => {
    let data = {};
    const jsonSchema = {};
    let templateId = "Diagnosis";
    dispatch(
      ehrActions.getTemplateById(data, templateId, (resCb) => {
        if (resCb) {
          console.log("Getdiagnosiss rescb", resCb);
          console.log("Diagnosisss ", resCb);
          setDiagnosistemplate(resCb);
        }
      })
    );
    return diagnosistemplate;
  };

  // console.log("Templateiddddd",templateId);
  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value);
    formErrors[name] = "";
    var patientNotes = notes;
    var keyList = [];
    patientNotes[name] = value;
    //  To handle data in edit case
    if (filterdProList && filterdProList.length > 0) {
      filterdProList.forEach((item) => {
        if (item.claSS == name) {
          keyList.push({
            claSS: item.claSS,
            notes: value,
            id: item.id,
            patientId: item.patientId,
            visitId: item.visitId,
          });
        }
        setfilterdProList([...keyList]);
      });
    }
    setNotes({ ...patientNotes });
  };

  const handleSubmit = (e) => {
    // Object.keys(notes).forEach((key) => {
    //   validateField(key, notes[key]);
    // });
    validateForm(e);
    setMessageStrip({ success: e.successMessages });
    setTimeout(() => {
      console.log("props:", props);
    }, 3000);

    //getDiagnosistemplateById();
  };
  const setMessageStrip = (e) => {
    console.log("message obtained", e);
    var ServerResVal = ServerRes;
    if (e.success) {
      console.log("success message:", e.success);
      ServerResVal.success = e.success;
    } else {
      console.log("error message:", e.error);
      ServerResVal.error = e.error;
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  };
  const UpdateRevisitDetails = () => {
    console.log(" insert  props", props);
    patientDetail.patientId = props.patientData.id;
    patientDetail.lastvisitId = props.patientData.visit.id;
    console.log("patientdetail.....", patientDetail, revisitDate);

    let date = format(new Date(revisitDate), "dd-MM-yyyy");

    notes["Follow Up Date"] = date;
    patientDetail.scheduleddate = date;
    setFollowUpdateFlag(date);
    console.log("notes adte:", notes["Follow Up Date"]);
    return patientDetail;
  };
  const handlePrint = (event, isupvote) => {
    console.log("In handleinput change", notes);

    var notesData = notes;
    setNotes({ ...notesData });

    setNotePrint(true);
  };

  const handleNotePrint = () => {
    setNotePrint(false);
  };
  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    switch (fieldName) {
      case "Final Diagnosis":
        fieldValidationErrors["Final Diagnosis"] =
          value.length > 0 ? "" : "Please Enter Final Diagnosis";
        break;
      case "Clinical Summary":
        fieldValidationErrors["Clinical Summary"] =
          value.length > 0 ? "" : "Please Enter Clinical Summary";
        break;
      case "Consultant Notes and Management Plan":
        fieldValidationErrors["Consultant Notes and Management Plan"] =
          value.length > 0
            ? ""
            : "Please Enter Consultant Notes and Management Plan ";
        break;
      default:
        break;
    }
    console.log("fieldValidationErrors:", fieldValidationErrors);
    setFormErrors({ ...fieldValidationErrors });
  };
  const validateForm = (e) => {
    console.log("Submit Notes:",e)
    console.log("formErrors : ", formErrors);
    const excludedColumns = ["Follow Up Date"];
    const allFieldsEmpty = Object.entries(notes).filter(([key]) => !excludedColumns.includes(key)).every(([_, value]) => !value);
    console.log("Submit Notes:",allFieldsEmpty)
    if (allFieldsEmpty) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
      
    
      // formErrors["Final Diagnosis"] === "" &&
      // formErrors["Clinical Summary"] === "" &&
      // formErrors["Consultant Notes and Management Plan"] === "";
      var ServerResVal = ServerRes;
      e.preventDefault();
      var proList = [];
      let finaldiagnosis = {};
      const followUpDetails = UpdateRevisitDetails();
      const keys = Object.keys(notes);
      // handle submit in edit case
      if (filterdProList && filterdProList.length > 0) {
        filterdProList.forEach((item) => {
          if (item.claSS === "Follow Up Date") {
            proList.push({
              id: item.id,
              claSS: item.claSS,
              notes: followUpDetails.scheduleddate,
              patientId: item.patientId,
              visitId: item.visitId,
            });
          } else {
            proList.push({
              id: item.id,
              claSS: item.claSS,
              notes: item.notes,
              patientId: item.patientId,
              visitId: item.visitId,
            });
          }
        });
        console.log("proList in edit case: ", proList);
      } else {
        // handle submit in add
        for (let key of keys) {
          proList.push({
            claSS: key,
            notes: notes[key],
            patientId: props.patientData.id,
            visitId: props.patientData.visit.id,
          });
          console.log("proList in add case: ", proList);
        }
      }
      notes["notes"] = "NOTE";
      proList.map((item) => {
        if (item.claSS === "Final Diagnosis") {
          finaldiagnosis = item;
          console.log("hellowww", finaldiagnosis);
        }
      });
      dispatch(
        doctorActions.addCaseRecord(proList, (resCb) => {
          if (resCb) {
            console.log("resCb doctor Response:", resCb);
            if (resCb.successMessages) {
              //if(finaldiagnosis != null )
              console.log("finaldiagnosis", finaldiagnosis, finaldiagnosis ? finaldiagnosis.notes : undefined);
              if (finaldiagnosis && finaldiagnosis.notes !== "" && finaldiagnosis.notes !== undefined) {
                  saveDiagnosisinEHR(finaldiagnosis);
              }

              props.onModalClose({ success: "Notes added successfully" });
            } else {
              props.onModalClose({ error: "Failed to add notes" });
            }
            console.log("resCbe : ", resCb);
          }
        })
      );
      setServerRes({ ...ServerResVal });

      setTimeout(() => {
        closeAlert();
      }, 3000);
    } 
  };

  const ChangeRevisitDate = (event) => {
    console.log("date: ", event.target.value);
    setRevisitDate(event.target.value);
  };

  return (
    <>
      <div className=" bg-none nav-wrapper h-100 d-flex flex-column service-view">
        <div className='"d-flex'>
          {/* <span
            className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
            onClick={(event) => closeServiceModal(event)}
          >
            X
          </span> */}
          <IconButton
            aria-label="close"
            onClick={(event) => closeServiceModal(event)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div
          className="w-100 service-container service-container1"
          style={{ overflowY: "auto" }}
        >
          <div className="d-flex m-3 justify-content-between px-4">
            <div className="w-100 flex-wrap flex-column">
              <Box className="row service-form">
                <div
                  id="notespadding"
                  className="pl-0  col-lg-12 col-xl-12 col-md-12 col-12"
                >
                  {showError && (
                            <div className="text-danger my-4">
                              All fields can not be empty!
                            </div>
                          )}
                  <TextField
                    fullWidth
                    className="p-2"
                    value={notes["Consultant Notes and Management Plan"]}
                    placeholder="Please Type Consultant Notes and Management Plan here"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(
                        "Consultant Notes and Management Plan",
                        event.target.value
                      );
                    }}
                    label={
                      <span>
                        Consultant Notes and Management Plan{" "}
                        {/* <span
                          className={`${classes.redAsterisk} MuiInputLabel-asterisk`}
                        >
                          *
                        </span> */}
                      </span>
                    }
                    // invalid={
                    //   formErrors["Consultant Notes and Management Plan"] === ""
                    //     ? false
                    //     : true
                    // }
                    multiline
                    minRows={2}
                    maxRows={10}
                  />
                  {/* <div style={{ color: "#EC4147" }}>
                    <span className="service-lable">
                      {formErrors["Consultant Notes and Management Plan"]}
                    </span>
                  </div> */}
                </div>

                <div
                  id="notespadding"
                  className="pl-0 col-lg-6 col-xl-6 col-md-6 col-12 "
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    value={notes["Chief Complaints"]}
                    label="Chief Complaints"
                    variant="outlined"
                    placeholder="Please Chief Complaints Type here..."
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("Chief Complaints", event.target.value);
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12 "
                >
                  <TextField
                    fullWidth
                    label="Onset"
                    className="p-2"
                    value={notes.Onset}
                    placeholder="Onset"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("Onset", event.target.value);
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12 "
                >
                  <TextField
                    fullWidth
                    multiline
                    className="p-2"
                    label=" Duration"
                    value={notes.duration}
                    placeholder="Please Type Duration here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange("duration", event.target.value);
                    }}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Please Type Progression here..."
                    className="p-2"
                    label="Progression"
                    value={notes.progression}
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("progression", event.target.value);
                    }}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0 col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  {/* <label className="service-lable">
                      Precipitating factor
                    </label> */}
                  <TextField
                    fullWidth
                    label="Precipitating Factor"
                    className="p-2"
                    value={notes["Precipitating factor"]}
                    placeholder="Please Type Precipitating Factor here..."
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(
                        "Precipitating factor",
                        event.target.value
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    value={notes["Clinical Summary"]}
                    placeholder="Please Type Clinical Summary here..."
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("Clinical Summary", event.target.value);
                    }}
                    label={
                      <span>
                        Clinical Summary{" "}
                        {/* <span
                          className={`${classes.redAsterisk} MuiInputLabel-asterisk`}
                        >
                          *
                        </span> */}
                      </span>
                    }
                    multiline
                    minRows={1}
                    maxRows={10}
                    // invalid={
                    //   formErrors["Clinical summary"] === "" ? false : true
                    // }
                  />
                  {/* <div style={{ color: "#EC4147" }}>
                    <span className="service-lable">
                      {formErrors["Clinical Summary"]}
                    </span>
                  </div> */}
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    label="Past and Treatment History"
                    value={notes["Past and Treatment History"]}
                    placeholder="Please Type Past and Treatment History here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange(
                        "Past and Treatment History",
                        event.target.value
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  style={{ float: "inline-start" }}
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    value={notes["Relevant Medical History"]}
                    label="Relevant Medical History"
                    placeholder="Please Type Relevant Medical History here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange(
                        "Relevant Medical History",
                        event.target.value
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0 col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    label="Relevant Family History"
                    value={notes["Relevant Family History"]}
                    placeholder="Please Type Relevant Family History here..."
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(
                        "Relevant Family History",
                        event.target.value
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>

                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    label="Personal History"
                    placeholder="Please Type Personal History here..."
                    className="p-2"
                    value={notes["Personal History"]}
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("Personal History", event.target.value);
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    label="Premorbid Personality"
                    multiline
                    className="p-2"
                    value={notes["Premorbid Personality"]}
                    placeholder="Please Type Premorbid Personality here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange(
                        "Premorbid Personality",
                        event.target.value
                      );
                    }}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    multiline
                    label="Psycho-social Factors"
                    placeholder="Please Type Psycho-social Factors here..."
                    className="p-2"
                    value={notes.psychosocialfactors}
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(
                        "Psycho-social Factors",
                        event.target.value
                      );
                    }}
                  />
                </div>
                <div className="w-100">
                  <label className="p-2 service-lable ml-2 mt-3">
                    Relevant Examination Findings :
                  </label>
                  <div className="d-flex justify-content-space-between">
                    <div
                      id="notespadding"
                      className="pl-0  col-lg-3 col-xl-3 col-md-3 col-12"
                    >
                      <TextField
                        multiline
                        label="Pulse"
                        className="p-2"
                        value={notes.pulse}
                        placeholder="Type here"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("pulse", event.target.value);
                        }}
                      />
                    </div>
                    <div
                      id="notespadding"
                      className="pl-0  col-lg-3 col-xl-3 col-md-3 col-12"
                    >
                      <TextField
                        multiline
                        label="BP"
                        className="p-2"
                        value={notes.bp}
                        placeholder="Type here"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("bp", event.target.value);
                        }}
                      />
                    </div>
                    <div
                      id="notespadding"
                      style={{ paddingLeft: "12px" }}
                      className="pl-1  col-lg-3 col-xl-3 col-md-3 col-12"
                    >
                      <TextField
                        multiline
                        label="Weight"
                        className="p-2"
                        value={notes.weight}
                        placeholder="Type here"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("weight", event.target.value);
                        }}
                      />
                    </div>
                    <div
                      id="notespadding"
                      style={{ paddingLeft: "12px" }}
                      className="pl-0  col-lg-3 col-xl-3 col-md-3 col-12"
                    >
                      <TextField
                        label="BMI"
                        multiline
                        className="p-2"
                        value={notes.bmi}
                        placeholder="Type here"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("bmi", event.target.value);
                        }}
                      />
                    </div>
                    {/* </Row> */}
                  </div>
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    label="Mental State Examination"
                    className="p-2"
                    value={notes["Mental State Examination"]}
                    placeholder="Please Type Mental State Examination here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange(
                        "Mental State Examination",
                        event.target.value
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                </div>
                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12"
                >
                  <TextField
                    fullWidth
                    multiline
                    className="p-2"
                    label="Provisional Diagnosis"
                    value={notes["Provisional Diagnosis"]}
                    placeholder="Please Type Provisional Diagnosis here..."
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(
                        "Provisional Diagnosis",
                        event.target.value
                      );
                    }}
                    invalid={
                      formErrors["Provisional Diagnosis"] === "" ? false : true
                    }
                  />
                </div>

                <div
                  id="notespadding"
                  className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12 pt-2"
                >
                  <TextField
                    fullWidth
                    className="p-2"
                    value={notes["Final Diagnosis"]}
                    placeholder="Please Type Final Diagnosis here..."
                    autoComplete="off"
                    type="text"
                    onChange={(event) => {
                      handleInputChange("Final Diagnosis", event.target.value);
                    }}
                    label={
                      <span>
                        Final Diagnosis{" "}
                        {/* <span
                          className={`${classes.redAsterisk} MuiInputLabel-asterisk`}
                        >
                          *
                        </span> */}
                      </span>
                    }
                    // invalid={
                    //   formErrors["Final Diagnosis"] === "" ? false : true
                    // }
                    multiline
                    minRows={1}
                    maxRows={10}
                  />
                  {/* <div style={{ color: "#EC4147" }}>
                    <span className="service-lable">
                      {formErrors["Final Diagnosis"]}
                    </span>
                  </div> */}
                </div>

                {/* <div id='notespadding' className="pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <TextField
                      fullWidth
                      className="p-2"
                      value={notes["Consultant Notes and Management Plan"]}
                      placeholder="Please Type Consultant Notes and Management Plan here"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Consultant Notes and Management Plan",
                          event.target.value);
                      }}
                      label={
                        <span>
                          Consultant Notes and Management Plan{' '}
                          <span className={`${classes.redAsterisk} MuiInputLabel-asterisk`}>*</span>
                        </span>
                      }
                      invalid={formErrors["Consultant Notes and Management Plan"] === "" ? false : true}
                      multiline
                      minRows={1}  
                      maxRows={10}
                    />
                    <div style={{ color: '#EC4147' }} >
                      <span className="service-lable">{formErrors["Consultant Notes and Management Plan"]}</span>
                    </div>
                  </div> */}

                <div
                  id="notespadding"
                  className="pl-0 col-lg-6 col-xl-6 col-md-6 col-12 "
                >
                  <label className="service-lable mb-0">Follow Up Date</label>
                  <input
                    name="scheduleddate"
                    id="scheduleddate"
                    className="form-control filterin Quantity"
                    placeholder="Enter ScheduledTime"
                    required
                    type="date"
                    readOnly={followUpDateFlag}
                    min={todayDate}
                    defaultValue={
                      patientDetail["scheduleddate"] === undefined
                        ? revisitDate
                        : patientDetail["scheduleddate"]
                    }
                    onChange={ChangeRevisitDate}
                  />
                </div>
              </Box>

              <div className="d-inline-block text-left my-4  savebutton">
                <Button
                  id="we"
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  className=" btn-primary"
                  onClick={(event) => handleSubmit(event)}
                >
                  Save
                </Button>
              </div>
              <div className="d-inline-block col-md-6 text-left my-4">
                <Button
                  id="we"
                  type="button"
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  className=" btn-primary"
                  onClick={(event) => handlePrint(event)}
                >
                  Print
                </Button>
              </div>

              {notePrint && notePrint === true ? (
                <div className="">
                  <Components.NotesPrint
                    parentCallBack={handleNotePrint}
                    patientdetails={props.patientData}
                    notes={notes}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notes;
