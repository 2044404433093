import {DISCHARGESUMMARY} from '../shared/constants/index'

function getSideBarNavItems() {
  var permittedUrls = [];
  var role = JSON.parse(localStorage.getItem("role"));
  console.log("Role from session : ", role);

  if (role) {
    // const roles = role.split(",");
    const roles = role;
//     if(!roles){
// roles = role
//     }
   var AccessLevel = JSON.parse(localStorage.getItem("AccessLevel"));
   if(AccessLevel){
    console.log("access level : ", AccessLevel)
    const access = AccessLevel.split(",");
    console.log("access level : ", access)
     for (let acclvl of access){
        permittedUrls.push(populateSideBarNavItems(acclvl));
     }
 }
    // if (roles && roles.length > 0) {
    //   console.log("roles.indexOf : ", roles.indexOf("Admin"));
    //   // if (roles.indexOf("Admin") !== -1) {
    //     // else if (roles.includes("serviceDesk") !== -1 ) {
    //     if (roles.indexOf("Admin") !== -1 ) {  
    //     console.log("Role from session : ", role);
    //     permittedUrls.push(populateSideBarNavItems("home"));
    //     permittedUrls.push(populateSideBarNavItems("admin"));
    //     permittedUrls.push(populateSideBarNavItems("form"));
    //     permittedUrls.push(populateSideBarNavItems("doctor"));
    //     permittedUrls.push(populateSideBarNavItems("service"));
    //     permittedUrls.push(populateSideBarNavItems("Patient"));
    //     permittedUrls.push(populateSideBarNavItems("Followup"));
    //     // permittedUrls.push(populateSideBarNavItems("doctorHome"));
        
    //   } else if (roles.indexOf("Doctor") !== -1) {
    //     permittedUrls.push(populateSideBarNavItems("doctorHome"));
    //   }
    //   else if (roles.indexOf("serviceDesk") !== -1 ) {
    //     permittedUrls.push(populateSideBarNavItems("home"));

    //   }
    //   else if (roles.indexOf("Nurse") !== -1 ) {
    //     permittedUrls.push(populateSideBarNavItems("patient"));

    //     console.log("Role from session of includes nurse: ", roles);
    //     // permittedUrls.push(populateSideBarNavItems("home"));
    //     permittedUrls.push(populateSideBarNavItems("admin"));
    //     //permittedUrls.push(populateSideBarNavItems("form"));
    //     // permittedUrls.push(populateSideBarNavItems("doctor"));
    //     // permittedUrls.push(populateSideBarNavItems("upload"));
    //     // permittedUrls.push(populateSideBarNavItems("Patient"));
    //   }
    // }
  }
  console.log("permitted urls : ", permittedUrls);
  return permittedUrls;
}

function populateSideBarNavItems(type) {
  var obj = {};

  switch (type) {
    case "staffAccess":
      obj["title"] = "Access";
      obj["htmlBefore"] = '<i class="material-icons">add_to_queue</i>';
      obj["to"] = "/staffList";
      obj["htmlAfter"] = "";
      break;

    case "patients":
      obj["title"] = "Patients";
      obj["htmlBefore"] = '<i class="material-icons">person</i>';
      obj["to"] = "/Home";
      obj["htmlAfter"] = "";
      break;

      case "FrontDeskPatients":
      obj["title"] = "Patients";
      obj["htmlBefore"] = '<i class="material-icons">search</i>';
      obj["to"] = "/Home";
      obj["htmlAfter"] = "";
      break; 
      
      case "InsurancePatients":
      obj["title"] = "Patients";
      obj["htmlBefore"] = '<i class="material-icons">search</i>';
      obj["to"] = "/Home";
      obj["htmlAfter"] = "";
      break; 

    case "bed":
      obj["title"] = "Bed";
      obj["htmlBefore"] = '<i class="material-icons">bed</i>';
      obj["to"] = "/Bed";
      // obj['htmlAfter'] = "";
      break;

    case "form":
      obj["title"] = "Consent Form";
      obj["htmlBefore"] = '<i class="material-icons">vertical_split</i>';
      obj["to"] = "/Forms";
      break;

    case "Doctor":
      obj["title"] = "Doctor";
      obj["htmlBefore"] = '<i class="material-icons">search</i>';
      obj["to"] = "/doctorlist";
      break;

    case "Patient":
      obj["title"] = "Patient List";
      obj["htmlBefore"] = '<i class="material-icons">note_add</i>';
      obj["to"] = "/patient";
      break;

    case "service":
      obj["title"] = "My Services";
      obj["htmlBefore"] = '<i class="material-icons">view_module</i>';
      obj["to"] = "/ServiceMaster";
      break;

    case "DashBoard":
      obj["title"] = "Dashboard";
      obj["htmlBefore"] = '<i class="material-icons">dashboard</i>';
      obj["to"] = "/Dashboard";
      obj["htmlAfter"] = "";
      break;
      
      
     
    case "admin":
      obj["title"] = "Visits";
      obj["htmlBefore"] = '<i class="material-icons">calendar_today</i>';
      obj["to"] = "/admin";
      obj["htmlAfter"] = "";
      break;

      case "TokenManagement":
      obj["title"] = "Token Management";
      obj["htmlBefore"] = '<i class="material-icons">token</i>';;
      obj["to"] = "/TokenDashBoard";
      obj["htmlAfter"] = "";
      break;

      case "MyReports":
      obj["title"] = "My Reports";
      obj["htmlBefore"] = '<i class="material-icons">summarize</i>';
      obj["to"] = "/MyReports";
      obj["htmlAfter"] = "";
      break;

      case "Appointments":
        obj["title"] = "Appointments";
        obj["htmlBefore"] = '<i class="material-icons">calendar_month</i>';
        obj["to"] = "/Appointments";
        obj["htmlAfter"] = "";
        break;

    case "Followup":
      obj["title"] = "Follow-Up";
      obj["htmlBefore"] = '<i class="material-icons">follow_the_signs</i>';
      obj["to"] = "/Followup";
      obj["htmlAfter"] = "";
      break;
      
      case "Settlements":
      obj["title"] = "Settlements";
      obj["htmlBefore"] = '<i class="material-icons">monetization_on</i>';
      obj["to"] = "/Settlements";
      obj["htmlAfter"] = "";
      break;

    case "insuranceSetup":
      obj["title"] = "Insurance-Setup";
      obj["htmlBefore"] = '<i class="material-icons">add_circle_outline</i>';
      obj["to"] = "/insuremasterlist";
      obj["htmlAfter"] = "";
      break;

    case "doctordataSetup":
      obj["title"] = "Doctor/Counsellor-Data-Setup";
      obj["htmlBefore"] = '<i class="material-icons">account_circle</i>';
      obj["to"] = "/doctorlist";
      obj["htmlAfter"] = "";
      break;

    case "tpaSetup":
      obj["title"] = "Tpa-Setup";
      obj["htmlBefore"] = '<i class="material-icons">add_to_queue</i>';
      obj["to"] = "/tpamasterlist";
      obj["htmlAfter"] = "";
      break;

      case "rooms":
      obj["title"] = "Room-Setup";
      obj["htmlBefore"] = '<i class="material-icons">bed</i>';
      obj["to"] = "/roomMasterList";
      obj["htmlAfter"] = "";
      break;
   
      case "ActiveToken":
      obj["title"] = "ActiveToken-Patients";
      obj["htmlBefore"] = '<i class="material-icons">token</i>';
      obj["to"] = "/activeTokenPatients";
      obj["htmlAfter"] = "";
      break; 
      
      case "packageSetup":
        obj["title"] = "Package-Setup";
        obj["htmlBefore"] = '<i class="material-icons">medical_services</i>';
        obj["to"] = "/packageList";
        obj["htmlAfter"] = "";
        break;
        case "Assistant":
          obj["title"] = "Assistant";
          obj["htmlBefore"] = '<i class="material-icons">note_add</i>';
          obj["to"] = "/assistant";
          break;

      // case "reports":
      // obj["title"] = "Reports";
      // obj["htmlBefore"] = '<i class="material-icons">assessment</i>';
      // obj["to"] = "/reports";
      // obj["htmlAfter"] = "";
      // break;

      // case DISCHARGESUMMARY :
      // obj["title"] = "";
      // obj["htmlBefore"] = '';
      // obj["to"] = "";
      // obj["htmlAfter"] = "";
      // break;

    default:
      break;
  }
  return obj;
}



const sideBarNavItemsServices = {
  getSideBarNavItems,
  populateSideBarNavItems,
};
export default sideBarNavItemsServices;
