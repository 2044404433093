import React, { useEffect, useState } from "react";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';


function SidebarNavItems() {
  const [navItems, setNavItems] = useState(Store.getSidebarItems());
  const onChange = () => {
    setNavItems(Store.getSidebarItems());
    console.log("nav items value : ", navItems);
  };

  useEffect(() => {
    Store.addChangeListener(onChange);
  }, []);
  
  useEffect(() => {
    Store.removeChangeListener(onChange);
  }, []);
  
 
  return (
    <div className="nav-wrapper">
      {/* <Nav className="nav--no-borders flex-column">
        {navItems.map(
          (item, idx) => (
            console.log("nav items value item : ", item),
            (<SidebarNavItem key={idx} item={item} />)
          )
        )}
      </Nav> */}
         <List className="nav--no-borders flex-column">
      {navItems.map((item, idx) => (
        <ListItem key={idx}>
          <ListItemIcon>
          </ListItemIcon>
          <SidebarNavItem item={item} />
        </ListItem>
      ))}
    </List>

    </div>
  );
}

export default SidebarNavItems;
