import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";



// import SearchPackageServiceList from './';
var showReqAppoinment = "";

function DoctorRegister(props){

const dispatch = useDispatch();    
const [packageServiceSelected,setPackageServiceSelected] = useState();
const [serviceList,setServiceList] = useState();
const [showForm, setShowForm] = useState(false);
const [packageService,setService] = useState({
  productId:'',packageid:'',packagetype:'',description:'',amount:'',billable:true
});
const [diplayServices, setDisplayServices] = useState();
const [procedure,setProcedure] = useState();

const [selectedOption, setSelectedOption] = useState(null);
const [deptId, setDeptId] = useState('');
const [options, setOption] = useState('');
const [search,SetSearch] = useState();

const handleServices = (service) => {
console.log("service selected :",service);
}
let proc ={}

    useEffect(() => {
      let data = {}
      console.log("dr action");
      console.log("props :")
    });
    const closeServiceModal = (event) =>{
        props.onModalClose(event)
      }
  
 return( 
    <>
    <div>
    <div className='border-bottom'>
          <h4 className='ml-4 pt-3'>Doctor Registration</h4> 
          {/* border-left  */}
          <span className="topright d-flex justify-content-end pl-4 pt-3 mr-4"  onClick={(event)=>closeServiceModal(event)}>X</span>       
        </div>

        
    </div>

      </>
    );
}
export default DoctorRegister;
