import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { doctorActions, patientActions } from "../../../core/actions";
import Select, { createFilter } from "react-select";
import { format } from "date-fns";
import { DateTimePicker } from "react-datetime-picker";
import Components from ".";

import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as ReactDOM from "react-dom";
import { filter } from "lodash";
import { addService } from "../../../core/actions/categoryActions";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {  InputAdornment, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

var showReqAppoinment = "";

function StaffList(props) {
  const dispatch = useDispatch();


  const [search, SetSearch] = useState();

  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [searchText, setSearchText] = useState("");

  const [searchError, setSearchError] = useState("");
  const [showList, setShowList] = useState(false);
  const [patient, setPatient] = useState({ page: 1, pageSize: 50 });
  const [staffSearchList, setStaffSearchList] = useState({});
  const [staffSearchFilterList, setStaffSearchfilterList] = useState({});
  const [addStaff, setStaff] = useState(false);

  const handleServices = (service) => {
    console.log("service selected :", service);
  };

let proc = {}

const clearSearch = (event) => {
  setSearchText("");
}
useEffect(() => {
  let data = {}
  console.log("dr action");
  console.log("props :");
  let type = "staff";
  
  dispatch(
    doctorActions.getStaffList(resCb => {
      if (resCb) {
        console.log(
          "doctor search resCb name in patient list : ",
          resCb.accresp.personList
        );
         const filteredList = resCb.accresp.personList.filter(person => (
         person.type != "doctor" && person.type !="counsellor"));
         console.log("filterdlist: ",filteredList );
       //if(resCb.accresp.personList.type!="Doctor"||resCb.accresp.personList.type!=counsellor){
          setStaffSearchList(filteredList);
          setStaffSearchfilterList(filteredList);
        //}filteredList
        console.log("response from api : ", resCb);
        console.log("StaffSearchfilterlist",staffSearchFilterList);
        console.log("setStaffSearchList",staffSearchList);

      }
    })
  );

  
  

  
}, []);
const closeServiceModal = (event) => {
    props.onModalClose(event);
  };
   const handleClick = (pDetail, isAddNew) => {
  console.log("props.history", props);
  props.history.push("/addstaff");
};

const handleInputChange = (event) => {
  let emptymsg = {};
  // setMessageDetails({message: null});
  let value = event.target.value;
  validateAndChange(value);
  // sessionStorage.setItem("searchvalue", JSON.stringify(value));
  console.log("value...", value);
};

const validateAndChange = (value) => {
    let nullarray = [];

    if (value.length < 3) {
        setSearchText(value);
      setSearchError("Search Text should contain atleast 3 letters");
      setShowMsgStrip(false);
      setStaffSearchfilterList(staffSearchList);
    } 

if (!value) {
    console.log(
        "staffSearchList null array !value : ",
        value,
        staffSearchList
      );
      setShowMsgStrip(false);
      setStaffSearchfilterList(staffSearchList);
    }
    if (value.length >= 3) {
        setSearchError("");
        setSearchText(value);
        // handleSearch(value);
        setShowList(true);
        let search = { patient: value };
        console.log("search input : ", value);
        searchStaffResult(value);
      }
    };


    const searchStaffResult = (data) => {
        console.log("data searched ", data);
        setMessageDetails({ message: "" });
        let filteredData = staffSearchList.filter(e => e.first_name.toLowerCase().indexOf(data.toLowerCase()) >= 0);
    
        setStaffSearchfilterList(filteredData);
    
        console.log("filteredData", staffSearchFilterList);
    
        return staffSearchFilterList;
        // doctorSearchFilterList=filteredData;
    };
    const closeModal = (data) => {
        //saving the form doctor data and closing the modal
        console.log("data ::", data);
        setStaff(!addStaff);
    };
    
    const callBackGrid = (data) => {
      console.log("datadata", data);
      let type = "staff";
      dispatch(doctorActions.getStaffList((resCb) => {
        if (resCb) {
          console.log("response from api : ", resCb);
          console.log(
            "staff search resCb name in patient list : ",
            resCb.accresp.personList
          );
    
          let personList = resCb.accresp.personList;
          let staffList = [];
    
          for (let i = 0; i < personList.length; i++) {
            if (personList[i].type !== 'doctor' && personList[i].type !== 'counsellor') {
              staffList.push(personList[i]);
              console.log("staff:", staffList);
            }
          }
    
          setStaffSearchList(staffList);
          setStaffSearchfilterList(staffList);
        }
      }));
    };

    
    
return (

    <>
    <div className="p-3"> 
     
      <div className="listTitles">

      <Grid item xs={12} 
    className="page-header mb-3 doctorList">
         <h5
            // subtitle="Dashboard"
            className="text-sm-left mb-0"
          >
            Staff
          </h5>
          <Button 
          variant="contained" 
          className="floatingbtn"  
          onClick={(event) => handleClick("", true)}
          style={{ textTransform: "none" }}>
        Add New
      </Button>

    </Grid>

      </div>
      <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
        <div className="input-group input-group-seamless">
          <div className="input-group-prepend mb-2">
            <span className="input-group-text">
              <i style={{ color: `#111b21` }} className="material-icons">
                search
              </i>
            </span>
          </div>
          {/* <FormInput
            className="navbar-search mb-2 menu-search bg-white"
            placeholder="Search Staff..."
            value={searchText}
            type="search"
            onChange={handleInputChange}
          /> */}
             <TextField 
               className="navbar-search mb-2 menu-search bg-white custom-text"
               placeholder="Search Staff..."
               autoFocus
               value={searchText}
               type="search"
               onChange={handleInputChange}
               InputProps={{
                style: { paddingLeft: '15px',height:"35px"}, 
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && ( // Render the close icon only if searchText exists
                      <IconButton edge="end" onClick={clearSearch} tabIndex={-1} 
                      size="small" 
                style={{ padding: '6px' }}
                >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
               />

        </div>
      </form>


      {staffSearchFilterList && staffSearchFilterList.length>0 && 
        <Components.StaffListGrid
        staffSearchList={staffSearchFilterList}
        onGridAction={(event) => callBackGrid(event)}
      />
      }
        {addStaff && (
          <div>
            {/* <Components.addDoctor onModalClose={(event) => closeModal(event)} /> */}
          </div>
        )}
      </div>
    </>
  );
      



      {/*   <Components.AddStaff onModalClose={(event) => closeModal(event)} /> */}


    
}
export default StaffList;
