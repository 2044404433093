import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Grid } from '@mui/material';
import { Paper } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { Alert} from "@mui/material";
import { patientActions,doctorActions } from "../../core/actions";
import { connect } from "react-redux";
import { getRoles } from "@testing-library/react";
import { DOCTOR_ROLE,NURSE_ROLE, RESET } from "../../shared/constants/index"
import { useDropzone } from "react-dropzone";
import MessageStrip from '../../shared/messagestrip/MessageStrips';
import Components from '..';
import { helperServices } from "../../shared/helper";
import { JsonForms } from "@jsonforms/react";
export const GET_UPLOAD_PATH = '/uploads/Hospital/';


function Attachments(props) {
  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});

  const [flieUploadMsg, setFlieUploadMsg] = useState("");
  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [displayImage, setDisplayImage] = useState({});
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [showUploadPreview, setShowUploadPreview] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const dispatch = useDispatch();
  const [viewAttachments, setviewAttachments] = useState(true);
  const [reload,setReload]= useState(false);
  const [preview,setpreview]= useState(false);
  const [imageData,setImageData] = useState();

  //true or false based on upload by doctor or adm8in
  const selectedFilesForUpload = useSelector((state) => state.patientDetailsState.procedureuploadinfo);
  const patientProceduresList = useSelector(state => state.doctorDetailsState.patientprocedurelist);
  const [uploadedFiles,setSelectedFiles] = useState();
  
  
  let role = '';
  useEffect(() => {
    console.log("entered use effect ")
    console.log("props.insurancelistupload",props.InsuranceList);

    console.log("patient details in attachment",props)
    role = localStorage.getItem("role");
    console.log("role:", role);
    setUploadedImages();
    // if (role === '"Doctor"' ) { 
    //   setShowUploadPreview(true);
    // } 
    // else if(role === "Admin" || role === "InsuranceDesk") {
    //   setviewAttachments(true);

    // }else
    //  {
    //   setShowUploadPreview(false);
    // }
   
    

      

     if(helperServices.CheckAccessLevel("Patient") || helperServices.CheckAccessLevel("Assistant")){
      console.log("console in if condition");
      setShowUploadPreview(true);
    }
    else if (helperServices.CheckAccessLevel("patients") || helperServices.CheckAccessLevel("InsurancePatients")) {
      console.log("console in else if condition");
      setShowUploadPreview(true);
    }
    else {
      console.log("console in else condition")
      setShowUploadPreview(false);
    }

    
    var index = 0;
    let images = [];
    console.log("viewattachemnts ", viewAttachments);
    console.log("showUploadPreview",showUploadPreview);
    console.log("selectedFilesForUpload :1", selectedFilesForUpload);
    console.log("patientProceduresList.uploadslist:",patientProceduresList.uploadslist);
    
    //Displaying insurance upload documents
    if(props.uploadType && props.uploadType == 'insurance'){
      let insuranceupload = props.InsuranceList ? props.InsuranceList.filter((item) => item.imgurl != null) : [];
      console.log("uploaded url of insurance:",insuranceupload);
      setSelectedFiles(insuranceupload)
      setReload(!reload);
       }

       else {
    let uploads = patientProceduresList.uploadslist ? patientProceduresList.uploadslist.filter((item) => item.imgurl != null) : [];
    console.log("filtred images:",uploads);
    setSelectedFiles(uploads)
    setReload(!reload);
    }

    if (selectedFilesForUpload && selectedFilesForUpload.length>0 && selectedFilesForUpload.at(0) != null ) {

    for (let image of selectedFilesForUpload) {
      if (image!=null && !image.imageresult) {
        
        image = readToDisplayImage(image, index);
      }
      console.log("index:",index);
      if (index == 0) {
        selectedImageDisplay(image, 0);
        //setDisplayImage({image:image,index:index});
        if(!helperServices.CheckAccessLevel("Patient") && !helperServices.CheckAccessLevel("Assistant")){
          setShowUploadPreview(true);
          setReload(!reload);
        }
     
      }
      console.log("image :", image);
      images.push(image);
      index = index + 1;
    }
    
  } else{
    setDisplayImage({...""});
  }
    setUploadedImages(...images);
    console.log("selectedFilesForUpload useeffect 53:", selectedFilesForUpload);
  }, [uploadedImages,selectedFilesForUpload,patientProceduresList]);


  
  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles:",acceptedFiles);
    let images = []
    console.log("Images list 1: ",images);
    images = [selectedFilesForUpload]
    console.log("Images list 2: ",images);
    
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
      console.log("file :", file);
      console.log("acceptedFiles :", acceptedFiles);
      console.log("reader :", reader);
      // uploadedImages.push(...file);
      // dispatchsetUploadedFiles
      images.push(file)
      console.log("Images list 3: ",images);
    });
    setUploadedImages([...images]);
    dispatch(patientActions.setUploadedFiles([...uploadedImages]));
    console.log("uploadedImages 1:", uploadedImages);
  }, []);
  
  // dispatch(patientActions.setUploadedFiles({...uploadedImages}));
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const selectedImageDisplay = (image, index) => {
      setDisplayImage({...{ image: image, index: index }});
      console.log("DisplayImage selected : ", displayImage);

  };

  // const updateSelectFile = (image,typ) => {
  //   if (typ == true) {
  //     uploadedImages.push(...image)
  //     dispatch(patientActions.setUploadedFiles(uploadedImages))
  //   } else {c
  //     // if (image.index==typ) {
  //       image = '';
  //       return image;
  //     // }
  //   }
  // }

  const addfile = (event) => {
  console.log("add files is called ", event, "event.target.file : ",event.target.files);
  event.preventDefault();
  //console.log('state.patientDetailsState.procedureuploadinfo', patientDetailsState.procedureuploadinfo);
    console.log(selectedFilesForUpload)
  let images = [{}]
  if (event.target.files && event.target.files.length>0) {
  
    images = [...selectedFilesForUpload]
    //let eventFileList = [{}]
  let eventFileList = event.target.files;
  console.log("eventFileList 117 : ",eventFileList);

  console.log("selectedFilesForUpload : ",images);
  for (let selectedImage of eventFileList){
    images.push(selectedImage)
}
  console.log("selectedFilesForUpload : ",images);

    setUploadedImages(images)
    dispatch(patientActions.setUploadedFiles([...images]))
    console.log("uploadedImages :",uploadedImages);
  // }, 200);
  console.log("selectedFilesForUpload 131 : ", selectedFilesForUpload);
  setShowUploadPreview(true);
}
}



  const readToDisplayImage = (image, index) => {
    var file = image;
    var reader = new FileReader();
    var url = null;
    // reader.readAsDataURL(file);
    var objurl = URL.createObjectURL(file);
    var stringObj = JSON.stringify(objurl)
    console.log("objurl formed:",objurl,stringObj);
    const blob = fetch(image).then((res) => res.blob());
      console.log("selfie blob : ",blob,blob.Blob);
      const formData = new FormData();
      formData.append('image',blob)
      formData.append('imageUrl',objurl)
    // setTimeout(() => {
    image["imageresult"] = objurl;
    image["imageFormData"] = formData;
    console.log("reader :", reader, "reader.result :", reader["result"]);
    console.log("url :", url, " objurl :", objurl);
    console.log("image : ", image);
    image["index"] = index;
    return image;
    // }, 50);
  };

  const readFile = (e) => {
      console.log("after fileData", fileData);
    setTimeout(() => {
      setFileData({ ...e.target.files[0] });
      console.log("after fileData", fileData);
    }, 500);
    setFlieUploadMsg("");
    setFileData({ ...e.target.files[0] });
    setFile(URL.createObjectURL(e.target.files[0]));
    setFiles([...files, e.target.files[0]]);
    var ServerResVal = ServerRes;
    console.log("fileData", fileData);
    console.log("e.target.files[0]", e.target.files[0]);
    console.log("fileData", files);
    if (fileData !== null) {
      dispatch(
        patientActions.uploadAttachments(fileData, (resCb) => {
          if (resCb) {
            console.log("ResCb-File", resCb.state, resCb.message);
            if (resCb.state === "success") {
              ServerResVal.success = resCb.message;
            } else if (resCb.state === "error") {
              ServerResVal.error = resCb.messages.message;
            }
            setServerRes({ ...ServerResVal });
          }
        })
      );
    }
  };

  const viewSelectedImage = (img) => {
    console.log("viewSelectedImage:",img);
  }

  const handleRemoveFile = (index) => {
    // var items = files;
    console.log("index to remove:",index);
    var items = selectedFilesForUpload;
    console.log("items 192:",items);
    items.splice(index, 1);
    console.log("items 194:",items);
    dispatch(patientActions.setUploadedFiles([...items]))
    setFiles([...items]);
    setUploadedImages([...items]);
    if(index == 0) {
      setSelectedFiles([]);
    }
    

    if(items.length == 0 && (!helperServices.CheckAccessLevel("Patient")) && (!helperServices.CheckAccessLevel("Assistant"))){
      console.log('entered 2nd if in removefile')
      setviewAttachments(true);
      setReload(!reload);
    }
  };

  const onSubmit = (event) => {
    var ServerResVal = ServerRes;
    console.log('selectedFilesForUpload: ', selectedFilesForUpload)
    if (selectedFilesForUpload && selectedFilesForUpload.length> 0 ) {
      let files = []
      files ["fileData"] = selectedFilesForUpload
      if (props && props.patientDetail) {
        if (props.patientDetail.id) {
          files ["id"] = props.patientDetail.id
        }
        if (props.patientDetail.visit && props.patientDetail.visit.id) {
        files ["visitId"] = props.patientDetail.visit.id
        }
        else {
          files ["visitId"] = props.patientDetail.visitId
        }
          files["typeOf"] = props.uploadType;
        
      }

      console.log('selectedFilesForUpload: ', selectedFilesForUpload)
      
      


      files ["type"] = ""
      console.log("onSubmit 213 :",files) ;  
      dispatch(patientActions.uploadFile(files, resCb => {
        //  props.uploadFile(fileUploadData, resCb => {
          if(resCb && resCb.messageStrip && resCb.state == "success"){
            //   setState({filePath:resCb.response.path})
            //   setState({flieUploadMsg:'File uploaded successfully'})
            // closeModal();
            console.log("UploadId is:", resCb.uploadIdList);
            if (resCb.state === "success") {
              ServerResVal.success = "File uploaded successfully";
            } else if (resCb.state === "error") {
              ServerResVal.error = "Failed to upload file";
            }
            setServerRes({ ...ServerResVal });
             
            dispatch(patientActions.setUploadedFiles(RESET));

            let type = "";
            if((!helperServices.CheckAccessLevel("Patient")) && (!helperServices.CheckAccessLevel("Assistant"))) {
              console.log(props)
              dispatch(
                patientActions.getPatientProcedureInfo(props.patientDetail.id, props.patientDetail.visitid, type,(resCb) => {
                  if (resCb) {
                    console.log("procedure list in api: ", resCb);
                    dispatch(doctorActions.setPatientProcedureList(resCb.patientresp));
                    let uploads = resCb.patientresp.uploadslist ? resCb.patientresp.uploadslist.filter((item) => item.imgurl != null) : [];
                    console.log("patientProceduresList after upload:",uploads);
                    setSelectedFiles(uploads)
                    setviewAttachments(true);
                    setDisplayImage({...""});
                    setReload(!reload); 
                  }
                })
              ); 
            }
          
            props.openAttachment({success:"File uploaded successfully"});
            console.log('selectedFilesForUpload: ', selectedFilesForUpload)
          }
          else{

            props.openAttachment({error:"Failed to upload file"});
            setShowMsgStrip(true);
          // setMessageDetails({message: resCb.messageStrip});
            setMessageDetails({error: 'error',
            message: resCb.messageStrip});
            // setTimeout(() => {
              //  setState({flieUploadErrorMsg:'Uploaded rough sheets will no more be accepted.'})
              console.log("Failed to upload file",resCb)
              // }, 2000);
              console.log('selectedFilesForUpload: ', selectedFilesForUpload)
            }

            console.log('selectedFilesForUpload: ', selectedFilesForUpload)
          }))
        }
    }

    const closeAttachment =() => {
      setUploadedImages(null)
      dispatch(patientActions.setUploadedFiles(RESET));
      props.openAttachment();
    }
    const closeMessageStrip=() => {
      console.log("closing msg strip fn in patientlist");
         setShowMsgStrip(!showMsgStrip);
     }
    
    const viewImage =(data) =>{
      console.log("data:",data);
      setImageData(data);
      setpreview(true);
    }
    const closeModal = (event) => {
      setpreview(false)
      //console.log(preview)
      
    setUploadedImages(null)
    dispatch(patientActions.setUploadedFiles(RESET));
    console.log("uploaded images : ", uploadedImages, event)
      props.openAttachment(event);
      console.log(props)
    }
  const toggleAttachments = () =>{
    setviewAttachments(!viewAttachments);
    setShowUploadPreview(true);
    setReload(!reload);

  }

  const addSelecetedFiles =(file) => {
    console.log("file selection from admin:",file);
  }

  const closeAlert = (e) => {
    setServerRes({...""});
}
     
  return (

    <>
      <div className="d-flex">                  
                    <span className="topright d-flex justify-content-end pl-4 pt-3 mr-4"  onClick={(event)=>closeModal(event)}>X</span>       
      </div>
       <main key={reload}>    
       {ServerRes.success && (
                  <Alert severity="success" className="alert-div">
                      {ServerRes.success}{" "}
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={(event) => closeAlert(event)} 
                      >
                      <CloseIcon fontSize="inherit" />
                      </IconButton>
                </Alert>

                  )}
                  {ServerRes.error && (
                    <Alert severity="error" className="alert-div">
                          {ServerRes.success}{" "}
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={(event) => closeAlert(event)}  
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                   </Alert>
                  )}
        {(!helperServices.CheckAccessLevel("Patient") && (!helperServices.CheckAccessLevel("Assistant"))) && 
       <h5 className="">Attachments:
             {(!helperServices.CheckAccessLevel("InsurancePatients")) || (helperServices.CheckAccessLevel("InsurancePatients")) 
            && props.uploadType == 'insurance' ? 
            <span className="ml-3"  onClick={toggleAttachments} style={{cursor:'pointer'}} >
                <i className="material-icons" title={viewAttachments ? 'Edit' : 'View'}>{viewAttachments ? 'edit' : 'visibility'}</i>
            </span>
             :'' } 
        </h5>
        }
       {viewAttachments && (!helperServices.CheckAccessLevel("Patient")) && (!helperServices.CheckAccessLevel("Assistant"))? 
          <div>
         
            <div className="border p-4 card">
            <div className="images-container">
              <div className="d-flex justify-content-center h-100"  style={{'background':'none'}}>
              {(uploadedFiles && uploadedFiles.at(0)!=null && uploadedFiles.length > 0) ? 
                
                <div className="d-flex">
                  {
                    uploadedFiles.map((SelectedImages, index) => (
                      
                      <div
                        className={displayImage.index ==index ? "position-relative img-block active w-100" : "position-relative img-block w-100"}
                        onClick={(event) =>
                          viewImage(index)
                        }
                      >
                        {(SelectedImages && SelectedImages!=null)&& (
                        
                        <img 
                          src={GET_UPLOAD_PATH + SelectedImages.imgurl}
                          className="join-meeting-icon"
                          style={{ width: `100%`, height: `100px` }}
                        ></img>)}
                      </div>
                    ))} 
               
                </div>
                :
                <div className="h-100 d-flex justify-content-center p-3">No Uploaded files yet</div>
                }
            
              </div>
            
            </div>
            </div>

            {preview &&
             <Components.PrescriptionLayoutModel
             data = {imageData}
             uploadedImages = {uploadedFiles}
             onModalClose={(event) => closeModal(event)}/>
             }
          </div>
          :
        <>
      {!showUploadPreview ? (
        <List component="nav" aria-label="file list" className="mb-3 d-none">
      <ListItem>
        <Paper className="p-3">
          <Grid container>
            <Grid item xs={12}>
              <form>
                <Grid container>
                  <Grid item xs={12} className="form-group">
                    <label htmlFor="fname">Upload Files</label>
                    <TextField
                      type="file"
                      accept="file/*"
                      onChange={(event) => {
                        readFile(event);
                      }}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </Grid>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell md="9" className="mb-2">
                        Files
                      </TableCell>
                      <TableCell md="3" className="mb-2">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files
                      ? files.map((file, index) => (
                          <TableRow key={index}>
                            <TableCell md="9" className="mb-2">
                              {file.name}
                            </TableCell>
                            <TableCell md="3" className="cpointer mb-2">
                              <IconButton
                                title="Delete form"
                                onClick={(event) => handleRemoveFile(index)}
                              >
                                delete
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </ListItem>
    </List>
      ) : ( 
       <>
        
        <div className={(!helperServices.CheckAccessLevel("Patient")) && (!helperServices.CheckAccessLevel("Assistant")) ? "h-100 p-4 border card": "h-100 p-4" } >
          <div className="h-100 d-flex justify-content-center file-upload">
            <div className="d-flex flex-column justify-content-between h-100 selected-image-container align-items-center">
              <div className="w-100 d-flex justify-content-between tools">
               {(JSON.parse(localStorage.getItem("role")) === DOCTOR_ROLE) || (JSON.parse(localStorage.getItem("role")) === NURSE_ROLE) && (
               <div>
                  <i className="material-icons close-attach" onClick={(event) => closeAttachment(event)}>
                    close
                  </i>
                </div>)}
                <div></div>
                <div></div>
              </div>
              <div className={(!helperServices.CheckAccessLevel("Patient")) && (!helperServices.CheckAccessLevel("Assistant")) ?"doc-selcted" : "doc-selcted minHeight" }>
                <div className="h-100">
                  {displayImage && displayImage.image ? (
                    <img
                      src={displayImage.image.imageresult}
                      className="join-meeting-icon"
                      style={{ width: `100%`, height: `300px` }}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* }
                   </div> */}
              {(JSON.parse(localStorage.getItem("role")) === DOCTOR_ROLE) && (
              <div className="text-input mx-5 mt-2">
                <form className=" w-100 d-md-flex d-lg-flex">
                  <div className="input-group input-group-seamless">
                  <TextField
                        placeholder="Type a message"
                        type='text'
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        style={{backgroundColor:'#fff'}}
                      />
                  </div>
                </form>
              </div>
              )}
            </div>
            <div className="images-container">
              <div className="d-flex justify-content-center">
              {(selectedFilesForUpload && selectedFilesForUpload.at(0)!=null && selectedFilesForUpload.length > 0) && (
                
                <div className="d-flex">
                  {
                    selectedFilesForUpload.map((SelectedImages, index) => (
                      <div
                        className={displayImage.index ==index ? "position-relative img-block active" : "position-relative img-block"}
                        onClick={(event) =>
                          selectedImageDisplay(SelectedImages, index)
                        }
                      >
                        <span className="remove-img" >
                          <i className="material-icons close-attach"  onClick={(event) => handleRemoveFile(index)}>close</i>
                        </span>
                        {(SelectedImages && SelectedImages!=null)&& (
                        <img
                          src={SelectedImages.imageresult}
                          className="join-meeting-icon"
                          style={{ width: `100%`, height: `52px` }}
                        ></img>)}
                      </div>
                    ))} 
               
                </div>
                )}
                <button
                  className="ml-2" 
                  title="Add file"
                  type="button"
                  aria-label="Add file"
                  // onClick={(event) => addfile(event)}
                >
                    {/* onClick={handleRemoveFile()} */}
                  <span className="add-file" >
                   
                   {/* <i className="material-icons close-attach" onClick={(event) => addfile(event)}>add</i> */}
                    <svg viewBox="0 0 24 24" width="24" height="24" className="position-absolute"  >

                      <path
                        fill="rgb(84, 101, 111)"
                        d="M20 13.5h-6.5V20h-2.9v-6.5H4v-2.9h6.5V4h2.9v6.5H20v3z"
                      >
                      </path>
                      {/* <input className="w-100 h-100" style={{opacity : `0`}} type="file" onClick={(event) => addfile(event)} multiple/> */}
                      
                    </svg>
                  <input className="w-100 h-100" style={{opacity : `0`}} type="file" onChange={(event) => addfile(event)} multiple/>
                  </span>
                </button>
                {/* </div> */}
              </div>
              <div className="text-right" onClick={(event) => onSubmit(event)}>
                <span title="Upload" className="material-icons">
                  file_upload
                 
                </span>
                
              </div>

              
            </div>
          </div>
         
        </div>
        
        </>
      )}

      {showMsgStrip ?
                <MessageStrip
                msgStrip = {messageDetails}
                closeMessageStrip={(event) => closeMessageStrip(event)}
                />
                  :''}
      </>
      }
      </main>
    </>
  );
}

/* function mapStateToProps(state) {
  return {
    patient: state.patientDetailsState.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadAttachments: (data, resCb) =>
      dispatch(patientActions.uploadAttachments(data, resCb)),
  };
}

const AttachmentsWithProps = connect(
  mapStateToProps,
  mapDispatchToProps
)(Attachments); */

export default Attachments;
