import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect } from "react";
import { MANASWINI_LOGO, MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";

// import {qz} from "../common/qz-tray";

function MergePdf (props) {
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();
  const suffix = getNumberWithSuffix(day);
  const formattedDate = `${suffix} ${month} ${year}`;
  const qz = require("qz-tray");
  
      
  
    useEffect(() => {
        console.log("Props in merge pdf is",props);
        formatData()
        let age = props.tokenDetails.age>0 ? props.tokenDetails.age : '';
        let gender = props.tokenDetails.gender  ? props.tokenDetails.gender : '';
        createRegCard(props.patientDetails,age,gender)
        // const script = document.createElement('script');
        // script.src = 'src/components/common/qz-tray.js';
      }, []);

      function getNumberWithSuffix(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const suffix = number % 100;
        return number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0]);
      }

      const formatData= ()=>{
        let age = props.tokenDetails.age>0 ? props.tokenDetails.age : '';
        let gender = props.tokenDetails.gender  ? props.tokenDetails.gender : '';
        let deskString = props.tokenDetails.deskNumber;
        const deskNo = deskString.replace(/\D/g, "");
        // createToken(age,gender);
      }

      function createRegCard(patientDetails,age,gender) {
        const docDefinition = {
            pageSize: 'A4',
             
            
            defaultStyle: {
              fontSize: 12,
            },
            // background: {
            //   image: MANASWINI_BACKGROUND,
            //   width: 1000,
            //   height: 600,
            //   color: '#ff0000'
            // },
            pageMargins: [2, 2, 2, 2],
            content: [
             
              {
                columns: [
                  // {width: '50%'},
                  {
                    image: MANASWINI_LOGO,
                    fit: [50, 50], // set the width and height of the image
                    alignment: "left",
                    margin: [20, 18, 0, 0],
                    width: 50, // set the top and bottom margin of the image
                  },
                  [
                    {
                      text: HOSPITAL_NAME,
                      style: "header",
                      margin: [30, 30, 0, 0], 
                    },
                    {
                      stack: [
                        {
                          text: HOSPITAL_DESC,
                          style: "subheader",
                          margin: [30, 5, 0, 0], 
                        },
                        
                      ],
                      
                    },
                    {
                      text: "Patient Name: "+ patientDetails.fname+" "+patientDetails.mname+" "+patientDetails.lname,
                      style: "heading",
                      width: "auto",
                      alignment:'left',
                      margin: [30, 15, 0, 0], 
                    },
                    {
                      text: "Patient ID: "+patientDetails.id,
                      style: "heading",
                      width: "auto",
                      alignment:'left',
                      margin: [30, 5, 0, 0], 
                    },
                    {
                      text:"Age: "+(patientDetails.age > 0 ? patientDetails.age : ' -'),
                      style: "heading",
                      width: 90,
                      alignment:'left',
                      margin: [30,5, 0, 0], 
                    },
                    {
                      text:"Gender: "+(patientDetails.gender ? patientDetails.gender : ' -'),
                      style: "heading",
                      width: 90,
                      alignment:'left',
                      margin: [30,5, 0, 0], 
                    },
                    {
                      text: "Phone Number: "+(patientDetails.phno ? patientDetails.phno : ' -'),
                      style: "heading",
                      width: "auto",
                      alignment:'left',
                      margin: [30, 5, 0, 0], 
                    },
                   {
                      text: HOSPITAL_NAME,
                      alignment: "left",
                      fontSize: 10,
                      color: "#176fad",
                      bold: true,
                      margin: [25, 20, 0, 0],
                    },
                    {
                      text: HOSPITAL_DESC,
                      alignment: "left",
                      fontSize: 6,
                      color: "#176fad",
                      bold: true,
                      margin: [25, 0, 0, 0],
                    },
                    {
                      text: HOSPITAL_ADDRESS,
                      alignment: "left",
                      fontSize: 6,
                      margin: [25, 0, 0, 0],
                    },
                    {
                      text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
                      alignment: "left",
                      fontSize: 6,
                      margin: [25, 0, 0, 5],
                    },
                  ],
                  
                  {
                    image: MANASWINI_LOGO,
                    fit: [50, 50], // set the width and height of the image
                    alignment: "left",
                    margin: [10, 18, 0, 0],
                    width: 50, // set the top and bottom margin of the image
                  },
                  [
                    {
                      text: HOSPITAL_NAME,
                      style: "header",
                      margin: [20, 30, 0, 0], 
                    },
                    
                    {
                      stack: [
                        {
                          text: HOSPITAL_DESC,
                          style: "subheader",
                          margin: [20, 5, 0, 0], 
                        },

                      ],
                      
                    },
                    {
                      text: "Token ID: "+ (props.tokenDetails.tokenNumber ? props.tokenDetails.tokenNumber : ' -'),
                      style: "heading",
                      width: "auto",
                      alignment:'center',
                      margin: [-70, 15, 0, 0], 
                    },
                    {
                      text: "Desk Number: "+ (props.tokenDetails.deskNumber ? props.tokenDetails.deskNumber : ' -'),
                      style: "subtext",
                      width: "auto",
                      alignment:'center',
                      margin: [-70, 5, 0, 0], 
                    },
                    {
                      text:"Patient Name: "+ props.tokenDetails.fname+ " "+ (props.tokenDetails.lname ? props.tokenDetails.lname : ' -'),
                      style: "subtext",
                      width: 90,
                      alignment:'center',
                      margin: [-70, 5, 0, 0]
                    },
                    {
                      text: "Issue Date: "+(formattedDate ? formattedDate : ' -'),
                      style: "subtext",
                      width: "auto",
                      alignment:'center',
                      margin: [-70, 5, 0, 0]
                    },
                    {columns :[
                    {
                      text: "Age: "+(age ? age : ' -'),
                      style: "subtext",
                      margin: [50, 5, 0, 0], 
                    },
                    {
                      text: "Gender: "+ (gender ?gender :' -') ,
                      style: "subtext",
                      margin: [-40, 5, 0, 0], 
                    },]},
                    {
                      text: HOSPITAL_NAME,
                      alignment: "left",
                      fontSize: 10,
                      color: "#176fad",
                      bold: true,
                      margin: [25, 20, 0, 0],
                    },
                    {
                      text: HOSPITAL_DESC,
                      alignment: "left",
                      fontSize: 6,
                      color: "#176fad",
                      bold: true,
                      margin: [25, 0, 0, 0],
                    },
                    {
                      text: HOSPITAL_ADDRESS,
                      alignment: "left",
                      fontSize: 6,
                      margin: [25, 0, 0, 0],
                    },
                    {
                      text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
                      alignment: "left",
                      fontSize: 6,
                      margin: [25, 0, 0, 5],
                    },
                  ],
                ],
              },
              
           
              [props.barcode[0],props.barcode[1],props.barcode[2],props.barcode[3],props.barcode[4],props.barcode[5]],

            ],
            styles: {
              header: {
                fontSize: 10,
                bold: true,
                color: "#176fad",
              },
              subheader: {
                fontSize: 7,
                bold: true,
                color: "#176fad",
              },
              heading:{
                fontSize: 10,
                bold: true,
                textTransform: 'capitalize'
              },
              subtext:{
                fontSize: 9,
              }
            },
            
          };
      
     // const combinedPrints = [docDefinition, docDefinition1];
      // PdfPrinter.createPdf({ content: combinedPrints }).open();
        // PdfPrinter.createPdf(docDefinition).open();
      //  <script type="text/javascript" src = "src/components/common/qz-tray.js">
        // const qz = window.qz;
        // var config = qz.configs.create("Printer Name");
        // qz.websocket.connect().then(() => {
        //   console.log('Connected to QZ Tray');
        // });
      // //  </script>
      let pdf;
      // PdfPrinter.createPdf(docDefinition).open(); 
      const pdfDocGenerator = PdfPrinter.createPdf(docDefinition);
      pdfDocGenerator.getBase64((data) => {
        pdf = data;
      });
      console.log("pdfdocument is" ,pdfDocGenerator)
      qz.websocket.connect().then(() => {
        console.log("connecting");
        return qz.printers.find();
    }).then((printers) => {
        console.log(printers);
        let config = qz.configs.create(printers[0]);
        console.log("config is" , config);
        return qz.print(config, [{
            type: 'pixel',
            format: 'pdf',
            flavor: 'base64',
             data:pdf,
           // data: '<h1>Hello JavaScript!</h1>'
        }]);
    }).then(() => {
        //return qz.websocket.disconnect();
    }).then(() => {
        // process.exit(0);
    }).catch((err) => {
        console.error(err);
        // process.exit(1);
    });

//     var config = qz.configs.create("DeskJet-1200-series");
// var data = [{
//    type: 'pixel',
//    format: 'html',
//    flavor: 'file', // or 'plain' if the data is raw HTML
//    data: 'assets/html_sample.html'
// }];
// qz.print(config, data).catch(function(e) { console.error(e); });
    
      
       }

      return (
        <>
        
        </>
      );
}

export default MergePdf;