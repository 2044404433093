import {FORM_DETAILS} from "../../shared/constants";
    
    const formDetailsState = {
        formDetail: {}
    };
    
    function setFormDetailsState(state = formDetailsState, action) {
        switch (action.type) {
            case FORM_DETAILS:
                return Object.assign({}, state, {
                    formDetail: action.payload
                });
            
            default:
                return state;
        }
    }
    export default setFormDetailsState;