import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Components from "../index";
import { useHistory } from "react-router-dom";
import { patientActions } from "../../core/actions";
import { Grid, Typography, FormControl, Select, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
function Payment(props) {
  var history = useHistory();
  var paymentLists = [];
  const dispatch = useDispatch();
  const [paymentOption, setPaymentOption] = useState("card");
  const [upipaid, setUpiPaid] = useState(0);
  const [creditPaid, setCreditPaid] = useState(0);
  const [cashPaid, setCashPaid] = useState(0);
  const [remaing, setRemaing] = useState();
  const [payType, setPayType] = useState("");
  const [errors, setErrors] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const cardPay = useSelector((state) => state.patientDetailsState.cardpayment);
  const cashPay = useSelector((state) => state.patientDetailsState.cashpayment);
  const upiPay = useSelector((state) => state.patientDetailsState.upipayment);
  const patient = useSelector(state => state.patientDetailsState.patient);
  const [paymentDetails, setPaymentDetails] = useState({
    customerName: props.match.params.name,
    totalAmount: 0,
    paymentType: paymentOption,
    remainingAmount: 0,
    patientId: props.match.params.patientId,
    executive: localStorage.getItem("name"),
    paidAmount: 0,
    // cashAmount:0
  });
  const [voucherList, setVoucherList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Default');
  const [remainingamount,setRemainingamount]=useState();
  const [totalamount,setTotalamount]=useState();
  const optionList = [
    // Assuming you have a optionList array
    { label: 'Default', value: 'Default' },
    { label: 'Override', value: 'Override' },
    // ... other doctor options
  ];


  useEffect(() => {
    console.log("props in payment page", props);
    dispatch(
      patientActions.totalFees((resCb) => {
        if (resCb) {
          paymentDetails.totalAmount = resCb;
          paymentDetails.remainingAmount = resCb;
          setRemainingamount(resCb)
          setTotalamount(resCb)
          setPaymentDetails(paymentDetails);
          setRemaing(paymentDetails.totalAmount);
        }
      })
    );
    getVouchers() 
  }, []);

  const getVouchers = () => {
    let customerId = props.match.params.erpCustId;
    dispatch(
      patientActions.getVouchers(customerId, (resCb) => {
        if (resCb && resCb.voucherList && resCb.voucherList.length > 0) {
          console.log("response from addPatient api", resCb.voucherList);
          setVoucherList(resCb.voucherList);
          // setloading(false);
        }
      })
    );
  }
  const paymentBy = (value) => {
    setPaymentOption(value);
  };

  const goToPreviousPath = () => {
    history.goBack();
  };

  const registrationFees = (customerId,totalfees,visitId,patientId,type,paymentLists) => {
    dispatch(patientActions.registrationFees(customerId,totalfees,visitId,patientId,type,paymentLists,(resCb) => {
      if (resCb) {
        console.log("registration fees api response", resCb);
        history.push({
          pathname: `/PatientSections/${patientId}/${visitId}`,
        });
      }
    }
  )
);
  }

  const ConfirmPayment = (e) => {
    paymentLists.push(cardPay);
    paymentLists.push(cashPay);
    paymentLists.push(upiPay);
    console.log("payment list in save:", paymentLists);
    let customerId = props.match.params.erpCustId;
    //let totalfees = 600.0;
    let totalfees = paymentDetails.totalAmount;
    console.log("total fees in :", totalfees);
    console.log("remaining amount:", paymentDetails.remainingAmount);
    console.log("total amount:", paymentDetails.totalAmount);
    let patientId = props.match.params.patientId;
    let visitId = props.match.params.visitId;
    //const location = process.env.REACT_APP_PRODUCT_HMS_LOCATION_OUTPATIENT;
    console.log("visitId is :", visitId);
    if(voucherList && voucherList.length > 0){
      console.log("payment list in voucherlist", paymentLists);
      let type = "vouchers";
        registrationFees(customerId,totalfees,visitId,patientId,type,paymentLists)
    }
    else if(paymentDetails.remainingAmount == 0 && flag== true){      
        console.log("payment list in ", paymentLists);
        let type = "Override";
        registrationFees(customerId,totalfees,visitId,patientId,type,paymentLists)
      }
    else{
        if(paymentDetails.remainingAmount == 0 ){
          console.log("payment list in ", paymentLists);
          let type = "";
          registrationFees(customerId,totalfees,visitId,patientId,type,paymentLists)
        }
      }
  };

  const updateRemaining = (value, type) => {
    let details = paymentDetails;
  
    if (type === "upi") {
      details.remainingAmount =
        details.totalAmount - value - creditPaid - cashPaid;
      setUpiPaid(value);
      console.log("upi payment", upipaid, details.remainingAmount);
    }
    if (type === "credit") {
      details.remainingAmount =
        details.totalAmount - value - upipaid - cashPaid;
      setCreditPaid(value);
      console.log(
        "creditPaid payment",
        creditPaid,
        details.remainingAmount,
        value + creditPaid + cashPaid
      );
    }
    if (type === "cash") {
      details.remainingAmount =
        details.totalAmount - value - creditPaid - upipaid;
      setCashPaid(value);
      console.log("cashPaid payment", cashPaid, details.remainingAmount);
    }
    // if(payType === ''){
    //   console.log("if------")
    //   setPayType(type);
    //   setRemaing(details.remainingAmount);
    // }
    // if(payType != type){
    //   console.log("else------")
    //   setRemaing(details.remainingAmount);
    //   setPayType(type);
    // }
    details.customerName = props.match.params.name;
    details.patientId = props.match.params.patientId;
    details.executive = localStorage.getItem("name");

    // details.remainingAmount = details.totalAmount - (upipaid+creditPaid+cashPaid);
    console.log("details.remainingAmount", details.remainingAmount);

    // setRemaining("");
    if(details.remainingAmount < 0){
      let errors ="Please enter a valid amount. Ensure that the total of credit card, UPI, and cash amounts adds up to Rs. 100.";
      setErrors(errors);
      setErrorFlag(true);
    }else{
      setErrorFlag(false);
    }
    setPaymentDetails({ ...details });
    console.log(
      "remaining amount",
      details.totalAmount,
      details.remainingAmount
    );
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(event.target.value);
   
     console.log("setSelectedOption",selectedOption)
    if(event.target.value === 'Override'){ // since state value is not updated used event value
      setFlag(true);
      paymentDetails.remainingAmount=0;
      paymentDetails.totalAmount=0;
      console.log("setSelectedOption",flag)
    }
    else{
      setFlag(false);
      paymentDetails.totalAmount=totalamount;
      paymentDetails.remainingAmount=remainingamount;
      console.log("setSelectedOption",flag)
    }

  };

  return (
    <>
      <div className="px-4 py-2 w-100 h-100" id="payment">
        <div>
          <div>
            <p className=" border-bottom pb-2 p-2 mb-2 text-center title">
              Registration Fees
            </p>
            <div className="border-bottom">
            <Grid container spacing={2} className="pb-2">
        <Grid item xs={6}>
          <Typography variant="body1" component="label" htmlFor="custName">
            Name:&nbsp;{paymentDetails.customerName}
          </Typography>
         
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="label" htmlFor="exeName">
            Executive: &nbsp;{paymentDetails.executive}
          </Typography>
        </Grid>
      </Grid>

      {/* Row 2 */}
      <Grid container spacing={2} className="pb-2">
        <Grid item xs={6}>
          <Typography variant="body1" component="label" htmlFor="total">
            Payment:&nbsp;{paymentDetails.totalAmount}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="label" htmlFor="remaining">
            Remaining:&nbsp;{paymentDetails.remainingAmount}
          </Typography>
        </Grid>
      </Grid>
              <Grid>
              <div className="col-md-3">
                  {/* <label for="total">Reason:</label> */}
                  <select
                    id="myDropdown"
                    value={selectedOption}
                    className="form-control"
                    onChange={handleSelectChange}
                  >
                    {optionList.map((optionL) => (
                    <option key={optionL.value} value={optionL.value}>
                    {optionL.label}
                     </option>
                   ))}
                  {voucherList.map((vouchers,index) => (
                          <option key={index}>{vouchers.voucherId}</option>
                          ))}
                  </select>
                  {/* <span>&nbsp;{paymentDetails.totalAmount}</span> */}
                </div>
              </Grid>
            </div>
          </div>

          <div className="d-flex mt-2 " style={{ minHeight: "390px" }}>
            <div className="card w-25 p-3 choosePayment"> 
              <h6>Choose Payment Option</h6>

              <div className="d-flex flex-direction-column payment-option">
      <Button
        disabled={flag}
        variant="outlined"
        className={`payOptn-btn d-flex justify-content-start mb-2 text-left ${paymentOption === 'card' ? 'payOptn-btn active' : 'payOptn-bt'}`}
        onClick={() => paymentBy('card')}
        
      >
       <span className="w-25">
       <PaymentIcon className="material-icons mr-1 text-success" />
        </span>
        <span> Credit Card</span>
       
      </Button>

      <Button 
        disabled={flag}
        variant="outlined"
        onClick={() => paymentBy('upi')}
        className={`payOptn-btn  d-flex justify-content-start mb-2 text-left ${paymentOption === 'upi' ? 'payOptn-btn active' : 'payOptn-bt'}`}
       
      >
          <span className="w-25">
          <AccountBalanceIcon className="material-icons mr-1 text-success" />
        </span>
        <span> UPI</span>
        
        
      </Button>

      <Button
        disabled={flag}
        variant="outlined"
        onClick={() => paymentBy('cash')}
        className={`payOptn-btn  d-flex justify-content-start mb-2 text-left  ${paymentOption === 'cash' ? 'payOptn-btn active' : 'payOptn-bt'}`}

        style={{ textAlign: 'left',marginBottom:"10px"  }}
      >
         <span className="w-25 text-center">
        <MonetizationOnIcon className="material-icons mr-1 text-success" />
        </span>
        <span> Cash</span>
      </Button>
    </div>
            </div>

            <div className="card p-3 payment-type flex-fill ml-3 border-left">
              {paymentOption === "card" && (
                <div>
                  <Components.CreditCardPayment
                    details={paymentDetails}
                    paid={creditPaid}
                    onClose={(event, value) => updateRemaining(event, value)}
                  />
                </div>
              )}

              {paymentOption === "cash" && (
                <div>
                  <Components.CashPayment
                    details={paymentDetails}
                    paid={upipaid}
                    onClose={(event, value) => updateRemaining(event, value)}
                  />
                </div>
              )}

              {paymentOption === "upi" && (
                <div>
                  <Components.UpiPayment
                    details={paymentDetails}
                    paid={upipaid}
                    onClose={(event, value) => updateRemaining(event, value)}
                  />
                </div>
              )}
               {errorFlag &&
                 <div style={{ color: "#EC4147" }}>
                 <span className="err-msg">{errors}</span>
               </div>
               }
              
            </div>
          </div>

          <div className="actions mt-3">
            <div className="d-flex justify-content-between w-100">
              <div>
                <button
                  id="PayButton"
                  className="btn btn-primary submit-button"
                  type="submit"
                  onClick={() => {
                    goToPreviousPath();
                  }}
                >
                  <span className="align-middle">Back</span>
                </button>
              </div>
              <div>
              <button 
                id="OverrideButton" 
                class="btn btn-primary submit-button" 
                type="submit" 
                disabled={!flag}
                onClick={(e) => {
                  ConfirmPayment(e);
                }}
                style = {{margin:" 7px",width: "32%"}}>
                  <span  className={!flag ?'save-diabled':"align-middle"}>Continue</span>
                </button>
                <button 
                  id="PayButton"
                  className="btn btn-primary submit-button p-20"
                  type="submit"
                  disabled={flag}
                  onClick={(e) => {
                    ConfirmPayment(e);
                  }}
                >
                  <span  className={flag ?'save-diabled':"align-middle"}>Payment Received</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
