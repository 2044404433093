import { CATEGORY_DETAILS} from "../../shared/constants";

const categoryDetailsState = {
  categories: [],
};

function setCategoryDetailsState(state = categoryDetailsState, action) {
    switch (action.type) {
      case CATEGORY_DETAILS:
          console.log("CATEGORY_DETAILS",action.payload)
        return Object.assign({}, state, {
            categories: action.payload,
        }); 
      default:
        return state;
    }
  }
  export default setCategoryDetailsState;