import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select,{ createFilter }from 'react-select';
// import {serviceCategoryList} from './serviceCategory'
import { connect } from "react-redux";
import { categoryActions } from "../../core/actions";

function BedSearch(props) {
  const[selectedOption, setSelectedOption] = useState(null)
  const[services, setServices] = useState([])
  const[categories, setCategories] = useState([])
  const [options, setOption] = useState('');
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(categoryActions.getAllServiceCategory((resCb) => {
      console.log("# - Response", resCb, "Props", categoriesList);
      if (resCb) {
        if (categoriesList) {
          let categoryInfo = categoriesList;
          Object.keys(categoryInfo).forEach((key) => {
            if (!categoryInfo[key]) {
              categoryInfo[key] = "";
            }
          });
          let deptOptions = []
            for(let cat of categoriesList){
                let categ = {}
                categ['value'] = cat.name
                categ['label'] = cat.name
                deptOptions.push(categ)
            }
          setOption(deptOptions)

          setCategories(categoryInfo);
        }
      }
    }));
  },[])

  const categoriesList = useSelector(state => state.categoryDetailsState.categories)

  const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
    if (selectedOption != null) {
      //Setting the services based on the selected service category
      // serviceCategoryList.map((serviceCategory, idx) =>
      categoriesList && categoriesList.length > 0 && 
      categoriesList.map((serviceCategory, idx) =>
        serviceCategory.name === selectedOption
          ? setServices(serviceCategory.services)
          : ""
      );
      props.parentCallBack(selectedOption.value, services);
    } else {
      props.parentCallBack(selectedOption, services);
    }
  }


    return (
      <div>
        <Select
          name="serviceCategory"
          value={selectedOption}
          onChange={handleChange}
          isClearable
          isSearchable
          options={options}
          filterOption={createFilter(options)}
        ></Select>
      </div>
    );
}
/* function mapStateToProps(state) {
  return {
      categories: state.categoryDetailsState.categories
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getAllServiceCategory : (resCb) => dispatch(categoryActions.getAllServiceCategory(resCb))
  };
}

const searchWithProps = connect(mapStateToProps, mapDispatchToProps)(BedSearch); */

export default BedSearch;
