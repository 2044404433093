import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../common/BtnCellRenderer";
import { doctorActions } from "../../core/actions";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';



function Services(props) {
    const [serviceList, setServiceList] = useState([]);
    const dispatch = useDispatch();

    const [columnDefs] = useState([
        {
          headerName: "Id",field: "id",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true, cellClass: ["ag-cell-center"],
        },
        {
          headerName: "Name",field: "name",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,onGridReady: "open",cellClass: ["ag-cell-center"],tooltipField: 'name'
        },
        {
          headerName: "Description",field: "description",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true,cellClass: ["ag-cell-center"],tooltipField: 'description'
        },
        {
          headerName: "Product Id",field: "productId",sortable: true,filter: true,flex: 1,resizable: true,floatingFilter: true, cellRenderer : 'BtnCellRenderer',cellRendererPopup:true,cellClass: ["ag-cell-center","ag-cell-productId"]
        },
        {
          headerName: "Actions",field: "Actions",sortable: true,filter: true,flex: 1,resizable: true,width: 150,cellRenderer: "BtnCellRenderer",cellClass: ["ag-cell-center","text-cenetr"]
        },
      ]);


      useEffect(() => {
        listServices();
      }, []);

      const context = { componentParent: (props) => handleRendererCall(props) };
      
      const handleRendererCall = (props) => {
        console.log(" handleRendererCall props :", props);
        let value = props.data
        
        if(props.data["key"] == 'action'){
          console.log("props.data:",value);
    
          dispatch(doctorActions.updateService(props.data, (resCb) => {
            if(resCb){
              console.log("update resCb",resCb);
              listServices();
            }
          }))
        }
       
      };

      const listServices = () => {

        let data = {};
        dispatch(
          doctorActions.getProductIdList(data, (resCb) => {
            if (resCb) {
              if (resCb.packageServiceList) {
                resCb.packageServiceList.forEach(element => {
                  if(element.productId === 0){
                    element.productId ='';
                    element.productName = '';
                  }
                });
                setServiceList(resCb.packageServiceList);
              }
            }
          }));
      }
    

    return (
        <>
        <main className="mainsection">
        <h5 class="page-title">Services</h5>
        <div
          className="ag-theme-balham ag-style card rounded table mb-0 tcol my-4  dashboard-grid"
          style={{ height: 500, width: `98%` }}
        >
          <AgGridReact
            frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
            context={context}
            rowData={serviceList}
            columnDefs={columnDefs}
           
          ></AgGridReact>

         
        </div>
        </main>
      
        </>
    )

}


export default Services;