import AdminHomeView from './patient/AdminHomeView';
import PatientForm from './patient/PatientForm';
import PatientSections from './patient/PatientSections';
import BedList from './bed/BedList';
import BedForm from './bed/BedForm';
import BedAddForm from './bed/BedAddForm';
import BedSearch from './bed/BedSearch';
import FormDetail from './forms/formDetail';
import FormList from './forms/formsList';
import SectionAdd from './forms/sectionAdd';
import FormPreview from './forms/formPreview';
import DocSetup from './doctor/docSetup';
import DocVisitFee from './doctor/docVisitFee';
import ServiceMaster from './serviceCategory/ServiceMaster';
import Search from './serviceCategory/Search';
import DiagnosisMaster from './diagnosis/DiagnosisMaster';
import SearchDiagnosis from './diagnosis/SearchDiagnosis';
import ListDoctor from './doctor/ListDoctor';
import Login from './login/Login';
import ListPatient from './doctor/ListPatient';
import Dashboard from './doctor/Dashboard';
import SearchDept from './doctor/SearchDept';
import BtnCellRenderer from './common/BtnCellRenderer';
import CustomTooltip from './common/CustomTooltip';
import DoctorHomeView from './patient/DoctorHomeView';
//import PatientPrescriptionLayout from './patient/PatientPrescriptionLayout';
import PrescriptionLayoutModel from './patient/PrescriptionLayoutModel';
import LayoutHeader from './common/LayoutHeader';
import AttachOptions from './patient/AttachOptions';
import PatientEditForm from './patient/PatientEditForm';
import BasicLayoutHeader from './common/BasicLayoutHeader';
import Dropzone from './patient/Dropzone';
import FileUploadPreview from './patient/FileUploadPreview';
import PatientListing from './appointmentList/PatientListing';
import GiveAppointment from './appointmentList/GiveAppointment';
import PatientRevisit from './appointmentList/PatientRevisit';
import PatientRevisitModal from './appointmentList/PatientRevisitModal';
import AddPatientmodel from './appointmentList/AddPatientmodel.js';
import PatientSchema from './patient/PatientSchema';
import Zoom from './zoommeeting/zoom';
import Zoommeeting from './zoommeeting/zoommeeting';
import ZoomNotes from './zoommeeting/ZoomNotes';
import ZoomMeetingReviewModel from './zoommeeting/ZoomMeetingReviewModel';
import NotesLabel from './notes-label/NotesLabel';
import Discharge from './discharge/dischargeSummary';
import TemplatePreview from './patient/TemplatePreview';
import Attachments from './patient/Attachments';
import PackageService from './patient/PackageService';
import ProcedureListing from './patient/ProcedureListing';
import AppointmentScheduleModal from './appointmentList/AppointmentScheduleModal'
import AdminAppointment from './appointmentList/AdminAppointment.js';
import PrintDischargeSummary from './discharge/dischargePrint.js';
import NotesPrint from './patient/notesPrint.js';
import DoctorRegister from './master/doctor/DoctorRegister';
import DoctorList from './master/doctor/DoctorList';
import DoctorListGrid from './master/doctor/DoctorListGrid';
import addDoctor from './master/doctor/addDoctor';
import InsuranceProcedureInput from './Insurance/InsuranceProcedureInput';
import insuranceMasteradd from './master/insurance/insuranceMasteradd';
import Tpaadd from './master/insurance/tpaadd';
import insuranceMasterList from './master/insurance/insuranceMasterList';
import tpamasterlist from './master/insurance/tpamasterlist';
import InsuranceProcedureSettlement from './Insurance/InsuranceProcedureSettlement';
import InsuranceProcedureListing from './Insurance/InsuranceProcedureListing';
import pdfprint from './patient/pdfprint';
import AddConsultant from "./common/AddConsultant";
import roomMasterList from './master/roomMaster/roomMasterList';
import RoomMasterAdd from './master/roomMaster/roomMasterAdd';
import Services from "./common/Services";
import VisitsByType from '../components/nurse/VisitsByType';
import Payment from './payment/Payment';
import CreditCardPayment from './payment/CreditCardPayment';
import CashPayment from './payment/CashPayment';
import UpiPayment from './payment/UpiPayment';
import Reports from './Reports/reports';
import TokenDashBoard from './TokenDashBoard/tokendashboard';
import TokenDashBoardDetails from './TokenDashBoardDetails/tokendashboarddetails';
import TokenPrint from './patient/TokenPrint';
import RegistrationCard from './patient/RegistrationCard';
import LabReportPrint from '../shared/schema/LabReportPrint';
import DischargePrint from './patient/DischargePrint';
import Vouchers from './patient/Vouchers';
import ActiveTokenPatients from './TokenDashBoard/activeTokenPatients';
import Package from './master/package/package';
import PackageMasterAdd from './master/package/packageMasterAdd';
import Notes from './patient/Notes';
import MyReports from './MyReports/MyReports.js'
import Prescription from './ehr/Prescription';
//import StaffList from './master/Staff/stafflist';
//impoListStaff from './master/Staff/LIstStaff';
//import AddStaff from './master/rt  addStaff from '../core/actions/doctorActions';
//import Staff/AddStaffrrr

import AddStaff from './master/Staff/AddStaff';
import StaffList from './master/Staff/StaffList';
const Components = {
  AdminHomeView,
  PatientForm,
  PatientSections,
  BedList,
  BedForm,
  BedAddForm,
  BedSearch,
  FormDetail,
  SectionAdd,
  FormList,
  FormPreview,
  DocSetup,
  DocVisitFee,
  ServiceMaster,
  Search,
  DiagnosisMaster,
  SearchDiagnosis,
  Login,
  StaffList,
  ListDoctor,
  ListPatient,
  AddStaff, 
  Dashboard,
  SearchDept,
  BtnCellRenderer,
  CustomTooltip,
  DoctorHomeView,
  LabReportPrint,
  //PatientPrescriptionLayout,
  PrescriptionLayoutModel,
  LayoutHeader,
  AttachOptions,
  PatientEditForm,
  BasicLayoutHeader,
  Dropzone,
  FileUploadPreview,
  PatientListing,
  GiveAppointment,
  PatientRevisit,
  PatientRevisitModal,
  AddPatientmodel,
  PatientSchema,
  Zoom,
  Zoommeeting,
  ZoomNotes,
  ZoomMeetingReviewModel,
  NotesLabel,
  Discharge,
  TemplatePreview,
  Attachments,
  PackageService,
  ProcedureListing,
  AppointmentScheduleModal,
  AdminAppointment,
  DoctorRegister,
  DoctorList,
  DoctorListGrid,
  addDoctor,
  InsuranceProcedureListing,
  InsuranceProcedureInput,
  InsuranceProcedureSettlement,
  insuranceMasteradd,
  Tpaadd,
  insuranceMasterList,
  tpamasterlist,
  pdfprint,
  AddConsultant
  , roomMasterList
  , RoomMasterAdd,
  Services,
  VisitsByType,
  Payment,
  CashPayment,
  CreditCardPayment,
  UpiPayment,
  Reports,
  TokenDashBoard,
  TokenDashBoardDetails,
  TokenPrint,
  DischargePrint,
  Vouchers,
  ActiveTokenPatients,
  RegistrationCard,
  Package,
  PackageMasterAdd,
  Notes,
  PrintDischargeSummary,
  NotesPrint,
  Prescription,
  MyReports,



}

export default Components;
