
export function getschemafromBloodSugarProfile(templateId,schema,flags){

    console.log("flags", flags)
    console.log(templateId)
    console.log('schema : ',schema);
  
    let jsonSchema = {}
    let elementName1;
    let obj1 = {};
    let obj2 = {}
    let elementName2;
    jsonSchema['properties'] = {}
  
    for (let content of schema.content){
      console.log("content: ", content)
  
      for (let datas of content.data.events){
        console.log("content data: ", datas)
  
        for (let item of datas.data.items){
          console.log("inside data items: ", item)
  
          if(item._type=== 'ELEMENT'){
            elementName1 = item.value.value;
            console.log("elementName1", elementName1)
  
            jsonSchema['properties'][elementName1] = {};
  
            obj1 = {type:"object"}
            jsonSchema['properties'][elementName1]=obj1;
  
            jsonSchema['properties'][elementName1]['properties' ]= {};
  
            console.log("jsonschema: ", jsonSchema)
          }
          else if (item._type === "CLUSTER"){
            for (let it of item.items){
              console.log('it', it)
  
              elementName2 = it.name.value;
              console.log("element2: ", elementName2);
  
              jsonSchema['properties'][elementName1]['properties'][elementName2] = {}
              console.log("jsonSchema2 :",jsonSchema);
  
              switch (elementName2){
  
                case "Result":
                  obj2 = (flags === "editComposition" ? 
                    {type:"string", default: it.value.magnitude + " " + it.value.units} :
                    {type:"string",default: "0"} )
                  jsonSchema['properties'][elementName1]['properties'][elementName2]=obj2;
                  break;
  
                case "Comment":
                  let defaultValue = (flags === "editComposition" ? it.value.value : "")
                  let commentenum = {
                    type:"string",
                    enum:["High", "Low", defaultValue],
                    enumNames:['High', 'Low', defaultValue], 
                    default: defaultValue
                  }
                  jsonSchema['properties'][elementName1]['properties'][elementName2]=obj2;
                  jsonSchema['properties'][elementName1]['properties'][elementName2] = commentenum;
                  break;
  
                case "Ref. Range":
                  let obj3 = {type: "string", default: it.value.value}
                  jsonSchema['properties'][elementName1]['properties'][elementName2] = obj3;
                  break;
              }
            }
          }
        }
      }
    }
    console.log("Jsonschema: ", jsonSchema);
    return jsonSchema
  }
  

  const bloodSugarProfile = {
    getschemafromBloodSugarProfile,
    
    
  }


  export default bloodSugarProfile;