import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {tokenActions} from "../../core/actions";
import {PATIENT_SECTION} from  "../../shared/constants";
import { Column } from 'ag-grid-community';
import {  AgGridReact } from "ag-grid-react";

function ActiveTokenPatients(){
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(true);
    const [paginationPageSize, setPaginationPageSize] = useState(10);
    const[tokenActivePatients,setTokenActivePatients] = useState([]);

    const [columnDefs] = useState([
        {
          headerName: "Desk",
          field: "deskId",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
        },
        {
          headerName: "Token Number",
          field: "tokenId",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
        },
          {
          headerName: "Patient Name",
          field: "fname",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
          onGridReady: "open",
        },
        {
          headerName: "Phone Number",
          field: "phno",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
          onGridReady: "open",
        },
        {
          headerName: "Address",
          field: "address",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
          onGridReady: "open",
        },
        
      ]);

      useEffect(() => {
        tokenDashBoardView(); 
     
        },[])

        const tokenDashBoardView = () =>{
            dispatch(tokenActions.deskDetails((resCb) => {
                if(resCb){ 
                  if(resCb.deskDetails){ 
                    let patientPush = [];                                                                                                                                                                                                                                                          
                    let activeTokenDetails = Object.values(resCb.deskDetails);   
                      for (let details of activeTokenDetails){
                       console.log("object  details is: " ,details);
                        for(let i=0; i<details.length; i++){
                        console.log(" inside loop is : " ,details[i]);
                        patientPush.push(details[i])
                        }
                      }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                   setTokenActivePatients([...patientPush]);                                                                                                                                           
                 }         
               }                                                                                            
           })
             );
        }

        return (<>
        <div>
    <center><h4>Patients List</h4></center>
             <div className="p-0 preview">
          <div className="ag-theme-balham ag-style card rounded table mb-0 tcol table-head"style={{fontSize:'14px', height: 500, width: `98%` }}>
       <AgGridReact
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              rowData={tokenActivePatients}
              columnDefs={columnDefs}
            ></AgGridReact>
    </div> 
    </div>
    </div>
        </>
        );    

}

export default ActiveTokenPatients;