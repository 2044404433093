import React, { useState } from 'react';

function MyReports() {

  const [patientsReport, setpatientsReport] = useState(true);
  const [invoiceReport, setinvoiceReport] = useState(false);
  const [patientPremise, setPatientPremise] = useState(false);
  const [Patients4, setPatients4] = useState(false);

  const handleButtonClick = (buttonNumber) => {
    switch (buttonNumber) {
      case 1:
        setpatientsReport(true);
        setinvoiceReport(false);
        setPatientPremise(false);
        setPatients4(false);
        break;
      case 2:
        setpatientsReport(false);
        setinvoiceReport(true);
        setPatientPremise(false);
        setPatients4(false);
        break;
      case 3:
        setpatientsReport(false);
        setinvoiceReport(false);
        setPatientPremise(true);
        setPatients4(false);
        break;
      case 4:
        setpatientsReport(false);
        setinvoiceReport(false);
        setPatientPremise(false);
        setPatients4(true);
        break;
      default:
        break;
    }
  };


  var currentDate = new Date();


  var oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);


  var fromDate = oneWeekAgo.toISOString().split('T')[0];
  var toDate = currentDate.toISOString().split('T')[0];


  // var patientReportUrl = process.env.REACT_APP_EVN_HOST + "/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjd9LCJwYXJhbXMiOnt9fQ.T9S7arqXO7zYgax0lS6dwnmfHeyltLb5auzmMPgnlnA?from_date=" + fromDate + "&to_date=" + toDate + "#bordered=true&titled=true";
  var patientReportUrl = process.env.REACT_APP_EVN_HOST + "/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjJ9LCJwYXJhbXMiOnt9fQ.NKDeWywb31JWu9segMgKbUpzeprWStUxr7j4h_CpD1o#bordered=true&titled=true";
  console.log("patientReportUrl", patientReportUrl);
  // var invoiceReportUrl = process.env.REACT_APP_EVN_HOST + "/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjR9LCJwYXJhbXMiOnt9fQ.iQa3Kj7RGwnWVeh03Q7r8oSFjzMTZdJoo_LXlR9Kh9w?from_date=" + fromDate + "&to_date=" + toDate + "#bordered&titled=true";
  var invoiceReportUrl = process.env.REACT_APP_EVN_HOST + "/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjN9LCJwYXJhbXMiOnt9fQ.AsSxTtl_2ikzX_US9YFbS5LKAlc7MRxcX5N5s8ppFjg#bordere=trued&titled=true";
  console.log("invoiceReportUrl", invoiceReportUrl);
  // var patientPremiseUrl=process.env.REACT_APP_EVN_HOST +"/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjExfSwicGFyYW1zIjp7fX0.ASp1YKFxkMBTSQpAMH94wrawPHjxLrOAiGuBk-ixwJA=" +fromDate + "&to_date=" + toDate + "#bordered&titled=true";
  // console.log("patientPremiseUrl", patientPremiseUrl);
  // var patientPremiseUrl=process.env.REACT_APP_EVN_HOST +"/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjl9LCJwYXJhbXMiOnt9fQ.b2603pgYhqHuSP--IyF64TtFMJw-k9CsItYJOV8LWmo?from_date=" +fromDate + "&to_date=" + toDate + "#bordered&titled=true";
  // var patientPremiseUrl=process.env.REACT_APP_EVN_HOST +"/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjR9LCJwYXJhbXMiOnt9fQ.iBdy3DHSytabU-xz2Eqn3h9-_gA8v26T_HATIHsGXh8?from_date=" +fromDate + "&to_date=" + toDate + "#bordered&titled=true";
  var patientPremiseUrl=process.env.REACT_APP_EVN_HOST +"/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjR9LCJwYXJhbXMiOnt9fQ.iBdy3DHSytabU-xz2Eqn3h9-_gA8v26T_HATIHsGXh8#bordered=true&titled=true";
  console.log("patientPremiseUrl", patientPremiseUrl);

  return (
    <div>
      <ul className="nav nav-tabs nav-fill">
        <li className="nav-item">
          <button className={`nav-link ${patientsReport ? 'active' : ''}`} onClick={() => handleButtonClick(1)}>
            Patients Report
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${invoiceReport ? 'active' : ''}`} onClick={() => handleButtonClick(2)}>
          Invoice Report
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${patientPremise ? 'active' : ''}`} onClick={() => handleButtonClick(3)}>
          Patient Premise
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${Patients4 ? 'active' : ''}`} onClick={() => handleButtonClick(4)}>
          Coming Soon
          </button>
        </li>
      </ul>
      <div>
      {patientsReport && (
          <iframe
            src={patientReportUrl}
            frameBorder="0"
            width="100%"
            height="600"
            allowtransparency
          ></iframe>
        )}
        {invoiceReport && (
          <iframe
            src={invoiceReportUrl}
            frameBorder="0"
            width="100%"
            height="600"
            allowtransparency
          ></iframe>
        )}
         {patientPremise && (
          <iframe
            src={patientPremiseUrl}
            frameBorder="0"
            width="100%"
            height="600"
            allowtransparency
          ></iframe>
        )}
       
      </div>
    </div>
  );
}

export default MyReports;
