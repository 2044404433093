import React, { useState, useEffect } from 'react';
import {Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {DialogContent } from "@mui/material";
import Button from '@mui/material/Button';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
  import { helperServices } from "../../shared/helper";
  import { doctorActions} from "../../core/actions";
  import {useDispatch} from "react-redux";


function VisitsByType(props) {
  const dispatch = useDispatch();
  const[selectedType, setSelectedType]= useState("");
  const[selectedDoctor, setSelectedDoctor]= useState();
  const[selectedFloor, setSelectedFloor]= useState();
  const[selectedDep, setSelectedDep]= useState();
  const[doctorList, setdoctorList] = useState([]);
  const[departmentList, setdepartmentList] = useState([]);
  const[floorList, setFloorList] = useState([]);
  const[sname,setsName] = useState();

    useEffect(() => {
      getDoctorsList(); 
      getDepartmentList();
      getFloorList();

    },[]);
    const[data,setData]=useState({
      targetId:"",
      floorId:"",
      department:"",
      selectedOption: "",
    })

    const handleSelect = (name,value, selectedOption) => {
      setSelectedType(value);
      let details = data;
      if(name == 'Doctor'){
        setSelectedDoctor(value);
        setSelectedFloor("--Select Floor--");
        setSelectedDep("--Select Department--");
        details.targetId = value;
        details.floorId = "";
        details.selectedOption= selectedOption;
        details.department = "";
      }
      if(name == 'Floor'){
        setSelectedFloor(value);
         setSelectedDoctor("--Select Doctor--");
         setSelectedDep("--Select Department--");
        details.floorId = value;
        details.selectedOption= selectedOption;
        details.targetId = "";
        details.department = "";
      }
      if(name == 'Dep'){
        setSelectedDep(value);
        setSelectedDoctor("--Select Doctor--");
        setSelectedFloor("--Select Floor--");
        details.department = value;
        details.selectedOption= selectedOption;
        details.floorId = "";
        details.targetId = "";
      }

      setData(details);
      sessionStorage.setItem("visitDetailsBy",JSON.stringify(details));
     
    }

    const handleSubmit = () =>{
      props.close(data);
    }

    const getDoctorsList = (data) =>{
      dispatch(doctorActions.getDoctorList(data,resCb=>{
       if(resCb){
          let list = [];
           for(let doc of resCb){        
            let opt = {}
            if(doc!=null){

            if (!doc.first_name){doc.first_name = "" }
            if(!doc.last_name){doc.last_name = ""}
           // opt['id'] = doc.personId
            opt['id'] = doc.gvId
            opt['type'] = doc.type
            const name=  helperServices.getFullName(doc.first_name,null,doc.last_name);
            opt['role'] = doc.role
            opt['value'] = doc.email;
            opt['department'] = doc.department;
            opt['firstName'] = doc.first_name;
            opt['lastName'] = doc.last_name;
            opt['label'] = doc.displaytitle+"."+" "+name +", "+"Dep of "+doc.department +" ("+doc.qualification+" )";

            list.push(opt);
            // if(props.patientData.person && props.patientData.person.personId==doc.personId){
            //   setselectedDoctor(opt['label']);  

            // }

            console.log("list doctor Response:", list)
            }
          }
          list = list.filter((item)=>(item.type).toLowerCase() == (props.type).toLowerCase());
          setdoctorList([...list])

        }
       }))
   }

    const getDepartmentList = (data) =>{
    dispatch(doctorActions.getDepartmentList(data,resCb=>{
      if(resCb){
        let deptlist = [];
        for(let dept of resCb){
          let deptopt = {}
          deptopt['id'] = dept.id;
          deptopt['name'] = dept.name;
          deptlist.push(deptopt);
        }
        setdepartmentList([...deptlist])
        console.log("departmentLists:",departmentList);
      }
    }))
     }

     const getFloorList = (data) =>{
      dispatch(doctorActions.getFloorList(data,resCb=>{
        if(resCb){
          let floorlist = [];
          for (let floor of resCb){
            let flooropt = {}
            flooropt['floorid'] = floor.floorId;
            flooropt['floorname'] = floor.floorName;
            floorlist.push(flooropt);
          }
          setFloorList([...floorlist])
        }
      }))
     }


    const closeModal = () =>{
        props.close();
      }

    return (  
        <>
              <Dialog
                      className="d-flex w-95 h-90 flex-column-reverse "
                      id="doctor-modal1"
                      open={true}
                      toggle={closeModal}
                    > 
                    <DialogTitle>
                    Today's Visit Details
                  <IconButton
                    aria-label="close"
                    onClick={(e) => closeModal(e)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                      <CloseIcon />
                </IconButton>
                </DialogTitle> 
                  <DialogContent>
                    <div className='mb-4 mt-3'>
                    <FormControl fullWidth>
                        <InputLabel id="select-doctor-label">Select Doctor</InputLabel>
                        <Select
                          labelId="select-doctor-label"
                          id="select-doctor"
                          value={selectedDoctor}
                          onChange={(event) => {handleSelect("Doctor", event.target.value,event.nativeEvent.srcElement.innerText)}}
                        >
                          <MenuItem value="" disabled>
                            --Select Doctor--
                          </MenuItem>
                          <MenuItem key={"All"} value={0} >
                              All Patient
                          </MenuItem>
                          {doctorList.map((options, index) => (
                            <MenuItem key={options.name} value={options.id}>
                              {options.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className='mb-4 mt-3'>
                    <FormControl fullWidth>
                            <InputLabel id="select-floor-label">Select Floor</InputLabel>
                            <Select
                              labelId="select-floor-label"
                              id="select-floor"
                              value={selectedFloor}
                              onChange={(event) => handleSelect("Floor", event.target.value,event.nativeEvent.srcElement.innerText)}
                            >
                              <MenuItem value="" disabled>
                                --Select Floor--
                              </MenuItem>
                              {floorList.map((options, index) => (
                                <MenuItem key={options.name} value={options.floorid}>
                                  {`${options.floorid} ${options.floorname}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                    </div>

                    <div className='mb-4 mt-3'>
                    <FormControl fullWidth>
                        <InputLabel id="select-dep-label">Select Department</InputLabel>
                        <Select
                          labelId="select-dep-label"
                          id="select-dep"
                          value={selectedDep}
                          name={sname}
                          onChange={(event) => {handleSelect("Dep", event.target.value, event.nativeEvent.srcElement.innerText); console.log("event", event)}}>
                          <MenuItem value="" disabled>
                            --Select Department--
                          </MenuItem>
                          {departmentList.map((options, index) => (
                            <MenuItem key={options.name} name ={options.name} value={options.id}>
                              {options.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  
                  </DialogContent>
                  <DialogActions>
                    <Button type="submit"  variant="contained" className='mr-3 mb-2'
                     color="primary" onClick={(event) => handleSubmit(event)}>
                      Ok
                    </Button>
                  </DialogActions>

                  </Dialog>
        </>
    )
}

export default VisitsByType;
