import { roomMasterServices } from "../services";

export function roomList(resCb) {
  console.log("action getInsuranceMaster(data");
  return (dispatch) => {
    roomMasterServices.getRoomList().then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}
export function addRoom(data, resCb) {
  console.log("action modifyDoctor(data", data);
  return (dispatch) => {
    roomMasterServices.addRoom(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function modifyRoom(data, resCb) {
  console.log("action modifyRoom(data", data);
  return (dispatch) => {
    roomMasterServices.modifyRoom(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function roomDelete(data, resCb) {
  console.log("action deleteTpa(data", data);
  return () => {
    console.log("action return (dispatch)", data);

    roomMasterServices.roomDelete(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}


const roomMasterActions = {
  roomList
  ,addRoom
  ,modifyRoom
,roomDelete
  };
  
  export default  roomMasterActions ;   