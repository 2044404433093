import React, { useState, useEffect } from "react";
import Select, { createFilter } from "react-select";
import { patientActions } from "../../core/actions";
import { useDispatch, useSelector } from "react-redux";

function CreditCardPayment(props) {
  const [cardPaymentDetails, setCardPaymentDetails] = useState({
    type: "card",
    amount: "",
    bankName: "",
    authorisationCode: "",
  });
  const storedCardpayment = useSelector((state) => state.patientDetailsState.cardpayment);
  const [selectedBank, setselectedBank] = useState();
  const dispatch = useDispatch();
  const [bankList, setbankList] = useState([
    {value:'Karnataka Bank', label:'Karnataka Bank'},
    // { value: "Bank Of Baroda", label: "Bank Of Baroda" },
    // { value: "Bank Of India", label: "Bank Of India" },
    // { value: "Bank Of Maharashtra", label: "Bank Of Maharashtra" },
    // { value: "Canara Bank", label: "Canara Bank" },
    // { value: "Central Bank of India", label: "Central Bank of India" },
  ]);

  useEffect(() => {
    let cardPay = JSON.parse(sessionStorage.getItem("cardPayment"));
    console.log("cardPay:", cardPay);
    if (Object.keys(storedCardpayment).length !== 0) {
      console.log("storedCardpayment", storedCardpayment);
      storedCardpayment.amount=storedCardpayment.amount !=""?"":storedCardpayment.amount
      setCardPaymentDetails(storedCardpayment);
      // setCardPaymentDetails(prevState => ({ 
      //   ...prevState, // spread the previous state object
      //   amount: storedCardpayment.amount// update the amount value
      // }));
    }
  }, []);

  const handleInputChange = (name, value) => {
    console.log("value", value);
    let details = cardPaymentDetails;
    details[name] = value;
    setCardPaymentDetails({ ...details });
    // sessionStorage.setItem('cardPayment',JSON.stringify(details))
    dispatch(patientActions.cardPayment(cardPaymentDetails));
    if (name === "amount") {
      props.onClose(value, "credit");
    }
  };

  const handleBankChange = (event) => {
    console.log("cardPayment:");
    setselectedBank(event.label);
    let details = cardPaymentDetails;
    details.bankName = event.label;
    setCardPaymentDetails({ ...details });
    dispatch(patientActions.cardPayment(cardPaymentDetails));
    console.log("cardPayment:", details);
  };

  return (
    <>
      <div>
        <form className="card-details">
          <div className>
            <div className>
              <div className="form-group">
                <label className="label" for="cashGiven">
                  Enter Amount
                </label>
                <input
                  id="cashGiven"
                  className="null card-image form-control"
                  type="number"
                  value={cardPaymentDetails.amount}
                  onChange={(event) => {
                    handleInputChange("amount", event.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label className="label" for="NameOnCard">
                Bank
              </label>
              <Select
                className="select-tag"
                classNamePrefix="select-options"
                name="Search Bank"
                value={bankList.filter(
                  (option) => option.label === selectedBank
                )}
                onChange={(event) => {
                  handleBankChange(event);
                }}
                isClearable
                isSearchable
                options={bankList}
                filterOption={createFilter(bankList)}
                defaultValue={selectedBank}
              ></Select>
            </div>
          </div>
          <div className>
            <div className="form-group">
              <label className="label" for="code">
                Authorization Code
              </label>
              <input
                id="code"
                className="null card-image form-control"
                type="text"
                value={cardPaymentDetails.authorisationCode}
                onChange={(event) => {
                  handleInputChange("authorisationCode", event.target.value);
                }}
              ></input>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreditCardPayment;
