import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select,{ createFilter }from 'react-select';
// import {diagnosis} from './diagnosis';
import {helperServices} from '../../shared/helper';
// import { diagnosisActions } from "../../core/actions";
import { connect } from "react-redux";

function SearchDiagnosis(props) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOption] = useState('');
    const [diagnosisArray, setDiagnosisArray] = useState({
        id:'',
        code:'',
        name:'',
        date:helperServices.formatDate(new Date())
    });
   
    const dispatch = useDispatch();

    const diagnosisMasterDetail = useSelector(state => state.diagnosisDetailsState.diagnosisMasterDetail)

    useEffect(() => {
        console.log('diagnosisMasterDetail',diagnosisMasterDetail)
        if (diagnosisMasterDetail) {
            let dignOptions = []
            for(let dign of diagnosisMasterDetail){
                let opt = {}
                opt['id'] = dign.id
                opt['value'] = dign.name
                opt['label'] = dign.name
                opt['code'] = dign.code
                dignOptions.push(opt)
            }
            setOption(dignOptions)
            //}
        }
    },[])
    
    const handleChange = (selectedOption ) => {
        let dState = diagnosisArray
        setSelectedOption(selectedOption);

        if(selectedOption != null){
            dState['id'] = selectedOption.id
            dState['code'] = selectedOption.code
            dState['name'] = selectedOption.value
            setDiagnosisArray({...diagnosisArray,dState})
            props.parentCallBack(selectedOption.value,diagnosisArray)
        }
        else{
            props.parentCallBack(selectedOption,diagnosisArray)
        }
        
      }

        return (
            <div>
                <Select
                    name="diagnosis"
                    value={selectedOption}
                    onChange={handleChange}
                    isClearable
                    isSearchable
                    options={options}
                    filterOption={createFilter(options)}
                ></Select>
            </div>
        )
}
/* function mapStateToProps(state) {
    return {
        diagnosisMasterDetail:state.diagnosisDetailsState.diagnosisMasterDetail
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
    };
  }
  
  const searchDiagnosisWithProps = connect(mapStateToProps, mapDispatchToProps)(searchDiagnosis); */
  
  export default SearchDiagnosis;

