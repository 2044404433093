import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect,useState } from "react";
import { MANASWINI_LOGO, MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";

function SeparatePdf(props){

  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();
  const suffix = getNumberWithSuffix(day);
  const regpdfUrl = props.patientDetails.regFeesPdfUrl;
  const formattedDate = `${suffix} ${month} ${year}`;
  const qz = require("qz-tray");
  //zplToken, zplREgCard, zplBarcode
  let patientRegInfo = [];
  let tokenDetails = [];
  let barCodeDetails = [];
  let numberOfPrints = 0;
  let barCodeZPLCode;
  let replacedBarcode = "";
  let tokenZPLCode;
  let replacedToken;
  let replacedRegCardDetails;
  let regCardZPLCode;
  const printerName = process.env.REACT_APP_PRINTER_NAME;

  useEffect(() => {
    console.log("Props in merge pdf is",props);
    //var visitIdFrmProps = props.tokenDetails.visitId;
    // console.log("Props in merge pdf is",props.deskDetails[visitIdFrmProps].person.first_name);
 
    formatData()
    let age = props.tokenDetails.age>0 ? props.tokenDetails.age : '';
    let gender = props.tokenDetails.gender  ? props.tokenDetails.gender : '';
    let strPrintOption = props.printOption;
    console.log("PrintOption is ", strPrintOption);

    
    
      // Call the function to initiate the process
      //fetchData()
    
    printerListenAndProcess (strPrintOption,age,gender);
   
    
  }, []);

  function replaceBarcode(zplCodeTemplate){
    var visitIdFrmProps = props.tokenDetails.visitId;
    var uac13Code = padWithZeros(visitIdFrmProps, 13)
    var strPatientId =  props.patientDetails.id;

    const zplCode = zplCodeTemplate
    .replace('{{ strPatientId }}', strPatientId)
    .replace('{{ uac13Code}}', uac13Code);

    console.log("barcode is  " + zplCode);
    return zplCode;
  }

  function replaceRegCard(regCardZPLCode,patientDetails,age,gender){
    console.log("The RegCardZPL to replace", regCardZPLCode);
    var dateFormat = new Date(patientDetails.registeredOn); 
    var strAge = (patientDetails.age > 0 ? patientDetails.age  : ' -');
    var strGender = gender?(gender == "Male" ? 'M' :'F'): '-' ;
    var strValidity = "*Card expires after 1yr of registration";
    var strPatientName = patientDetails.fname + " " + patientDetails.mname + " " +  patientDetails.lname ;

    var strCity =  patientDetails.city;
    var strPatientId =  patientDetails.id;



    var regOn = dateFormat.getDate()+"-"+(dateFormat.getMonth()+1)+ "-"+dateFormat.getFullYear();
    const strDay = dateFormat.getDate();
    const strMonth = dateFormat.toLocaleString('default', { month: 'short' });
    const strYear = dateFormat.getFullYear();
    const strSuffix = getNumberWithSuffix(strDay);
    const formattedDate = `${strSuffix} ${strMonth} ${strYear}`;
    
    
    const zplCode = regCardZPLCode
    .replace('{{strPatientName}}', strPatientName)
    .replace('{{gender}}', strGender)
    .replace('{{age}}',strAge )
    .replace('{{strCity}}', strCity)
    .replace('{{ strPatientId }}', strPatientId)
    .replace('{{ formattedDate   }}', formattedDate)
    .replace('{{strValidity}}', strValidity);

    console.log("The RegCardZPL after replace", zplCode);

    return zplCode;
  }

  function replaceTokenDetails(tokenZPLCode,age,gender){
    console.log("Token before replace ", tokenZPLCode);
    var strToken = (props.tokenDetails.tokenNumber ? props.tokenDetails.tokenNumber : ' -');
    var strDeskNo = props.tokenDetails.deskNumber;
    let strDeskAndToken =  strDeskNo + "-" + strToken;
    var strAge = (age ? age + "yrs" : ' -');
    var strGender = (gender?(gender == "Male" ? 'M' :'F'): '-');
    var strDocName = "";
    var strCounName="";
    var strPatientName = props.patientDetails.fname + " " + props.patientDetails.mname + " " +  props.patientDetails.lname ;
    var strCity =  props.patientDetails.city;
    var strPatientId =  props.patientDetails.id;
    var visitIdFrmProps = props.tokenDetails.visitId;
    // console.log("Props in merge pdf is",props.deskDetails[visitIdFrmProps].person.first_name);
    if(props.deskDetails[props.patientDetails.id] && props.deskDetails[props.patientDetails.id].person.first_name){
      strDocName = "Dr " + props.deskDetails[props.patientDetails.id].person.first_name + " " + props.deskDetails[props.patientDetails.id].person.last_name;
    }
    if(props.deskDetails[props.patientDetails.id] && props.deskDetails[props.patientDetails.id].counsellor.first_name){
      strCounName = "Dr " + props.deskDetails[props.patientDetails.id].counsellor.first_name + " " + props.deskDetails[props.patientDetails.id].counsellor.last_name;
    }

    const zplCode = tokenZPLCode
    .replace('{{strDeskAndToken}}', strDeskAndToken)
    .replace('{{strDocName}}', strDocName)
    .replace('{{strCounName}}', strCounName)
    .replace('{{strPatientName}}', strPatientName)
    .replace('{{strGender}}', strGender)
    .replace('{{age}}', age)
    .replace('{{strCity}}', strCity)
    .replace('{{formattedDate}}', formattedDate)
    .replace('{{strPatientId}}', strPatientId);

    console.log("The RegCardZPL after replace", zplCode);

    return zplCode;


  }

  async function readZPLFile(filePath) {
    const response = await fetch(filePath);

    const zplCode = await response.text();
    return zplCode;
  }

  async function fetchData(age,gender) {
    try {
      barCodeZPLCode = await readZPLFile('/static/barCodeTemplate.zpl');
      
      replacedBarcode = await replaceBarcode(barCodeZPLCode);
      console.log(" The Barcode ZPL iis--> ", replacedBarcode);

      tokenZPLCode = await readZPLFile('/static/tokenCodeTemplate.zpl');
      replacedToken = await replaceTokenDetails(tokenZPLCode,age,gender);
      console.log(" The Replaced Barcode ZPL iis ", replacedToken);

      regCardZPLCode = await readZPLFile('/static/regCardTemplate.zpl');
      replacedRegCardDetails = await replaceRegCard(regCardZPLCode,props.patientDetails,age,gender)

      console.log(" The Replaced REgCard ZPL iis ", replacedRegCardDetails);


    }catch (error) {
  console.error('Error reading ZPL code:', error);
}
  }

  async function printerListenAndProcess(strPrintOption,age,gender) {
    await fetchData(age,gender);
    if(strPrintOption.token){
      var tokenGen = false;
      var patientIdFrmProps = props.tokenDetails.id ;
      console.log("VisitId is  ", patientIdFrmProps);
      if( props.deskDetails[patientIdFrmProps] && props.deskDetails[patientIdFrmProps].person && props.deskDetails[patientIdFrmProps].person.first_name.length){
        tokenGen = true;
        console.log("number of print in side for",numberOfPrints);
        createToken(age,gender,tokenZPLCode)
        numberOfPrints++;
      }
      
      console.log("number of print ",numberOfPrints)
      
    } 
    if(strPrintOption.idcard){
      var regCardGen= true;
      console.log("Next");
      numberOfPrints++;
      console.log("number of print ",numberOfPrints)
      createRegCard(props.patientDetails,age,gender,regCardZPLCode)
    } 
    
    if(strPrintOption.barcode){
      var barcodeFormat = true;
      numberOfPrints++;
      console.log("number of print ",numberOfPrints)
      console.log("Next");
      createBarCode()
    }

    if(strPrintOption.receipt ){
      var receipt = true;
      numberOfPrints++;
      console.log("receipt token",receipt);
     // createRegFeeReciept()
    }

    if (numberOfPrints > 0){
      console.log("The Print is");
       printAll(tokenGen,regCardGen,barcodeFormat,receipt);
   }else{
    console.log("The Print is");
    props.onModalClose("could not print returning");
  }

  }

  function getNumberWithSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = number % 100;
    return number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0]);
  }

  const formatData= ()=>{
    let age = props.tokenDetails.age>0 ? props.tokenDetails.age : '';
    let gender = props.tokenDetails.gender  ? props.tokenDetails.gender : '';
    let deskString = props.tokenDetails.deskNumber;
    const deskNo = deskString.replace(/\D/g, "");
    // createToken(age,gender);
  }
  //Added on 28-10-2023
  function padWithZeros(number, width) {
    const numberString = number.toString();
    const zerosToAdd = Math.max(0, width - numberString.length);
    return '0'.repeat(zerosToAdd) + numberString;
  }
  //Added Ends Here

  function createRegCard(patientDetails,age,gender,regCardZPLCode) {
  // let regOn = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(patientDetails.registeredOn);
    var dateFormat = new Date(patientDetails.registeredOn); 
    var strAge = (patientDetails.age > 0 ? patientDetails.age +"yrs" : ' -');
    console.log("strGender",gender, gender == "Male" ? 'M' :'F');
    var strGender = gender?(gender == "Male" ? 'M' :'F'): '-' ;
    var strValidity = "*Card expires after 1yr of registration";
    var strPatientName = patientDetails.fname + " " + patientDetails.mname + " " +  patientDetails.lname ;
    console.log("strPatientName",strPatientName, age);
    var strCity =  patientDetails.city;
    var strPatientId =  patientDetails.id;
    console.log("Reg On", dateFormat);

    console.log("Date: "+ dateFormat.getDate()+
           "-"+(dateFormat.getMonth()+1)+
           "-"+dateFormat.getFullYear());
    var regOn = dateFormat.getDate()+"-"+(dateFormat.getMonth()+1)+ "-"+dateFormat.getFullYear();
    const strDay = dateFormat.getDate();
    const strMonth = dateFormat.toLocaleString('default', { month: 'short' });
    const strYear = dateFormat.getFullYear();
    const strSuffix = getNumberWithSuffix(strDay);
    const formattedDate = `${strSuffix} ${strMonth} ${strYear}`;
    

   

    var zplREgCard = [
      `^XA
^LH20,20
^FO0,0^A0N,30^FD`+strPatientName+`^FS
^FO0,35^A0N,22^FD`+gender+`^FS
^FO80,35^A0N,22^FD`+age+`^FS
^FO120,35^A0N,20^FDyrs^FS
^FO180,35^A0N,20^FD`+strCity+`^FS
^FO0,60^A0N,22^FDValid From^FS
^FO180,60^A0N,22^FD`+ formattedDate +`^FS
^FO0,90^A0N,22^FDMAIN^FS
^FO180,90^A0N,22^FD `+ strPatientId+`^FS
^FO0,110^A0N,15^FD`+strValidity+ `^FS
^XZ`];

    var zplREgCard1= [
    `^XA
    ^FO50,2^A0N,30^FD`+strPatientName+`^FS
    ^FO50,35^A0N,22^FD`+gender+`^FS
    ^FO80,35^A0N,22^FD`+age+`^FS
    ^FO120,35^A0N,20^FDyrs^FS
    ^FO180,35^A0N,20^FD`+strCity+`^FS
    ^FO50,60^A0N,22^FDValid From^FS
    ^FO180,60^A0N,22^FD `+ formattedDate +`^FS
    ^FO50,85^A0N,22^FDMAIN^FS
    ^FO180,85^A0N,22^FD `+ strPatientId+`^FS
    ^FO50,140^A0N,15^FD`+strValidity+ `^FS
    ^XZ`
    ]

    console.log("The Replaced Regcard  is ",replacedRegCardDetails );
    // patientRegInfo = zplREgCard;
    // patientRegInfo = replacedRegCardDetails;
    patientRegInfo.push(replacedRegCardDetails);
    //Print the Registration Card
    console.log("Reg Card");
    //printAll(patientRegInfo);

   
}
//Function which creates the Token
function createToken(age,gender,tokenZPLCode){
  console.log("gender type", typeof(gender), gender);
  var strToken = (props.tokenDetails.tokenNumber ? props.tokenDetails.tokenNumber : ' -');
  var strDeskNo = props.tokenDetails.deskNumber;
  let strDeskAndToken =  strDeskNo + "-" + strToken;
  var strAge = (age ? age + "yrs" : ' -');
  var strGender = (gender?(gender == "Male" ? 'M' :'F'): '-');
  var strDocName = "";
  var strPatientName = props.patientDetails.fname + " " + props.patientDetails.mname + " " +  props.patientDetails.lname ;
  var strCity =  props.patientDetails.city;
  var strPatientId =  props.patientDetails.id;
  console.log("The Deskno ", strDeskAndToken);
  var visitIdFrmProps = props.tokenDetails.visitId;
  // console.log("Props in merge pdf is",props.deskDetails[visitIdFrmProps].person.first_name);
  if(props.deskDetails[visitIdFrmProps] && props.deskDetails[visitIdFrmProps].person.first_name){
    strDocName = "Dr " + props.deskDetails[visitIdFrmProps].person.first_name + " " + props.deskDetails[visitIdFrmProps].person.last_name;
  }
  console.log("Doctor Name  ", strDocName);
  var zplToken = [
    `^XA

    ^FO50,5^A0N,50^FD`+strDeskAndToken +`^FS
    ^FO200,5^A0N,25^FD`+strDocName + `^FS
    ^FO50,55^A0N,25^FD` + strPatientName +`^FS
    ^FO50,85^A0N,22^FD` + strGender + `^FS
    ^FO80,85^A0N,22^FD`+ age +`^FS
    ^FO120,85^A0N,20^FDyrs^FS
    ^FO180,85^A0N,20^FD` + strCity + `^FS
    ^FO50,115^A0N,22^FDVisited On^FS
    ^FO160,115^A0N,22^FD` + formattedDate +`^FS
    ^FO300,115^A0N,22^FDMAIN^FS
    ^FO360,115^A0N,22^FD `+ strPatientId +`^FS
    
    
    ^XZ`
    
  ];
  var zplToken1 = [

  `^XA

^FO30,5^A0N,50^FD`+strDeskAndToken +`^FS
^FO190,5^A0N,25^FD`+strDocName + `^FS
^FO30,55^A0N,25^FD` + strPatientName +`^FS
^FO30,85^A0N,22^FD` + strGender + `^FS
^FO50,85^A0N,22^FD`+ age +`^FS
^FO100,85^A0N,20^FDyrs^FS
^FO160,85^A0N,20^FD` + strCity + `^FS
^FO30,115^A0N,22^FDVisited On^FS
^FO140,115^A0N,22^FD` + formattedDate +`^FS
^FO280,115^A0N,22^FDMCode^FS
^FO340,115^A0N,22^FD `+ strPatientId +`^FS


^XZ`
  ];
  console.log(" The Replaced Token Card ZPL iis ", replacedToken);
  // tokenDetails = zplToken;
  tokenDetails.push(replacedToken);
  // tokenDetails = replacedToken;

  console.log("Print Token");
  //printAll(tokenDetails);
 
  
}

async function createBarCode(){

  await fetchData()
  var visitIdFrmProps = props.tokenDetails.visitId;
  var uac13Code = padWithZeros(visitIdFrmProps, 13)
  console.log("uac 13 code " +uac13Code);
  var strPatientId =  props.patientDetails.id;

  console.log(" The Replaced Barcode ZPL iis--> ", replacedBarcode);

  var zplBarcode1 = [

`^XA
^BEN,75,Y,N
^FD`+uac13Code+`^FS
^FO10,120^A0N,22^FD MAIN` + strPatientId +`^FS


^XZ` //this is 13 digit visitid
  ];

  var zplBarcode = [
    `^XA
^FO50,10^A0N,22^FD MAIN ` + strPatientId +`^FS
^LH50,20
^FO50,20 
^BEN,80,Y,N
^FD`+uac13Code+`^FS



^XZ`
  ]

  console.log(" The Barcode ZPL iis--> ", zplBarcode);
  // barCodeDetails = zplBarcode;
  barCodeDetails.push(replacedBarcode);
  // barCodeDetails = replacedBarcode;
  //printAll(barCodeDetails);
  
}

//regestration fee 
function createRegFeeReciept(){
  console.log("create reg receipt");
  qz.websocket.connect().then(function() {
    var config = qz.configs.create(printerName,
    // var config = qz.configs.create("HP DeskJet 1200 series",
  // var config = qz.configs.create("Canon LBP6030/6040/6018L");
  {
    size: {width: 148, height: 210}, units: 'mm',
        scaleContent: false,
        }
        );
  var data = [{
    type: 'pdf',
     data: regpdfUrl
    //data: "91_HMS23_24_78.pdf"
  }];
  
  qz.print(config, data).catch(function(e) {
    console.error(e);
    alert('Error printing:\n\n\t' + e);
  });
  }).catch(function(e) { console.error(e); });
  
  }

//Added on 20th Oct
function printAll(tokenCard,regCard,barCodeFormat, receipt){
  qz.websocket.connect().then(function() { 
    return qz.printers.find("IMPACT by Honeywell IH-2 (ZPL)");              // Pass the printer name into the next Promise
 }).then(function(printer) {
    var config = qz.configs.create(printer);       // Create a default config for the found printer
    console.log("The Printers are ", printer);
    if(barCodeFormat){
      console.log("barCodeDetails = ", barCodeDetails);
      qz.print(config, barCodeDetails ).then(() => {
            console.log("print complete");
            numberOfPrints--;
          console.log("number of print before disconnect ",numberOfPrints)
          if(numberOfPrints === 0){
             qz.websocket.disconnect().then(() => {

              props.onModalClose("printed successfully");
            
          
        });;
          }
            //Promise.resolve('Printing successful');
            // return qz.websocket.disconnect();
        }).catch(function(e) { 
          console.log("caught error");
          numberOfPrints--;
        console.log("number of print in barcode catch error ",numberOfPrints)
        if(numberOfPrints === 0){
           qz.websocket.disconnect().then(() => {
            props.onModalClose("printed successfully");
        })
          }
          });
    }

    if(tokenCard){
      console.log("tokenDetails = ",tokenDetails);
      qz.print(config, tokenDetails ).then(() => {
        console.log("print complete");
        numberOfPrints--;
      console.log("number of print before disconnect ",numberOfPrints)
      if(numberOfPrints === 0){
         qz.websocket.disconnect().then(() => {

          props.onModalClose("printed successfully");
        
      
    });
      }
        //Promise.resolve('Printing successful');
        // return qz.websocket.disconnect();
    }).catch(function(e) { 
      console.log("caught error");
      numberOfPrints--;
    console.log("number of print in token catch error ",numberOfPrints)
    if(numberOfPrints === 0){
       qz.websocket.disconnect().then(() => {
        props.onModalClose("printed successfully");
    })
      }
      });
    }

    if(regCard){
      console.log("patientRegInfo = ", patientRegInfo);
      qz.print(config, patientRegInfo ).then(() => {
        console.log("print complete");
        numberOfPrints--;
      console.log("number of print before disconnect ",numberOfPrints)
      if(numberOfPrints === 0){
         qz.websocket.disconnect().then(() => {

          props.onModalClose("printed successfully");
        
      
    });
      }
        //Promise.resolve('Printing successful');
        // return qz.websocket.disconnect();
    }).catch(function(e) { 
      console.log("caught error");
      numberOfPrints--;
    console.log("number of print in reg card catch error ",numberOfPrints)
    if(numberOfPrints === 0){
       qz.websocket.disconnect().then(() => {
        props.onModalClose("printed successfully");
    })
      }









      });
    }
    
    if(receipt){
      console.log("create reg receipt");
      var configs = qz.configs.create(printerName,
      //var configs = qz.configs.create("HP DeskJet 1200 series",
      // var configs = qz.configs.create("Canon LBP6030/6040/6018L",
      {
        size: {width: 148, height: 210}, units: 'mm',
            scaleContent: false,
            }
            );
      var data = [{
        type: 'pdf',
        data: regpdfUrl 
        //data: "reciept.pdf"
       // data: "91_HMS23_24_78.pdf"
      }];
      
      qz.print(configs, data).then(() =>{
        console.log("print complete");
        numberOfPrints--;
      console.log("number of print before disconnect ",numberOfPrints)
      if(numberOfPrints === 0){
         qz.websocket.disconnect().then(() => {
          props.onModalClose("printed successfully");
    });
      }
      }).catch(function(e) { 
        console.log("caught error");
        numberOfPrints--;
      console.log("number of print in recipt catch error ",numberOfPrints)
      if(numberOfPrints === 0){
         qz.websocket.disconnect().then(() => {
          props.onModalClose("printed successfully");
      })
        }
        });
    
    }
   
    // return qz.print(config, data4 );
 }).catch(function(e) { 
  console.error("connect failure" ,e);
  props.onModalClose("could not print returning");
 });
}




return (
  <>
  
  </>
);

}


export default SeparatePdf;
