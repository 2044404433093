import InsuranceMasterList from './insuranceMasterList';
import insuranceMasterGrid from './insuranceMasterGrid';

import InsuranceMasteradd from './insuranceMasteradd';

import TpaGrid from './tpaGrid';






const icomponents = {
    InsuranceMasterList,
    insuranceMasterGrid,
    InsuranceMasteradd,
    TpaGrid,

}

export default icomponents;
