import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getAllServiceCategory() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    const url = basePath + `/master/category`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        console.log("Category Details: ", data);
        return data;
      });
}

function addService(data) {
  var accessToken;
  if(helperServices.getAuthorization())
  {
      accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
      method: "POST",
      headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken
      },
      body: JSON.stringify(data),
    };
  const url = basePath + `/master/category`;
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(data => {
          return data;
      });
}


const categoryServices = {
    getAllServiceCategory,
    addService
  };
  export default categoryServices;
  
