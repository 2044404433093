import { COMPOSITION_SAVE, COMPOSITION_SAVE_FAILURE, GET_TEMPLATEID_DETAILS, 
    GET_TEMPLATEID_DETAILS_FAILURE,GET_COMPOSITIONID_DETAILS,GET_COMPOSITIONID_DETAILS_FAILURE, GET_ICD10_DETAILS,GET_ICD10_DETAILS_FAILURE} from "../../shared/constants";
import {ehrServices} from "../services";

export function saveComposition(data,patientId,visitId,templateId,patientEhrid,notesDetails,resCb) {
    console.log("SearchText,Action",data);
    return (dispatch) => {
        console.log("SearchText,Action",data)
        ehrServices.saveComposition(data,patientId,visitId,templateId,patientEhrid,notesDetails)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(COMPOSITION_SAVE, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(COMPOSITION_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getTemplateById(data,templateId,resCb) {
    return (dispatch) => {
        ehrServices.getTemplateById(templateId)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(GET_TEMPLATEID_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_TEMPLATEID_DETAILS_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function getIcd10Values(data,resCb) {
    return (dispatch) => {
        ehrServices.getIcd10Values()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(GET_ICD10_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_ICD10_DETAILS_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function getCompositionByVid(data,versionUid,patientEhrid,resCb) {
    return (dispatch) => {
        ehrServices.getCompositionByVid(versionUid,patientEhrid)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(GET_COMPOSITIONID_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_COMPOSITIONID_DETAILS_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

function success(type, data){
    return { type: type, payload : data }
}
function failure(type, value) {
    return { type: type, payload : value };
}

const ehrActions = {
    saveComposition,
    getTemplateById,
    getCompositionByVid,
    getIcd10Values
}

export default ehrActions;
