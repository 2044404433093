import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
import { helperServices } from "../../shared/helper";
import Components from "./index";
import {Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';


function Vouchers(props) {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patientDetailsState.patient);
  const [voucherList, setVoucherList] = useState([]);
  const [patientInfo, setPatientInfo] = useState();
  const [nonRedeemed, setNonRedeemed] = useState(true);
  const [loading, setloading] = useState(true);
  const [disabled, setDisabled]=  useState(false);
  const [openVoucherModal, setOpenVoucherModal]=  useState(props.addVouchers);
  
  
  useEffect(() => {
    console.log("Vouchers Props",patient, props.pateintInfo);
    if( props.pateintInfo){
    setPatientInfo(props.pateintInfo.visit);
    }
    dispatch(
      patientActions.getVouchers(patient.erpCustId, (resCb) => {
        if (resCb) {
          console.log("response from addPatient api", resCb.voucherList);
          setVoucherList(resCb.voucherList);
          setloading(false);
        }
      })
    );
  }, []);

  const handleRedeemClick = (voucher,index)=>{
    console.log("voucherredeemed",index);
    voucherList[index]["redeemed"] = true;
    console.log("voucherredeemednjj",voucherList);
    dispatch(
        patientActions.redeemVoucher(patientInfo.id, patientInfo.patientId, voucher, (resCb) => {
          if (resCb) {
            console.log("response from voucherredeemed api",resCb);
              if(resCb.successMessages){
                  setNonRedeemed(false);
                  setDisabled(true);
                  props.redeemVoucher({success:resCb.successMessages});
              }
              if(resCb.errorMessages){
                  props.redeemVoucher({success:"Failed to redeemed voucher."});
              }
          }
        })
      );
  }

  const closeVoucherModal = () =>{
    props.onCloseVoucher();
  }
  console.log("voucherList", voucherList);
  return (
    <>
      {/* <main className="">
      {(!helperServices.CheckAccessLevel("Assistant")) && (!helperServices.CheckAccessLevel("Patient"))  && (voucherList && voucherList.length >0) && (
          <h5 className="">Vouchers</h5>
        )}
      </main> */}
      <Dialog
                className="d-flex flex-column-reverse voucher "
                id=""
                open={openVoucherModal}
                size="lg"
            
            >
                <div>
                <DialogTitle>
                Vouchers

                    <IconButton
                    aria-label="close"
                    className="close-model"
                    onClick={(event) => closeVoucherModal()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                {voucherList && voucherList.length >0 ? 
      <div style={{ fontSize: "14px" }}>
        <Card className="border ">
          <CardContent  className="p-3 vocherdisplay">
            {loading && 
             <main className="mainsection">
             <div className="voucherpreview">Loading....</div>
           </main>}
            {voucherList === undefined ? (
              <main className="mainsection">
                <div className="voucherpreview">No Voucher Available</div>
              </main>
            ) : (
              <div>
                {voucherList &&
                  voucherList.map((voucher, index) => (
                    <div key={index}>
                      {(helperServices.CheckAccessLevel("Assistant")) && (helperServices.CheckAccessLevel("Patient")) && (
                        // <Button theme="danger" onClick={(event)=>handleRedeemClick(voucher)}className="redeem">
                        //   Redeem{" "}
                        // </Button>
                            <Button variant="contained" color="secondary" onClick={() => handleRedeemClick(voucher)} className="redeem">
                            Redeem
                          </Button>
                      )}

<Grid container id="vochersList">
      <Grid item xs={12} md={12}>
        <Typography variant="subtitle1" className="service-label">
          Voucher Code
          <span>:</span> {voucher.voucherId}
        </Typography>
     
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="subtitle1" className="service-label">
          Narration
          <span>:</span>
          {voucher.narration}
        </Typography>
        <Typography><br/></Typography>
      </Grid>
    </Grid>
                    </div>
                  ))}
              </div>
            )}
          </CardContent >
        </Card>
      </div>
      :''}
                  </DialogContent>
                </div>
                </Dialog>
     
   
    </>
  );
}

export default Vouchers;
