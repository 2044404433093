import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { doctorActions, patientActions } from "../../../core/actions";
import Select, { createFilter } from "react-select";
import Components from "../insurance/index";
import { insuranceActions } from "../../../core/actions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {  InputAdornment, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TpaMasterList(props) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const [tpaMasterSearchFilterList, setTpaMasterSearchFilterList] = useState(
    {}
  );
  const [addTpaMaster, setTpaMaster] = useState(false);
  const [tpaSearchList, setTpaSearchList] = useState({});

  const handleServices = (service) => {
    console.log("service selected :", service);
  };
  let proc = {};
  const clearSearch = (event) => {
    setSearchText("");
  }

  useEffect(() => {
    dispatch(
      insuranceActions.gettpaMaster((resCb) => {
        if (resCb) {
          console.log("getTpaMaster", resCb);
          setTpaSearchList(resCb.tpaMasterList);
          setTpaMasterSearchFilterList(resCb.tpaMasterList);
          console.log("response from api : ", resCb);
        }
      })
    );

    //setting opening modal
  }, []);
  const closeServiceModal = (event) => {
    props.onModalClose(event);
  };

  const handleClick = (pDetail, isAddNew) => {
    setTpaMaster(!addTpaMaster);
    console.log("props.history", props);
    props.history.push("/tpamaster");
  };

  const handleInputChange = (event) => {
    let emptymsg = {};
    // setMessageDetails({message: null});
    let value = event.target.value;
    validateAndChange(value);
    // sessionStorage.setItem("searchvalue", JSON.stringify(value));
    console.log("value...", value);
  };

  const validateAndChange = (value) => {
    let nullarray = [];

    if (value.length < 3) {
      // setinsuranceSearchList(nullarray);
      setSearchText(value);
      //   setSearchError("Search Text should contain atleast 3 letters");
      // messageDetails.messageStrip='';
      //   setShowMsgStrip(false);
      setTpaMasterSearchFilterList(tpaSearchList);
    }
    if (!value) {
      // messageDetails.messageStrip='';
      // setinsuranceSearchList(nullarray);
      console.log("tpaSearchList null array !value : ", value, tpaSearchList);
      //   setShowMsgStrip(false);
      setTpaMasterSearchFilterList(tpaSearchList);
    }
    //  else {
    if (value.length >= 3) {
      //   setSearchError("");
      setSearchText(value);
      // handleSearch(value);
      //   setShowList(true);
      let search = { patient: value };
      console.log("search input : ", value);
      searchPatientResult(value);
    }
    // }
  };
  const getFullName = (fname, mname, lname) => {
    var fullName = "";
    console.log("fname : ", fname);
    console.log("mname : ", mname);
    console.log("lname : ", lname);
    if (!fname || fname == null) {
      fname = "";
    }
    if (!mname || mname == null) {
      mname = "";
    }
    if (!lname || lname == null) {
      lname = "";
    }
    var pfname = fname;
    console.log("pfname : ", pfname);
    //   .toLowerCase();
    var pmname = mname;
    console.log("pmname : ", pmname);
    //   .toLowerCase();
    var plname = lname;
    console.log("plname : ", plname);
    //   .toLowerCase();
    if (pfname == null) {
      pfname = "";
    }
    if (pmname == null) {
      pmname = "";
    }
    if (plname == null) {
      plname = "";
    }
    pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1);
    pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1);
    plname = plname.charAt(0).toUpperCase() + plname.slice(1);
    if (fname && lname) {
      fullName = pfname + " " + plname;
      console.log("fullName : ", fullName);
    } else if (fname && mname) {
      fullName = pfname + " " + pmname;
      console.log("fullName : ", fullName);
    } else if (fname && mname && lname) {
      fullName = pfname + " " + pmname + " " + plname;
      console.log("fullName : ", fullName);
    } else {
      fullName = pfname + "      ";
      console.log("fullName : ", fullName);
    }
    return fullName;
  };
  const searchPatientResult = (data) => {
    console.log("data searched ", data);
    // setMessageDetails({ message: "" });

    let filteredData = tpaSearchList.filter((item) => {
      return item.first_name && item.first_name.includes(data);
    });

    setTpaMasterSearchFilterList(filteredData);

    console.log("filteredData", tpaMasterSearchFilterList);

    return tpaMasterSearchFilterList;
    // insuranceMasterSearchFilterList=filteredData;
  };
  const callBackGrid = (data) => {
    console.log("datadata", data);
    dispatch(
      insuranceActions.gettpaMaster((resCb) => {
        if (resCb) {
          console.log("getTpaMaster", resCb);
          setTpaSearchList(resCb.tpaMasterList);
          setTpaMasterSearchFilterList(resCb.tpaMasterList);
          console.log("response from api : ", resCb);
        }
      })
    );
  };

  return (
    <>
      <div class="mainsection">
        <div >
          {/* border-left  */}
          <span
            className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
            onClick={(event) => closeServiceModal(event)}
          >
            X
          </span>
        </div>
        <div className="listTitles">
        <Grid item xs={12} 
    className="page-header mb-3 doctorList">
          <h5
            // subtitle="Dashboard"
            className="text-sm-left mb-0"
          >
            Third Party Administrators
          </h5>
          
          <Button 
          variant="contained" 
          className="floatingbtn"  
          onClick={(event) => handleClick("", true)}
          style={{ textTransform: "none" }}>
        Add New
      </Button>
        </Grid>
        </div>
        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{ color: `#111b21` }} className="material-icons">
                  search
                </i>
              </span>
            </div>
            {/* <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search ComapanyName..."
              value={searchText}
              type="search"
              onChange={handleInputChange}
            /> */}

<TextField 
               className="navbar-search mb-2 menu-search bg-white custom-text"
               placeholder="Search ComapanyName..."
               autoFocus
               value={searchText}
               type="search"
               onChange={handleInputChange}
               InputProps={{
                style: { paddingLeft: '15px',height:"35px"}, 
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && ( // Render the close icon only if searchText exists
                      <IconButton edge="end" onClick={clearSearch} tabIndex={-1} 
                      size="small" 
                style={{ padding: '6px' }}
                >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
               />
          </div>
        </form>

      {tpaMasterSearchFilterList && tpaMasterSearchFilterList.length > 0 &&
        <Components.TpaGrid
        tpamaster={tpaMasterSearchFilterList}
        onGridAction={(event) => callBackGrid(event)}
      />
      }
      
      </div>
    </>
  );
}
export default TpaMasterList;
