import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    history.push("/");
    window.location.reload();
  }

function saveComposition(data,patientId,visitId,templateId,patientEhrid,notesDetails) {
  console.log("paydghdbckc",patientEhrid)
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "POST",
      headers: {
       // Accept: "application/json",
       Prefer:'return={representation|minimal}',
        "Content-Type": "application/json",
        // Authorization: 'Basic c3VtYTpzdW1hcGFzcw==',
      	Authorization: accessToken,
      },
      body: JSON.stringify(data),
      // body: data,
    };
   const url = basePath + `/v1/Ehr/Composition/Create?patientId=${patientId}&visitId=${visitId}&templateId=${templateId}&patientEhrid=${patientEhrid}&notesDetails=${notesDetails}`;
  //  const url = 'http://164.52.223.224:8080/ehrbase/rest/openehr/v1/ehr/42fb7477-de5a-498c-9300-03243d53c643/composition';
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        // console.log('data : ',data)
        return data;
      }
      );
  }
  
  function getTemplateById(templateId) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/ehr/template/preview?templateId=${templateId}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
      console.log("data:",data);
        return data;
      } );
  }

function getIcd10Values() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/ehr/Composition/diagnosis/icd10`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
      console.log("data:",data);
        return data;
      } );
  }

  function getCompositionByVid(versionUid,patientEhrid) {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/ehr/Composition/preview?versionUid=${versionUid}&patientEhrid=${patientEhrid}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
      console.log("data:",data);
        return data;
      } );
  }

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}


const ehrServices = {
    saveComposition,
    getTemplateById,
    getCompositionByVid,
    getIcd10Values
  };
  export default ehrServices;
