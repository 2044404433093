import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect } from "react";
import { MANASWINI_LOGO, MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";

function RegistrationCard(props){ 
    useEffect(() => {
        createRegCard(props.patientDetails);
    }, []);
    function createRegCard(patientDetails) {
     const docDefinition = {
      pageSize: {
        width: 	1012,
        height:	638 ,
      },
      defaultStyle: {
        fontSize: 12,
      },
      background: {
        image: MANASWINI_BACKGROUND,
        width: 1000,
        height: 600,
        color: '#ff0000'
      },
      pageMargins: [2, 2, 2, 2],
      content: [
        {columns: [
          {
            image: MANASWINI_LOGO,
            fit: [90, 90], // set the width and height of the image
            alignment: "left",
            margin: [20, 18, 0, 0],
            width: 50, // set the top and bottom margin of the image
          },
          [
            {
              text: HOSPITAL_NAME,
              style: "header",
              margin: [80, 30, 0, 0], 
            },
            {
              stack: [
                {
                  text: HOSPITAL_DESC,
                  style: "subheader",
                  margin: [80, 5, 0, 0], 
                },
              ],
            },
          ],
        ]},
        {
            text: "Patient Name: "+ patientDetails.fname+" "+patientDetails.mname+" "+patientDetails.lname,
            style: "heading",
            width: "auto",
            alignment:'left',
            margin: [30, 60, 0, 0], 
          },
          {
            text: "Patient ID: "+patientDetails.id,
            style: "heading",
            width: "auto",
            alignment:'left',
            margin: [30, 15, 0, 0], 
          },
          {
            text:"Age: "+(patientDetails.age > 0 ? patientDetails.age : ' -'),
            style: "heading",
            width: 90,
            alignment:'left',
            margin: [30,15, 0, 0], 
          },
          {
            text:"Gender: "+(patientDetails.gender ? patientDetails.gender : ' -'),
            style: "heading",
            width: 90,
            alignment:'left',
            margin: [30,15, 0, 0], 
          },
          {
            text: "Phone Number: "+(patientDetails.phno ? patientDetails.phno : ' -'),
            style: "heading",
            width: "auto",
            alignment:'left',
            margin: [30, 15, 0, 0], 
          },
         {
            text: HOSPITAL_NAME,
            alignment: "left",
            fontSize: 20,
            color: "#176fad",
            bold: true,
            margin: [25, 120, 0, 0],
          },
          {
            text: HOSPITAL_DESC,
            alignment: "left",
            fontSize: 20,
            color: "#176fad",
            bold: true,
            margin: [25, 0, 0, 0],
          },
          {
            text: HOSPITAL_ADDRESS,
            alignment: "left",
            fontSize: 16,
            margin: [25, 0, 0, 0],
          },
          {
            text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
            alignment: "left",
            fontSize: 16,
            margin: [25, 0, 0, 5],
          }, 
      ],
      styles: {
        header: {
          fontSize: 30,
          bold: true,
          color: "#176fad",
        },
        subheader: {
          fontSize: 20,
          bold: true,
          color: "#176fad",
        },
        heading:{
          fontSize: 25,
          bold: true,
          textTransform: 'capitalize'
        },
        subtext:{
          fontSize: 12,
        }
      },
      
    };
    
    PdfPrinter.createPdf(docDefinition).open();
    }

    return (<></>);

}

export default RegistrationCard;

