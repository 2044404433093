import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { patientActions } from "../../core/actions";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../common/BtnCellRenderer";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import  {Grid}  from '@mui/material';


function InsuranceProcedureSettlement() {

  const dispatch = useDispatch();
  const [insurancesettle, setInsurancesettle] = useState();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [recievedAmount, setRecivedAmount] = useState("");
  const [settleclick, setSettleclick] = useState(false);
  const [rowselect, setRowselect] = useState([]);
  const [searchVid, setSearchVid] = useState('');
  const [searchtext, setSearchtext] = useState(false);
 // const [validate, setValidate] = useState(false);
  const [sedetails, setSedetails] = useState([{
    seBankRefno: "",
    seDesc: "",
    seDate: "",
  }]);
  // const [formErrors, setFormErrors]=useState({
  //   seBankRefno: "",
  //   seDate: "",
  //   seDesc: "",
  // })

  const [bankrefErrors, setBankRefErrors] = useState({
    seBankRefno: "",
  })

  const [dateErrors, setDateErrors] = useState({
    seDate: "",
  })

  const [descErrors, setDescErrors] = useState({
    seDesc: "",
  })

  const [columnDefs] = useState([
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "InvoiceNumber",
      field: "invoiceNum",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "VisitDate",
      field: "visitDate",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "ClaimNumber",
      field: "claimNum",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "ClaimAmount",
      field: "amount",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
  ]);

  const [columnDef] = useState([
    {
      headerName: "InvoiceNumber",
      field: "invoiceNum",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "ClaimNumber",
      field: "claimNum",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "ClaimAmount",
      field: "amount",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
  ]);

  const [diffAmount, setdiffAmount] = useState("");

  const [settledlist, setSettledlist] = useState(
  [ { 
    id: "",
    patientId: "",
    visitId: "",
    invoiceNum: "",
    type: "",
    seBankRefno: "",
    seDesc: "",
    seDate: "",
    status: "",
    udt : "",
    }
  ]
  
  );
  const [list,setList] = useState([]);
  let insurancelocal = "";
  useEffect(() => {
    let data = {};

    
    
    dispatch(
      patientActions.ListInsuranceSettlements(data, (resCb) => {
        console.log("resCb", resCb.insuranceSettlementList);

        if (resCb.insuranceSettlementList) {
          let insurancelocal = resCb.insuranceSettlementList;
          for (let settle of insurancelocal) {
            settle["name"] =
              settle.firstName +
              " " +
              settle.middleName +
              " " +
              settle.lastName;

             
          }
          setInsurancesettle(insurancelocal);
          console.log("insurancelocal", insurancelocal);
          console.log("insurance_setle", insurancesettle);
        }
      })
    );

  }, []);

 
    
   
  
    const didMount = useRef(false);
    useEffect(() => {
   
      if (!didMount.current) {
        didMount.current = true;
        return;
      }
      console.log("listuseeffect", settledlist);
      if (settledlist && settledlist.length != 0) {
       
        dispatch(patientActions.updateInsuranceProcedure(settledlist,(resCb) => {
        console.log("settlementlist:",settledlist);
        if(resCb.count > 0){
            console.log("resCb",resCb);
           
            sedetails.seBankRefno = ''
            sedetails.seDate = ''
            sedetails.seDesc = ''
            setSettleclick(false);
        }
        }));

       
      }
    }, [settledlist]);

    const Mount = useRef(false);
    useEffect ((name) => {
      if (!Mount.current) {
        Mount.current = true;
        return;
      }
        validateField("seDate")
        validateField("seBankRefno")
        validateField("seDesc")
      
 
      
    },[sedetails]);
  


  const settlement = (e) => {
    setSettleclick(true);
  };

  const closeModel = (e) => {
    setSettleclick(false);
  };

  const rowSelectionType = "multiple";

  const handleInputChange = (e) => {
    console.log(e.target.value);
    let Recvamnt = 0;
    Recvamnt = e.target.value;
    setRecivedAmount(Recvamnt);
    console.log("recivedamount", recievedAmount);
  };

  const onSelectionChanged = (e) => {
    console.log("rowselect", e.api.getSelectedRows());
    let settle = e.api.getSelectedRows();
    console.log("settlelength",settle.length);
    setRowselect([...settle])
    console.log("selectedrows",rowselect);
    let amount = 0;
    let diffamount = 0;
    if (settle){
      for(let amnt of settle ){
        amount = amount + amnt.amount;
      }
      console.log("totalamount",amount);
      diffamount = recievedAmount - amount;
      console.log("diffamount", diffamount);
      setdiffAmount(diffamount);
    } 
  }

 
  const handleSubmit = (e) => {
  if (!validateField()){
    return
  }
  
   console.log("selrows:",rowselect);
      let selecteddata = [...rowselect];
      console.log("seldata", selecteddata.length);
      let settledListlocal = []
      
      let amount = 0;
      let diffamount = 0; 
      if (selecteddata) {
        for (let singlerow of selecteddata) {
          console.log(singlerow);
          
          let settleditem = {};
          amount = amount + singlerow.amount;
          settleditem["id"] = singlerow.id;
          settleditem["patientId"] = singlerow.patientId;
          settleditem["visitId"] = singlerow.visitId;
          settleditem["invoiceNum"] = singlerow.invoiceNum;
          settleditem["type"] = singlerow.type;
          settleditem["seBankRefno"] = sedetails["seBankRefno"];
          if(settleditem["seBankRefno"] === ''){
            settleditem["status"] = "noc";
          } else {
            settleditem["status"] = "Closed";
          }
          settleditem["seDate"] = sedetails["seDate"];
          settleditem["seDesc"] = sedetails["seDesc"];
          settleditem["udt"] = "";
          
          console.log("settleditem",settleditem);
         
          settledListlocal.push(settleditem)
          
        }
        console.log("settledlistlocal", settledListlocal);
        
        setSettledlist([...settledListlocal])
        
      }
       console.log("finaldata", settledlist);
      
    
  };
  
  const handleChange = (name, value) => {
  let settlementlocal = {...sedetails};
  settlementlocal[name] = value;
  setSedetails({...settlementlocal})
  console.log("sedetails", sedetails);
  };

  
  const searchByvisitId = (event) => { 
   let value = event.target.value;
    console.log("vid_search", value);
    if(value > 0){
      setSearchtext(true)
    } else {
      setSearchtext(false)
    }
    console.log("setext", searchtext);
    setSearchVid(value)
    
    console.log(searchVid);
    let data= {}
    data['visitId'] = value;
      console.log("datavid", data);
      if (value && value.length > 0){
    dispatch(
      patientActions.EditInsuranceSettlements(data, (resCb) => {
        console.log("responseclosed", resCb.insuranceSettlementList);
        if (resCb.insuranceSettlementList) {
          let insurancelocal = resCb.insuranceSettlementList;
          for (let settle of insurancelocal) {
            settle["name"] =
              settle.firstName +
              " " +
              settle.middleName +
              " " +
              settle.lastName; 
             
            sedetails.seBankRefno = settle.seBankRefno;
            sedetails.seDate = settle.seDate;
            sedetails.seDesc = settle.seDesc;
              
             
          }
          setInsurancesettle(insurancelocal);
          console.log("insurancelocal", insurancelocal);
          console.log("insurance_setle", insurancesettle);
        }
      })
    );
      }   

      else {
        let data = {}
        dispatch(
          patientActions.ListInsuranceSettlements(data, (resCb) => {
            console.log("resCb", resCb.insuranceSettlementList);
    
            if (resCb.insuranceSettlementList) {
              let insurancelocal = resCb.insuranceSettlementList;
              for (let settle of insurancelocal) {
                settle["name"] =
                  settle.firstName +
                  " " +
                  settle.middleName +
                  " " +
                  settle.lastName;
              }
              setInsurancesettle(insurancelocal);
              console.log("insurancelocal", insurancelocal);
              console.log("insurance_setle", insurancesettle);
            }
          })
        );
        
      }
  }

  const validateField = (name) => {
    let fieldValidationErrors = {...bankrefErrors};
    let dateValidationErrors = {...dateErrors};
    let descValidationErrors = {...descErrors};
    let validation = true

    if(searchtext){
     }
   else {

      if((!name && !sedetails["seBankRefno"] || sedetails["seBankRefno"] === '' )
      || (name == 'seBankRefno' && (!sedetails[name] || sedetails[name] === ''))){
      fieldValidationErrors.seBankRefno = 'Please fill BankRef Number';
      validation = false
      setBankRefErrors(fieldValidationErrors)

      }
   else if(name == 'seBankRefno' && (sedetails[name] || sedetails[name] !== '')) {
      fieldValidationErrors.seBankRefno = ''
      setBankRefErrors(fieldValidationErrors)
      }
      }
     
      
    if((!name && !sedetails["seDate"]  || sedetails["seDate"] === '' )
    || (name == 'seDate' && (!sedetails[name] || sedetails[name] === '' ))){
      dateValidationErrors.seDate =  'Please Select Date';
    validation = false
    setDateErrors(dateValidationErrors)

    } else if(name == 'seDate' && (sedetails[name] || sedetails[name] !== '')) {
      dateValidationErrors.seDate = ''
      setDateErrors(dateValidationErrors)
    }
    

   
   if((!name && !sedetails["seDesc"] || sedetails["seDesc"] === '' )
   || (name == 'seDesc' && (!sedetails[name] || sedetails[name] === '' ))){
    descValidationErrors.seDesc =  'Please fill Description';
    validation = false
    setDescErrors(descValidationErrors);
   } else if(name == 'seDesc' && (sedetails[name] || sedetails[name] !== '')){
    descValidationErrors.seDesc = ''
    setDescErrors(descValidationErrors);
   }

   
    console.log("dateerrors", dateErrors);
    console.log("bankreferrors", bankrefErrors);
    console.log("descerrors", descErrors);
    return validation;
    
  }
  

  return (
    <>
      <main class="mainsection list-patient">
       
        <h5 className="text-sm-left mb-0 p-2">Insurance Settlement</h5>
        <form className="main-navbar__search w-100 d-md-flex d-lg-flex p-2">
        <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{color:`#111b21`}} className="material-icons">search</i>
              </span>
            </div>
            {/* <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search by visit id to edit settled record..."
              value={searchVid}
              type="number"
              onChange={searchByvisitId}
            /> */}

             <TextField 
               className="navbar-search mb-2 menu-search bg-white Settlement"
               placeholder="Search by visit id to edit settled record..."
               autoFocus
               value={searchVid}
               type="number"
               onChange={searchByvisitId}
               InputProps={{
                style: { paddingLeft: '15px',height:"35px",display: "flex", justifyContent: "center", alignItems: "center" }, 
                
              }}
              
               />
          </div>
          </form>
        <div className="p-0 preview">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              rowData={insurancesettle}
              columnDefs={columnDefs}
              //checkBox={headercheckbox}
              // onRowClicked={(e) => getAggriddata(e)}
            ></AgGridReact>
            <div className="d-flex justify-content-end p-3">
              <button
                id="we"
                className="btn btn-submit btn-primary btn-style bottomright"
                type="submit"
                onClick={(e) => settlement(e)}
              >
                Settle
              </button>
              {/* <Button
            variant="contained"
            color="primary"
            type="submit"
              className=""
              onClick={(e) => settlement(e)}
              style={{ textTransform: "none" }}
             >
              Settle
           </Button> */}
            </div>
          </div>
        </div>
      </main>
      {settleclick ? 
        <div class="modalmn">
          <div
            className="p-3 preview m-5 flex-column"
            style={{ background: `#fff` }}
          >
            <div>
            <h5 className="text-sm-left mb-0">Insurance Settlement</h5>
              <span
                className=""
                style={{ float: "right" }}
                onClick={(e) => closeModel(e)}
              >
                X
              </span>
            </div>
            <div
              className="ag-theme-balham ag-style card rounded table mb-0 tcol mt-4"
              style={{ height: 500, width: `100%` }}
            >
              <AgGridReact
                frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                rowData={insurancesettle}
                columnDefs={columnDef}
                rowSelection={rowSelectionType}
                onSelectionChanged={(e) => onSelectionChanged(e)}
                rowMultiSelectWithClick={true}
                //checkBox={headercheckbox}
                //  isRowSelectable={(node) => isRowSelectable(node)}
                //checkBox={headercheckbox}
                //onRowClicked={(e) => getAggriddata(e)}
              ></AgGridReact>
            </div>
            <Grid container spacing={2} className="mt-4">
      <Grid item xs={12}>
        <form>
                  {/* <Row Form>
                    <Col md="4" className="form-group">
                      <label htmlFor="AmountrecievedDate">
                        Amount recieved Date
                      </label>
                      <FormInput
                        type="date"
                        value={sedetails.seDate}
                        onChange={(e) => handleChange("seDate", e.target.value)}
                      />
                       <div style={{ color: '#EC4147' }} >
                      <span className='err-msg'>{dateErrors.seDate}</span>
                      </div> 
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="RecievedAmount">Recieved Amount:</label>
                      <FormInput
                        type="number"
                        value={recievedAmount}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>
                  </Row> */}
                  <Grid container spacing={2}>
      <Grid item md={4}>
        <label htmlFor="AmountrecievedDate">Amount received Date</label>
        <TextField
          type="date"
          value={sedetails.seDate}
          onChange={(e) => handleChange('seDate', e.target.value)}
          variant="outlined"
          error={Boolean(dateErrors.seDate)}
          helperText={dateErrors.seDate}
          fullWidth
          InputProps={{
            style: { height:"35px" }, 
            
          }}
        />
      </Grid>
      <Grid item md={4}>
        <label htmlFor="ReceivedAmount">Received Amount:</label>
        <TextField
          type="number"
          value={recievedAmount}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          InputProps={{
            style: { height:"35px" }, 
            
          }}
        />
      </Grid>
    </Grid>



                  {/* <Row Form>
                    <Col md="4" className="form-group">
                      <label htmlFor="AmountDifference">
                        Amount Difference:
                      </label>
                      <FormInput
                        type="number"
                        value={diffAmount}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>

                    <Col md="4" className="form-group">
                      <label htmlFor="Bankrefno">Bank Reference Number</label>
                     
                      <FormInput
                        type="text"
                        required
                        value={sedetails.seBankRefno}
                        onChange={(e) =>
                          handleChange("seBankRefno", e.target.value)
                        }
                      />
                       <div style={{ color: '#EC4147' }} >
                      <span className='err-msg'>{bankrefErrors.seBankRefno}</span>
                      </div> 
                    </Col>

                    <Col md="4" className="form-group">
                      <label htmlFor="desc">Description</label>
                      <FormInput
                        type="text"
                        value={sedetails.seDesc}
                        onChange={(e) => handleChange("seDesc", e.target.value)}
                      />
                       <div style={{ color: '#EC4147' }} >
                      <span className='err-msg'>{descErrors.seDesc}</span>
                      </div> 
                    </Col>
                  </Row> */}

                  <Grid container spacing={2}>
      <Grid item md={4}>
        <label htmlFor="AmountDifference">Amount Difference:</label>
        <TextField
          type="number"
          value={diffAmount}
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        <label htmlFor="Bankrefno">Bank Reference Number</label>
        <TextField
          type="text"
          required
          value={sedetails.seBankRefno}
          onChange={(e) =>
            handleChange("seBankRefno", e.target.value)
          }
          variant="outlined"
          error={Boolean(bankrefErrors.seBankRefno)}
          helperText={bankrefErrors.seBankRefno}
          fullWidth
        />
      </Grid>
      <Grid item md={4}>
        <label htmlFor="desc">Description</label>
        <TextField
          type="text"
          value={sedetails.seDesc}
          onChange={(e) => handleChange("seDesc", e.target.value)}
          variant="outlined"
          error={Boolean(descErrors.seDesc)}
          helperText={descErrors.seDesc}
          fullWidth
        />
      </Grid>
    </Grid>



    </form>
      </Grid>
    </Grid>

            <div className="d-flex justify-content-end p-3">
              
              <button
                className="btn btn-submit btn-primary btn-style bottomright"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
       : "" }
    </>
  );
}

export default InsuranceProcedureSettlement;
