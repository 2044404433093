import React from "react";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; // Import an appropriate icon
import UserActions from "./UserActions";

export default () => (
  // <Nav navbar className="border-left flex-row">
  //   <UserActions />
  // </Nav>
   <div className="border-left flex-row d-flex">
   {/* <IconButton color="inherit"> */}
     {/* <MenuIcon /> */}
   {/* </IconButton> */}
   <UserActions />
 </div>
);
