import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select, { createFilter } from "react-select";
import { connect } from "react-redux";
import { doctorActions} from "../../core/actions";

function SearchPackageService(props) {
const [selectedOption, setSelectedOption] = useState(null);
const [deptId, setDeptId] = useState('');
const [options, setOption] = useState('');
const dispatch = useDispatch();
// const doctor = useSelector(state => state.doctorDetailsState.doctor)
const[doctorList, setdoctorList] = useState({
    doctors:""
});

useEffect(() => {
    // console.log('Doctor- props',doctor)
    // if(doctor){
    // setSelectedOption(doctor.deptName)
    // }
    // Get All Doctors by Dept
    let data= {}
   console.log("dr action");
    dispatch(doctorActions.getServiceList(data,resCb=>{
        if(resCb){
            //console.log("Diagnosis Master API Response:",resCb)
            // setdoctorList(resCb)
            // doctorList.doctors=resCb;
          //  setTimeout(() => {
            console.log("resCb doctor Response:", resCb)
            let deptOptions = []
            // for(let det of resCb){
            //     console.log("det:", det)
            //     let opt = {}
            //     opt['id'] = det.id
            //     opt['value'] = det.name
            //     opt['productId'] = det.productId
            //     opt['label'] = det.name
            //     deptOptions.push(opt)
            // }
              console.log("list doctor Response:", doctorList)
              setOption(deptOptions) 
              console.log(" deptOptions Response:", deptOptions)
           // },400)
            }
    }))
        // dispatch(doctorActions.getDoctorList(data,resCb=>{
        //   if(resCb){
        //     //console.log("Diagnosis Master API Response:",resCb)
        //     // setdoctorList(resCb)
        //     // doctorList.doctors=resCb;
        //   //  setTimeout(() => {
        //     console.log("resCb doctor Response:", resCb)
        //     let deptOptions = []
        //     for(let det of resCb){
        //         console.log("det:", det)
        //         let opt = {}
        //         if (!det.first_name){det.first_name = "" }
        //         if(!det.last_name){det.last_name = ""}
        //         opt['id'] = det.personId
        //         opt['value'] = det.email
        //         opt['role'] = det.role
        //         opt['label'] = det.first_name + ' ' +det.last_name
        //         deptOptions.push(opt)
        //     }
        //       console.log("list doctor Response:", doctorList)
        //       setOption(deptOptions) 
        //       console.log(" deptOptions Response:", deptOptions)
        //    // },400)
        //     }
        // }))
    
    // }
}, [props]);
    // dispatch(doctorActions.getDoctorsByDept((resCb) => {
    // console.log("Doctor By Dept", resCb);
    // if (resCb) {
    //     //if (doctorsDetail) {
    //     //let doctorsInfo = doctorsDetail;
    //     /* Object.keys(doctorsInfo).forEach((key) => {
    //         if (!doctorsInfo[key]) {
    //         doctorsInfo[key] = "";
    //         }                
    //     }); */
    //     let deptOptions = []
    //     for(let det of resCb){
    //         let opt = {}
    //         opt['id'] = det.departments.id
    //         opt['value'] = det.departments.name
    //         opt['label'] = det.departments.name
    //         deptOptions.push(opt)
    //     }
    //     setOption(deptOptions)
    //     //}
    // }
    // }));
// },[])


//const doctorsDetail = useSelector(state => state.doctorDetailsState.doctorsDetail)

const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
    if (selectedOption != null) {
    props.parentCallBack(selectedOption);
    // this.props.parentCallBack({selectedBatch : selectedOption})
    } 
    
}

    return (
    <div className='px-4 py-3 w-100'>
        <Select
        name="Search Service"
        value={selectedOption}
        onChange={handleChange}
        isClearable
        isSearchable
        options={options}
        filterOption={createFilter(options)}
        defaultValue={selectedOption}
        ></Select>
    </div>
    );
}

/* function mapStateToProps(state) {
return {
    doctorsDetail: state.doctorDetailsState.doctorsDetail,
    doctor: state.doctorDetailsState.doctor,
};
}

function mapDispatchToProps(dispatch) {
return {
    getDoctorsByDept : (resCb) => dispatch(doctorActions.getDoctorsByDept(resCb))
};
}

const searchDeptWithProps = connect(mapStateToProps, mapDispatchToProps)(searchDept); */

export default SearchPackageService;
