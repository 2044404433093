import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../common/BtnCellRenderer";
import PageTitle from "../common/PageTitle";
import { connect } from "react-redux";
import { diagnosisActions } from "../../core/actions";
import { doctorActions } from "../../core/actions";
import { DASHBOARD } from "../../shared/constants/index";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Dashboard(props) {
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [diagnosis, setDiagnosis] = useState(
    {page:1,pageSize:50,searchText: '',
    }
  );
  const dispatch = useDispatch();

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    sessionStorage.removeItem('searchvalue');
    dispatch(doctorActions.setPageHeader(DASHBOARD));
    //Get all diagnosis of patients
    // dispatch(diagnosisActions.getAllDiagnosis(diagnosis, (resCb) => {
    //     console.log("ResCb", resCb, "Props", diagnosisDetail);
    //     if (resCb) {
    //       if (diagnosisDetail) {
    //         let diagnosisInfo = diagnosisDetail;
    //         Object.keys(diagnosisInfo).forEach((key) => {
    //           if (!diagnosisInfo[key]) {
    //             diagnosisInfo[key] = "";
    //           }
    //         });
    //       }
    //     }
    //   })
    // );
    listServices();
  }, []);

  const listServices = () => {

    let data = {};
    dispatch(
      doctorActions.getProductIdList(data, (resCb) => {
        if (resCb) {
          if (resCb.packageServiceList) {
            resCb.packageServiceList.forEach(element => {
              if(element.productId === 0){
                element.productId ='';
                element.productName = '';
              }
            });
            setServiceList(resCb.packageServiceList);
          }
        }
      }));
  }

  const diagnosisDetail = useSelector(
    (state) => state.diagnosisDetailsState.diagnosisDetail
  );

  const handleCardClick = () => {
    props.history.push("/ListPatient");
  };

  const handleProductIdCount = () => {
   // setShowServiceList(true);
    props.history.push("/Services");
  };

  return (
    <main className="mainsection">
      {/* Page Header */}
      <Grid container justifyContent="flex-start" alignItems="center" className="page-header mb-2">
      <Typography variant="h5" className="text-sm-left mb-3">
        List of Patients
      </Typography>
    </Grid>
      <div className="d-flex flex-row">
        <Card
          small
          className="stats-small stats-small--1 card card-small cpointer mr-2"
          style={{ width: `230px`, height: `100px` }}
          onClick={handleCardClick}
        >
          <CardContent className="p-0 d-flex card-body">
            <div className="d-flex flex-column m-auto">
              <div className="stats-small__data text-center">
                <span className="stats-small__label text-uppercase">
                  No of Patients
                </span>
                <h6 className="stats-small__value count my-3">25</h6>
              </div>
            </div>
          </CardContent>
        </Card>


        <Card
          small
          className="stats-small stats-small--1 card card-small cpointer mr-2"
          style={{ width: `230px`, height: `100px` }}
          onClick={handleProductIdCount}
        >
          <CardContent className="p-0 d-flex card-body">
            <div className="d-flex flex-column m-auto">
              <div className="stats-small__data text-center">
                <span className="stats-small__label text-uppercase">
                  Update Services
                </span>
                <h6 className="stats-small__value count my-3">
                  {serviceList.length}
                </h6>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    

    </main>
  );
}

export default Dashboard;
