import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get('Authorization');
    if(token != null && token != undefined){
      localStorage.setItem('token', JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function addInsurance(data) {
    console.log("data inside service editdoctor",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    console.log("addInsurance service");
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/addinsurance`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }

  function modifyInsurance(data) {
    console.log("data inside service modifyInsurance",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    console.log("addInsurance service");
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/changeinsurance?id=${data.id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }
  

  function getInsuranceMaster() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/insurancedetails`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }
  function deleteInsurance(data) {
    console.log("data inside service removetpa",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/deleteinsurance?id=${data.id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }

  //tpamster
  function getTpaMaster() {
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: JSON.stringify(),
    };
    const url = basePath + `/v1/tpadetails`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }
  
  
  function addTpaMaster(data) {
    console.log("data inside service editdoctor",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    console.log("addInsurance service");
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/addtpa`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }



  function modifyTpa(data) {
    console.log("data inside service modifyInsurance",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    console.log("addInsurance service");
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/changetpa?id=${data.id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }


  function deleteTpa(data) {
    console.log("data inside service removetpa",data);
    var accessToken;
    if (helperServices.getAuthorization()) {
      accessToken = helperServices.getAuthorization();
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // body: data,
      body: JSON.stringify(data),
    };
    const url = basePath + `/v1/removetpa?id=${data.id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
  
        return data;
      } );
  }


const insuranceServices = {
    handleResponse,
    logout,
    addInsurance,
    getInsuranceMaster,
    modifyInsurance,
    deleteInsurance,

    getTpaMaster,
    addTpaMaster,
    modifyTpa,
    deleteTpa
  };
  export default insuranceServices;
