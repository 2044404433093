import {FORM_DETAILS} from "../../shared/constants";


function getFormDetails(fDetails,resCb) {
    console.log('in getFormDetails pDetails ',fDetails );
    
    return dispatch => {
        console.log('dispathcing success');
        dispatch(success(FORM_DETAILS, fDetails));
        resCb(fDetails);
    };
}

function success(type, data){
    return { type: type, payload : data }
}

const fActions = {
    getFormDetails
}

export default fActions;
