import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { bedActions } from "../../core/actions";
import { patientActions } from "../../core/actions";
import { doctorActions } from "../../core/actions";
import Select, { createFilter } from "react-select";
import {
  DISCHARGESUMMARY,
  VISIT_STATUS_OPEN,
  ROOM_STATUS_AVAILABLE,
  ROOM,
  DASHBOARD,
  VISIT_STATUS_CLOSED,
  PRODUCT_HMS_LOCATION,
} from "../../shared/constants";
import Button from "@mui/material/Button";
import { helperServices } from "../../shared/helper";

function BtnCellRenderer(props) {
  // console.log("props in cellerenderer:", props);
  //console.log("displayProducts:displayProducts:",displayProducts);
  const [search, SetSearch] = useState();
  const [showModal, setShowModal] = useState(false);
  //const [assignRoomValue, setAssignRoomValue] = useState(Boolean);
  const [productId, setProductId] = useState();
  const [displayProducts, setDisplayProducts] = useState([]);
  const dispatch = useDispatch();
  const handleEditClick = (bedDetailList, event) => {
    console.log("data : ", bedDetailList);
    let instInfo = {};
    // instInfo['instId'] = instId
    // instInfo['userTopicid'] = userTopicid
    props.context.componentParent({ bedDetailList });
  };
  // let displayProducts =[];

  const handleFormEditClick = (id) => {
    console.log("edit:", id);
    props.context.componentParent({ id });
  };

  const patientVisitClick = (visitsList, event) => {
    console.log("data : ", visitsList);
    visitsList["typeFlag"] = "assignDoctor";
    props.context.componentParent({ visitsList });
  };

  const patientCloseClick = (visitsList, event) => {
    console.log("data : ", visitsList);
    visitsList["typeFlag"] = "patientClose";
    props.context.componentParent({ visitsList });
  };

  const patientOnboard = (visitsList, event) => {
    console.log("patientOnboard : ", visitsList);
    visitsList["typeFlag"] = "patientOnBoard";
    props.context.componentParent({ visitsList });
  };

  const patientInsurance = (visitsList, event) => {
    console.log("patientInsurance : ", visitsList);
    visitsList["typeFlag"] = "Insurance";
    props.context.componentParent({ visitsList });
  };

  const addComplaints = (visitsList, event) => {
    console.log("addComplaints : ", visitsList);
    visitsList["typeFlag"] = "Complaint";
    props.context.componentParent({ visitsList });
  };

  const attachFiles = (visitsList, event) => {
    console.log("attachFiles : ", visitsList);
    visitsList["typeFlag"] = "AttachFile";
    props.context.componentParent({ visitsList });
  };

  const moveToBill = (visitsList, event) => {
    console.log("movetobill : ", visitsList);
    visitsList["typeFlag"] = "MoveToBill";
    props.context.componentParent({ visitsList });
  };

  const allocateRoom = (visitsList, event) => {
    console.log("attachFiles : ", visitsList);
    visitsList["typeFlag"] = "AllocateRoom";
    props.context.componentParent({ visitsList });
  };

  const patientRevisitClick = (revisitsList, event) => {
    console.log("data : ", revisitsList);
    revisitsList["typeFlag"] = "bookvisit";
    props.context.componentParent({ revisitsList });
  };

  const patientfollowUp = (revisitsList, event) => {
    console.log("data : ", revisitsList);
    revisitsList["typeFlag"]="appointment";
    props.context.componentParent({ revisitsList });
  };

  const patientRescheduleClick = (revisitsList, event) => {
    console.log("data : ", revisitsList);
    revisitsList["typeFlag"] = "reschedule";
    props.context.componentParent({ revisitsList });
  };

  const patientOutClick = (revisitsList, event) => {
    console.log("data : ", revisitsList);
    revisitsList["typeFlag"] = "cancel";
    props.context.componentParent({ revisitsList });
  };

  const dischargeSummaryClick = (visitsList) => {
    console.log("data : ", visitsList);
    visitsList["typeFlag"] = "summary";
    props.context.componentParent({ visitsList });
  };

  const assignRoom = (value, data) => {
    // document.getElementById("roomassign").checked = value;
    data.checked = value;
    console.log("data,event assign room : ", value, data);
    data["assignedroom"] = value;
    //data.checked =  data["assignedroom"];
    props.context.componentParent({ data });
  };

  const updateProductId = (value, data) => {
    console.log("value, data :", value, data);
    console.log("productId :", productId);
    // data.productId = value;
    data["key"] = "action";
    props.context.componentParent({ data });
  };

  const getProducts = (value, data) => {
    SetSearch(value);
    console.log("prod value", value);
    data["key"] = "product";
    data.productName = value;
    //['productId'] = value;
    console.log("prod name", data);
    if (value.length > 2) {
      searchProduct(value);
    } else {
      setDisplayProducts(null);
      //props.data.value = value;
    }
  };

  const searchProduct = (value) => {
    let productsearch = value;
    let location = 0;
    console.log("searchedvalue", productsearch);
    dispatch(
      doctorActions.getProductDetails(
        productsearch,
        PRODUCT_HMS_LOCATION,
        (resCb) => {
          if (resCb && resCb.erpProductList) {
            console.log("Response of searched product", resCb.erpProductList);
            //  setDisplayProducts([...resCb.products]);
            setDisplayProducts([...resCb.erpProductList]);
          }
        }
      )
    );
  };

  const productSelected = (data) => {
    console.log("data in product", data);
    data.productName = data.productName;
    SetSearch(data.productName);
    setDisplayProducts(null);
    props.context.componentParent({ data });
  };

  const tpaDeleteClick = (event) => {
    let instInfo = {};

    props.context.componentParent({});
  };

  const pServiceDeleteClick = (event) => {
    console.log("event of service delete click", event);
  };

  const adminPageHeader = useSelector(
    (state) => state.patientDetailsState.pageheader
  );

  const handleChange = (event, data) => {
    // console.log("selectedOption",event.target.selectedOptions[0].getAttribute('id'));
    console.log("data:", data);
    // data.productId = event.target.selectedOptions[0].getAttribute('id');
    data["productName"] = event.target.value;
    data["key"] = "productId";
    setDisplayProducts([]);
    props.data.value = event.target.value;
    props.context.componentParent({ data });
  };

  return (
    <>
      {console.log("props in cellerenderer:", props)}
      {window.location.href.split("/")[3] == "Bed" ? (
        <>
          <a title="Edit">
            {/* material-icons */}
            <i
              className=""
              style={{ marginLeft: `10px` }}
              onClick={(event) => handleEditClick(props.data, event)}
            >
              edit
            </i>
          </a>
          <a title="Delete">
            style={{ color: "#a32424" }}
            <i className="material-icons mr-1">delete</i>
          </a>{" "}
        </>
      ) : (
        ""
      )}

      {window.location.href.split("/")[3] == "Followup" ? (
        <>
          {/* <a title="Edit">
           
            <i
              className="material-icons mr-1"
              title="Edit"
              style={{ marginLeft: `10px` }}
              onClick={(event) => patientRevisitClick(props.data, event)}
            >
              edit
            </i>
          </a> */}

          <a title="Onboard">
            <i
              className="material-icons mr-1"
              //  style={{  color: '#rgb(97 166 193)' }}
              onClick={(event) => patientRevisitClick(props.data, event)}
            >
              add
            </i>
          </a>
          <a title="Appointment">
            <i className="material-icons mr-1"
              onClick={(event) => patientfollowUp(props.data, event)}

            >calendar_month</i>
          </a>
          <a title="Update">
            <i
              className="material-icons mr-1"
              title="Update"
              onClick={(event) => patientRescheduleClick(props.data, event)}
            >
              update
            </i>
          </a>
          <a title="Delete">
            <i
              className="material-icons mr-1"
              style={{ color: "#a32424" }}
              onClick={(event) => patientOutClick(props.data, event)}
            >
              delete
            </i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "Admin" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {props &&
            props.data &&
            props.data.visit &&
            props.data.visit.status != VISIT_STATUS_CLOSED &&
            props.data.visit.internalStatus !== "notInSite" &&
            props.data.visit.type != "InPatient" &&
            props.data.visit.meetingStatus != "completed" &&
            (helperServices.CheckAccessLevel("patients") ||
              helperServices.CheckAccessLevel("FrontDeskPatients")) && (
              <a title="Schedule Appointment">
                <i
                  className="material-icons mr-1"
                  style={{ color: "#006fe6" }}
                  onClick={(event) => patientVisitClick(props.data, event)}
                >
                  edit
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>  */}
                </i>
              </a>
            )}

          {props.data &&
            props.data.visit &&
            (props.data.visit.status === VISIT_STATUS_CLOSED ||
              props.data.visit.internalStatus == "notInSite") &&
            helperServices.CheckAccessLevel("patients") && (
              <a title="Onboard">
                <i
                  className="material-icons mr-1"
                  //  style={{  color: 'blue' }}
                  onClick={(event) => patientOnboard(props.data, event)}
                >
                  add
                </i>
              </a>
            )}
          {props.data &&
            props.data.insurance == "YES" &&
            props.data.visit.type == "InPatient" &&
            helperServices.CheckAccessLevel("FrontDeskPatients") && (
              <a title="Insurance" className="disable-btn">
                <i
                  className="material-icons mr-1"
                  onClick={(event) => patientInsurance(props.data, event)}
                >
                  policy
                </i>
              </a>
            )}
          {props.data &&
            props.data.visit &&
            props.data.visit.status != "close" &&
            props.data.visit.internalStatus !== "notInSite" &&
            helperServices.CheckAccessLevel("patients") && (
              <a title="Compliants">
                <i
                  className="material-icons mr-1"
                  style={{ color: "#176217" }}
                  onClick={(event) => addComplaints(props.data, event)}
                >
                  note_add
                </i>
              </a>
            )}
          {/* Attachments button in AGgrid temporarly hidden */}
          {/* {props.data &&
            props.data.visit && props.data.visit.status != 'close' &&(helperServices.CheckAccessLevel("patients")) &&
            
            (
              <a title="Attach Files">
               <i class="material-icons"              
                onClick={(event) => attachFiles(props.data, event)}>file_present</i>
              </a>
            )} */}

          {props.data &&
            props.data.visit &&
            props.data.visit.type == "InPatient" &&
            helperServices.CheckAccessLevel("patients") && (
              <a title="Assign Room" className="disable-btn">
                <i
                  class="material-icons"
                  onClick={(event) => allocateRoom(props.data, event)}
                >
                  room_preferences
                </i>
              </a>
            )}

          {props.data &&
            props.data.visit &&
            props.data.visit.type == "InPatient" &&
            //  props.data.visit.moveToBillBtnAction == 'true' &&
            helperServices.CheckAccessLevel("patients") && (
              <a title="Move to billing" classname="actionbutton">
                <i
                  class="material-icons  mr-1"
                  onClick={(event) => moveToBill(props.data, event)}
                >
                  attach_money
                </i>
              </a>
            )}

          {props.data &&
            props.data.visit &&
            (props.data.visit.status != "close" ||
              props.data.visit.internalStatus === "notInSite") &&
            // props.data.visit.closeBtnAction == 'true' &&
            helperServices.CheckAccessLevel("patients") && (
              <a title="Close patient">
                <i
                  className="material-icons mr-1"
                  style={{ color: "#a32424" }}
                  onClick={(event) => patientCloseClick(props.data, event)}
                >
                  delete
                </i>
              </a>
            )}

          {/* && props.data.procedure && props.data.procedure.groupname === DISCHARGESUMMARY
        <a title='Discharge Summary'>
            <i className="material-icons mr-1"  title='Discharge Summary'
            onClick={(event) => dischargeSummaryClick(props.data,event)}>report</i>
        </a> */}
        </>
      ) : (
        ""
      )}

      {adminPageHeader === ROOM ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {/* {(props.data.roomStatus && props.data.roomStatus.bookstatus != 'closed' ||
            props.data.assignedroom === true) && (
            <input
              className="p-2"
              type="checkbox"
              id="roomassign"
              placeholder="Quantity"
              autoComplete="off"
              onChange={(event) => assignRoom(event.target.checked, props.data)}
              defaultChecked={props.data.assignedroom}
            />
          )} */}
        </>
      ) : (
        ""
      )}

      {adminPageHeader === DASHBOARD ? (
        <>
          {props.colDef.field == "productId" && (
            <>
              <input
                className="p-2 ag-grid-input border-0"
                type="text"
                value={search}
                placeholder="Search Product"
                autoComplete="off"
                onChange={(event) =>
                  getProducts(event.target.value, props.data)
                }
                style={{ height: "27px" }}
              />

              <div className=" mt-2 shadow product-list-container position-absolute">
                {displayProducts &&
                  displayProducts.length > 0 &&
                  displayProducts.map((product, index) => (
                    <div
                      className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                      onClick={(event) => productSelected(product)}
                    >
                      <span className="mb-0">
                        {" "}
                        {product.productName}&nbsp;
                        <span className="text-muted">
                          ({product.productId})
                        </span>
                      </span>
                    </div>
                  ))}
              </div>
            </>
          )}

          {props.colDef.field == "Actions" && (
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              variant="contained"
              theme="primary"
              type="submit"
              className="py-1"
              onClick={(event) => updateProductId(event, props.data)}
            >
              Save
            </Button>
          )}
        </>
      ) : (
        ""
      )}

      {window.location.href.split("/")[3] == "ServiceMaster" ? (
        <>
          <a title="Delete">
            <i
              className="material-icons mr-1"
              onClick={(event) => tpaDeleteClick(props.data, event)}
              title="Delete Service"
            >
              delete
            </i>
          </a>{" "}
        </>
      ) : (
        ""
      )}

      {window.location.href.split("/")[3] == "Forms" ? (
        <>
          <div className="d-inline-block item-icon-wrapper cpointer">
            <i
              className="material-icons mr-1"
              title="Edit form"
              onClick={(event) => handleFormEditClick(props.data.basicinfo.id)}
            >
              edit
            </i>
          </div>
          &nbsp;&nbsp;
          <div className="d-inline-block item-icon-wrapper cpointer">
            <i className="material-icons mr-1" title="Delete form">
              delete
            </i>
          </div>
          <a title="Delete">
            <i className="material-icons mr-1" title="Delete form">
              delete
            </i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "insurance" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props : ", props)}
          <a title="Delete">
            <i className="material-icons mr-1">delete</i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "tpa" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props : ", props)}
          <a title="Delete">
            <i className="material-icons mr-1">delete</i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "staff" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props : ", props)}
          <a title="Delete">
            <i className="material-icons mr-1">delete</i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "doctor" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props : ", props)}
          <a title="Delete">
            <i className="material-icons mr-1">delete</i>
          </a>
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "roomList" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props : ", props)}
          {props.data && props.data.status == "active" && (
            <a title="active">
              <i className="material-icons mr-1 text-success">check</i>
            </a>
          )}
          {props.data && props.data.status == "inactive" && (
            <a title="inactive">
              <i className="material-icons mr-1 text-danger">close</i>
            </a>
          )}
        </>
      ) : (
        ""
      )}

      {adminPageHeader === "packageService" ? (
        // window.location.href.split("/")[3] == 'admin' ?
        <>
          {console.log("btn cell renderer props service: ", props)}
          <a title="Delete">
            <i
              className="material-icons mr-1"
              onClick={(event) => pServiceDeleteClick(event)}
            >
              delete
            </i>
          </a>
        </>
      ) : (
        ""
      )}
    </>
  );
}

/* function mapStateToProps(state) {
  console.log("State in cell render", state,this.props);
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

const BtnCellRendererWithProps = connect(mapStateToProps, mapDispatchToProps)(BtnCellRenderer);

const BtnCellRendererWithRouter = withRouter(BtnCellRendererWithProps);
export default BtnCellRendererWithRouter; */
// const adminPageHeader = useSelector(state => state.patientDetailsState.pageheader)

export default BtnCellRenderer;
