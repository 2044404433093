import {TOKEN_DETAILS} from "../../shared/constants";

const tokenDetailsState = {
    token:{}
  
};
function setTokenDetailsState(state = tokenDetailsState, action) {
    switch (action.type) {
      case TOKEN_DETAILS:
      return Object.assign({}, state, {
        token: action.payload,

        });
    default:
 return state;

    }
}
export default setTokenDetailsState;