import InsuranceProcedureListing from './InsuranceProcedureListing';
import InsuranceProcedureInput from './InsuranceProcedureInput';
import InsuranceProcedureSettlement from './InsuranceProcedureSettlement';
import VisitList from '../patient/VisitList';
import PrescriptionLayoutModel from '../patient/PrescriptionLayoutModel'
const rcomponents = {
    InsuranceProcedureListing,
    InsuranceProcedureInput,
    InsuranceProcedureSettlement,
    VisitList,
    PrescriptionLayoutModel
}

export default rcomponents;
