import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect, useState } from "react";
import {
  HOSPITAL_ADDRESS,
  EMAIL_ID,
  PHONE_NO,
  HOSPITAL_NAME,
  HOSPITAL_DESC,
} from "../../shared/constants";
import { MANASWINI_LOGO, MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
var fonts = require("pdfmake/build/vfs_fonts");

function DischargePrint(props) {
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString("default", { month: "short" });
  const year = now.getFullYear();
  const suffix = getNumberWithSuffix(day);
  const formattedDate = `${suffix} ${month} ${year}`;
  function getNumberWithSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = number % 100;
    return (
      number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0])
    );
  }
  useEffect(() => {
    console.log("props discharge summary",props);
    formatData();
  }, []);

  const formatData =()=>{
    let fullname = props.dischargeSummary.fname +" "+ (props.dischargeSummary.lname ? props.dischargeSummary.lname : '') ;
    const date = new Date(props.dischargeSummary.procedure.cdt);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const formattedDate = `${day}-${month}-${year}`;
    addDischargeSummary(fullname,formattedDate);
  }

  const addDischargeSummary = (fullname,formattedDate) =>{
    var docDefinition = {
      pageMargins: [40, 60, 40, 60],
      background: {
        image: MANASWINI_BACKGROUND,
        width: 600,
        height: 800,
        color: '#ff0000'
      },
      content: [
        {
          text: "Discharge Summary",
          style: "heading",
          width: "auto",
          alignment:'center',
          margin: [0, 20, 0, 0], 
        },
        {columns :[
        {
          text: "Patient Name:",
          style: "subtext",
          width: 90,
          margin: [0, 15, 0, 0], 
        },
        {
          text: fullname,
          style: "subtext",
          width: "auto",
          margin: [0, 15, 0, 0], 
        }]},
        {columns :[
        {
          text: "Doctor Name:",
          style: "subtext",
          width: 90,
          margin: [0, 10, 0, 0], 
        },
        {
          text: "Dr.Ravish",
          style: "subtext",
          width: "auto",
          margin: [0, 10, 0, 0], 
        }]},
        {columns :[
        {
          text: "Description:",
          style: "subtext",
          width: 90,
          margin: [0, 10, 0, 0], 
        },
        {
          text: props.dischargeSummary.procedure.notes,
          style: "subtext",
          width: "auto",
          margin: [0, 10, 0, 0], 
        }]},
        {columns :[{
          text: "Created By:",
          style: "subtext",
          width: 90,
          margin: [0, 10, 0, 0], 
        },
        {
          text: props.dischargeSummary.procedure.preparedBy,
          style: "subtext",
          width: "auto",
          margin: [0, 10, 0, 0], 
        }]},
        {columns :[
        {
          text: "Approved By:",
          style: "subtext",
          width: 90,
          margin: [0, 10, 0, 0], 
        },
        {
          text: props.dischargeSummary.procedure.approver,
          style: "subtext",
          width:"auto",
          margin: [0, 10, 0, 0], 
        },]},
        {columns :[
        {
          text: "Discharge Date:",
          style: "subtext",
          width: 90,
          margin: [0, 10, 0, 0], 
        }, 
        {
          text: formattedDate,
          style: "subtext",
          width: "auto",
          margin: [0, 10, 0, 0], 
        }]}   
      ],
      header: {
        columns: [
          {
            image: MANASWINI_LOGO,
            fit: [60, 60], // set the width and height of the image
            alignment: "left",
            margin: [25, 0, 0, 20],
            width: 90, // set the top and bottom margin of the image
          },
          [
            {
              text: HOSPITAL_NAME,
              style: "header",
              width: "auto",
              margin: [5, 10, 0, 0], 
            },
            {
              stack: [
                {
                  text: HOSPITAL_DESC,
                  style: "subheader",
                  width: "auto",
                  margin: [5, 0, 0, 20],
                  border: [false, false, false, true],
                },
              ],
              //margin: [0, 0, 10, 20], 
            },
          ],
        ],
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: HOSPITAL_NAME,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [25, 7, 0, 0],
          },
          {
            text: HOSPITAL_DESC,
            alignment: "left",
            fontSize: 8,
            color: "#176fad",
            bold: true,
            margin: [25, 0, 0, 0],
          },
          {
            text: HOSPITAL_ADDRESS,
            alignment: "left",
            fontSize: 8,
            margin: [25, 0, 0, 0],
          },
          {
            text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
            alignment: "left",
            fontSize: 8,
            margin: [25, 0, 0, 5],
          },
          {
            text: currentPage.toString() + " of " + pageCount.toString(),
            alignment: "center",
            fontSize: 8,
          },
        ];
      },
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          color: "#176fad",
        },
        subheader: {
          fontSize: 14,
          bold: true,
          color: "#176fad",
        },
        heading:{
          fontSize: 18,
          bold: true,
        },
        subtext:{
          fontSize: 12,
        }
      },
    };

    PdfPrinter.createPdf(docDefinition).open();
  }

  return <></>;
}
export default DischargePrint;

// import * as React from "react";
// import { useRef, useState, useEffect } from "react";
// import { useReactToPrint } from "react-to-print";
// import { Button } from "shards-react";
// function DischargePrint(props) {

//   useEffect(() => {

//     var currentdate = new Date();
//     var datetime =
//       currentdate.getDay() +
//       "/" +
//       currentdate.getMonth() +
//       "/" +
//       currentdate.getFullYear() +
//       "  " +
//       currentdate.getHours() +
//       ":" +
//       currentdate.getMinutes() +
//       ":" +
//       currentdate.getSeconds();
//     setcurrentDate(datetime);
//    // console.log("selected patient info",props.selectedPatient.person.fullName)
//     //setdoctorName(props.selectedPatient.person.fullName);
//   }, []);

//   const [layoutSelection, setLayoutSelection] = useState({
//     text: "A4",
//     value: "size-a4",
//   });

//   const closePdfModal = (event) => {
//     props.onModalClose(event);
//   };

//   const [currentDate, setcurrentDate] = useState();
//   const [HospitalData, setHospitalData] = useState({
//     Name: "Manaswini",
//     contact: "+91 8123935190",
//     email: "tmanaswiniarkula@gmail.com",
//     address: "Arkula Campus, Kuchoor, Farangipete[P.O] Mangalore 574143, Karnataka India",
//   });

//   const componentRef = useRef();
//   const handlePrint = useReactToPrint({

//     content: () => componentRef.current,
//   });
//   const [doctorName, setdoctorName] = useState();
//   let discharge = props.dischargeSummary;
//   console.log(discharge) ;

//   return (
//     <div className="pdfbody">
//       <div className="header">
//         <div className="box wide hidden-on-narrow">
//           <div className="box-col d-flex">
//             <Button primary={true} onClick={handlePrint}>
//               Export PDF
//             </Button>
//           </div>
//         </div>
//         <div>
//           <span
//             className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
//             onClick={(event) => closePdfModal(event)}
//           >
//             X
//           </span>
//         </div>
//       </div>

//       <div id="example">
//         <div className="page-container hidden-on-narrow">
//           <div
//             ref={componentRef}
//             className={`pdf-page ${layoutSelection.value}`}
//           >
//             <div className="inner-page">
//               <div className="pdf-header">
//                 <span>
//                   <div className="hospital-name">
//                     <div>
//                       <div className=" d-flex  company-logo">
//                         {HospitalData.Name}
//                         <img
//                           src="Manaswini_Logo.jpg"
//                           alt="Hospital Logo"
//                           style={{ width: `40px`, height: `40px` }}
//                         />
//                       </div>
//                       <div className="hospitalDetails">
//                         <span> {HospitalData.contact} </span>
//                         <span> {HospitalData.email}</span>
//                         <span> {HospitalData.address}</span>
//                       </div>
//                     </div>
//                   </div>
//                 </span>
//               </div>
//               <div>

//               </div>
//               <div className="dischargeform">
//                 Discharge Summary
//               </div>
//               <div>
//               <div style={{marginTop:'1.2rem'}}>
//                 Patient Name :{discharge.fname}{" "+discharge.lname}
//               </div>

//               <div>
//                 Doctor Name: {doctorName}

//               </div>

//               <div>
//               notes:{discharge.procedure.notes}
//               </div>
//               {/* <div>
//               Doctor:{doctorName}
//               </div> */}
//               <div style={{marginTop:'1.2rem'}}>
//                 Created by :{discharge.procedure.preparedBy}
//               </div>
//               <div>
//                 Approved by :{discharge.procedure.approver}
//               </div>
//               <div>Date: {currentDate}</div>
//             </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DischargePrint;
