import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { useHistory } from "react-router-dom";

import { insuranceActions } from '../../../core/actions';

import { patientActions } from '../../../core/actions';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";


function TpaGrid(props) {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [insuranceForm, setInsuranceData] = useState(false);
  const [insuranceRowData, setinsuranceRowData] = useState({});
  const [insuranceRowlist, setRowData] = useState();


  const dispatch = useDispatch();
  const [tpaDetail, setTpaDetail] = useState(
    {
      id: "",
      companyName:"",
      address: "",
      email: "",
      phone1:"",
      phone2:"",
      description:""
  
    }
  );
  const [confirm, setConfirm] = useState(false);
  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  };

  const getAggriddata = (e) => {
    console.log("props",props);
   console.log("getAggriddata",e.data);

    if(e.colDef.field=="Actions"){
      setConfirm(!confirm);
      console.log("delete rowdata:",e.data);
        setTimeout(() => {
      setTpaDetail(e.data);
      console.log("tpaDetail",tpaDetail);
    }, 1000);
    console.log("tpaDetail",tpaDetail);

    }else{
    history.push( "/tpamaster",
     {
         rowData:e.data,
         label:"edit"
     }
     );
    }
   };
   const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };


  const [columnDefs] = useState([
    {
      headerName: "Company Name ",
      field: "companyName",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      comparator: customComparator,
      
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Contact 1",
      field: "phone1",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Contact 2",
      field: "phone2",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Description.",
      field: "description",
      
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      comparator: customComparator,
      // onCellClicked:getAggriddata(e)
      
    },
    { headerName:"Actions",field: "Actions", sortable: true, filter: true, flex: 1,
     resizable: true, width: 150, cellRenderer: "BtnCellRenderer" },

    
  ]);


  const visitsList = useSelector(
    (state) => state.patientDetailsState.visitsList
  );



  useEffect(() => {
    dispatch(patientActions.setPageHeader("tpa"));

    let data = {};
    console.log("props are:", props.tpamaster);
    if (props && props.tpamaster && props.tpamaster.length > 0) {
      let pslist = props.tpamaster;
      for (let item of pslist) {
        setRowData(pslist);
      }
  
    }else{
      console.log("no prop");
      setRowData(tpaDetail);
    }
  }, [props]);


  

  const handleSubmit = (event) => {
    console.log("event", event);
    console.log("tpaDetail",tpaDetail);
    dispatch(
    insuranceActions.deleteTpa(tpaDetail, (resCb) => {
      if (resCb) {
        console.log("deleteTpa", resCb);
        // props.history.push("/tpamasterlist");
        props.onGridAction();
        setConfirm(!confirm);

      }
    }));
  };
  const toggle = (event) => {
    setConfirm(!confirm);
  };

  const rowStyle = { background: "white" };

  return (
    <>
      {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
      <div>
        <div className="p-0 preview">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
            //   context={context}
              rowData={insuranceRowlist}
              columnDefs={columnDefs}
              // onRowClicked={(e) => getAggriddata(e)}
              rowStyle={rowStyle}
            //   getRowStyle={getRowStyle}

            onCellClicked={(e) => {
              console.log("event onCellclicked::",e);
              const field = e.colDef.field;
              const colIndex = e.columnApi
                .getAllColumns()
                ?.findIndex((col) => col.getColDef().field === field);
          
              console.log("cell clicked ",field, colIndex);
              getAggriddata(e)
            }}
            ></AgGridReact>
           
          </div>
          {confirm && (
      <div >
      {/* <Components.Confirmation onModalClose={(event) => closeModal(event)} /> */}
      {/* <Modal
        className="d-flex w-95 h-90 flex-column-reverse "
        id="doctor-modal"
        open={onmodal}
      > */}
          <Dialog
           open={onmodal}
           id="doctor-modal"
           className="d-flex w-95 h-90 flex-column-reverse"
           >

        <div>
          {/* <ModalHeader>
            Confirm Operation
            <span className="close-model" onClick={(event) => toggle(event)}>
              X
            </span>
          </ModalHeader> */}
          <DialogTitle className="py-2">
          Confirm Operation
      <IconButton
      className="close-model"
        aria-label="close"
        onClick={(event) => toggle(event)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
            <div className="confirmationheader">
              <h6>This operation cannot be undo! Do you want to continue? </h6>
            </div>
              {/* <h6>Do you want to continue?</h6> */}
            <main className="mainsection">
              <div className="buttonsetconfirm">

                {/* <Button className='btn-danger'type="submit" onClick={(event) => toggle(event)}>
                  Cancel
                </Button> */}
                <Button
                   type="submit"
                   variant="contained"
                   color="error"
                   onClick={(event) => toggle(event)}
                   style={{ textTransform: "none" }}
                >
                  Cancel
               </Button>
                {/* <Button type="submit" onClick={(event) => handleSubmit(event)}>
                  Delete
                </Button> */}
                <Button
                   type="submit"
                   variant="contained"
                   color="primary"
                   onClick={(event) => handleSubmit(event)}
                   style={{ textTransform: "none" }}
                  >
                  Delete
                </Button>
              </div>
       
         
            </main>
          </DialogContent>
        </div>
        </Dialog>

      </div>)}
         
        </div>
      </div>
    </>
  );
}
export default TpaGrid;
