import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { doctorActions } from "../../core/actions";
import Components from "./index";
import PageTitle from "../common/PageTitle";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';



function ListDoctor(props) {
  const [doctorByDeptData, setDoctorByDeptData] = useState([]);
  const [showDoctorDetail, setShowDoctorDetail] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [doctor, setDoctor] = useState(
    {page:1,pageSize:50,searchText: '',
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    listDoctors()
  },[])

  const listDoctors = () => {
    dispatch(doctorActions.getDoctorInfo(doctor, (resCb) => {
      console.log("#Response", resCb);
      if (resCb) {
        if (doctorsDetail) {
          let doctorsInfo = doctorsDetail;
          Object.keys(doctorsInfo).forEach((key) => {
            if (!doctorsInfo[key]) {
              doctorsInfo[key] = "";
            }
          });
          setDoctorByDeptData(doctorsInfo);
        }
      }
    }));
    console.log("#Props", doctorsDetail);
  }
  const doctorsDetail = useSelector(state => state.doctorDetailsState.doctorsDetail)

  const handleClick = (dDetail, isAddNew) => {
    console.log("D-t",dDetail)
    if(isAddNew === true){
      setAddNew(true);
    }
    else{
      setAddNew(false);
    }
    dispatch(doctorActions.setDoctorDetails(dDetail));
   
    console.log("add new : ", addNew);
    setShowDoctorDetail(false);

    setTimeout(() => {
      setShowDoctorDetail(true);
    }, 500);

    //Navigating to Patient Section
    props.history.push({
      pathname: `/Docsetup`,
      state: {
        docDetail: doctorInfo,
      },
    });
  }

  const doctorInfo = useSelector(state => state.doctorDetailsState.doctor)

  const handleInputChange = (value) => {
    if (value.length < 3) {
      setSearchText(value);
      setSearchError("Search Text should contain atleast 3 letters");
    } else {
      setSearchError("");
      setSearchText(value);
      handleSearch(value);
    }
  }

  const handleSearch = (value) => {
    var doctorState = doctor;
    doctorState['searchText'] = value;
    setDoctor(doctorState)
    listDoctors()
  }

    return (
      <main className="mainsection">
        {/* Page Header */}
        {/* <Row noGutters className="page-header">
          <PageTitle title="List of Doctors" 
            // subtitle="Dashboard" 
            className="text-sm-left mb-3" />
          <Button
            type="submit"
            className="floatingbtn"
            onClick={(event) => handleClick("", true)}
          >
            Add New Doctor
          </Button>  
        </Row> */}
         <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PageTitle title="List of Doctors" className="text-sm-left mb-3" />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className="floatingbtn"
          onClick={(event) => handleClick(event)}
        >
          Add New Doctor
        </Button>
      </Grid>
    </Grid>


        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i style={{color:`#111b21`}} className="material-icons">search</i>
              </span>
            </div>
            <TextField
              className="navbar-search"
              placeholder="Search Doctors..."
              value={searchText}
              type="search"
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </div>
        </form>
        
        {searchError && (
          <div style={{ color: "#EC4147" }}>
            <p>
              <span>{searchError}</span>
            </p>
          </div>
        )}
        
        <div className="main-content-container container-fluid cont">
          <div className="row">
            <div className="mb-12 col-sm-12 col-md-12 hpcol bg">
                <div className="blog-comments card pcard">
                  {doctorsDetail && doctorsDetail.map((doct,index)=>(
                    doct.doctors.map((dr,index)=>(
                        <div
                        className="blog-comments__item d-flex p-3 cpointer"
                        key={index}
                        onClick={(event) =>
                            handleClick(dr, "false")
                        }
                        >
                            <div className="blog-comments__content">
                                <div className="blog-comments__meta text-mutes">
                                    <div className="text-secondary pname">
                                        {dr.fname + " " + dr.mname + " " + dr.lname}
                                    </div>
                                </div>
                                <p className="m-0 text-muted">
                                    {doct.departments.name}
                                </p>
                            </div>
                        </div>
                    ))
                  ))}
              </div>
            </div>

            {showDoctorDetail === true &&
            ((doctorsDetail && doctorsDetail.doctors) ||
            addNew === true) ? (
              <div className="mb-12 col-sm-12 col-md-12 hpcol">
                <div className="blog-comments card pcard">
                  <Components.DocSetup
                    docDetail={doctorsDetail}
                  />
                </div>
              </div>
            ):("")}
          </div>

        </div>
      </main>
    );
}

/* function mapStateToProps(state) {
  return {
    doctorsDetail: state.doctorDetailsState.doctorsDetail,
    doctor: state.doctorDetailsState.doctor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDoctorInfo: (data,resCb) => dispatch(doctorActions.getDoctorInfo(data,resCb)),
    setDoctorDetails: (val) => dispatch(doctorActions.setDoctorDetails(val))
  };
}

const ListDoctorWithProps = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDoctor); */

export default ListDoctor;
