export const BED_CATEGORIES = [
  { id: 1, value: "Regular" },
  { id: 2, value: "Semi Delux" },
  { id: 3, value: "Delux" },
];

export const VISIT_CATEGORIES = [
  { id: 1, value: "Emergency" },
  { id: 2, value: "Regular" },
];

export const TOP_SECTIONS = [
  { id: 1, value: "Patient Details (UID | Name | Sex | Age)" },
  { id: 2, value: "Patient Address & Phone" },
  { id: 3, value: "Consultant Details" },
  { id: 4, value: "Bed Details" },
  { id: 5, value: "Current Date" },
  { id: 6, value: "Admission No." },
  { id: 7, value: "Admission Date" },
  { id: 8, value: "Discharge Date" },
];

export const BOTTOM_SECTIONS = [
  { id: 1, value: "Patient's Signature" },
  { id: 2, value: "Consultant Doctor's Signature" },
  { id: 3, value: "Relative's Signature" },
  { id: 4, value: "Authorised Signatory" },
];

export const ZOOMNOTESLABEL = [
  { id: 1, label: 'Family', color: 'lightgreen'},
  { id: 2, label: 'Job', color: 'yellow'},
  { id: 3, label: 'Personal', color: 'red'},
  { id: 4, label: 'Friends', color: 'purple'},
  { id: 5, label: 'Health', color: 'blue'},
  // { id: 6, label: 'All', color: 'gray'},
]

export const ZOOMNOTESLABELMAP = {
  Family : { id: 1, label: 'Family', color: 'lightgreen'} ,
  Job : { id: 2, label: 'Job', color: 'yellow'} ,
  Personal : { id: 3, label: 'Personal', color: 'red'},
  Friends : { id: 4, label: 'Friends', color: 'purple'},
  Health : { id: 5, label: 'Health', color: 'blue'},
  // { id: 6, label: 'All', color: 'gray'},
}
