import { DIAGNOSIS_DETAILS ,SET_DIAGNOSIS_DETAILS,DIAGNOSIS_MASTER_DETAILS} from "../../shared/constants";

const diagnosisDetailsState = {
  diagnosisDetail: [],
  diagnosisMasterDetail:[]
};

function setDiagnosisDetailsState(state = diagnosisDetailsState, action) {
  switch (action.type) {
    case DIAGNOSIS_DETAILS:
      console.log("DIAGNOSIS_DETAILS", action.payload);
      return Object.assign({}, state, {
        diagnosisDetail: action.payload,
      });
    case SET_DIAGNOSIS_DETAILS:
      console.log("SET_DIAGNOSIS_DETAILS", action.payload);
      return Object.assign({}, state, {
        dPatient: action.payload,
      });
    case DIAGNOSIS_MASTER_DETAILS:
      console.log("DIAGNOSIS_MASTER_DETAILS", action.payload);
      return Object.assign({}, state, {
        diagnosisMasterDetail: action.payload,
      });
    default:
      return state;
  }
}

export default setDiagnosisDetailsState;
