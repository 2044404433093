import React from "react";
import { Dispatcher, Constants } from "../../../flux";

function NavbarToggle() {
  const handleClick = () => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });

    document.getElementById('sidebar').classList.remove('sidebar-hidden')
    document.getElementById('mainpage').classList.remove('sidebar-hidden')
  };

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
      
        onClick={handleClick}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center"
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
}
export default NavbarToggle;
