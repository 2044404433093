import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { doctorActions, patientActions,tokenActions } from "../../core/actions";
import { format } from "date-fns";
import Tooltip from '@mui/material/Tooltip';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import BtnCellRenderer from '../common/BtnCellRenderer';
 import Components from '..';
 import { SET_PAGE_HEADER } from '../../shared/constants';

 import CustomTooltip from '../common/CustomTooltip';
import { PropertyKeys } from 'ag-grid-community';
import { NoBackpackSharp } from '@mui/icons-material';

function TokenDashBoardDetails (props) {
  const dispatch = useDispatch();
  const [pagination,setPagination] = useState(true);
  const [paginationPageSize,setPaginationPageSize] = useState(10);
  const [desk, setDesk] = useState([]);
  const [toolTip, setToolTip] = useState();
  
  const [columnDefs] = useState(
    [
    { headerName:"DeskName",field:"deskId", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true,  },
    { headerName:"Doctors",field: "doctorName", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true, onGridReady: 'open', },
    { headerName:"CurrentToken",field: "currentToken", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true },
    { headerName:"LastGivenToken",field: "lastGivenToken", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true }
    
]);

  useEffect(() => {
   
    deskDetails();
        
      },[props.updateTokenDashboard])
      const rowHeight = 100;   

      const deskDetails = () =>{
        dispatch(tokenActions.deskDetails((resCb) => {
          if(resCb && resCb.tokenSummaryMap){
               console.log("desk detail is:",resCb.tokenSummaryMap);
               let desklist = Object.values(resCb.tokenSummaryMap);
              
               console.log("desk value is",desklist)
              for(let desks of desklist){
                console.log("desk in for loop",desks);
                if(desks && desks.currentToken == 999){
                  desks['currentToken'] = '';
                }
                if(desks && desks.lastGivenToken == 999){
                  desks['lastGivenToken'] = '';
                }
                desks['tooltip']=false;
                //  desks['deskId'] = desks.deskId;
                // desks['doctorName'] = desks.doctorName;
                //  desks['currentToken']=desks.currentToken;
                //  desks['lastGivenToken']=desks.lastGivenToken;              
             }
            setDesk([...desklist]);
            
          }
        })
        );
      }
   var today = new Date();
   const todayDate = format(today.setDate(today.getDate()), "dd-MM-yyyy");

   const toggle = (i) =>{
    console.log("index:",i);
    let list = desk;
    console.log("index:",list[i].tooltip);
    list[i].tooltip = !list[i].tooltip;
    setDesk([...list]);
    // setToolTip(!toolTip);
   }
      
              
return(
          <>

                {/* <div id="Token" class="my-3 comp collapse show" style={{display:'block'}}><main class="">
                  <h5 class="">Issued Tokens Today ({todayDate})
                  <span class="ml-3" style={{cursor:'pointer'}}>
                  </span></h5><div><div class="border card">
                  <div class="p-3 card-body">
                  <div><div class="my-2 d-flex justify-content-between" style={{fontweight:'400'}}>
                 
                  <div className="ag-theme-balham ag-style card rounded table mb-0 tcol" style={{ height: 300   , width: `98%` }} >
                  
                  <AgGridReact
               
                frameworkComponents={{ BtnCellRenderer: BtnCellRenderer, }}
                pagination={pagination} paginationPageSize={paginationPageSize}
                rowData={desk}
                columnDefs={columnDefs}
                >
            </AgGridReact>
</div>


                 
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </main>
                  </div> */}

              <div className="my-0 justify-content-between w-100 ">
                <div className="col-12 d-flex">
                {desk &&
                    desk.map((data, index) => (
                      <>
                      <div class="mr-3">
                        <span className=''>
                        <span className='font-weight-bold' id={'Tooltip' + index}>{data.deskId}: &nbsp; </span>
                        {/* <Tooltip
                          open={desk[index].tooltip}
                          target={'#Tooltip' + index}
                          toggle={() => toggle(index)}
                          
                        >
                        {data.doctorName + ',' }
                        </Tooltip> */}
                        
                        <Tooltip
                          open={desk[index].tooltip}
                          title={data.doctorName + ','}
                          arrow
                          placement="top"
                        >
                          {/* Your trigger element, e.g., a button or any other element */}
                          <span id={'Tooltip' + index} onClick={() => toggle(index)}>
                            {/* Content that triggers the tooltip */}
                            {/* ... */}
                          </span>
                        </Tooltip>
                        {/* <i title={data.doctorName} class="material-icons" style={{ cursor:"pointer" }}>info</i> */}
                        </span>

                        <span>CT: &nbsp;{data.currentToken}</span>,
                        &nbsp;<span>LT: &nbsp;{data.lastGivenToken}</span>
                      </div>
              
                      </>
               
                  ))}
                 
                </div>
               
                </div> 

 
          </>
                  
)

}
export default TokenDashBoardDetails
