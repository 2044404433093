import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { IconButton, Menu, MenuItem, ListItemText, Typography } from '@mui/material';


export default function UserActions() {
  const [visible, setVisible] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    var loginId = localStorage.getItem("loginId");
    var name = localStorage.getItem("name");
    setLoginId(loginId);
    setName(name.charAt(0).toUpperCase() + name.slice(1));
  });

  const toggleUserActions = () => {
    setVisible(true);
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleUserActions();
  };

  const handleClose = () => {
    setAnchorEl(null);
    toggleUserActions();
  };

  return (
    // <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
    //   <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
    //     <span className="d-md-inline-block">{name}</span>
    //   </DropdownToggle>
    //   <Collapse tag={DropdownMenu} right small open={visible}>
    //     <DropdownItem divider />
    //     <DropdownItem
    //       tag={Link}
    //       to="/"
    //       onClick={(event) => logout()}
    //       className="text-danger"
    //     >
    //       <i className="material-icons text-danger">&#xE879;</i> Logout
    //     </DropdownItem>
    //   </Collapse>
    // </NavItem>

    <React.Fragment>
    <IconButton onClick={handleMenuClick}>
    <Typography variant="body2" className="d-md-inline-block">
          {name}
        </Typography>      
        <ExpandMoreIcon />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem component={Link} to="/" onClick={logout}>
        <LogoutIcon color="error" />
        <ListItemText className="p-2" primary="Logout" />
      </MenuItem>
    </Menu>
  </React.Fragment>
  );
}
