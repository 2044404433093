import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { patientActions } from "../../core/actions";
import { Box, Button } from '@mui/material';
import Token from '../../shared/config/Token.json';
import {tokenActions} from "../../core/actions";
import {PATIENT_SECTION} from  "../../shared/constants";
import { Column } from 'ag-grid-community';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TokenDashBoard(){
    const [runningToken, setrunningToken] = useState([]
       
    );
    const dispatch = useDispatch();
   useEffect(() => {
    deskDetails();
    setInterval(deskDetails, 60000);
        
},[])

 console.log("runningToken is" ,runningToken);


 const deskDetails = () => {
    dispatch(tokenActions.deskDetails((resCb) => {
        if(resCb){ 
       console.log("running token map is",resCb.tokenSummaryMap);
     
      let running =resCb.tokenSummaryMap;
       setrunningToken(Object.values(running));
      console.log("value is" ,Object.values(running));           
       }
   })
     );
 }
 const iconStyle = {
    color: 'green', // Change the color here
  };

return (<>

<div>
    <center><h4>MANASWINI</h4></center>
    <center><h5>Find Your Turn</h5></center>
        <br></br>
       
        
        {/* <div class='orientation' style={{display:'grid',gridTemplateColumns:'400px 400px',gridRowGap:'50px',gridColumnGap:'50px',marginLeft:'120px'}}> */}
            <div  style={{display:'flex', flexWrap:'wrap' }}> 
            {runningToken.map((t,key)=> {
                return(
                <div class='desk' key={key}>
                {
                    <div class='col-lg'>
                        
                            <div class="stats-small stats-small--1 card card-small ">
                            <center><span class='deskid'>{t.deskId} </span></center>
                                {/* <div class='card-body p-5 d-flex'> */}
                                     <div class='d-flex flex-column m-auto'>
                                         <div class='stats-small__data text-center'>  
                                            <br></br>

                                            <div style={{float:'left' ,display:'grid',gridTemplateColumns:'150px 150px',gridRowGap:'50px',paddingBottom:'30px'}}>
                                                <div class='col-lg'  style={{display:'inline-block' }}>                  
                                                    <div class="stats-small stats-small--1 card card-small" style={{width:'90%'}}>

                                                        <span  class='currenttoken'> {t.currentToken && t.currentToken != 999 ? t.currentToken : "-"}</span>
                                                        {/* <span class='stats-small__percentage stats-small__percentage--decrease currentToken' > Current Token</span> */}

                                                        <div className="stats-small__percentage currentToken">
                                                        <ExpandMoreIcon color='error' className="stats-small__percentage-.-decrease " />
                                                        <Typography variant="body1" color='error'  >Current Token</Typography>
                                                        </div>

                                                    </div>
                                                 </div>                     
                                                <div class='col-lg'  style={{display:'inline-block'}}>
                                                     <div class="stats-small stats-small--1 card card-small" style={{width:'90%'}}>
                                                       
                                                         {/* <span class='nexttoken' > {(t.nextToken && t.tokenStatus==='active') ? t.nextToken :"-" }</span>  */}
                                                         <span class='nexttoken' > {t.nextToken && t.nextToken != 999 ? t.nextToken :"-"}</span>
                                                        
                                                         {/* <span class='stats-small__percentage stats-small__percentage--increase nextToken' > Next Token</span> */}
                                                         <div className="stats-small__percentage nextTkn">
                                                          <ExpandMoreIcon className="stats-small__percentage--increase nextToken" style={iconStyle} />
                                                          <Typography variant="body1" style={iconStyle} >Next Token</Typography>
                                                         </div>
                                                     </div>
                                                </div>  
                                         </div>  
                                         <br></br>   
                                         <br></br>    
                                     </div>       
                                </div>                                           
                            </div>
                    </div>          
                    }
                </div>      
                );
              }
            )}
       </div>
    </div> 
    </>
    );
}

 export default TokenDashBoard;

 {/* {Token.map((e)=>{
       return (
       <Box component="span" 
       sx={{ p: 4, border: '2px solid grey' }}
       Name={e.Name} 
       Id={e.Id} 
       CurrentToken={e.CurrentToken} 
       NextToken={e.NextToken} 
       LastToken={e.LastToken}>
     </Box>
     );})} */}


 
