import { insuranceServices } from "../services";
import { SET_PAGE_HEADER } from "../../shared/constants";
export function addInsuranceMaster(data, resCb) {
  console.log("action modifyDoctor(data", data);
  return (dispatch) => {
    insuranceServices.addInsurance(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function modifyInsurance(data, resCb) {
  console.log("action modifyDoctor(data", data);
  return (dispatch) => {
    insuranceServices.modifyInsurance(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function getInsuranceMaster(resCb) {
  console.log("action getInsuranceMaster" , resCb);
  return (dispatch) => {
    insuranceServices.getInsuranceMaster().then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function deleteInsurance(data, resCb) {
  console.log("action deleteInsurance(data", data);
  return () => {
    insuranceServices.deleteInsurance(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}
//tpamster
export function gettpaMaster(resCb) {
  console.log("action getInsuranceMaster(data");
  return (dispatch) => {
    insuranceServices.getTpaMaster().then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}
export function addTpaMaster(data, resCb) {
  console.log("action modifyDoctor(data", data);
  return (dispatch) => {
    insuranceServices.addTpaMaster(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function modifyTpa(data, resCb) {
  console.log("action modifyTpa(data", data);
  return (dispatch) => {
    insuranceServices.modifyTpa(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}
export function deleteTpa(data, resCb) {
  console.log("action deleteTpa(data", data);
  return () => {
    console.log("action return (dispatch)", data);

    insuranceServices.deleteTpa(data).then(
      (user) => {
        console.log("Res", user);
        resCb(user);
      },
      (error) => {
        resCb(error.toString);
      }
    );
  };
}

export function setPageHeader(value) {
  return { type: SET_PAGE_HEADER, payload: value };
}
const insuranceActions = {
  addInsuranceMaster,
  getInsuranceMaster,
  modifyInsurance,
  deleteInsurance,

  gettpaMaster,
  addTpaMaster,
  modifyTpa,
  deleteTpa,

//   setPageHeader,
};

export default insuranceActions;
