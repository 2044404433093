import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import { Store } from "../../../flux";
import {sideBarNavItemsServices} from "../../../data/index";
import { Dispatcher, Constants } from "../../../flux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: sideBarNavItemsServices.getSideBarNavItems()
    };
    this.handleRefreshPage = this.handleRefreshPage.bind(this);

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    this.handleRefreshPage();
    console.log("Main sidebar componentWillMount:");
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: sideBarNavItemsServices.getSideBarNavItems()
    });
    console.log("Main sidebar onchange:");
  }

  handleRefreshPage() {
    console.log("handleRefreshpage");
    Dispatcher.dispatch({
      actionType: Constants.REFRESH_BAR
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
    
      this.state.menuVisible && "open"
    );

    return (
      <div
        tag="aside"
        id="sidebar"
        className={classes}
       
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        
        <SidebarNavItems />
      </div>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;