import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from '../../core/actions';
import { insuranceActions } from '../../core/actions';
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormControl, Select, Radio, RadioGroup, MenuItem } from '@mui/material';

import Components from '..';


function InsuranceProcedureInput(props) {
  const dispatch = useDispatch();
  const [isReadonly, setIsReadonly] = useState(false);
  const [ServerRes, setServerRes] = useState(
    { success: '', error: '', }
  );
  const { insurance } = "insurance";
  const [selectedRadio, setSelectedRadio] = useState()
  const [insuranceList, setInsuranceList] = useState()
  const [tpa, setTpa] = useState([]);
  const [companyname, setCompanyName] = useState([]);
  const [insuranceDetails, setInsuranceDetails] = useState({
    patientId: props.patientInfo.patientId,
    visitId: props.patientInfo.visitId,
    type: props.insurancetype,
    policyNum: "",
    iName: "",
    iOrg: "",
    tpa: "",
    description: "",
    uploadId: "",
    status: "",
    claimNum: "",
    amount: "",
    invoiceNum: ""


  });

  const [formErrors, setFormErrors] = useState({
    policyNum: "",
    iName: "",
    iOrg: "",
    amount: "",
    status: "",
    claimNum: "",
  });

  useEffect(() => {

    console.log("insurance props:", props);
    getInsMaster();
    getTtpMaster();

    if (props.mode == 'edit') {
      setFormDetails();
    }

  }, [])

  const getInsMaster = () => {
    dispatch(insuranceActions.getInsuranceMaster((resCb) => {
      if (resCb) {
        console.log("getInsuranceMaster", resCb.masterinsurancelist);

        console.log("response from api : ", resCb);
        let company = [];
        let companylist = resCb.masterinsurancelist;
        if (companylist && companylist != null) {
          for (let comp of companylist) {
            console.log("comp", comp.companyName);

            company.push(comp)
            //company.push(comp.id)
          }
          setCompanyName(company);
        }
      }
    })
    );
  }

  const getTtpMaster = () => {
    dispatch(
      insuranceActions.gettpaMaster((resCb) => {
        if (resCb) {
          console.log("getTpaMaster", resCb.tpaMasterList);
          console.log("response from api : ", resCb);
          let tpainsurance = [];
          let tpalist = resCb.tpaMasterList;
          if (tpalist && tpalist != null) {
            for (let tpa of tpalist) {
              tpainsurance.push(tpa);
              // insuranceDetails['tpa'] = tpa.id;
            }
            setTpa(tpainsurance);
          }
        }
      })
    );
  }

  const setFormDetails = () => {
    let insurancelistlocal = props.insurancelists;
    console.log("insurancelistlocal", insurancelistlocal);

    if (insurancelistlocal && props.insurancetype === 'Basic') {
      for (let insure of insurancelistlocal) {
        if (insure.type === 'Basic') {
          insuranceDetails['policyNum'] = insure.policyNum;
          insuranceDetails['iName'] = insure.iName;
          insuranceDetails['iOrg'] = insure.iOrg;
          insuranceDetails['tpa'] = insure.tpa;
          insuranceDetails['description'] = insure.description;
          insuranceDetails['id'] = insure.id;
          insuranceDetails['udt'] = "";
        }
      }
      setInsuranceDetails({ ...insuranceDetails })
      console.log("insurance after set", insuranceDetails);
    }


    if (props.insurancelists && props.insurancetype === 'Claim Request') {
      for (let insure of props.insurancelists) {
        if (insure.type === 'InvoiceGen') {
          setIsReadonly(true)
          insuranceDetails['invoiceNum'] = insure.invoiceNum;
          insuranceDetails['amount'] = insure.amount;
        }
      }
      setInsuranceDetails({ ...insuranceDetails })

    }

    if (props.insurancelists && props.insurancetype === 'Pre Auth Request') {
      for (let insure of props.insurancelists) {
        if (insure.type === 'Pre Auth Request') {
          setIsReadonly(true)
          insuranceDetails['description'] = insure.description;
          insuranceDetails['amount'] = insure.amount;
        }
      }
      setInsuranceDetails({ ...insuranceDetails })

    }

    if (props.insurancelists && props.insurancetype === 'Pre Auth Response') {
      for (let insure of props.insurancelists) {
        if (insure.type === 'Pre Auth Response') {
          setIsReadonly(true)
          insuranceDetails['description'] = insure.description;
          insuranceDetails['amount'] = insure.amount;
          insuranceDetails['claimNum'] = insure.claimNum;
          insuranceDetails['status'] = insure.status;
          setSelectedRadio(insure.status);
        }
      }
      setInsuranceDetails({ ...insuranceDetails })

    }

  }
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log("finalData", insuranceDetails);
    Object.keys(insuranceDetails).forEach((key) => {
      validateField(key, insuranceDetails[key])
    })
    validateForm(event);


  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = insuranceDetails;
    console.log("Basic Info", basicInfo)
    console.log("value:", value);
    ;
    switch (fieldName) {
      case "policyNum":
        if (props.insurancetype === 'Basic') {
          fieldValidationErrors.policyNum = value.length > 0 ? '' : 'Please Enter Policy Number';
          basicInfo.policyNum = value !== '' ? value : '';
        }

        break;
      case "iName":
        if (props.insurancetype === 'Basic') {

          fieldValidationErrors.iName = value.length > 0 ? '' : 'Please Enter Insurer Name';
          basicInfo.iName = value !== '' ? value : '';
        }
        break;

      case "amount":
        if (props.insurancetype === "Pre Auth Request" || props.insurancetype === "Pre Auth Response") {
          fieldValidationErrors.amount = value != "" > 0 ? '' : 'Please Enter Amount';
          basicInfo.amount = value !== '' ? value : '';
        }

        break;

      case "claimNum":
        if (props.insurancetype === "Pre Auth Response") {
          fieldValidationErrors.claimNum = value.length > 0 ? '' : 'Please Enter Claim Number';
          basicInfo.claimNum = value !== '' ? value : '';
        }
        break;

      case "status":
        if (props.insurancetype === "Pre Auth Response") {

          fieldValidationErrors.status = value.length > 0 ? '' : 'Please Select Status';
          basicInfo.status = value !== '' ? value : '';
        }
        break;
      default:
        break
    }
    console.log("fieldValidationErrors:", fieldValidationErrors);
    setFormErrors({ ...fieldValidationErrors });
  }

  const validateForm = (event) => {
    event.preventDefault();
    let formValid =
      formErrors.policyNum === "" &&
      formErrors.iName === "" &&
      formErrors.status === "" &&
      formErrors.claimNum === "" &&
      formErrors.amount === "";
    console.log("formValid:", formValid);
    if (formValid) {
      if (insuranceDetails && insuranceDetails['id']) {
        let data = []
        var ServerResVal = ServerRes;
        data.push(insuranceDetails)
        dispatch(patientActions.updateInsuranceProcedure(data, (resCb) => {
          console.log("insuranceDetails:", insuranceDetails);
          if (resCb) {

            if (resCb.successMessages) {
              props.onModalClose({ success: "Insurance details updated succesfully" })
              // ServerResVal.success = "Insurance details saved succesfully"
            }
            else {
              // ServerResVal.error = "Failed to save Insurance details";
              props.onModalClose({ error: "Failed to update Insurance details" })
            }


            setServerRes({ ...ServerResVal });
            console.log("resCb");
            //  props.onModalClose()
            insuranceDetails.policyNum = ""
            insuranceDetails.iName = ""
            insuranceDetails.iOrg = ""
            insuranceDetails.tpa = ""
            insuranceDetails.description = ""
            insuranceDetails.uploadId = ""
            insuranceDetails.status = ""
            insuranceDetails.claimNum = ""
            insuranceDetails.amount = ""
            insuranceDetails.invoiceNum = ""
          }
        }));

      } else {
        dispatch(patientActions.addInsuranceProcedure(insuranceDetails, (resCb) => {
          console.log("insuranceDetails:", insuranceDetails);
          var ServerResVal = ServerRes;
          if (resCb) {
            if (resCb.successMessages) {
              props.onModalClose({ success: "Insurance details saved succesfully" })
              // ServerResVal.success = "Insurance details saved succesfully"
            }
            else {
              // ServerResVal.error = "Failed to save Insurance details";
              props.onModalClose({ error: "Failed to save Insurance details" })
            }

            setServerRes({ ...ServerResVal });
            console.log("resCb");
            //   props.onModalClose()
            insuranceDetails.policyNum = ""
            insuranceDetails.iName = ""
            insuranceDetails.iOrg = ""
            insuranceDetails.tpa = ""
            insuranceDetails.description = ""
            insuranceDetails.uploadId = ""
            insuranceDetails.status = ""
            insuranceDetails.claimNum = ""
            insuranceDetails.amount = ""
            insuranceDetails.invoiceNum = ""
          }
        }));
      }

    } else {
      setTimeout(() => {
        var errors = document.querySelectorAll(".is-invalid");
        console.log("errors:", errors);
        if (errors.length > 0) {
          errors[0].focus();
        }
      }, 500);

    }
  }

  const handleInputChange = (name, value) => {
    formErrors[name] = "";
    console.log("changed", value);
    let insurancelocal = insuranceDetails;
    insurancelocal[name] = value;

    setInsuranceDetails({ ...insuranceDetails, name: value })
    console.log("polynumber:", insuranceDetails);
  }

  const closeModal = (e) => {
    props.onModalClose()
  }

  const addfile = (event) => {
    console.log("images:", event.target.files);
    let images = [{}]
  }

  const onValueChange = (value) => {
    setSelectedRadio(value)
    console.log("selectedOptionRadio", value);
    insuranceDetails['status'] = value;
  }

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }

  const closeAttachment = (e) => {
    closeModal();
  }

  return (<>




    {/* <Modal className='insurance-procedure bg-none' id="insurance-modal" open={true}  toggle={closeModal}> */}
    <Dialog
      className="insurance-procedure bg-none"
      id="insurance-modal"
      open={true} // Assuming the modal is always open
      onClose={closeModal}
      disableEnforceFocus
    >

      {ServerRes.success && (
        // <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
        <Alert severity="success" className="alert-div">
          {ServerRes.success}{" "}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(event) => closeAlert(event)}


          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Alert>
      )}
      {ServerRes.error && (
        // <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
        <Alert severity="error" className="alert-div">
          {ServerRes.error}{" "}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(event) => closeAlert(event)}

          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Alert>
      )}


      <DialogTitle className="py-2">
        {props.insurancetype}
        {props.insurancetype && props.insurancetype != "Upload Document" ?
        <IconButton
          className="close-model"
          aria-label="close"
          onClick={(e) => { closeModal(e) }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        :''}
      </DialogTitle>


      <div className="px-3 py-2 modal-subsection">
        <div className="d-flex flex-column">
          <div className='d-flex'>
            <div className='w-50 ml-3' style={{ fontSize: '13px' }}>
              {/* <label className="label ">Patient: </label> */}
              <div>
                <div>
                  <span>Name:&nbsp;</span>
                  <span style={{ textTransform: `capitalize` }}>{props.patientInfo.fname}&nbsp;{props.patientInfo.lname}</span>
                </div>
                <div>
                  <span>Phone No:&nbsp;{props.patientInfo.phno}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalBody id="appointment-body" className="py-1 justify-content-between h-100 "> */}
      <DialogContent id="appointment-body" className="py-1 justify-content-between h-100">
        <div className="mb-3  position-relative">

          <div className="p-2 bg-none border-0 h-100">
            {/* <Row className="h-100">
                          <Col>
                            <Form className="mt-2">
                              
                              <Row form> */}
            < Grid container className="h-100">
              <Grid item xs={12}>
                <form className="mt-2">
                  <Grid container spacing={2}>
                    <>
                      {props.insurancetype && props.insurancetype === 'Basic' ?

                        <Grid item md={6} className="form-group">
                          <label htmlFor="policynumber" className='basicInf'>
                            PolicyNumber <span className='text-danger'>*</span>
                          </label>


                          <TextField
                            type="number"
                            id="polyname"
                            value={insuranceDetails.policyNum}
                            placeholder="Policy Num"
                            required
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("policyNum", event.target.value);
                            }}
                            error={formErrors.policyNum !== ""}
                            helperText={<span style={{ color: '#EC4147' }}>{formErrors.policyNum}</span>}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />



                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Basic' ?

                        <Grid item md={6} className="form-group">
                          <label htmlFor="insurance name" className='basicInf'>
                            Insurer Name <span className='text-danger'>*</span>
                          </label>
                          <TextField
                            id="iname"
                            value={insuranceDetails.iName}
                            placeholder="Insurer name"
                            onChange={(event) => {
                              handleInputChange("iName", event.target.value);
                            }}
                            error={formErrors.iName !== ""}
                            helperText={<span style={{ color: '#EC4147' }}>{formErrors.iName}</span>}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />


                        </Grid>
                        : ''}
                      {props.insurancetype && props.insurancetype === 'Basic' ?
                        //           <Col md="6" className="form-group">
                        //             <label htmlFor="iorg">Insurance Oragnization</label>
                        //             <select value={insuranceDetails.iOrg} name="selectType" className='w-100 form-control' onChange={(event) => {handleInputChange("iOrg", event.target.value)}}>
                        //     <option defaultValue={'selected Type'}>Select Type</option>
                        //     {companyname.map((options,index) => (
                        //     <option key={index} value={options.id}>{options.companyName}</option>
                        //     ))}

                        // </select>

                        //           </Col>
                        <Grid item md={6} className="form-group">
                          <label htmlFor="iorg">Insurance Organization</label>

                          <FormControl fullWidth class="insuranceDropdown" >
                            <Select
                              displayEmpty        // labelId="iorg-label"
                              value={insuranceDetails.iOrg}
                              name="selectType"
                              onChange={(event) => { handleInputChange("iOrg", event.target.value) }}
                              variant="outlined"
                            >
                              <MenuItem value="" >
                                Select Type
                              </MenuItem>
                              {companyname.map((options, index) => (
                                <MenuItem key={index} value={options.id}>
                                  {options.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>


                        </Grid>
                        : ''}



                      {props.insurancetype && props.insurancetype === 'Basic' ?
                        // <Col md="6" className="form-group">
                        //   <label htmlFor="insurane claim">Insurance Claim</label>
                        //   <select value={insuranceDetails.tpa} name="selectType" className='w-100 form-control' onChange={(event) => {handleInputChange("tpa", event.target.value)}}>
                        //     <option defaultValue={'selected Type'}>Select Type</option>
                        //     {tpa.map((options,index) => (
                        //     <option key={index} value={options.id}>{options.companyName}</option>
                        //     ))} 

                        //   </select>

                        // </Col>
                        <Grid item md={6} className="form-group">
                          <label htmlFor="insurane claim">Insurance Claim</label>
                          <FormControl fullWidth class="insuranceDropdown">
                            <Select
                              displayEmpty
                              value={insuranceDetails.tpa}
                              name="selectType"
                              className='w-100'
                              onChange={(event) => {
                                handleInputChange("tpa", event.target.value);
                              }}
                            >
                              <MenuItem value="" >
                                Select Type
                              </MenuItem>
                              {tpa.map((options, index) => (
                                <MenuItem key={index} value={options.id}>
                                  {options.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Basic' || props.insurancetype === 'Pre Auth Request' || props.insurancetype === 'Pre Auth Response'
                        || props.insurancetype === 'Claim Request' || props.insurancetype === 'Claim Approval' ?
                        // <Col md="6" className="form-group">
                        //   <label htmlFor="description">Description</label>
                        //   <FormInput
                        //     id="desc"
                        //   value={insuranceDetails.description}
                        //     placeholder="Add Description.."
                        //     autoComplete="off"
                        //     onChange={(event) => {
                        //     handleInputChange("description", event.target.value);
                        //     }}
                        //   // invalid={formErrors.dob === "" ? false :true }
                        //   />
                        //   {/* <div style={{ color: '#EC4147' }} >
                        //     <span>{formErrors.dob}</span>
                        //   </div> */}
                        // </Col>
                        <Grid item md={6} className="form-group">
                          <label htmlFor="description" className='basicInf'>Description</label>
                          <TextField
                            id="desc"
                            value={insuranceDetails.description}
                            placeholder="Add Description.."
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("description", event.target.value);
                            }}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />



                          {/* <FormHelperText style={{ color: '#EC4147' }}>
        {formErrors.dob}
      </FormHelperText> */}
                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Pre Auth Response' || props.insurancetype === 'InvoiceGen' || props.insurancetype === 'BillClear' ?

                        <Grid item md={6} className="form-group">
                          <label htmlFor="gender" className='basicInf'> Claim Number <span className='text-danger'>*</span></label>

                          <TextField
                            id="cnumber"
                            type="number"
                            value={insuranceDetails.claimNum}
                            placeholder="Claim number"
                            onChange={(event) => {
                              handleInputChange("claimNum", event.target.value);
                            }}
                            error={formErrors.claimNum !== ""}
                            helperText={<span style={{ color: '#EC4147' }}>{formErrors.claimNum}</span>}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />


                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Pre Auth Request' || props.insurancetype === 'Pre Auth Response' || props.insurancetype === 'InvoiceGen'
                        || props.insurancetype === 'Claim Approval' || props.insurancetype === 'Claim Request' ?

                        <Grid item md={6} className="form-group">
                          <label htmlFor="amount" className='basicInf'>Amount <span className='text-danger'>*</span></label>

                          <TextField
                            id="amnt"
                            type="number"
                            value={insuranceDetails.amount}
                            onChange={(event) => {
                              handleInputChange("amount", event.target.value);
                            }}
                            error={formErrors.amount !== ""}
                            helperText={<span style={{ color: '#EC4147' }}>{formErrors.amount}</span>}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />


                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Claim Request' || props.insurancetype === 'BillClear' ?
                        // <Col md="6" className="form-group">
                        //   <label htmlFor="invoice number">Invoice Number</label>
                        //   <FormInput
                        //     id="inumber"
                        //     type="number"
                        //     value={insuranceDetails.invoiceNum}
                        //     autoComplete="off"    
                        //     readOnly={isReadonly}
                        //     onChange={(event) => {
                        //       handleInputChange("invoiceNum", event.target.value);
                        //     }}
                        //   />

                        // </Col>
                        <Grid item md={6} className="form-group">
                          <label htmlFor="invoice number">Invoice Number</label>

                          <TextField
                            id="inumber"
                            type="number"
                            value={insuranceDetails.invoiceNum}
                            onChange={(event) => {
                              handleInputChange("invoiceNum", event.target.value);
                            }}
                            error={formErrors.invoiceNum !== ""}
                            helperText={<span style={{ color: '#EC4147' }}>{formErrors.invoiceNum}</span>}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "35px" },

                            }}
                          />

                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'Pre Auth Response' || props.insurancetype === 'Claim Approval' ?
                        // <Col md="12" className="form-group">
                        //   <p className="mb-2">Status:</p>
                        //   <FormRadio

                        //     name="approve"
                        //    checked={selectedRadio === "approve"}
                        //     onChange={(event) => {onValueChange('approve')}}
                        //   >
                        //     Approve{" "}
                        //   </FormRadio>
                        //   <FormRadio

                        //     name="deny"
                        //     checked={selectedRadio === "deny"}
                        //     onChange={(event) => {onValueChange('deny')}}
                        //   >
                        //     Deny{""}
                        //   </FormRadio>

                        //   <div style={{ color: '#EC4147' }} >
                        //     <span>{formErrors.status}</span>
                        //   </div>
                        // </Col>
                        <Grid item md={12} className="form-group">
                          <p className="mb-2">Status:</p>
                          <RadioGroup row value={selectedRadio} onChange={(event) => onValueChange(event.target.value)}>
                            <FormControlLabel
                              value="approve"
                              control={<Radio />}
                              label="Approve"
                              checked={selectedRadio === 'approve'}
                              onChange={(event) => onValueChange(event.target.value)}
                            />
                            <FormControlLabel
                              value="deny"
                              control={<Radio />}
                              label="Deny"
                              checked={selectedRadio === 'deny'}
                              onChange={(event) => onValueChange(event.target.value)}
                            />
                          </RadioGroup>
                          <div style={{ color: '#EC4147' }}>
                            <span>{formErrors.status}</span>
                          </div>
                        </Grid>
                        : ''}

                      {props.insurancetype && props.insurancetype === 'BillClear' ?
                        // <Col md="12" className="form-group">
                        //   <p className="mb-2">Status:</p>
                        //   <FormRadio

                        //     name="failed"
                        //     checked={selectedRadio === "failed"}
                        //     onChange={(event) => {onValueChange('failed')}}
                        //   >
                        //     Failed{" "}
                        //   </FormRadio>
                        //   <FormRadio

                        //     name="noc"
                        //     checked={selectedRadio === "noc"}
                        //     onChange={(event) => {onValueChange('noc')}}
                        //   >
                        //   Noc{""}
                        //   </FormRadio>
                        // </Col>
                        <Grid item md={12} className="form-group">
                          <p className="mb-2">Status:</p>
                          <RadioGroup
                            className="radioStatus"
                            row
                            value={selectedRadio}
                            onChange={(event) => onValueChange(event.target.value)}
                          >
                            <FormControlLabel
                              value="failed"
                              control={<Radio />}
                              label="Failed"
                              checked={selectedRadio === 'failed'}
                              onChange={(event) => onValueChange(event.target.value)}
                            />
                            <FormControlLabel
                              value="noc"
                              control={<Radio />}
                              label="Noc"
                              checked={selectedRadio === 'noc'}
                              onChange={(event) => onValueChange(event.target.value)}
                            />
                          </RadioGroup>
                        </Grid>


                        : ''}
                    </>
                  </Grid>

                </form>

                {props.insurancetype != "Upload Document" ?
                  <div className="fbtn mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={(event) => handleSubmit(event)}
                      style={{ textTransform: "none" }}
                    >
                      Save
                    </Button>
                  </div>
                  : ""}
                <br />
              </Grid>
            </Grid>
          </div>
        </div>

        {props.insurancetype === 'Upload Document' ?
          <Components.Attachments
            patientDetail={props.patientInfo}
            InsuranceList={props.insurancelists}
            uploadType={'insurance'}
            openAttachment={(event) => closeAttachment(event)}
          />
          : ''}

      </DialogContent>




      {/* </Modal> */}
    </Dialog>

  </>
  );

}
export default InsuranceProcedureInput;

