import { DEPT_DOCTOR_DETAILS ,DEPT_DOCTOR_DETAILS_FAILURE,SET_DOCTOR_DETAILS,ZOOM_REVIEW_NOTES,NEWPROCEDURE_SAVE_SUCCESS,TEMPLATE_DETAILS,SET_PATIENT_PROCEDURELIST,DOC_INFO,GET_COMPOSITIONID_DETAILS, PATIENT_SECTION} from "../../shared/constants";

const doctorDetailsState = {
  doctorsDetail: [],
  zoomReview:[],
  procedure:[],
  templates:[],
  patientprocedurelist:[],
  docInfo:{},
  compositions:[],
};

function setDoctorDetailsState(state = doctorDetailsState, action) {
  switch (action.type) {
    case DEPT_DOCTOR_DETAILS:
      console.log("DEPT_DOCTOR_DETAILS", action.payload);
      return Object.assign({}, state, {
        doctorsDetail: action.payload,
      });
    case SET_DOCTOR_DETAILS:
      console.log("SET_DOCTOR_DETAILS", action.payload);
      return Object.assign({}, state, {
        doctor: action.payload,
      });
    case ZOOM_REVIEW_NOTES:
      console.log("ZOOM_REVIEW_NOTES", action.payload);
      return Object.assign({}, state, {
        zoomReview: action.payload,
      });
    case NEWPROCEDURE_SAVE_SUCCESS:
      console.log("NEWPROCEDURE_SAVE_SUCCESS", action.payload);
      return Object.assign({}, state, {
        procedure: action.payload,
      });
    case SET_PATIENT_PROCEDURELIST:
      console.log("SET_PATIENT_PROCEDURELIST :",action.payload);
      return  Object.assign({}, state, {
        patientprocedurelist: action.payload,
      });
    case TEMPLATE_DETAILS:
      console.log("TEMPLATE_DETAILS", action.payload);
      return Object.assign({}, state, {
        templates: action.payload,
      });   
    case GET_COMPOSITIONID_DETAILS:
      console.log("GET_COMPOSITIONID_DETAILS", action.payload);
      return Object.assign({}, state, {
        compositions: action.payload,
      }); 
    case DOC_INFO:
        console.log("DOC_INFO", action.payload);
        return Object.assign({}, state, {
          docInfo: action.payload,
        }); 
    default:
      return state;
  }
}

export default setDoctorDetailsState;
